import React from "react";
import { useResultContext } from "../Contexts/LayoutContext";

const formData = new FormData();

const PanoramaImg = (props) => {
  const { detailFacilityId } = useResultContext();

  const handleUploadImg = (e) => {
    console.log(e.target.files[0].type)
    if (
      e.target.files[0].type === "image/jpg" ||
      e.target.files[0].type === "image/jpeg" ||
      e.target.files[0].type === "image/png"
    ) {
      formData.append("img", e.target.files[0]);
      if (props.facilityid !== "") {
        formData.append("facilityid", props.facilityid);
      } else if (detailFacilityId) {
        formData.append("facilityid", detailFacilityId);
      }
      formData.append("seq", props.seq);

      for (var key of formData.entries()) {
        console.log(key[0] + ", " + key[1]);
      }
    } else {
      alert("jpg, jpeg의 확장자 이미지만 등록할 수 있습니다.");
      e.target.value = null;
    }
  };

  return (
    <>
      <input
        type="file"
        multiple
        accept="image/jpeg, image/jpg, image/png"
        className="p-4px bottom-0"
        name="panoramaImg"
        onChange={(e) => handleUploadImg(e)}
      />
    </>
  );
};

export { PanoramaImg, formData };
