import React, { useEffect, useState } from "react";
import { Layout } from "../../pages/facilitylayout/layout.js";
import { Fms } from "../../pages/fms/fms.js";
import Evaluation from "../../pages/evaluation.js";
import ResearchCard from "../information/researchCard.js";
import Report from "../../pages/report/report.js";
import { CardWithText } from "../information/cardWithText.js";
import PrivateRoute from "../lib/privateRoute";
import { Information } from "../information/information.js";
import { useResultContext } from "../../Contexts/LayoutContext.jsx";
import Supervision from "../users/supervision.js";
import Prospecter from "../users/prospecter.js";
import Prosedit from "../users/prosedit.js";

function TotalWrap() {
  const { detailFacilityId, returnPrjDivide } = useResultContext();
  const [UFI, setUFI] = useState("");
  const [UFD, setUFD] = useState("");

  useEffect(() => {
    setUFI(detailFacilityId);
    setUFD(returnPrjDivide);
  }, [detailFacilityId]);

  return (
    <>
      <div className="container row m-auto">
        <Layout />
        <PrivateRoute
          component={(props) => <Fms {...props} ID={UFI} />}
          path="/fms"
          exact
        />
        <PrivateRoute
          component={(props) => <Information {...props} ID={UFI} />}
          path="/information"
          exact
        />
        <PrivateRoute
          path="/evaluation"
          exact
          component={(props) => <Evaluation {...props} ID={UFI} UFD={UFD} />}
        />
        <PrivateRoute
          component={ResearchCard}
          path="/information/researchCard"
          exact
        />
        <PrivateRoute
          component={(props) => <Report {...props} ID={UFI} />}
          path="/report"
          exact
        />
        <PrivateRoute
          component={(props) => <Supervision {...props} />}
          path="/supervision"
          exact
        />
        <PrivateRoute
          component={(props) => <Prospecter {...props} />}
          path="/supervision/prospecter"
          exact
        />
        <PrivateRoute
          component={(props) => <Prosedit {...props} />}
          path="/supervision/prospecterEdit/:no"
          exact
        />
        <PrivateRoute
          component={(props) => <CardWithText {...props} ID={UFI} />}
          path="/information/cardWithText"
          exact
        />
      </div>
    </>
  );
}
export { TotalWrap };
