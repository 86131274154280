import React, { useState } from "react";
import { Link } from "react-router-dom";
import { registerLocale } from "react-datepicker";
import ko from "date-fns/locale/ko";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";

registerLocale("ko", ko);

function Deactive(props) {
  const [edit, setEdit] = useState(false);
  const [ap, setAp] = useState({
    id: props.id,
    idx: props.idx,
    name: props.name,
    phone: props.phone,
    project: props.project,
    project_id: props.project_id,
    pw: props.pw,
    startdate: props.startdate,
    enddate: props.enddate,
    active: props.active,
  });
  const handleEdit = (e) => {
    setEdit(!edit);
  };

  return (
    <>
      <tr className="bg-gray">
        <td className="text-red">비활성</td>
        <td>{props.name}</td>
        <td>{props.phone}</td>
        <td>{props.id}</td>
        <td>{props.pw}</td>
        <td>
          {moment(props.startdate).format("yyyy.MM.DD")}~
          {moment(props.enddate).format("yyyy.MM.DD")}
        </td>
        <td>
          <Link
            to={{
              pathname: `/supervision/prospecterEdit/${ap.id}`,
              state: {
                id: props.id,
                idx: props.idx,
                name: props.name,
                phone: props.phone,
                project: props.project,
                project_id: props.project_id,
                pw: props.pw,
                startdate: props.startdate,
                enddate: props.enddate,
                active: props.active,
              },
            }}
          >
            <button className="btn btn-purple" onClick={handleEdit}>
              수정
            </button>
          </Link>
        </td>
      </tr>
    </>
  );
}

export default Deactive;
