import React from "react";
import { useResultContext } from "../Contexts/LayoutContext";

const formData_elevation = [];

const ElevationImg = (props) => {
    
    console.log(props);
    const { detailFacilityId } = useResultContext();

    let elevation = props.elevation;
    let arr = [];

    for (let i = 0; i < elevation; i++) {
        arr[i] = [i];
    }
    
    const handleUploadImg = (e) => {
        
        const formData = new FormData();
        if (e.target.files[0].type === "image/jpg" || e.target.files[0].type === "image/jpeg" || e.target.files[0].type === "image/png" ) {
            formData.append("img", e.target.files[0]);
            formData.append("floor", String(Number(e.target.name)));
            console.log(e.target.files[0]);
            console.log(String(Number(e.target.name)));
            
            if (props.facilityid !== "") {
                formData.append("facilityid", props.facilityid);
            } else if (detailFacilityId) {
                formData.append("facilityid", detailFacilityId);
            }
            formData_elevation.push(formData);
            
            for (var key of formData.entries()) {
                console.log(key[0] + ", " + key[1]);
            }
            console.log(formData_elevation);

        } else {
            alert("jpg, jpeg의 확장자 이미지만 등록할 수 있습니다.");
            e.target.value = null;
        }
    };

    const result = [];

    for (let i = 0; i < arr.length; i++) {
        result.push(
            <li className="d-inline-flex" key={arr[i]}>
                <div style={{ lineHeight: "34px", whiteSpace: "nowrap" }}>
                    {(999-i) === 999 && "정면도"}
                    {(999-i) === 998 && "우측면도"}
                    {(999-i) === 997 && "배면도"}
                    {(999-i) === 996 && "좌측면도"}
                </div>
                <input
                    type="file"
                    multiple
                    accept="image/jpeg, image/jpg, image/png"
                    className="p-4px"
                    name={999-i}
                    id={"elevationfile" + (999-i)}
                    onChange={(e) => handleUploadImg(e)}
                    key={arr[i]}
                />
            </li>
        );
    }

    return <>{result}</>;
};

export { ElevationImg, formData_elevation };
