import React, { useEffect, useState, useRef, useCallback } from "react";
import DatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";
import ko from "date-fns/locale/ko";
import { COMPANY_HWP_SERVER } from "./../../config/app-settings.js";
import moment from "moment";
import { result_prj, isSearched } from "../../pages/facilitylayout/layout";
import { useResultContext } from "../../Contexts/LayoutContext.jsx";
import CircularProgress from "@mui/material/CircularProgress";
import "react-datepicker/dist/react-datepicker.css";
import "bootstrap/dist/css/bootstrap.css";
import styles from "./../../index.css";
import fetchData from "../../utils/api";
import {
  selectColor,
  drawArc,
  drawDiamond,
  drawHexagon,
  drawRectangle,
  drawStar,
  drawTriangle,
} from "../../utils/canvas";
import ProgressBar from "react-bootstrap/ProgressBar";
import { formControlClasses } from "@mui/material";
import { responsiveProperty } from "@mui/material/styles/cssUtils";

const URL = process.env.REACT_APP_BACKEND_HOST;
const REPORT_URL = URL + "/report/make/result";
const API_POSI_IMG = URL + "/project/position_list";
const API_PANO_IMG = URL + "/project/panorama_list";
const API_CHECK_UPDATE = URL + "/project/update/checkpoint";
const API_CHECK = URL + "/project/checkpoint";
const API_HWP_REPORT = COMPANY_HWP_SERVER + "projects/";

registerLocale("ko", ko);

const token = "bearer " + sessionStorage.getItem("token");

// 프로젝트 조회 함수
async function getProjectInfo(facilityid) {
  const projectInfoURL = URL + "/project/info";
  const response = await fetchData(
    projectInfoURL,
    "GET",
    ["facilityid"],
    [facilityid]
  );
  return response;
}

// 캡처용 도면 이미지 조회 함수
async function getPlanImg(facilityid) {
  const planImgURL = URL + "/project/plan_list";
  const response = await fetchData(
    planImgURL,
    "GET",
    ["facilityid"],
    [facilityid]
  );
  return response;
}

// 캡처용 조사망도 조사좌표 조회 함수
async function getPlanCoordinate(facilityid, floor, type) {
  const coordinateURL = URL + "/project/list/planCoordinate";
  const params = ["facilityid", "floor", "type"];
  const data = [facilityid, floor, type];
  const response = await fetchData(coordinateURL, "GET", params, data);
  return response;
}

// 보고서 생성 현황 조회
async function getReportProgress(facilityid) {
  const progressURL = URL + "/report/view/progress";
  const response = await fetchData(progressURL, "GET", ["id"], [facilityid]);
  return response[0];
}

async function postCapturedImg(data) {
  const saveCapturedImgURL = URL + "/report/capture/image";
  const response = await fetchData(saveCapturedImgURL, "POST", 0, data);
  return response;
}

async function deleteCapturedImg(projectid) {
  const deleteCapturedImgURL = URL + "/report/delete/capturedImg";
  const params = ["projectid"];
  const data = [projectid];
  const response = await fetchData(deleteCapturedImgURL, "GET", params, data);
  return response;
}

// 초단위 대기
function sleep(sec) {
  return new Promise((resolve) => setTimeout(resolve, sec * 1000));
}

function Report() {
  const { detailFacilityId, returnPrjDivide, returnPrjName, returnPrjAddress } =
    useResultContext();

  const [panoImg, setPanoImg] = useState("");
  const [posiImg, setPosiImg] = useState("");
  const [submitDate, setSubmitDate] = useState(null);
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [disableMakeBtn, setDisableMakeBtn] = useState(false);
  const [result, setResult] = useState({ time: "", name: "" });
  const [downLoadBtn, setDownLoadBtn] = useState(false);
  const downLoadBtnRef = useRef(false);
  // 캔버스로 조사망도 그리기
  const [isCaptureStart, setIsCaptureStart] = useState(false);
  const [points, setPoints] = useState([]);
  const [isCaptured, setIsCaptured] = useState({
    inspection_plan_captured: false,
  });
  const [webCheck, setWebCheck] = useState({
    equipments: false,
    inspect_result: false,
    project_chief: false,
    panorama: false,
    position: false,
    updated: false,
  });
  const [mobileCheck, setMobileCheck] = useState({
    arc_inspect: false,
    carbon_inspect: false,
    conc_inspect: false,
    crack_inspect: false,
    iron_inspect: false,
    slope_inspect: false,
  });

  const [reportCheck, setReportCheck] = useState({
    arc_report: 0,
    carbon_report: 0,
    conc_report: 0,
    crack_report: 0,
    iron_report: 0,
    slope_report: 0,
  });

  const [diffCheck, setDiffCheck] = useState({
    remuneration: false,
    structural_result: false,
  });

  const [updated, setUpdated] = useState();
  const [haveReport, setHaveReport] = useState(false);
  const [reportFileName, setReportFileName] = useState("");
  const reportFileNameRef = useRef("");
  const [progressBar, setProgressBar] = useState(0);
  const [progressText, setProgressText] = useState("준비중");
  const [captureBar, setCaptureBar] = useState(0);
  const [captureText, setCaptureText] = useState("조사망도 생성 중");
  const [reportDone, setReportDone] = useState(0);
  const [imgX, setImgX] = useState("");
  const [imgY, setImgY] = useState("");
  const progressBarRef = useRef(0);
  const progressTextRef = useRef("준비중");
  const captureBarRef = useRef(0);
  const reportDoneRef = useRef(0);
  const canvasRef = useRef(null);
  const contextRef = useRef(null);
  const CAPTURE_TERM = 0.5;

  // 조사망도 그리는 함수, researchCard.js 와 중복되므로 통합 필요
  const drawPoint = (type, i, centerPositions) => {
    const ratio_x = 740 / imgX;
    const ratio_y = 523 / imgY;
    const x = points[i][1] * ratio_x;
    const y = points[i][2] * ratio_y;
    const index = points[i][3];

    // 도형 그리기 전에 겹침을 검사하고 조정
    const adjustedPosition = adjustPosition(x, y, centerPositions);
    // 중심 위치 저장
    centerPositions.push({ x: adjustedPosition.x, y: adjustedPosition.y });

    // 도형별 크기 정하기
    const arc_size = 8;
    const triangle_size = 10;
    const rectangle_size = 5;
    const hexagon_size = 7;
    const dia_size = 7;
    const star_size = 6;
    // 색상 설정 (현재 기본 빨강색)
    const color = selectColor(type);
    let ctx = contextRef.current;
    ctx.beginPath();
    ctx.fillStyle = color;
    // 조사 타입별 모양 설정
    if (type === 1 || type > 6) {
      ctx = drawArc(ctx, adjustedPosition.x, adjustedPosition.y, arc_size);
    } else if (type === 2) {
      ctx = drawTriangle(ctx, adjustedPosition.x, adjustedPosition.y, triangle_size);
    } else if (type === 3) {
      ctx = drawHexagon(ctx, adjustedPosition.x, adjustedPosition.y, hexagon_size);
    } else if (type === 4) {
      ctx = drawRectangle(ctx, adjustedPosition.x, adjustedPosition.y, rectangle_size);
    } else if (type === 5) {
      ctx = drawDiamond(ctx, adjustedPosition.x, adjustedPosition.y, dia_size);
    } else if (type === 6) {
      ctx = drawStar(ctx, adjustedPosition.x, adjustedPosition.y, 5, star_size);
    }
    // 도형 채우기 및 그리기 종료
    ctx.fill();
    ctx.closePath();
    ctx.font = "bold 12px apple";
    ctx.fillStyle = "black";
    ctx.textAlign = "center";       // 가로 중앙 정렬
    ctx.textBaseline = "middle";    // 세로 중앙 정렬
    ctx.fillText(index, adjustedPosition.x, adjustedPosition.y + 1);
  };

     // 켄버스 도형의 중심 위치를 조정하여 겹치지 않게 만드는 함수
  function adjustPosition(x, y, centers) {
    const radius = 7;
    const padding = 0; // 원 사이의 최소 간격
    
    let adjustedX = x;
    let adjustedY = y;

    for (const center of centers) {
        const distance = Math.sqrt((center.x - adjustedX) ** 2 + (center.y - adjustedY) ** 2);
        if (distance < radius + radius) {
            // 겹침을 감지하면 x축만 위치를 조정
            if(center.x - adjustedX < 0){
              adjustedX += (radius + radius + padding + (center.x - adjustedX))
            } else {
              adjustedX -= (radius + radius + padding - (center.x - adjustedX))
            }
        }
    }

    return { x: adjustedX, y: adjustedY };
  }

  // 캡처 시작 버튼
  const handleCapture = useCallback(async () => {
    console.log("------------ 캡처 START --------------")
    setIsCaptureStart(true);
    // 캡처 전 기존 데이터 삭제
    await deleteCapturedImg(detailFacilityId);
    // 새로 캡처할 데이터 생성 및 캡처 시작
    const planData = await getPlanImg(detailFacilityId);
    const plan_data = planData[0].results;
    for (const i of plan_data) {
      console.log(`------------ 캡처 i index ${i} --------------`)
      setPoints([]);
      // 조사 타입별 (6가지)
      const term = 100 / plan_data.length;
      setCaptureBar((captureBarRef.current += term));
      for (let j = 0; j < 6; j++) {
        console.log(`------------ 캡처 j index ${j} --------------`)
        
        setPoints([]);
        if (j === 0) {
          setCaptureText("균열 조사망도");
        } else if (j === 1) {
          setCaptureText("콘크리트강도 조사망도");
        } else if (j === 2) {
          setCaptureText("철근배근 조사망도");
        } else if (j === 3) {
          setCaptureText("부재제원 조사망도");
        } else if (j === 4) {
          setCaptureText("기울기 조사망도");
        } else if (j === 5) {
          setCaptureText("탄산화 조사망도");
        }
        const canvas = document.getElementById("plan");
        const ctx = canvas.getContext("2d");
        const background = new Image();
        let url_path = i.img.split("/");
        let result_path = url_path[url_path.length - 3] + "/" + url_path[url_path.length - 2] + "/" + url_path[url_path.length - 1];
        
        background.src = URL + "/" + result_path;
        background.crossOrigin = "Anonymous";
        background.onload = function () {
          ctx.drawImage(background, 0, 0, 740, 523);
          setImgX(background.width);
          setImgY(background.height);
        };
        const coordinateData = await getPlanCoordinate(detailFacilityId, i.floor, j + 1);
        const coordinate_data = coordinateData[0].results;
        
        await sleep(CAPTURE_TERM);
        for (const c of coordinate_data) {
          let point = [];
          point.push(c["type"]);
          point.push(c["x"]);
          point.push(c["y"]);
          point.push(c["index"]);
          setPoints((points) => [...points, point]);
        }

        await sleep(CAPTURE_TERM);
        canvas.toBlob(async function (blob) {
          const formData = new FormData();
          const filename = i.floor > 0 ? (String(i.floor) + "_" + String(j + 1)) : ("0" + String(Math.abs(i.floor)) + "_" + String(j + 1));
          formData.append("facilityid", detailFacilityId);
          formData.append("img", blob, filename + ".jpeg");
          formData.append("floor", i.floor);
          formData.append("type", j + 1);
          await postCapturedImg(formData);
        });
      }
    }

    setIsCaptured({ inspection_plan_captured: true });

    const formData = new FormData();
    formData.append("facilityid", detailFacilityId);
    formData.append("inspection_plan_captured", 1);

    fetch(API_CHECK_UPDATE, {
      method: "PATCH",
      headers: { Authorization: token },
      body: formData,
    }).then((res) => {
      if (res.status / 100 === 2) {
        return res.json();
      } else {
        alert("알수없는 오류가 발생하였습니다. 새로고침 후 다시 시도해주세요.");
      }
    });
  });

  // 캡쳐 완료 작업지점 업데이트
  useEffect(() => {
    if (isCaptured.inspection_plan_captured && haveReport) {
      const formData = new FormData();

      formData.append("facilityid", detailFacilityId);
      formData.append("inspection_plan_captured", 1);

      fetch(API_CHECK_UPDATE, {
        method: "PATCH",
        headers: {
          Authorization: token,
        },
        body: formData,
      }).then((res) => {
        if (res.status / 100 === 2) {
          return res.json();
        } else {
          alert(
            "알수없는 오류가 발생하였습니다. 새로고침 후 다시 시도해주세요."
          );
        }
      });
    }
  }, [isCaptured.inspection_plan_captured, detailFacilityId, haveReport]);

  // 타입, 층 별 조사 좌표 데이터
  useEffect(() => {
    const canvas = canvasRef.current;
    const centerPositions = [];

    if (canvas !== null) {
      const context = canvas.getContext("2d");
      context.scale(1, 1);
      contextRef.current = context;

      for (let i = 0; i < points.length; i++) {
        drawPoint(points[i][0], i, centerPositions);
      }
    }
  }, [points]);

  //프로그레스바 진행
  const progress = (facilityid) => {
    setReportDone(0);
    const id = setInterval(frame, 500);
    async function frame() {
      if (progressBarRef.current >= 100) {
        clearInterval(id);
        const projectInfo = await getProjectInfo(detailFacilityId);
        const filename = projectInfo[0].results[0].report_file_name;
        setReportFileName((reportFileNameRef.current = filename));
        setReportDone((reportDoneRef.current = 1));
        if (reportDoneRef.current === 1) {
          setDownLoadBtn((downLoadBtnRef.current = true));
        }
      } else if (progressBarRef.current >= 0 && progressBarRef.current < 5) {
        setProgressBar((progressBarRef.current += 1));
      } else if (progressBarRef.current >= 5 && progressBarRef.current <= 15) {
        setProgressBar((progressBarRef.current += 1));
        setProgressText((progressTextRef.current = "사진파일 전송 중"));
      } else if (progressBarRef.current > 90) {
        setProgressBar((progressBarRef.current += 1));
        setProgressText((progressTextRef.current = "프로젝트 폴더 압축 중"));
      } else {
        const res = await getReportProgress(facilityid);
        if (
          res.cover === true &&
          progressBarRef.current <= 20 &&
          progressBarRef.current > 15
        ) {
          setProgressBar((progressBarRef.current += 1));
          setProgressText((progressTextRef.current = "표지 생성 중"));
        }
        if (
          res.index === true &&
          progressBarRef.current <= 25 &&
          progressBarRef.current > 20
        ) {
          setProgressBar((progressBarRef.current += 1));
          setProgressText(
            (progressTextRef.current = "목차 및 일반사항 생성 중")
          );
        }
        if (
          res.crack === true &&
          progressBarRef.current <= 30 &&
          progressBarRef.current > 25
        ) {
          setProgressBar((progressBarRef.current += 1));
          setProgressText((progressTextRef.current = "균열 보고서 생성 중"));
        }
        if (
          res.conc === true &&
          progressBarRef.current <= 40 &&
          progressBarRef.current > 30
        ) {
          setProgressBar((progressBarRef.current += 1));
          setProgressText(
            (progressTextRef.current = "콘크리트 강도조사 보고서 생성 중")
          );
        }
        if (
          res.iron === true &&
          progressBarRef.current <= 50 &&
          progressBarRef.current > 40
        ) {
          setProgressBar((progressBarRef.current += 1));
          setProgressText(
            (progressTextRef.current = "철근배근 보고서 생성 중")
          );
        }
        if (
          res.arc === true &&
          progressBarRef.current <= 60 &&
          progressBarRef.current > 50
        ) {
          setProgressBar((progressBarRef.current += 1));
          setProgressText(
            (progressTextRef.current = "부재제원 보고서 생성 중")
          );
        }
        if (
          res.slope === true &&
          progressBarRef.current <= 70 &&
          progressBarRef.current > 60
        ) {
          setProgressBar((progressBarRef.current += 1));
          setProgressText((progressTextRef.current = "기울기 보고서 생성 중"));
        }
        if (
          res.carbon === true &&
          progressBarRef.current <= 80 &&
          progressBarRef.current > 70
        ) {
          setProgressBar((progressBarRef.current += 1));
          setProgressText((progressTextRef.current = "탄산화 보고서 생성 중"));
        }
        if (
          res.result === true &&
          progressBarRef.current <= 90 &&
          progressBarRef.current > 80
        ) {
          setProgressBar((progressBarRef.current += 1));
          setProgressText((progressTextRef.current = "결과 보고서 생성 중"));
        }
      }
    }
  };

  //생성 전 검증단계
  useEffect(() => {
    if (detailFacilityId.length !== 0) {
      fetch(URL + "/project/info" + "?facilityid=" + detailFacilityId, {
        method: "GET",
        headers: {
          Authorization: token,
        },
      })
        .then((res) => {
          return res.json();
        })
        .then((res) => {
          if (res.results[0].report_file_name) {
                        setReportFileName(res.results[0].report_file_name);
          }
        });
      fetch(API_CHECK + "?facilityid=" + detailFacilityId, {
        method: "GET",
        headers: {
          Authorization: token,
        },
      })
        .then((res) => {
          if (res.status / 100 === 2) {
            return res.json();
          } else {
            alert(
              "알수없는 오류가 발생하였습니다. 새로고침 후 다시 시도해주세요."
            );
          }
        })
        .then((res) => {
          fetch(API_PANO_IMG + "?facilityid=" + result_prj, {
            method: "GET",
            headers: {
              "Content-Type": "application/json;charset=UTF-8",
              Authorization: token,
              Accept: "application/json;charset=UTF-8",
            },
          })
            .then((res) => {
              if (res.status / 100 === 2) {
                return res.json();
              } else {
                alert(
                  "알수없는 오류가 발생하였습니다. 새로고침 후 다시 시도해주세요."
                );
              }
            })
            .then((res) => {
              if (res.results[0] !== undefined) {
                setPanoImg(res.results[0]["img"]);
              }
            });

          fetch(API_POSI_IMG + "?facilityid=" + result_prj, {
            method: "GET",
            headers: {
              "Content-Type": "application/json;charset=UTF-8",
              Authorization: token,
              Accept: "application/json;charset=UTF-8",
            },
          })
            .then((res) => {
              if (res.status / 100 === 2) {
                return res.json();
              } else {
                alert(
                  "알수없는 오류가 발생하였습니다. 새로고침 후 다시 시도해주세요."
                );
              }
            })
            .then((res) => {
              if (res.results[0] !== undefined) {
                setPosiImg(res.results[0]["img"]);
              }
            });

          if (res.results.length !== 0) {
            setMobileCheck({
              arc_inspect: res.results[0].arc_inspect,
              carbon_inspect: res.results[0].carbon_inspect,
              conc_inspect: res.results[0].conc_inspect,
              crack_inspect: res.results[0].crack_inspect,
              iron_inspect: res.results[0].iron_inspect,
              slope_inspect: res.results[0].slope_inspect,
            });

            setIsCaptured({
              inspection_plan_captured: res.results[0].inspection_plan_captured,
            });

            setReportCheck({
              arc_report: res.results[0].arc_report,
              carbon_report: res.results[0].carbon_report,
              conc_report: res.results[0].conc_report,
              crack_report: res.results[0].crack_report,
              iron_report: res.results[0].iron_report,
              slope_report: res.results[0].slope_report,
            });

            setWebCheck({
              equipments: res.results[0].equipments,
              inspect_result: res.results[0].inspect_result,
              project_chief: res.results[0].project_chief,
              panorama: res.results[0].panorama,
              position: res.results[0].position,
              updated: res.results[0].updated,
            });

            if (returnPrjDivide === "안전점검") {
              setDiffCheck({
                remuneration: true,
                structural_result: true,
              });
            } else {
              setDiffCheck({
                remuneration: res.results[0].remuneration,
                structural_result: res.results[0].structural_result,
              });
            }
            setHaveReport(res.results[0].report);

            setUpdated(res.results[0].updated);
          }
        });
    }
  }, [detailFacilityId, returnPrjDivide]);

  const makeReport = (e) => {
    setDisableMakeBtn(true);
    progress(detailFacilityId);
    const data = JSON.stringify({
      facilityid: detailFacilityId,
      report_start_date: moment(dateRange[0]).format("yyyy.MM.DD"),
      report_end_date: moment(dateRange[1]).format("yyyy.MM.DD"),
      submit_date: moment(submitDate).format("yyyy.MM.DD"),
    });

    fetch(REPORT_URL, {
      method: "POST",
      headers: {
        Authorization: token,
      },
      body: data,
    })
      .then((res) => {
        return res.json();
      })
      .then((res) => {
        setResult({
          time: res.time,
          name: res.name,
        });
      });
  };

  const DisabledReportDownload = () => {
    return (
      <div className="col-6">
        <button
          disabled={true}
          type="button"
          className="btn btn-indigo d-block col-12"
          style={{
            fontSize: "16px",
            padding: "20px",
            marginTop: "20px",
          }}
        >
          보고서 다운로드
        </button>
      </div>
    );
  };

  const ChangeableMakeReportBtn = () => {
    return (
      <div className="col-6">
        <button
          type="button"
          className={`btn btn-warning col-12`}
          style={{
            fontSize: "16px",
            padding: "20px",
            marginTop: "20px",
          }}
          onClick={makeReport}
          disabled={disableMakeBtn}
        >
          보고서 생성
        </button>
      </div>
    );
  };

  const AlwaysDisabledMakeReport = () => {
    return (
      <div className="col-6">
        <button
          type="button"
          className="btn btn-warning col-12"
          style={{
            fontSize: "16px",
            padding: "20px",
            marginTop: "20px",
          }}
          disabled={true}
        >
          보고서 생성
        </button>
      </div>
    );
  };

  const ReportDownload = () => {
    return (
      <div className="col-6">
        <a
          href={URL + "/uploadImg/uploadFile/report/" + detailFacilityId + "/" + reportFileName + ".zip"}
          type="button"
          className="btn btn-indigo d-block col-12"
          style={{
            fontSize: "16px",
            padding: "20px",
            marginTop: "20px",
          }}
        >
          보고서 다운로드
        </a>
      </div>
    );
  };

  const NullMobileMakeReport = (props) => {
    return (
      <div className="border-red">
        모바일 어플에서 ' {props.text} 완료 ' 을(를) 눌러주세요.
      </div>
    );
  };

  const PassMobileMakeReport = (props) => {
    return <div className="border-blue">모바일 어플 조사 완료</div>;
  };

  const NullReportCheckReport = (props) => {
    return (
      <div className="border-red">
        최소 {props.num}개의 조사결과 이미지를 보고서에 등록해야합니다. <br />
        {props.text}
        {props.sub}.
      </div>
    );
  };

  const PassReportCheckReport = () => {
    return <div className="border-blue">보고서에 업로드 이미지 통과</div>;
  };

  const NullWebMakeReport = (props) => {
    return (
      <div className="border-red">
        {props.text}에서 제대로 생성되지 않은 데이터가 존재합니다. <br />
      </div>
    );
  };

  const PassWebMakeReport = () => {
    return <div className="border-blue">현장조사 분석 평가 통과</div>;
  };

  const DoneCaptured = () => {
    return (
      <input
        type="text"
        className="form-control"
        readOnly
        disabled
        value="캡처를 완료하였습니다."
      />
    );
  };

  const CaptureBtn = () => {
    return (
      <div className="input-group">
        <button
          className="btn btn-indigo"
          onClick={handleCapture}
          disabled={isCaptureStart}
        >
          캡처
        </button>
      </div>
    );
  };

  const CheckPanoNull = () => {
    return (
      <div className="border-red">
        시설물 관리 페이지로 이동 후 시설물 전경 사진을 등록하세요.
      </div>
    );
  };

  const CheckPosiNull = () => {
    return (
      <div className="border-red">
        시설물 관리 페이지로 이동 후 시설물 위치 사진을 등록하세요.
      </div>
    );
  };

  return (
    <>
      <div className="reportFrame col-sm-8">
        {detailFacilityId.length !== 0 ? (
          // 1차 보고서 여부 체크
          haveReport ? (
            <>
              <>
                <div id="insertPage" className="m-auto mb-3">
                  <div className="row mb-10px">
                    <label className="form-label col-form-label col-md-3">
                      도면 캡처하기
                    </label>
                    {updated ? (
                      <div className="col-md-9">
                        {isCaptured.inspection_plan_captured ? (
                          <DoneCaptured />
                        ) : (
                          <CaptureBtn />
                        )}
                      </div>
                    ) : (
                      <div className="col-md-9">
                        <DoneCaptured />
                      </div>
                    )}
                  </div>
                  <div className="row mb-10px">
                    <label className="form-label col-form-label col-md-3">
                      시설물명
                    </label>
                    <div className="col-md-9">
                      <div className="input-group">
                        <input
                          type="text"
                          className="form-control"
                          readOnly
                          disabled
                          value={returnPrjName + " - " + returnPrjDivide}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row mb-10px">
                    <label className="form-label col-form-label col-md-3">
                      시설물 주소
                    </label>
                    <div className="col-md-9">
                      <div className="input-group">
                        <input
                          type="text"
                          className="form-control"
                          readOnly
                          disabled
                          value={returnPrjAddress}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <AlwaysDisabledMakeReport />
                    <ReportDownload />
                  </div>
                </div>
              </>
            </>
          ) : (
            <>
              <div id="insertPage" className="m-auto mb-3">
                <div className="row mb-10px">
                  <label className="form-label col-form-label col-md-3">
                    모바일 조사 완료 체크
                  </label>
                  <div className="col-md-9">
                    {mobileCheck.arc_inspect &&
                    mobileCheck.carbon_inspect &&
                    mobileCheck.conc_inspect &&
                    mobileCheck.crack_inspect &&
                    mobileCheck.iron_inspect &&
                    mobileCheck.slope_inspect ? (
                      <PassMobileMakeReport />
                    ) : (
                      <NullMobileMakeReport
                        text={
                          !mobileCheck.conc_inspect
                            ? "콘크리트 강도 조사"
                            : !mobileCheck.arc_inspect
                            ? "부재 제원 조사"
                            : !mobileCheck.carbon_inspect
                            ? "탄산화 조사"
                            : !mobileCheck.crack_inspect
                            ? "균열 및 외관 강도 조사"
                            : !mobileCheck.iron_inspect
                            ? "철근 배근 조사"
                            : !mobileCheck.slope_inspect
                            ? "기울기 조사"
                            : "통과3"
                        }
                      />
                    )}
                  </div>
                </div>

                <div className="row mb-10px">
                  <label className="form-label col-form-label col-md-3">
                    현장조사 관리
                  </label>
                  <div className="col-md-9">
                    {reportCheck.arc_report &&
                    reportCheck.carbon_report &&
                    reportCheck.conc_report &&
                    reportCheck.crack_report &&
                    reportCheck.iron_report &&
                    reportCheck.slope_report &&
                    webCheck.equipments ? (
                      <PassReportCheckReport />
                    ) : (
                      <NullReportCheckReport
                        text={
                          !reportCheck.conc_report
                            ? "콘크리트 강도 조사"
                            : !reportCheck.arc_report
                            ? "부재 제원 조사"
                            : !reportCheck.carbon_report
                            ? "탄산화 조사"
                            : !reportCheck.crack_report
                            ? "균열 및 외관 강도 조사"
                            : !reportCheck.iron_report
                            ? "철근 배근 조사"
                            : !reportCheck.slope_report
                            ? "기울기 조사"
                            : !webCheck.equipments
                            ? "사용장비 "
                            : "통과2"
                        }
                        num={!webCheck.equipments ? "6" : "1"}
                        sub={
                          !webCheck.equipments
                            ? "를 6개 이상 등록해주세요."
                            : "에서 보고서 등록 체크를 진행해주세요"
                        }
                      />
                    )}
                  </div>
                </div>
                <div className="row mb-10px">
                  <label className="form-label col-form-label col-md-3">
                    현장조사 분석평가
                  </label>
                  <div className="col-md-9">
                    {webCheck.inspect_result &&
                    webCheck.project_chief &&
                    diffCheck.remuneration &&
                    diffCheck.structural_result ? (
                      <PassWebMakeReport />
                    ) : (
                      <>
                        <NullWebMakeReport
                          text={
                            !diffCheck.remuneration
                              ? "구조검토 결과"
                              : !diffCheck.structural_result
                              ? "보수 보강 방안"
                              : !webCheck.inspect_result
                              ? "상태조사 결과"
                              : !webCheck.project_chief
                              ? "책임자기술자 종합 의견"
                              : "통과1"
                          }
                        />
                      </>
                    )}
                  </div>
                </div>

                {webCheck.panorama ? null : (
                  <div className="row mb-10px">
                    <label className="form-label col-form-label col-md-3">
                      시설물 전경사진
                    </label>
                    <div className="col-md-9">
                      <CheckPanoNull />
                    </div>
                  </div>
                )}

                {webCheck.position ? null : (
                  <div className="row mb-10px">
                    <label className="form-label col-form-label col-md-3">
                      시설물 위치사진
                    </label>
                    <div className="col-md-9">
                      <CheckPosiNull />
                    </div>
                  </div>
                )}

                {mobileCheck.arc_inspect &&
                mobileCheck.carbon_inspect &&
                mobileCheck.conc_inspect &&
                mobileCheck.crack_inspect &&
                mobileCheck.iron_inspect &&
                mobileCheck.slope_inspect &&
                reportCheck.arc_report &&
                reportCheck.carbon_report &&
                reportCheck.conc_report &&
                reportCheck.crack_report &&
                reportCheck.iron_report &&
                reportCheck.slope_report &&
                webCheck.equipments &&
                webCheck.inspect_result &&
                webCheck.project_chief &&
                diffCheck.remuneration &&
                diffCheck.structural_result &&
                webCheck.panorama &&
                webCheck.position ? (
                  <>
                    <div className="row mb-10px">
                      <label className="form-label col-form-label col-md-3">
                        도면 캡처하기
                      </label>
                      <div className="col-md-9">
                        {/* 보고서 없는 사애에서 캡쳐를 진행 했을때 */}
                        {isCaptured.inspection_plan_captured ? (
                          <DoneCaptured />
                        ) : (
                          <CaptureBtn />
                        )}
                      </div>
                    </div>
                    {/* 캡쳐 시작했을때 */}
                    {isCaptureStart ? (
                      isCaptured.inspection_plan_captured ? null : (
                        <div className="row mb-10px">
                          <ProgressBar
                            now={captureBar}
                            label={`${captureText}`}
                          />
                          <canvas
                            // 클릭으로 조사망도 그리기
                            // onMouseDown={deleteDrawing}
                            // onMouseUp={startDrawing}
                            id="plan"
                            ref={canvasRef}
                            width="740"
                            height="523"
                            className={`${styles.canvas}`}
                            style={{
                              // backgroundImage: "url(" + URL + planUrl + ")",
                              cursor: "crosshair",
                              backgroundRepeat: "no-repeat",
                              margin: "auto",
                              backgroundSize: "contain",
                              width: "-webkit-fill-available",
                            }}
                          />
                        </div>
                      )
                    ) : null}
                    <div className="row mb-10px">
                      <label className="form-label col-form-label col-md-3">
                        보고서 제출일
                      </label>
                      <div className="col-md-9">
                        <div className="input-group">
                          <DatePicker
                            locale="ko"
                            dateFormat="yyyy.MM.dd"
                            selected={submitDate}
                            className="form-control"
                            onChange={(date) => setSubmitDate(date)}
                            placeholderText="제출일을 선택해주세요."
                          />
                        </div>
                      </div>
                    </div>
                    {/* <div className="row mb-10px">
                      <label className="form-label col-form-label col-md-3">
                        시설물명
                      </label>
                      <div className="col-md-9">
                        <div className="input-group">
                          <input
                            type="text"
                            className="form-control"
                            readOnly
                            disabled
                            value={info.facilityname}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row mb-10px">
                      <label className="form-label col-form-label col-md-3">
                        시설물 주소
                      </label>
                      <div className="col-md-9">
                        <div className="input-group">
                          <input
                            type="text"
                            className="form-control"
                            readOnly
                            disabled
                            value={info.facilityaddress}
                          />
                        </div>
                      </div>
                    </div> */}
                    <div className="row form-group mb-10px">
                      <label className="form-label col-form-label col-md-3">
                        시설물 조사기간
                      </label>
                      <div className="col-md-9">
                        <div className="row">
                          <DatePicker
                            className="col form-control"
                            selectsRange={true}
                            startDate={startDate}
                            endDate={endDate}
                            onChange={(update) => {
                              setDateRange(update);
                            }}
                            locale="ko"
                            dateFormat="yyyy.MM.dd"
                            placeholderText="선택해주세요."
                          />
                        </div>
                      </div>
                    </div>
                  </>
                ) : null}
              </div>
              <div className="row">
                {/* 생성하기 버튼 */}
                {detailFacilityId.length !== 0 ? (
                  isCaptured.inspection_plan_captured &&
                  submitDate !== null &&
                  dateRange[0] !== null &&
                  dateRange[1] !== null ? (
                    <ChangeableMakeReportBtn />
                  ) : (
                    <AlwaysDisabledMakeReport />
                  )
                ) : null}

                {/* 보고서 생성 중 프로그래스바 */}
                {disableMakeBtn && !downLoadBtn ? (
                  <div className="disablePage">
                    <ProgressBar now={progressBar} label={`${progressText}`} />
                    <div className="progressText">
                      <p>보고서 생성중 입니다.</p>
                      <p>조사자료에 따라 소요되는 시간은 상이합니다.</p>
                      <p>최대 30분 소요될 수 있습니다.</p>
                    </div>
                  </div>
                ) : null}

                {/* 보고서 다운로드  */}
                {detailFacilityId.length !== 0 ? (
                  isCaptured.inspection_plan_captured &&
                  submitDate !== null &&
                  dateRange[0] !== null &&
                  dateRange[1] !== null ? (
                    downLoadBtn ? (
                      <ReportDownload />
                    ) : (
                      <DisabledReportDownload />
                    )
                  ) : (
                    <DisabledReportDownload />
                  )
                ) : null}
              </div>
            </>
          )
        ) : null}
      </div>
    </>
  );
}

export default Report;
