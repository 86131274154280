import React from "react";
import { Collapse, CardBody } from "reactstrap";
import { CardWithText } from "./cardWithText";
import {
  crack_arr,
  arc_arr,
  conc_arr,
  iron_arr,
  carbon_arr,
  slope_arr,
  equipment_arr,
} from "./information";
import styles from "../../assets/css/information.module.css";

const formData = new FormData();
const token = "bearer " + sessionStorage.getItem("token");

class InformationFloorPlan extends React.Component {
  constructor(props) {
    super(props);

    this.newCard = [];
    this.newRow = [];

    this.state = {
      cardZone: "",
      searchInput: "",
      facilityid: this.props.facilityid,
      nowPill: "1",
      conc_key: "",
      crack_key: "",
      iron_key: "",
      arc_key: "",
      carbon_key: "",
      slope_key: "",

      // newCard: [],
      newRow: this.newRow,
      // newRow: "",
      collapse: [
        { id: 1, collapse: true },
        { id: 2, collapse: true },
      ],
      totalUP: this.props.totalUP,
      totalDOWN: this.props.totalDOWN,
      totalElevation: this.props.totalElevation,
    };
    this.createCard = this.createCard.bind(this);
    this.createRow = this.createRow.bind(this);
    this.toggleCollapse = this.toggleCollapse.bind(this);
    this.handleCreateImg = this.handleCreateImg.bind(this);
  }
  // createCard(e) {
  //   e.preventDefault();
  //   this.forceUpdate();

  //   this.newCard.push(
  //     <CardWithText
  //       nowPill={this.props.activePill}
  //       totalDOWN={this.props.totalDOWN}
  //       totalUP={this.props.totalUP}
  //       facilityid={this.props.facilityid}
  //     />
  //   );

  //   this.setState({
  //     newCard: this.newCard,
  //   });
  // }

  createCard = (e) => {
    formData.append("facilityid", this.props.facilityid);

    if (this.state.e_subject === undefined) {
      alert("조사 항목을 선택하세요.");
    } else if (this.state.e_method === undefined) {
      alert("검사방법을 선택하세요.");
    } else {
      formData.append("name", this.state.e_name);
      formData.append("subject", this.state.e_subject);
      formData.append("method", this.state.e_method);
      formData.append("report", 1);

      fetch(URL + "/project/equipment", {
        method: "POST",
        headers: {
          Authorization: token,
        },
        body: formData,
      })
        .then((res) => {
          if (res.status / 100 === 2) {
            return res.json();
          } else {
            alert(
              "알수없는 오류가 발생하였습니다. 새로고침 후 다시 시도해주세요."
            );
          }
        })
        .then((res) => {
          alert("저장을 완료하였습니다.");
          // window.location.replace("/login");
        });
    }
  };

  handleCreateImg = (e) => {
    this.setState({ [e.target.name]: e.target.files[0] });
    formData.append("img", e.target.files[0]);
  };

  createRow(e) {
    e.preventDefault();
    this.forceUpdate();

    this.newRow.push(
      <CardWithText
        nowPill={this.props.activePill}
        facilityid={this.props.facilityid}
      />
    );

    this.setState({
      newRow: this.newRow,
    });
  }

  toggleCollapse(index) {
    var newArray = [];

    for (let collapseObj of this.state.collapse) {
      newArray.push(collapseObj);
    }

    this.setState({
      collapse: newArray,
    });
  }

  render() {
    const inputstyle = {
      border: "none",
      borderBottom: "1px solid #ccc",
    };
    const nowPill = this.props.activePill;

    return (
      <>
        {nowPill === "1" ? (
          <div>
            <Collapse isOpen={true}>
              <CardBody className="row">
                {this.newCard}

                {crack_arr.map((crack, i) => {
                  return this.props.select === crack_arr[i].floor ? (
                    <CardWithText
                      key={i}
                      nowPill={nowPill}
                      facilityid={this.state.facilityid}
                      id={i}
                      zone={crack.zone}
                      crack_size={crack.crack_size}
                      report={crack.report}
                      floor={crack.floor}
                      crack_box_size={crack.crack_box_size}
                      crack_length={crack.crack_length}
                      position={crack.position}
                      type={crack.type}
                      etc={crack.etc}
                      file_id={crack.file_id}
                      index={crack.index}
                      auto_analyze={crack.auto_analyze}
                      seq={crack.seq}
                      totalDOWN={this.props.totalDOWN}
                      totalUP={this.props.totalUP}
                      totalElevation= {this.props.totalElevation}
                    />
                  ) : (
                    ""
                  );
                })}
              </CardBody>
            </Collapse>
          </div>
        ) : (
          ""
        )}

        {nowPill === "2" ? (
          <>
            <Collapse isOpen={true}>
              <CardBody className={`${styles.card_body}`}>
                {this.newCard}
                {conc_arr.map((conc, i) => {
                  return this.props.select === conc_arr[i].floor ? (
                    <CardWithText
                      nowPill={nowPill}
                      id={i}
                      index={conc.index}
                      crack_size={conc.crack_size}
                      floor={conc.floor}
                      c_position={conc.c_position}
                      c_etc={conc.c_etc}
                      c_zone={conc.c_zone}
                      c_inspectAge={conc.c_inspectAge}
                      c_hitAngle={conc.c_hitAngle}
                      c_num1={conc.c_num1}
                      c_num2={conc.c_num2}
                      c_num3={conc.c_num3}
                      c_num4={conc.c_num4}
                      c_num5={conc.c_num5}
                      c_num6={conc.c_num6}
                      c_num7={conc.c_num7}
                      c_num8={conc.c_num8}
                      c_num9={conc.c_num9}
                      c_num10={conc.c_num10}
                      c_num11={conc.c_num11}
                      c_num12={conc.c_num12}
                      c_num13={conc.c_num13}
                      c_num14={conc.c_num14}
                      c_num15={conc.c_num15}
                      c_num16={conc.c_num16}
                      c_num17={conc.c_num17}
                      c_num18={conc.c_num18}
                      c_num19={conc.c_num19}
                      c_num20={conc.c_num20}
                      report={conc.report}
                      c_file_id={conc.c_file_id}
                      facilityid={this.state.facilityid}
                      c_seq={conc.c_seq}
                      totalDOWN={this.props.totalDOWN}
                      totalUP={this.props.totalUP}
                      totalElevation= {this.props.totalElevation}
                      age_correction_factor={conc.age_correction_factor}
                      average={conc.average}
                      data_average={conc.data_average}
                      delta_r={conc.delta_r}
                      f1={conc.f1}
                      f2={conc.f2}
                      f3={conc.f3}
                      standard_hardness={conc.standard_hardness}
                      strength={conc.strength}
                      strength_updated={conc.strength_updated}
                      arr={[
                        Number(conc.c_num1),
                        Number(conc.c_num2),
                        Number(conc.c_num3),
                        Number(conc.c_num4),
                        Number(conc.c_num5),
                        Number(conc.c_num6),
                        Number(conc.c_num7),
                        Number(conc.c_num8),
                        Number(conc.c_num9),
                        Number(conc.c_num10),
                        Number(conc.c_num11),
                        Number(conc.c_num12),
                        Number(conc.c_num13),
                        Number(conc.c_num14),
                        Number(conc.c_num15),
                        Number(conc.c_num16),
                        Number(conc.c_num17),
                        Number(conc.c_num18),
                        Number(conc.c_num19),
                        Number(conc.c_num20),
                      ]}
                    />
                  ) : (
                    ""
                  );
                })}
              </CardBody>
            </Collapse>
          </>
        ) : (
          ""
        )}
        {nowPill === "3" ? (
          <>
            <Collapse isOpen={true}>
              <CardBody className={`${styles.card_body}`}>
                {this.newCard}
                {iron_arr.map((iron, i) => {
                  return this.props.select === iron_arr[i].floor ? (
                    <CardWithText
                      nowPill={nowPill}
                      id={i}
                      index={iron.index}
                      i_zone={iron.i_zone}
                      report={iron.report}
                      i_position={iron.i_position}
                      i_etc={iron.i_etc}
                      result_img={iron.result_img}
                      floor={iron.floor}
                      i_file_id={iron.i_file_id}
                      facilityid={this.state.facilityid}
                      i_seq={iron.i_seq}
                      totalDOWN={this.props.totalDOWN}
                      totalUP={this.props.totalUP}
                      totalElevation= {this.props.totalElevation}
                    />
                  ) : (
                    ""
                  );
                })}
              </CardBody>
            </Collapse>
          </>
        ) : (
          ""
        )}

        {nowPill === "4" ? (
          <>
            <Collapse isOpen={true}>
              <CardBody className={`${styles.card_body}  row`}>
                {this.newCard}
                {arc_arr.map((arc, i) => {
                  return this.props.select === arc_arr[i].floor ? (
                    <CardWithText
                      nowPill={nowPill}
                      id={i}
                      index={arc.index}
                      a_zone={arc.a_zone}
                      a_position={arc.a_position}
                      report={arc.report}
                      a_etc={arc.a_etc}
                      a_seq={arc.a_seq}
                      floor={arc.floor}
                      aw_size={arc.aw_size}
                      ah_size={arc.ah_size}
                      a_file_id={arc.a_file_id}
                      facilityid={this.state.facilityid}
                      totalDOWN={this.props.totalDOWN}
                      totalUP={this.props.totalUP}
                      totalElevation= {this.props.totalElevation}
                    />
                  ) : (
                    ""
                  );
                })}
              </CardBody>
            </Collapse>
          </>
        ) : (
          ""
        )}
        {nowPill === "5" ? (
          <>
            <Collapse isOpen={true}>
              <CardBody className={`${styles.card_body}  row`}>
                {this.newCard}
                {slope_arr.map((slope, i) => {
                  return this.props.select === slope_arr[i].floor ? (
                    <CardWithText
                      nowPill={nowPill}
                      id={i}
                      index={slope.index}
                      s_zone={slope.s_zone}
                      report={slope.report}
                      s_position={slope.s_position}
                      floor={slope.floor}
                      s_num1={slope.s_num1}
                      s_num2={slope.s_num2}
                      s_num3={slope.s_num3}
                      s_num4={slope.s_num4}
                      s_file_id={slope.s_file_id}
                      facilityid={this.state.facilityid}
                      s_seq={slope.s_seq}
                      s_etc={slope.s_etc}
                      totalDOWN={this.props.totalDOWN}
                      totalUP={this.props.totalUP}
                      totalElevation= {this.props.totalElevation}
                    />
                  ) : (
                    ""
                  );
                })}
              </CardBody>
            </Collapse>
          </>
        ) : (
          ""
        )}
        {nowPill === "6" ? (
          <>
            <Collapse isOpen={true}>
              <CardBody className={`${styles.card_body}  row`}>
                {this.newCard}
                {carbon_arr.map((carbon, i) => {
                  return this.props.select === carbon_arr[i].floor ? (
                    <CardWithText
                      nowPill={nowPill}
                      id={i}
                      index={carbon.index}
                      ca_zone={carbon.ca_zone}
                      report={carbon.report}
                      ca_position={carbon.ca_position}
                      floor={carbon.floor}
                      ca_num={carbon.ca_num}
                      ca_file_id={carbon.ca_file_id}
                      facilityid={this.state.facilityid}
                      ca_seq={carbon.ca_seq}
                      ca_etc={carbon.ca_etc}
                      totalDOWN={this.props.totalDOWN}
                      totalUP={this.props.totalUP}
                      totalElevation= {this.props.totalElevation}
                    />
                  ) : (
                    ""
                  );
                })}
              </CardBody>
            </Collapse>
          </>
        ) : (
          ""
        )}
        {nowPill === "7" ? (
          <>
            <div>
              <button
                className="btn btn-indigo"
                onClick={(e) => {
                  this.createRow(e);
                }}
              >
                사용장비 추가
              </button>
            </div>

            <div className="text-red mt-3">
              &#8251; 항목별 사용장비를 1개씩 등록해야 보고서 생성이 가능합니다.
              (총 6개)
            </div>
            <div className={`d-flex ${styles.fake} mt-3`}>
              <div className="text-center" style={{ width: "200px" }}>
                항목
              </div>
              <div className="text-center" style={{ width: "150px" }}>
                검사방법
              </div>
              <div className="text-center" style={{ width: "220px" }}>
                사용장비
              </div>
              <div className="text-center" style={{ width: "200px" }}>
                사진
              </div>
              <div className="text-center" style={{ width: "80px" }}>
                등록/삭제
              </div>
            </div>
            <Collapse isOpen={true}>
              <CardBody>
                {this.newRow.map((i) => {
                  return (
                    <>
                      <CardWithText
                        nowPill={nowPill}
                        facilityid={this.props.facilityid}
                      />
                    </>
                  );
                })}
                {equipment_arr.map((equipment, i) => {
                  return (
                    <>
                      <CardWithText
                        nowPill={nowPill}
                        facilityid={this.props.facilityid}
                        e_id={equipment.e_id}
                        e_name={equipment.e_name}
                        e_subject={equipment.e_subject}
                        e_method={equipment.e_method}
                        e_file_id={equipment.e_file_id}
                      />
                    </>
                  );
                })}
              </CardBody>
            </Collapse>
          </>
        ) : (
          ""
        )}
      </>
    );
  }
}
export { InformationFloorPlan };
