import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { UpImg, formData_upper } from "../upImg.js";
import { UnderImg, formData_under } from "../underImg.js";
import { ElevationImg, formData_elevation } from "../elevationImg.js";
import { PanoramaImg, formData as panorama } from "../panoramaImg.js";
import { BuildingImg, formData as position } from "../buildingImg.js";
import styles from "../../assets/css/fms.module.css";
import { result_prj, isSearched } from "../facilitylayout/layout";
import { useResultContext } from "../../Contexts/LayoutContext";
import guide from "../../assets/img/guide1.jpg";

const URL = process.env.REACT_APP_BACKEND_HOST;

const API = URL + "/project/";
const UPDATE = "update/";
const API2 = URL + "/project/prj_list";
const API_PLAN_IMG = URL + "/project/plan_list";
const API_PANO_IMG = URL + "/project/panorama_list";
const API_POSI_IMG = URL + "/project/position_list";
const API_UPDATE = URL + "/project/update/project";
const API_IMG = URL + "/project/post_building_img";
const API_PANO = "panorama";
const API_POSI = "position";

let project = {
    facilityaddress: "",
    facilityarea1: "",
    facilityarea2: "",
    facilitydate: "",
    facilitydivide: "",
    facilityetc: "",
    facilityfloordown: "",
    facilityfloorup: "",
    facilityelevation: "",
    facilityid: "",
    facilityname: "",
    facilityowner: "",
    facilitystructure: "",
    facilityuse: "",
    userid: "",
};

function Fms() {
    const { detailFacilityId } = useResultContext();
    const [, updateState] = useState();
    const forceUpdate = useCallback(() => updateState({}), []);
    const [active, setActive] = useState("");
    const [editSign, setEditSign] = useState(false);
    const [editImgSign, setEditImgSign] = useState(false);
    const [projectDiv, setProjectDiv] = useState({
        facilitydivide: "",
    });
    const [disableCreateImg, setDisableCreateImg] = useState(false);
    const [input, setInput] = useState({
        facilityid: "",
        facilityname: "",
        facilityarea2: "",
        facilityarea1: "",
        facilityfloorup: "",
        facilityfloordown: "",
        facilityelevation: "",
        facilityaddress: "",
        facilitystructure: "",
        facilityuse: "",
        facilityowner: "",
        facilityetc: "",
        facilitydate: "",
        zoneCode: "",
        isRegister: false,
        register: [],
        errors: {
            facilityid: "",
            facilityname: "",
            facilityarea2: "",
            facilityarea1: "",
            facilityfloorup: "",
            facilityfloordown: "",
            facilityelevation: "",
            facilityaddress: "",
            facilitystructure: "",
            facilityuse: "",
            facilityowner: "",
            facilityetc: "",
            facilitydate: "",
        },
    });
    const [planArr, setPlanArr] = useState([]);
    const [posiImg, setPosiImg] = useState({
        posi_seq: "",
        img: "",
    });
    const [panoImg, setPanoImg] = useState({
        pano_seq: "",
        img: "",
    });
    const [nullImg, setNullImg] = useState(true);

    const {
        facilityname,
        facilityarea2,
        facilityarea1,
        facilityfloorup,
        facilityfloordown,
        facilityelevation,
        facilityaddress,
        facilitystructure,
        facilityuse,
        facilityowner,
        facilityetc,
        facilitydate,
    } = input;
    const goNext1 = document.getElementById("goNext1");
    const goNext2 = document.getElementById("goNext2");
    const fmsForm1 = document.getElementById("FmsFirst");
    const fmsForm2 = document.getElementById("FmsSecond");
    const fmsForm2Create = document.getElementById("FmsSecondCreate");
    // const updateFmsFirst = document.getElementById("updateFmsFirst");
    // const updateFmsSecond = document.getElementById("updateFmsSecond");
    // const createFmsFirst = document.getElementById("createFmsFirst");
    // const createFmsSecond = document.getElementById("createFmsSecond");

    const handleChange = (name, value) => {
        setInput({
            ...input,
            [name]: value,
        });
    };

    const addActiveClass = (e) => {
        let clicked = e.currentTarget.id;

        if (active === clicked) {
            setActive({ active: "" });
        } else {
            setActive({ active: clicked });
        }
    };

    const handleOnChange = (e) => {
        const newName = e.target.name;
        const curVal = e.target.value;

        setProjectDiv({
            [newName]: curVal,
        });
    };

    useEffect(() => {
        const buildingWrap = document.getElementById("BuildingWrap");
        const addNewBtn = document.getElementById("addNewBuilding");
        if (isSearched) {
            buildingWrap.classList.remove("d-none");
            buildingWrap.classList.add("d-block");
            addNewBtn.style.bottom = "130px";

            const token = "bearer " + sessionStorage.getItem("token");
            //건물 정보 호출
            fetch(API2, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json;charset=UTF-8",
                    Authorization: token,
                    Accept: "application/json;charset=UTF-8",
                },
            })
                .then((res) => {
                    if (res.status / 100 === 2) {
                        return res.json();
                    } else {
                        alert(
                            "알수없는 오류가 발생하였습니다. 새로고침 후 다시 시도해주세요."
                        );
                    }
                })
                .then((res) => {
                    console.log(res);
                    let prj_list = [];

                    for (let i = 0; i < res["results"].length; i++) {
                        project = res["results"][i];
                        prj_list.push(project);
                    }

                    for (let i = 0; i < prj_list.length; i++) {
                        if (prj_list[i]["facilityid"] === result_prj) {
                            setInput({
                                facilityname: prj_list[i]["facilityname"],
                                facilityaddress: prj_list[i]["facilityaddress"],
                                facilitydate: prj_list[i]["facilitydate"],
                                facilityetc: prj_list[i]["facilityetc"],
                                facilityowner: prj_list[i]["facilityowner"],
                                facilitystructure: prj_list[i]["facilitystructure"],
                                facilityuse: prj_list[i]["facilityuse"],
                                facilityfloordown: prj_list[i]["facilityfloordown"],
                                facilityelevation: prj_list[i]["facilityelevation"],
                                userid: prj_list[i]["userid"],
                                facilityarea2: prj_list[i]["facilityarea2"],
                                facilityfloorup: prj_list[i]["facilityfloorup"],
                                facilityid: prj_list[i]["facilityid"],
                                facilityarea1: prj_list[i]["facilityarea1"],
                            });

                            setProjectDiv({
                                facilitydivide: prj_list[i]["facilitydivide"],
                            });
                        }
                    }
                });

            //건물 이미지들 호출

            fetch(API_PLAN_IMG + "?facilityid=" + result_prj, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json;charset=UTF-8",
                    Authorization: token,
                    Accept: "application/json;charset=UTF-8",
                },
            })
                .then((res) => {
                    if (res.status / 100 === 2) {
                        return res.json();
                    } else {
                        alert(
                            "알수없는 오류가 발생하였습니다. 새로고침 후 다시 시도해주세요."
                        );
                    }
                })
                .then((res) => {
                    // setPlanArr([]);
                    console.log(res);
                    let plan_arr = [];
                    let tmp_planArr1 = [];
                    let tmp_planArr2 = [];
                    if (res["results"].length !== 0) {
                        for (let i = 0; i < res["results"].length; i++) {
                            plan_arr.push({
                                floor: res["results"][i]["floor"],
                                img: res["results"][i]["img"],
                                seq: res["results"][i]["seq"],
                            });
                            setPlanArr((oldArray) => [
                                ...oldArray,
                                {
                                    floor: res["results"][i]["floor"],
                                    img: res["results"][i]["img"],
                                    seq: res["results"][i]["seq"],
                                },
                            ]);
                        }
                        setNullImg(false);
                    } else {
                        setNullImg(true);
                    }
                    if (plan_arr) {
                        for (let i = 0; i < plan_arr.length; i++) {
                            if (plan_arr[i].floor < 0) {
                                tmp_planArr1.push(plan_arr[i]);
                            } else {
                                tmp_planArr2.push(plan_arr[i]);
                            }
                        }
                        tmp_planArr1.reverse();
                        plan_arr = tmp_planArr1.concat(tmp_planArr2);
                    }
                    setPlanArr(plan_arr);
                });

            fetch(API_PANO_IMG + "?facilityid=" + result_prj, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json;charset=UTF-8",
                    Authorization: token,
                    Accept: "application/json;charset=UTF-8",
                },
            })
                .then((res) => {
                    if (res.status / 100 === 2) {
                        return res.json();
                    } else {
                        alert(
                            "알수없는 오류가 발생하였습니다. 새로고침 후 다시 시도해주세요."
                        );
                    }
                })
                .then((res) => {
                    if (res.results[0] !== undefined) {
                        setPanoImg({
                            pano_seq: res.results[0]["seq"],
                            img: res.results[0]["img"],
                        });
                        setNullImg(false);
                    } else {
                        setNullImg(true);
                    }
                });

            fetch(API_POSI_IMG + "?facilityid=" + result_prj, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json;charset=UTF-8",
                    Authorization: token,
                    Accept: "application/json;charset=UTF-8",
                },
            })
                .then((res) => {
                    if (res.status / 100 === 2) {
                        return res.json();
                    } else {
                        alert(
                            "알수없는 오류가 발생하였습니다. 새로고침 후 다시 시도해주세요."
                        );
                    }
                })
                .then((res) => {
                    if (res.results[0] !== undefined) {
                        setPosiImg({
                            posi_seq: res.results[0]["seq"],
                            img: res.results[0]["img"],
                        });
                        setNullImg(false);
                    } else {
                        setNullImg(true);
                    }
                });
        }

        addNewBtn.style.bottom = "-528px";
    }, [detailFacilityId]);

    const hadleUpdateSign = (e) => {
        e.preventDefault();
        setEditSign(true);
    };

    const handleImgSign = (e) => {
        e.preventDefault();
        setEditImgSign(true);
    };

    const handleFmsRedirect = () => {
        alert("도면 등록이 완료되었습니다.");
        window.location.href = '/fms'
    }

    const handleCreateImg = (e) => {
        e.preventDefault();

        goNext1.classList.add("completed");
        fmsForm1.classList.add("d-none");
        fmsForm2Create.classList.remove("d-none");
        fmsForm2Create.classList.add("d-block");
        goNext2.classList.add("active");

        const token = "bearer " + sessionStorage.getItem("token");
        let a = false;
        let b = false;

        // 지하 도면 + 지상 도면 + (입면도 4) + (시설물 위치 사진 + 시설물 전경 사진 = 2)
        // 등록 개수 카운팅
        const totalRedirect = Number(input.facilityfloordown) + Number(input.facilityfloorup) + Number(input.facilityelevation) + 2
        let count = 0;

        // 시설물 위치사진 등록
        if (document.getElementsByName("buildingImg")[0].files.length !== 0) {
            try {
                fetch(API + API_PANO, { method: "POST", headers: { Authorization: token }, body: panorama }).then((res) => {
                    res.text();
                    count++
                    if (totalRedirect === count) { handleFmsRedirect(); }
                    if (res.status / 100 === 2) {
                        console.log("등록 성공");
                    } else {
                        alert("시설물 위치사진 등록에 실패하였습니다. 새로고침 후 다시 시도해주세요.");
                    }
                })
            } catch (error) {
                alert("시설물 위치사진 등록에 실패하였습니다. 새로고침 후 다시 시도해주세요.");
            }
        } else {
            count++
        }

        // 시설물 전경사진 등록
        if (document.getElementsByName("panoramaImg")[0].files.length !== 0) {
            try {
                fetch(API + API_POSI, { method: "POST", headers: { Authorization: token }, body: position }).then((res) => {
                    res.text();
                    count++
                    if (totalRedirect === count) { handleFmsRedirect(); }
                    if (res.status / 100 === 2) {
                        console.log("등록 성공");
                    } else {
                        alert("시설물 전경사진 등록에 실패하였습니다. 새로고침 후 다시 시도해주세요.");
                    }
                });
            } catch (error) {
                alert("시설물 전경사진 등록에 실패하였습니다. 새로고침 후 다시 시도해주세요.");
            }
        } else {
            count++
        }

        // 지하 도면 등록
        for (let i = 0; i < input.facilityfloordown; i++) {
            if (formData_under[i]) {
                try {
                    fetch(API_IMG, { method: "POST", headers: { Authorization: token }, body: formData_under[i] }).then((res) => {
                        res.text();
                        count++;
                        if (totalRedirect === count) { handleFmsRedirect(); }
                        if (res.status / 100 === 2) {
                            console.log("등록 성공");
                        } else {
                            alert("지하도면 등록에 실패하였습니다. 새로고침 후 다시 시도해주세요.");
                        }
                    });
                } catch (error) {
                    alert("지하도면 등록에 실패하였습니다. 새로고침 후 다시 시도해주세요.");
                }
            } else {
                count++
                if (totalRedirect === count) { handleFmsRedirect(); }
            }
        }

        // 지상 도면 등록
        for (let i = 0; i < input.facilityfloorup; i++) {
            if (formData_upper[i]) {
                try {
                    fetch(API_IMG, { method: "POST", headers: { Authorization: token }, body: formData_upper[i] }).then((res) => {
                        res.text();
                        count++;
                        if (totalRedirect === count) { handleFmsRedirect(); }
                        if (res.status / 100 === 2) {
                            console.log("등록 성공");
                        } else {
                            alert("지상도면 등록에 실패하였습니다. 새로고침 후 다시 시도해주세요.");
                        }
                    });
                } catch (error) {
                    alert("지상도면 등록에 실패하였습니다. 새로고침 후 다시 시도해주세요.");
                }
            } else {
                count++
                if (totalRedirect === count) { handleFmsRedirect(); }
            }
        }

        for (let i = 0; i < input.facilityelevation; i++) {
            if (formData_elevation[i]) {
                try {
                    fetch(API_IMG, { method: "POST", headers: { Authorization: token }, body: formData_elevation[i] }).then((res) => {
                        res.text();
                        count++;
                        if (totalRedirect === count) { handleFmsRedirect(); }
                        if (res.status / 100 === 2) {
                            console.log("등록 성공");
                        } else {
                            alert("입면도 등록에 실패하였습니다. 새로고침 후 다시 시도해주세요.");
                        }
                    });
                } catch (error) {
                    alert("입면도 등록에 실패하였습니다. 새로고침 후 다시 시도해주세요.");
                }
            } else {
                count++
                if (totalRedirect === count) { handleFmsRedirect(); }
            }
        }
    };

    const handleDeleteSubmit = (e) => {
        e.preventDefault();

        const token = "bearer " + sessionStorage.getItem("token");
        if (window.confirm("시설물 삭제를 진행 하시겠습니까?")) {
            fetch(API + "delete/project/" + input.facilityid, {
                method: "DELETE",
                headers: {
                    Authorization: token,
                }
            })
                .then((res) => res.text())
                .then((res) => window.location.href = '/fms', alert("삭제가 완료되었습니다."));
        } else {

        }
    }

    const hadleUpdateSubmit = (e) => {
        e.preventDefault();

        const result_state = Object.assign(input, projectDiv);
        const formData = new FormData();

        formData.append("facilityid", input.facilityid);
        formData.append("facilityowner", input.facilityowner);
        formData.append("facilityfloordown", input.facilityfloordown);
        formData.append("facilityfloorup", input.facilityfloorup);
        formData.append("facilityarea1", input.facilityarea1);
        formData.append("facilityarea2", input.facilityarea2);

        //console.log(result_state.facilityetc);
        if (result_state.facilityetc === "") {
            formData.append("facilityetc", "");
        } else {
            formData.append("facilityetc", input.facilityetc);
        }

        formData.append("facilitydivide", projectDiv.facilitydivide);

        if (result_state.facilityaddress === "") {
            alert("시설물 주소는 필수항목입니다.");
        } else if (result_state.facilityowner === "") {
            alert("대표자명은 필수항목입니다.");
        } else if (result_state.facilitydivide === "") {
            alert("프로젝트 구분은 필수항목입니다.");
        } else if (result_state.facilityfloordown === "" || result_state.facilityfloorup === "") {
            alert("프로젝트 층수는 필수항목입니다.");
        } else if (result_state.facilityarea1 === "") {
            alert("프로젝트 연면적은 필수항목입니다.");
        } else if (result_state.facilityarea2 === "") {
            alert("프로젝트 건축면적은 필수항목입니다.");
        } else {
            const token = "bearer " + sessionStorage.getItem("token");

            fetch(API_UPDATE, {
                method: "POST",
                headers: {
                    Authorization: token,
                },
                body: formData,
            })
                .then((res) => res.text())
                .then((res) => alert("수정이 완료되었습니다."));
            forceUpdate();

            goNext1.classList.add("completed");
            fmsForm1.classList.add("d-none");
            fmsForm2.classList.remove("d-none");
            fmsForm2.classList.add("d-block");
            goNext2.classList.add("active");
        }
    };

    const handleUpdateImg = async (e) => {
        //console.log("1");
        const token = "bearer " + sessionStorage.getItem("token");
        let panoCheck;
        let posiCheck;
        for (let i of panorama.entries()) {
            panoCheck = i[1];
        }
        for (let i of position.entries()) {
            posiCheck = i[1];
        }

        //console.log(panoCheck);
        if (panoCheck !== undefined) {
            fetch(API + UPDATE + API_PANO, {
                method: "POST",
                headers: {
                    Authorization: token,
                },
                body: panorama,
            })
                .then((res) => {
                    return res.json();
                })
                .then((res) => {
                    //console.log(res);
                });
        }
        if (posiCheck !== undefined) {
            fetch(API + UPDATE + API_POSI, {
                method: "POST",
                headers: {
                    Authorization: token,
                },
                body: position,
            }).then((res) => {
                res.json();
                //console.log(res);
            });
        }
        if (formData_upper.length !== 0) {
            for (let i of formData_upper) {
                fetch(API + UPDATE + "plan", {
                    method: "POST",
                    headers: {
                        Authorization: token,
                    },
                    body: i,
                }).then((res) => res.json());
            }
        }
        if (formData_under.length !== 0) {
            for (let i of formData_under) {
                fetch(API + UPDATE + "plan", {
                    method: "POST",
                    headers: {
                        Authorization: token,
                    },
                    body: i,
                }).then((res) => res.json());
            }
        }
        if (formData_elevation.length !== 0) {
            for (let i of formData_elevation) {
                fetch(API + UPDATE + "plan", {
                    method: "POST",
                    headers: {
                        Authorization: token,
                    },
                    body: i,
                }).then((res) => res.json());
            }
        }
        alert("수정이 완료 되었습니다.");
        // window.location.reload(true);
        window.location.replace("/fms");
    };

    const prev = (e) => {
        e.preventDefault();

        goNext1.classList.add("active");
        fmsForm1.classList.remove("d-none");
        fmsForm2.classList.add("d-block");
        fmsForm2.classList.remove("d-block");
        fmsForm2.classList.add("d-none");
        goNext2.classList.remove("active");
    };

    const next = (e) => {
        e.preventDefault();

        goNext2.classList.add("active");
        fmsForm2.classList.remove("d-none");
        fmsForm1.classList.add("d-block");
        fmsForm1.classList.remove("d-block");
        fmsForm1.classList.add("d-none");
        goNext1.classList.remove("active");
    };
    //console.log(planArr);

    return (
        <div className={`${styles.forcedFrame} col-sm-8`}>
            {detailFacilityId.length !== 0 ? null : <img src={guide} alt="" />}

            <div className={`${styles.add_new_listItem} `} id="addNewBuilding">
                <Link
                    type="button"
                    className={`btn btn-outline-primary d-block p-15px fs-16px m-auto`}
                    to="/fms/write"
                >
                    <i className="ion ion-md-add-circle-outline pe-5px"></i>
                    신규 건물 등록
                </Link>
            </div>

            <div className={`d-none AddBuildingWrap`} id="BuildingWrap" onClick={addActiveClass}>
                <div className={`${styles.container} fs-15px m-auto`}>
                    <div className={`nav-wizards-container ${styles.nav_wizards_container}`}>
                        <nav className="nav nav-wizards-2 mb-3">
                            <div className="nav-item w-200px">
                                <div className="nav-link active" id="goNext1" onClick={prev}>
                                    <div className="nav-title fw-lighter text-center">
                                        1. 시설물 정보 입력
                                    </div>
                                </div>
                            </div>
                            <div className="nav-item w-200px">
                                <div className="nav-link" id="goNext2" onClick={next}>
                                    <div className="nav-title fw-lighter text-center">
                                        2. 도면첨부
                                    </div>
                                </div>
                            </div>
                        </nav>
                    </div>
                    
                    <form id="FmsFirst" className={styles.newFmsFirst}>
                        {isSearched ? (
                            <div id="updateFmsFirst">
                                조회 및 수정
                                <div className={`${styles.tab_group}`}>
                                    <ul className={`${styles.select_task_division}`}>
                                        <div className="m-0">과업선택</div>
                                        <li className={styles.form_check}>
                                            <input
                                                className={`form-check-input ${styles.form_check_input}`}
                                                type="radio"
                                                onChange={handleOnChange}
                                                id="안전점검"
                                                defaultValue="안전점검"
                                                name="facilitydivide"
                                                checked={projectDiv.facilitydivide === "안전점검"}
                                                disabled={!editSign}
                                                style={{ opacity: "1" }}
                                            />
                                            <label
                                                className="form-check-label col-form-label p-0"
                                                htmlFor="안전점검"
                                                style={{ opacity: "1" }}
                                            >
                                                안전점검
                                            </label>
                                        </li>
                                        <li className={styles.form_check}>
                                            <input
                                                className={`form-check-input ${styles.form_check_input}`}
                                                type="radio"
                                                onChange={handleOnChange}
                                                id="안전진단"
                                                defaultValue="안전진단"
                                                name="facilitydivide"
                                                checked={projectDiv.facilitydivide === "안전진단"}
                                                disabled={!editSign}
                                                style={{ opacity: "1" }}
                                            />
                                            <label
                                                className="form-check-label col-form-label p-0"
                                                htmlFor="안전진단"
                                                style={{ opacity: "1" }}
                                            >
                                                안전진단
                                            </label>
                                        </li>
                                        <li className={styles.form_check}>
                                            <input
                                                className={`form-check-input ${styles.form_check_input}`}
                                                type="radio"
                                                onChange={handleOnChange}
                                                id="정밀안전진단"
                                                defaultValue="정밀안전진단"
                                                name="facilitydivide"
                                                // checked={facilitydivide === "정밀안전진단"}
                                                checked={projectDiv.facilitydivide === "정밀안전진단"}
                                                disabled={!editSign}
                                                style={{ opacity: "1" }}
                                            />
                                            <label
                                                className="form-check-label col-form-label p-0"
                                                htmlFor="정밀안전진단"
                                                style={{ opacity: "1" }}
                                            >
                                                정밀안전진단
                                            </label>
                                        </li>
                                        <li className={styles.form_check}>
                                            <input
                                                className={`form-check-input ${styles.form_check_input}`}
                                                type="radio"
                                                onChange={handleOnChange}
                                                id="하자진단"
                                                defaultValue="하자진단"
                                                name="facilitydivide"
                                                checked={projectDiv.facilitydivide === "하자진단"}
                                                disabled={!editSign}
                                                style={{ opacity: "1" }}
                                            />
                                            <label
                                                className="form-check-label col-form-label p-0"
                                                htmlFor="하자진단"
                                                style={{ opacity: "1" }}
                                            >
                                                하자진단
                                            </label>
                                        </li>
                                        <li className={styles.form_check}>
                                            <input
                                                className={`form-check-input ${styles.form_check_input}`}
                                                type="radio"
                                                onChange={handleOnChange}
                                                id="기타"
                                                defaultValue="기타"
                                                name="facilitydivide"
                                                checked={projectDiv.facilitydivide === "기타"}
                                                disabled={!editSign}
                                                style={{ opacity: "1" }}
                                            />
                                            <label
                                                className="form-check-label col-form-label p-0"
                                                htmlFor="기타"
                                                style={{ opacity: "1" }}
                                            >
                                                기타
                                            </label>
                                        </li>
                                    </ul>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="row mb-3">
                                            <label className={`form-label col-form-label w-100px`}>
                                                시설물 명칭
                                            </label>
                                            <div className="col">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="facilityname"
                                                    value={facilityname}
                                                    onChange={(e) =>
                                                        handleChange("facilityname", e.target.value)
                                                    }
                                                    readOnly
                                                // disabled={!editSign}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="row mb-3">
                                            <label className={`form-label col-form-label w-100px`}>
                                                대표자명
                                            </label>
                                            <div className="col">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="예) 홍길동"
                                                    value={facilityowner}
                                                    name="facilityowner"
                                                    onChange={(e) =>
                                                        handleChange("facilityowner", e.target.value)
                                                    }
                                                    disabled={!editSign}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-3">
                                    <label className={`form-label col-form-label w-100px`}>
                                        시설물 주소
                                    </label>
                                    <div className="col-md-9">
                                        <div className="modalCell col">
                                            <div className="address mb-1">
                                                <div
                                                    className="form-control fs-13p"
                                                    style={{ height: "34px" }}
                                                    readOnly
                                                >
                                                    {facilityaddress}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-7">
                                        <div className="row mb-3">
                                            <label className={`form-label col-form-label  w-100px`}>
                                                사용승인일
                                            </label>
                                            <div className="col-md-9">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="facilitydate"
                                                    value={facilitydate}
                                                    onChange={(e) =>
                                                        handleChange("facilitydate", e.target.value)
                                                    }
                                                    readOnly
                                                // disabled={!editSign}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="row mb-3">
                                            <label className={`form-label col-form-label w-100px`}>
                                                건축층수
                                            </label>
                                            <div className="col row" style={{ display: "flex" }}>
                                                <label className={`form-label col-form-label w-60px`}>
                                                    지하
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control col text-end"
                                                    placeholder="숫자만 입력하세요"
                                                    id="facilityfloordown"
                                                    value={facilityfloordown}
                                                    name="facilityfloordown"
                                                    onChange={(e) =>
                                                        handleChange("facilityfloordown", e.target.value)
                                                    }
                                                    // readOnly
                                                    disabled={!editSign}
                                                />
                                                <label className={`form-label col-form-label w-30px`}>
                                                    층
                                                </label>
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <label className={`form-label col-form-label w-100px`}>
                                                연면적
                                            </label>
                                            <div className="col" style={{ display: "flex" }}>
                                                <input
                                                    type="text"
                                                    pattern="[0-9]*"
                                                    className="form-control col text-end"
                                                    placeholder="숫자만 입력하세요"
                                                    value={facilityarea1}
                                                    name="facilityarea1"
                                                    id="facilityarea1"
                                                    onChange={(e) =>
                                                        handleChange("facilityarea1", e.target.value)
                                                    }
                                                    // readOnly
                                                    disabled={!editSign}
                                                />
                                                <label
                                                    className={`form-label col-form-label w-40px text-end`}
                                                >
                                                    m<sup>2</sup>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <label className={`form-label col-form-label w-100px`}>
                                                주용도
                                            </label>
                                            <div className="col">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="예) 삼성 아이티밸리"
                                                    name="facilityuse"
                                                    value={facilityuse}
                                                    onChange={(e) =>
                                                        handleChange("facilityuse", e.target.value)
                                                    }
                                                    readOnly
                                                // disabled={!editSign}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="row mb-3">
                                            <div className="w-100px">
                                                <div className={styles.tilde}>~</div>
                                            </div>
                                            <label className={`form-label col-form-label w-60px`}>
                                                지상
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control col text-end"
                                                placeholder="숫자만 입력하세요"
                                                id="facilityfloorup"
                                                name="facilityfloorup"
                                                value={facilityfloorup}
                                                onChange={(e) =>
                                                    handleChange("facilityfloorup", e.target.value)
                                                }
                                                // readOnly
                                                disabled={!editSign}
                                            />
                                            <label className={`form-label col-form-label w-30px`}>
                                                층
                                            </label>
                                        </div>
                                        <div className="row mb-3">
                                            <label className={`form-label col-form-label w-100px`}>
                                                건축면적
                                            </label>
                                            <div className="col" style={{ display: "flex" }}>
                                                <input
                                                    type="text"
                                                    pattern="[0-9]*"
                                                    className="form-control col text-end"
                                                    placeholder="숫자만 입력하세요"
                                                    name="facilityarea2"
                                                    value={facilityarea2}
                                                    id="facilityarea2"
                                                    onChange={(e) =>
                                                        handleChange("facilityarea2", e.target.value)
                                                    }
                                                    // readOnly
                                                    disabled={!editSign}
                                                />
                                                <label
                                                    className={`form-label col-form-label w-40px text-end`}
                                                >
                                                    m<sup>2</sup>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="row mb-3">
                                            <label className={`form-label col-form-label w-100px`}>
                                                주구조
                                            </label>
                                            <div className="col">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="예) 삼성 아이티밸리"
                                                    name="facilitystructure"
                                                    value={facilitystructure}
                                                    onChange={(e) =>
                                                        handleChange("facilitystructure", e.target.value)
                                                    }
                                                    readOnly
                                                // disabled={!editSign}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="mb-3 row">
                                        <label className={`form-label col-form-label w-100px`}>
                                            비고
                                        </label>
                                        <div className="col-md-9">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="내용을 입력하세요."
                                                value={facilityetc}
                                                name="facilityetc"
                                                onChange={(e) =>
                                                    handleChange("facilityetc", e.target.value)
                                                }
                                                disabled={!editSign}
                                            />
                                        </div>
                                    </div>
                                </div>
                                {editSign === false ? (
                                    <>
                                        <button
                                            className="btn btn-danger btn-lg float-end m-1"
                                            onClick={handleDeleteSubmit}
                                        >
                                            삭제
                                        </button>
                                        <button
                                            className="btn btn-info btn-lg float-end m-1"
                                            onClick={hadleUpdateSign}
                                        >
                                            수정
                                        </button>
                                    </>
                                ) : (
                                    <button
                                        className="btn btn-info btn-lg float-end"
                                        onClick={hadleUpdateSubmit}
                                        id="result"
                                    >
                                        수정 완료
                                    </button>
                                )}
                            </div>
                        ) : (
                            ""
                        )}
                    </form>

                    <div id="FmsSecond" className="d-none">
                        {isSearched ? (
                            nullImg ? (
                                <div id="FmsSecondCreate">
                                    <div id="createFmsSecond">
                                        .jpg 또는 jpeg 형식의 파일만 업로드 할 수 있습니다.(최대
                                        5MB를 권장합니다.)
                                        {/* (가로가 긴 이미지만 사용해 주세요.) */}
                                        <div className="row mb-3 position-relative">
                                            <label className={`form-label col-form-label col-md-3`}>
                                                시설물 위치사진
                                            </label>
                                            <div className="col-md-9">
                                                {/* {posiImg} */}
                                                <BuildingImg
                                                    className="position-absolute"
                                                    facilityid={input.facilityid}
                                                />
                                            </div>
                                        </div>
                                        <div className="row mb-3 position-relative">
                                            <label className={`form-label col-form-label col-md-3`}>
                                                시설물 전경사진
                                            </label>
                                            <div className="col-md-9">
                                                <PanoramaImg facilityid={input.facilityid} />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div>
                                                <label className="form-label col-form-label w-100px">
                                                    도면첨부
                                                </label>
                                                <span className="fas fa-exclamation">
                                                    .jpg 또는 jpeg 형식의 파일만 업로드 할 수
                                                    있습니다.(최대 5MB를 권장합니다.)
                                                    {/* (가로가 긴 이미지만 사용해 주세요.) */}
                                                </span>
                                            </div>
                                            <ul className="col">
                                                <UnderImg
                                                    floor={facilityfloordown}
                                                    facilityid={detailFacilityId}
                                                    facilityid2={input.facilityid}
                                                />
                                            </ul>
                                            <ul className="col">
                                                <UpImg
                                                    floor={facilityfloorup}
                                                    facilityid={detailFacilityId}
                                                    facilityid2={input.facilityid}
                                                />
                                            </ul>
                                            <div>
                                                <ul className="col">
                                                    <ElevationImg
                                                        elevation={facilityelevation}
                                                        facilityid={detailFacilityId}
                                                        facilityid2={input.facilityid}
                                                    />
                                                </ul>
                                            </div>
                                        </div>
                                        <button
                                            className="btn btn-info btn-lg float-start"
                                            onClick={prev}
                                        >
                                            이전으로
                                        </button>
                                        <input
                                            type="submit"
                                            name="submit"
                                            id="submit"
                                            className="btn btn-info btn-lg float-end"
                                            onClick={handleCreateImg}
                                            value="도면 등록"
                                            disabled={disableCreateImg}
                                        />
                                    </div>
                                </div>
                            ) : (
                                <div id="updateFmsSecond">
                                    <div className="row mb-3">
                                        <div className={`col-sm-6 ${styles.get_img_box}`}>
                                            <label className={`form-label col-form-label`}>
                                                시설물 위치사진
                                            </label>

                                            {posiImg.posi_seq !== "" ? (
                                                <img
                                                    src={posiImg.img}
                                                    alt={posiImg.seq}
                                                    id={posiImg.seq}
                                                    className="w-100 d-block"
                                                />
                                            ) : (
                                                <div className="text-red">
                                                    등록된 시설물 위치 사진이 없습니다.
                                                </div>
                                            )}

                                            {editImgSign ? (
                                                <BuildingImg
                                                    facilityid={input.facilityid}
                                                    seq={posiImg.posi_seq}
                                                />
                                            ) : (
                                                ""
                                            )}
                                        </div>
                                        <div className={`col-sm-6 ${styles.get_img_box}`}>
                                            <label className={`form-label col-form-label`}>
                                                시설물 전경사진
                                            </label>
                                            {panoImg.pano_seq !== "" ? (
                                                <img
                                                    src={panoImg.img}
                                                    alt={panoImg.seq}
                                                    id={panoImg.seq}
                                                    className="w-100 d-block"
                                                />
                                            ) : (
                                                <div className="text-red">
                                                    등록된 시설물 전경 사진이 없습니다.
                                                </div>
                                            )}

                                            {editImgSign ? (
                                                <PanoramaImg
                                                    facilityid={input.facilityid}
                                                    seq={panoImg.pano_seq}
                                                />
                                            ) : (
                                                ""
                                            )}
                                        </div>
                                    </div>
                                    <div className="row">
                                        {editImgSign ? (
                                            <div color="text-red">
                                                <label className="form-label col-form-label w-100px">
                                                    도면첨부
                                                </label>
                                                <span className="fas fa-exclamation">
                                                    .jpg 또는 jpeg 형식의 파일만 업로드 할 수
                                                    있습니다.(최대 5MB를 권장합니다.)
                                                </span>
                                            </div>
                                        ) : null}
                                        <div className="col-sm-7">
                                            {
                                                planArr.map((i) => {
                                                    let k = Math.abs(i.floor);
                                                    // console.log(planArr, i.floor);
                                                    return (

                                                        <div className={`${styles.planArr_card}`} key={i.seq}>
                                                            {i.floor < 0 && "지하" + k + " 층"}
                                                            {i.floor > 0 && i.floor < 996 && "지상" + i.floor + " 층"}
                                                            {i.floor === 999 && "정면도"}
                                                            {i.floor === 998 && "우측면도"}
                                                            {i.floor === 997 && "배면도"}
                                                            {i.floor === 996 && "좌측면도"}
                                                            <img
                                                                src={i.img}
                                                                alt={i.floor < 0 ? "지하" + k : "지상" + i.floor}
                                                                className={`${styles.planArr_img}`}
                                                            />
                                                        </div>
                                                    );
                                                })
                                            }
                                        </div>
                                        <div className="col-sm-5">
                                            {editImgSign ? (
                                                <>
                                                    <ul className={`${styles.editImgInput} col p-0`}>
                                                        <UnderImg
                                                            floor={facilityfloordown}
                                                            facilityid={detailFacilityId}
                                                            facilityid2={input.facilityid}
                                                        />
                                                    </ul>
                                                    <ul className={`${styles.editImgInput} col p-0`}>
                                                        <UpImg
                                                            floor={facilityfloorup}
                                                            facilityid={detailFacilityId}
                                                            facilityid2={input.facilityid}
                                                        />
                                                    </ul>
                                                    <div>
                                                        <ul className={`${styles.editImgInput} col p-0`}>
                                                            <ElevationImg
                                                                elevation={facilityelevation}
                                                                facilityid={detailFacilityId}
                                                                facilityid2={input.facilityid}
                                                            />
                                                        </ul>
                                                    </div>
                                                </>
                                            ) : (
                                                ""
                                            )}
                                        </div>
                                    </div>
                                    {editImgSign ? (
                                        <button
                                            className="btn btn-info btn-lg float-end"
                                            onClick={handleUpdateImg}
                                        >
                                            이미지 수정 완료
                                        </button>
                                    ) : (
                                        <button
                                            className="btn btn-default float-end"
                                            onClick={handleImgSign}
                                        >
                                            이미지 수정
                                        </button>
                                    )}
                                </div>
                            )
                        ) : (
                            ""
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export { Fms };
