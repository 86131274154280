import React, { useState, useCallback, useEffect } from "react";
import { useResultContext } from "../../Contexts/LayoutContext";

const URL = process.env.REACT_APP_BACKEND_HOST;

const API = URL + "/project/prj_list";

let prj_list = [];
let result_prj = "";
let result_name = "";
let result_date = "";
let isSearched = false;
let mutex = 0;
let project = {
  facilityaddress: "",
  facilityarea1: "",
  facilityarea2: "",
  facilitydate: "",
  facilitydivide: "",
  facilityetc: "",
  facilityfloordown: "",
  facilityfloorup: "",
  facilityid: "",
  facilityname: "",
  facilityowner: "",
  facilitystructure: "",
  facilityuse: "",
  userid: "",
};

let ownList_arr = [];
let ownList_item = {
  address: "",
  name: "",
  divide: "",
  facilityID: "",
};
const Hangul = require("hangul-js");

function Layout() {
  const [searchInput, setSearchInput] = useState("");
  const [projectName, setProjectName] = useState("");
  const [projectAddress, setProjectAddress] = useState("");
  const [projectDivide, setProjectDivide] = useState("");
  const {
    setResult,
    setDetailFacilityId,
    setReturnPrjName,
    setReturnPrjAddress,
    setReturnPrjDivide,
  } = useResultContext();
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);

  const token = "bearer " + sessionStorage.getItem("token");
  useEffect(() => {
    try {
      if (sessionStorage.getItem("token") === null) {
        window.location.replace("/home");
      } else {
        fetch(API, {
          method: "GET",
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
            Authorization: token,
            Accept: "application/json;charset=UTF-8",
          },
        })
          .then((res) => {
            if (res.status / 100 === 2) {
              return res.json();
            } else {
              alert(
                "알수없는 오류가 발생하였습니다. 새로고침 후 다시 시도해주세요."
              );
            }
          })
          .then((res) => {
            const response_arr = res["results"];
            ownList_arr = [];

            if (res["results"].length > 0) {
              for (let i = 0; i < res["results"].length; i++) {
                project = res["results"][i];
                prj_list.unshift(project);
              }

              for (let i = 0; i < response_arr.length; i++) {
                ownList_item.address = response_arr[i]["facilityaddress"];
                ownList_item.name = response_arr[i]["facilityname"];
                ownList_item.divide = response_arr[i]["facilitydivide"];
                ownList_item.facilityID = response_arr[i]["facilityid"];

                ownList_arr.unshift(ownList_item);

                ownList_item = {
                  address: "",
                  name: "",
                  divide: "",
                  facilityID: "",
                };
              }
            }

            forceUpdate();
          });
      }
    } catch (e) {
      throw new Error("비정산적인 접근입니다. 로그인 후 이용해주세요.");
    }

    if (sessionStorage.getItem("token") === null) {
      alert("비정산적인 접근입니다. 로그인 후 이용해주세요.");
      window.location.replace("/login");
    }
  }, []);

  const searchText = (e) => {
    let searchValue = e.target.value;
    setSearchInput(searchValue);
  };

  ownList_arr.forEach(function (item) {
    var dis = Hangul.disassemble(item.name, true);
    var cho = dis.reduce(function (prev, elem) {
      elem = elem[0] ? elem[0] : elem;

      return prev + elem;
    }, "");
    item.diassembled = cho;
  });

  function hangulSearch() {
    document
      .getElementById("searchInput")
      .addEventListener("keyup", function () {
        let ul = document.getElementById("find");
        while (ul.firstChild) {
          ul.removeChild(ul.firstChild);
        }

        var search = this.value;
        var search1 = Hangul.disassemble(search).join(""); // ㄺ=>ㄹㄱ

        ownList_arr
          // 문자열 검색 || 초성검색
          .filter(function (item) {
            return (
              item.name.includes(search) || item.diassembled.includes(search1)
            );
          })
          // 검색결과 ul 아래에 li 로 추가
          .forEach(function (item) {
            var div = document.createElement("div");

            div.setAttribute("data-divide", item.divide);
            div.setAttribute("data-name", item.name);
            div.setAttribute("data-address", item.address);
            div.setAttribute("data-facilityid", item.facilityID);
            div.onclick = function (e) {
              getListInfo(e);
            };
            div.style.cursor = "pointer";

            let content = '<li class="search_result_listItem d-flex mb-5px">';
            content += '<div class="d-flex">';
            content +=
              '<div class="result_listItem_division fw-bold w-90px" data-divide="' +
              item.divide +
              '" data-name="' +
              item.name +
              '" data-address="' +
              item.address;
            content += '" style="color:';
            if (item.divide === "안전점검") {
              content += '#D4AC0D">';
            } else if (item.divide === "정밀진단") {
              content += '#52BE80">';
            } else if (item.divide === "구조점검") {
              content += '#DC7633">';
            } else if (item.divide === "하자진단") {
              content += '#5DADE2">';
            } else {
              content += '#1F618D">';
            }
            content += item.divide + "</div>";
            content += '<div class="ps-10px w-200px">';
            content += '<div class="result_listItem_building fw-bold"';
            content += 'data-divide="';
            content += item.divide + '" data-name="';
            content += item.name + '" data-address="';
            content += item.address + '" >';
            content += item.name;
            content += " </div>";
            content +=
              ' <div class="result_listItem_address fs-12px text-gray-600" style="display: block; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;"';
            content += 'data-divide="';
            content += item.divide + '" data-name="';
            content += item.name + '" data-address="';
            content += item.address + '" >';
            content += item.address;
            content += "</div>";
            content += "</div>";
            content += "</li>";
            content += "</div>";
            div.innerHTML = content;

            if (search1 === "") {
              div.innerHTML = "";
            }
            ul.appendChild(div);
          });
      });
  }

  const getListInfo = (e) => {
    const getDivide = e.target.getAttribute("data-divide");
    const getAddress = e.target.getAttribute("data-address");
    const getName = e.target.getAttribute("data-name");
    const getID = e.target.getAttribute("data-facilityid");

    for (let i = 0; i < prj_list.length; i++) {
      if (prj_list[i].facilityid === Number(getID)) {
        setProjectName(getName);
        setProjectAddress(getAddress);
        setProjectDivide(getDivide);
        result_prj = prj_list[i]["facilityid"];
        result_name = prj_list[i]["facilityname"];
        result_date = prj_list[i]["facilitydate"];
        mutex = 1;
        isSearched = true;
        setResult(1);
        setDetailFacilityId(result_prj);
        setReturnPrjName(prj_list[i]["facilityname"]);
        setReturnPrjAddress(prj_list[i]["facilityaddress"]);
        setReturnPrjDivide(prj_list[i]["facilitydivide"]);
        forceUpdate();
      }
    }
  };

  useEffect(() => {
    hangulSearch();
  }, []);

  return (
    <div className="search_form_wrap col-sm-4">
      <div
        className={
          (useResultContext().detailFacilityId.length === 0
            ? "d-none "
            : "d-block ") + "search_form_right"
        }
      >
        <label className="form-label text-gray-500" htmlFor="시설물명">
          시설물명
        </label>
        <div
          className="col form-control bg-blue-100"
          style={{ height: "34.97px" }}
        >
          {projectName}
        </div>

        <label className="form-label text-gray-500" htmlFor="시설물 주소">
          시설물 주소
        </label>
        <div
          className="col form-control bg-blue-100"
          style={{ height: "34.97px" }}
        >
          {projectAddress}
        </div>
      </div>
      <div className="search_form_left">
        <div className="search_form">
          <label
            className="form-check-label col-form-label p-0 mb-1 text-gray-600"
            htmlFor="inlineCheckbox1"
          >
            시설물명 조회
          </label>
          <div className="menu">
            <div className="menu-search mb-n3">
              <input
                type="text"
                className="form-control"
                placeholder="건축물 이름을 검색하세요."
                onKeyUp={searchText}
                name="searchInput"
                id="searchInput"
                autoComplete="false"
              />
              {/* <button
                  type="submit"
                  className="btn btn-search search_btn"
                  onClick={hadleSearch}
                >
                  <i className="fa fa-search"></i>
                </button> */}
            </div>
          </div>
        </div>
        <div className="search_result_list_wrap">
          <ul id="find" className="searchResultUl">
            <li className="findChild"></li>
          </ul>
          <div className="around">등록된 시설물 리스트</div>
          <ul>
            {ownList_arr.map((listItem, i) => {
              return (
                <div
                  onClick={(e) => getListInfo(e)}
                  data-divide={listItem.divide}
                  data-name={listItem.name}
                  data-address={listItem.address}
                  data-facilityid={listItem.facilityID}
                  style={{
                    cursor: "pointer",
                  }}
                  key={i}
                >
                  <li key={i} className="search_result_listItem d-flex mb-5px">
                    <div className="d-flex">
                      <div
                        className="result_listItem_division fw-bold w-90px"
                        data-divide={listItem.divide}
                        data-name={listItem.name}
                        data-facilityid={listItem.facilityID}
                        data-address={listItem.address}
                        style={{
                          color:
                            listItem.divide === "안전점검"
                              ? "#D4AC0D"
                              : listItem.divide === "정밀진단"
                              ? "#52BE80"
                              : listItem.divide === "구조점검"
                              ? "#DC7633"
                              : listItem.divide === "하자진단"
                              ? "#5DADE2"
                              : "#1F618D",
                        }}
                      >
                        {listItem.divide}
                      </div>
                      <div className="ps-10px w-200px">
                        <div
                          className="result_listItem_building fw-bold"
                          data-divide={listItem.divide}
                          data-name={listItem.name}
                          data-facilityid={listItem.facilityID}
                          data-address={listItem.address}
                        >
                          {listItem.name}
                        </div>
                        <div
                          style={{
                            display: "block",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                          }}
                          className="result_listItem_address fs-12px text-gray-600"
                          data-divide={listItem.divide}
                          data-name={listItem.name}
                          data-facilityid={listItem.facilityID}
                          data-address={listItem.address}
                        >
                          {listItem.address}
                        </div>
                      </div>
                    </div>
                  </li>
                </div>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
}
export {
  Layout,
  result_prj,
  result_date,
  result_name,
  isSearched,
  mutex,
  prj_list,
};
