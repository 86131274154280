import { React, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import moment from "moment";

const URL = process.env.REACT_APP_BACKEND_HOST;
const LIST_VIEW = URL + "/boards/view/notice";
const LIST_PATCH = URL + "/boards/update/notice";
const CHECK_MEM = URL + "/members/check/admin";
const token = "bearer " + sessionStorage.getItem("token");

const CsFaqView = ({ history, match }) => {
  const [data, setData] = useState({});
  const [date, setDate] = useState({
    created_at: "",
    updated_at: "",
  });
  const [admin, setAdmin] = useState(false);

  const { no } = match.params;

  useEffect(() => {
    fetch(CHECK_MEM, {
      method: "GET",
      headers: {
        Authorization: token,
      },
    })
      .then((res) => {
        if (res.status / 100 === 2) {
          return res.json();
        } else {
          alert(
            "알수없는 오류가 발생하였습니다. 새로고침 후 다시 시도해주세요."
          );
        }
      })
      .then((res) => {
        setAdmin(res);
      });
  });

  useEffect(() => {
    fetch(LIST_VIEW + "?id=" + no, {
      method: "GET",
    })
      .then((res) => {
        if (res.status / 100 === 2) {
          return res.json();
        } else {
          alert(
            "알수없는 오류가 발생하였습니다. 새로고침 후 다시 시도해주세요."
          );
        }
      })
      .then((res) => {
        setData(res);

        setDate({
          created_at: moment(res.created_at).format("yyyy.MM.DD"),
          updated_at: moment(res.updated_at).format("yyyy.MM.DD"),
        });
      });
  }, []);

  const handleDelete = () => {
    const formData = new FormData();

    alert("삭제하시겠습니까?");

    formData.append("id", no);
    formData.append("is_public", "0");

    fetch(LIST_PATCH, {
      method: "PATCH",
      headers: {
        Authorization: token,
      },
      body: formData,
    })
      .then((res) => {
        if (res.status / 100 === 2) {
          return res.json();
        } else {
          alert(
            "알수없는 오류가 발생하였습니다. 새로고침 후 다시 시도해주세요."
          );
        }
      })
      .then((res) => {
        alert("삭제 되었습니다.");
        window.location.replace("/cs/faq");
      });
  };

  return (
    <>
      <div className="post-view-wrapper col-lg-7 col-md-9 ">
        <div className="common_title">
          <h2 className="title">지주하는 질문</h2>
          <p className="txt">
            자주 하는 질문들을 모아놨습니다.기본적인 사용 가이드와 함께
            참고하세요.
          </p>
        </div>

        {data ? (
          <div className="post-view-table">
            <div className="post-view-title">
              <div className="tit_end">{data.title}</div>
              <div>
                {date.created_at !== date.updated_at
                  ? date.updated_at
                  : date.created_at}
              </div>
            </div>

            <div className="post-view-row">
              <div dangerouslySetInnerHTML={{ __html: data.content }}></div>
            </div>
          </div>
        ) : (
          "해당 게시글을 찾을 수 없습니다."
        )}
        {admin ? (
          <>
            <Link
              className="btn btn-primary float-end mt-1"
              to={{
                pathname: `/faqOADMCEdit/${no}`,
                state: {
                  content: data.content,
                  title: data.title,
                },
              }}
            >
              수정
            </Link>

            <button
              className="btn btn-primary float-end mt-1"
              onClick={() => handleDelete()}
            >
              삭제
            </button>
          </>
        ) : null}
        <button
          className="btn btn-primary float-end mt-1"
          onClick={() => history.goBack()}
        >
          목록으로 돌아가기
        </button>
      </div>
    </>
  );
};

export { CsFaqView };
