import React, { useCallback } from "react";
import { Link, withRouter } from "react-router-dom";
import { AppSettings } from "./../../config/app-settings.js";
import styles from "../../assets/css/register.module.css";
import DaumPostCode from "react-daum-postcode";

const URL = process.env.REACT_APP_BACKEND_HOST;
const API = URL + "/members/"; //수정 필요
// const API = "members/"; //수정 필요

const validateForm = (errors) => {
  let valid = true;
  Object.values(errors).forEach((val) => val.length > 0 && (valid = false));
  return valid;
};

const isEmail = (email) => {
  const emailRegex =
    /^(([^<>()\[\].,;:\s@"]+(\.[^<>()\[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;

  return emailRegex.test(email);
};
class Register extends React.Component {
  static contextType = AppSettings;

  constructor(props) {
    super(props);
    this.state = {
      userid: "",
      usingid: false,
      password: "",
      passwordAgain: "",
      companydivide: "0",
      companyname: "",
      companyaddress: "",
      addressdetail: "",
      companynumber1: "",
      companynumber2: "",
      companynumber3: "",
      presidentname: "",
      personname: "",
      personphone1: "",
      personphone2: "",
      personphone3: "",
      companyemail: "",
      stringyear: "",
      stringmonth: "",
      stringday: "",
      agreementCheckbox: 0,
      department: "",
      zoneCode: "",
      isDaumPost: false,
      isRegister: false,
      register: [],
      //아이디 중복
      checkError: "",
      errors: {
        userid: "",
        usingid: "",
        password: "",
        passwordAgain: "",
        companydivide: "",
        companyname: "",
        companyaddress: "",
        addressdetail: "",
        companynumber1: "",
        companynumber2: "",
        companynumber3: "",
        presidentname: "",
        personname: "",
        personphone1: "",
        personphone2: "",
        personphone3: "",
        companyemail: "",
        stringyear: "",
        stringmonth: "",
        stringday: "",
        department: "",
      },
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.selectComDiv = this.selectComDiv.bind(this);
    this.checkID = this.checkID.bind(this);
  }

  handleAddress = (data) => {
    let AllAddress = data.address;
    let extraAddress = "";
    let zoneCodes = data.zonecode;

    if (data.addressType === "R") {
      if (data.bname !== "") {
        extraAddress += data.bname;
      }
      if (data.buildingName !== "") {
        extraAddress +=
          extraAddress !== "" ? `, ${data.buildingName}` : data.buildingName;
      }
      AllAddress += extraAddress !== "" ? ` (${extraAddress})` : "";
    }
    this.setState({
      companyaddress: AllAddress,
      zoneCode: zoneCodes,
    });
  };

  noSpaceForm(obj) {
    var str_space = /\s/; // 공백 체크
    if (str_space.exec(obj.value)) {
      // 공백 체크
      alert("해당 항목에는 공백을 사용할 수 없습니다.\n\n공백 제거됩니다.");
      obj.focus();
      obj.value = obj.value.replace(" ", ""); // 공백제거
      return false;
    }
  }

  handleInput = (e) => {
    const { name, value } = e.target;
    let errors = this.state.errors;

    const passCheck = /^(?=.*?[0-9])(?=.*?[#?!@$ %^&*-]).{8,15}$/;
    const idCheck = /^[a-zA-Z][0-9a-zA-Z]{5,15}$/;
    const blank_pattern = /[\s]/g;

    switch (name) {
      case "userid":
        errors.userid =
          idCheck.test(value) && !blank_pattern.test(value)
            ? ""
            : "대문자/공백/특수문자가 포함되었거나, 숫자로 시작 또는 숫자로만 이루어진 아이디는 사용할 수 없습니다. (영문소문자/숫자, 6~15자)";
        break;
      case "usingid":
        errors.userid = value === false ? "아이디 중복확인을 해주세요2." : "";
        break;
      case "password":
        errors.password =
          passCheck.test(value) && !blank_pattern.test(value)
            ? ""
            : "영문 대소문자/숫자/특수문자( #?!@$ %^&*- )최소 1개씩 포함, 8자~15자";
        break;
      case "companydivide":
        errors.companydivide = value === "" ? "확인해주세요." : "";
        break;
      case "companyname":
        errors.companyname =
          value.length < 2 ? "회사명을 정확하게 입력해주세요." : "";
        break;
      case "companyaddress":
        errors.companyaddress =
          value.length < 3 ? "주소를 정확하게 입력해주세요." : "";
        break;
      case "addressdetail":
        errors.addressdetail =
          value.length < 2 ? "상세주소를 정확하게 입력해주세요." : "";
        break;
      case "companynumber1":
        errors.companynumber1 =
          value.length < 3 ? "첫자리는 3자리 입니다." : "";
        break;
      case "companynumber2":
        errors.companynumber2 = value.length < 2 ? "중간은 2자리 입니다." : "";
        break;
      case "companynumber3":
        errors.companynumber3 =
          value.length < 5 ? "마지막은 5자리 입니다." : "";
        break;
      case "presidentname":
        errors.presidentname =
          value.length < 2 ? "대표자명을 정확하게 입력해주세요." : "";
        break;
      case "personname":
        errors.personname =
          value.length < 3 ? "사용자이름을 정확하게 입력해주세요." : "";
        break;
      case "personphone1":
        errors.personphone1 =
          value.length < 2 ? "핸드폰 번호를 정확히 입력해주세요." : "";
        break;
      case "personphone2":
        errors.personphone2 =
          value.length < 3 ? "핸드폰 번호를 정확히 입력해주세요." : "";
        break;
      case "personphone3":
        errors.personphone3 =
          value.length < 3 ? "핸드폰 번호를 정확히 입력해주세요." : "";
        break;
      case "department":
        errors.department = value.length < "3" ? "소속을 입력해주세요." : "";
        break;
      case "companyemail":
        errors.companyemail = value.includes("@")
          ? ""
          : "유효하지 않은 이메일 형식입니다.";
        break;
      default:
        break;
    }

    if (this.state.password !== this.state.passwordAgain) {
      errors.passwordAgain =
        this.state.password !== this.state.passwordAgain
          ? "비밀번호가 일치하지않습니다."
          : "";
    }
    if (this.state.password === this.state.passwordAgain) {
      errors.passwordAgain = "";
    }

    this.setState({ errors, [name]: value }, () => {});
  };

  selectComDiv = (e) => {
    this.setState({ companydivide: e.target.value });
  };

  toggleChange = (e) => {
    this.setState({
      agreementCheckbox: !this.state.agreementCheckbox,
    });
  };

  checkID(e) {
    e.preventDefault();
    const formdata = new FormData();

    if (this.state.userid === "") {
      alert("아이디를 입력해주세요.");
    } else if (this.state.errors.userid.length !== 0) {
      alert("영문 소 대 문자/숫자 6~15자 입니다.");

      this.setState({ userid: "" });
    } else if (this.state.errors.userid.length === 0) {
      formdata.append("userID", this.state.userid);

      fetch(API + "checkDuplication/", {
        method: "post",
        body: formdata,
      })
        .then((res) => {
          return res.json();
        })
        .then((json) => {
          if (json.duplication === "true") {
            alert("사용가능한 ID입니다");
            this.setState({
              usingid: true,
            });
          } else {
            alert("다른 ID를 입력해주세요");
          }
        });
    }
  }

  handleSubmit(event) {
    event.preventDefault();

    if (this.state.userid === "" || this.state.errors.userid !== "") {
      alert("회사아이디를 확인해주세요.");
    } else if (
      this.state.usingid === false ||
      this.state.errors.usingid !== ""
    ) {
      alert("아이디 중복확인을 해주세요1.");
    } else if (this.state.password === "" || this.state.errors.userid !== "") {
      alert("비밀번호를 확인해주세요.");
    } else if (
      this.state.passwordAgain === "" ||
      this.state.errors.passwordAgain !== ""
    ) {
      alert("비밀번호를 재확인 해주세요.");
    } else if (
      this.state.companydivide === "0" ||
      this.state.errors.companydivide !== ""
    ) {
      alert("회사 구분을 확인해주세요.");
    } else if (
      this.state.companyname === "" ||
      this.state.errors.companyname !== ""
    ) {
      alert("회사명을 확인해주세요.");
    } else if (
      this.state.companyaddress === "" ||
      this.state.errors.companyaddress !== ""
    ) {
      alert("회사 주소를 확인해주세요.");
    } else if (
      this.state.addressdetail === "" ||
      this.state.errors.addressdetail !== ""
    ) {
      alert("상세주소를 확인해주세요.");
    } else if (
      this.state.companynumber1 === "" ||
      this.state.errors.companynumber1 !== ""
    ) {
      alert("사업자번호를 확인해주세요.");
    } else if (
      this.state.companynumber2 === "" ||
      this.state.errors.companynumber2 !== ""
    ) {
      alert("사업자번호를 확인해주세요.");
    } else if (
      this.state.companynumber3 === "" ||
      this.state.errors.companynumber3 !== ""
    ) {
      alert("사업자번호를 확인해주세요.");
    } else if (
      this.state.presidentname === "" ||
      this.state.errors.presidentname !== ""
    ) {
      alert("대표자명을 확인해주세요.");
    } else if (
      this.state.personname === "" ||
      this.state.errors.personname !== ""
    ) {
      alert("사용자명을 확인해주세요.");
    } else if (
      this.state.personphone1 === "" ||
      this.state.errors.personphone1 !== ""
    ) {
      alert("사용자 전화번호를 확인해주세요.");
    } else if (
      this.state.personphone2 === "" ||
      this.state.errors.personphone2 !== ""
    ) {
      alert("사용자 전화번호를 확인해주세요.");
    } else if (
      this.state.personphone3 === "" ||
      this.state.errors.personphone3 !== ""
    ) {
      alert("사용자 전화번호를 확인해주세요.");
    } else if (
      this.state.companyemail === "" ||
      this.state.errors.companyemail !== ""
    ) {
      alert("회사메일을 확인해주세요.");
    } else if (
      this.state.department === "" ||
      this.state.errors.department !== ""
    ) {
      alert("소속을 확인해주세요.");
    } else if (this.state.agreementCheckbox === 0) {
      alert("이용약관에 동의하셔야 합니다.");
    } else {
      if (validateForm(this.state.errors)) {
        fetch(API + "company/", {
          method: "POST",
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
          },
          body: JSON.stringify({
            companydivide: this.state.companydivide,
            companyname: this.state.companyname,
            companyaddress: this.state.companyaddress,
            addressdetail: this.state.addressdetail,
            companynumber:
              this.state.companynumber1 +
              "-" +
              this.state.companynumber2 +
              "-" +
              this.state.companynumber3,
            presidentname: this.state.presidentname,
            zoneCode: this.state.zoneCode,
          }),
        })
          .then((res) => {
            return res.json();
          })
          .then((res) => {
            fetch(API + "create/", {
              method: "POST",
              headers: {
                "Content-Type": "application/json;charset=UTF-8",
              },
              body: JSON.stringify({
                userid: this.state.userid,
                password: this.state.password,
                personname: this.state.personname,
                personphone1: this.state.personphone1,
                personphone2: this.state.personphone2,
                personphone3: this.state.personphone3,
                companyemail: this.state.companyemail,
                stringyear: this.state.stringyear,
                stringmonth: this.state.stringmonth,
                stringday: this.state.stringday,
                department: this.state.department,
                companynumber:
                  this.state.companynumber1 +
                  "-" +
                  this.state.companynumber2 +
                  "-" +
                  this.state.companynumber3,
              }),
            })
              .then((res) => {
                return res.json();
                // if (res.status / 100 === 2) {
                //   return res.json();
                // } else {
                //   alert(
                //     "알수없는 오류가 발생하였습니다. 새로고침 후 다시 시도해주세요."
                //   );
                // }
              })
              .then((res) => {
                if (res.message === "ok") {
                  alert("회원가입에 성공하였습니다.");
                  this.props.history.push("/home");
                } else {
                  alert("회원가입에 실패하였습니다.");
                }
              });
          });
      }
    }
  }

  render() {
    const { handleInput } = this;
    const {
      // kaddress,
      addressdetail,
      isDaumPost,
      companyaddress,
      zoneCode,
      errors,
    } = this.state;

    const modalStyle = {
      position: "absolute",
      // top: 0,
      width: "600px",
      height: "400px",
      left: "340px",
      zIndex: "100",
      border: "1px solid #000000",
      overflow: "hidden",
    };

    return (
      <div className="register register-with-news-feed">
        <div className="row">
          <form
            onSubmit={this.handleSubmit}
            className="fs-13px col-sm-8 row m-auto"
          >
            <div className="register-header mb-25px h1">
              <div className="mb-1">회원가입</div>
              <small className="d-block fs-15px lh-16">
                건축시설물 안전진단 솔루션 Ascope에 오신것을 환영합니다.
              </small>
            </div>
            <div className={`${styles.divide}`}>회사 정보</div>
            <div className="col-sm-6">
              <div className="row mb-3">
                <label className="form-label col-form-label col-md-4">
                  회사명
                  <span className="text-danger">*</span>
                </label>

                <div className="col-md-8">
                  <input
                    type="text"
                    onChange={handleInput}
                    name="companyname"
                    id="companyname"
                    className={
                      (errors.companyname.length > 0 ? "border-danger " : "") +
                      "form-control fs-13px col"
                    }
                    placeholder="회사명"
                  />
                  {errors.companyname.length > 0 && (
                    <span className={`${styles.errorMessage}`}>
                      {errors.companyname}
                    </span>
                  )}
                </div>
              </div>
              <div className="row mb-3">
                <label className="form-label col-form-label col-md-4">
                  회사 구분
                  <span className="text-danger">*</span>
                </label>
                <div className="col-md-8">
                  <select
                    className={
                      (errors.companydivide.length > 0
                        ? "border-danger "
                        : "") + "form-select"
                    }
                    onChange={this.selectComDiv}
                    value={this.state.companydivide}
                    defaultValue="0"
                  >
                    <option value="0" name="" disabled>
                      선택하세요
                    </option>
                    <option value="개인" name="개인">
                      개인
                    </option>
                    <option value="법인" name="법인">
                      법인
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="row mb-3" style={{ position: "relative" }}>
                <label className="form-label col-form-label col-md-4">
                  사업자 번호
                  <span className="text-danger">*</span>
                </label>
                <div className="col-md-8">
                  <div className="d-flex">
                    <input
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      type="text"
                      onChange={handleInput}
                      name="companynumber1"
                      id="companynumber1"
                      className={
                        (errors.companynumber1.length > 0
                          ? "border-danger "
                          : "") + "form-control fs-13px col"
                      }
                      maxLength="3"
                    />
                    <input
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      type="text"
                      onChange={handleInput}
                      name="companynumber2"
                      id="companynumber2"
                      className={
                        (errors.companynumber2.length > 0
                          ? "border-danger "
                          : "") + "form-control fs-13px col"
                      }
                      maxLength="2"
                    />
                    <input
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      type="text"
                      onChange={handleInput}
                      name="companynumber3"
                      id="companynumber3"
                      className={
                        (errors.companynumber3.length > 0
                          ? "border-danger "
                          : "") + "form-control fs-13px col"
                      }
                      maxLength="5"
                    />
                  </div>
                </div>
              </div>
              <div className="row mb-3">
                <label className="form-label col-form-label col-md-4">
                  대표자명
                  <span className="text-danger">*</span>
                </label>
                <div className="col-md-8">
                  <input
                    type="text"
                    onChange={handleInput}
                    name="presidentname"
                    id="presidentname"
                    className={
                      (errors.presidentname.length > 0
                        ? "border-danger "
                        : "") + "form-control fs-13px col"
                    }
                    placeholder="대표자명"
                  />
                  {errors.presidentname.length > 0 && (
                    <span className={`${styles.errorMessage}`}>
                      {errors.presidentname}
                    </span>
                  )}
                </div>
              </div>
            </div>
            <div className="row">
              <label className="form-label col-form-label col-md-2">
                회사 주소
                <span className="text-danger">*</span>
              </label>
              <div className="modalCell col">
                <div className="cellFirst mb-1 d-flex">
                  <div
                    className="zipCode form-control fs-13px w-100px me-3"
                    style={{ height: "34px" }}
                    readOnly
                  >
                    {zoneCode}
                  </div>
                  <button
                    type="button"
                    className="btn btn-default"
                    onClick={() =>
                      this.setState({
                        isDaumPost: !this.state.isDaumPost,
                      })
                    }
                  >
                    <span>우편번호 찾기</span>
                  </button>
                </div>
                {isDaumPost ? (
                  <div>
                    <DaumPostCode
                      onComplete={this.handleAddress}
                      autoClose
                      style={modalStyle}
                      isDaumPost={isDaumPost}
                    />
                  </div>
                ) : null}
                <div className="address col-md-10 mb-1">
                  <div
                    className="form-control fs-13p"
                    style={{ height: "34px" }}
                    readOnly
                  >
                    {companyaddress}
                  </div>
                </div>
                <div className="addressBox col-md-4">
                  <input
                    type="text"
                    // value={kaddress}
                    value={addressdetail}
                    // name="kaddress"
                    name="addressdetail"
                    onChange={handleInput}
                    className="form-control fs-13px"
                    placeholder="상세 주소를 입력해주세요."
                  />
                </div>
              </div>
            </div>
            <div className={`${styles.divide}`}>아이디 정보</div>
            <div className="row mb-3">
              <div className="row col-sm-6">
                <label className="form-label col-form-label col-md-4">
                  회사 아이디
                  <span className="text-danger">*</span>
                </label>
                <div className="col-md-8">
                  <div className="row gx-3">
                    <div className="mb-2 mb-md-0">
                      <input
                        type="text"
                        onChange={handleInput}
                        name="userid"
                        id="userid"
                        className={
                          (errors.userid.length > 0 ? "border-danger " : "") +
                          "form-control fs-13px col"
                        }
                        placeholder="회사 아이디"
                      />
                      {errors.userid.length > 0 && (
                        <div
                          className={`${styles.errorMessage}`}
                          placeholder="사용자 ID"
                        >
                          {errors.userid}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col">
                <label className="form-label col-form-label"></label>
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={this.checkID}
                >
                  중복확인
                </button>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="row mb-3">
                <label className="form-label col-form-label col-md-4">
                  비밀번호
                  <span className="text-danger">*</span>
                </label>
                <div className="col-md-8">
                  <input
                    type="password"
                    // onChange={handleInput}
                    onKeyUp={handleInput}
                    onChange={this.checkPassword}
                    name="password"
                    id="password"
                    className={
                      (errors.password.length > 0 ? "border-danger " : "") +
                      "form-control fs-13px col"
                    }
                    placeholder="비밀번호"
                    maxLength={15}
                  />
                  {errors.password.length > 0 && (
                    <div
                      className={`${styles.errorMessage}`}
                      placeholder="사용자 ID"
                    >
                      {errors.password}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="row mb-3">
                <label className="form-label col-form-label col-md-4">
                  비밀번호 재확인
                  <span className="text-danger">*</span>
                </label>
                <div className="col-md-8">
                  <input
                    type="password"
                    // onChange={handleInput}
                    onKeyUp={handleInput}
                    name="passwordAgain"
                    id="passwordAgain"
                    className={
                      (this.state.password === this.state.passwordAgain
                        ? ""
                        : "border-danger ") + "form-control fs-13px col"
                    }
                    placeholder="비밀번호"
                  />
                  {this.state.password === this.state.passwordAgain ? (
                    ""
                  ) : (
                    <div
                      className={`${styles.errorMessage}`}
                      placeholder="비밀번호 재확인"
                    >
                      {errors.passwordAgain}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className={`${styles.divide}`}>담당자 정보</div>
            <div className="col-sm-6">
              <div className="row mb-3">
                <label className="form-label col-form-label col-md-4">
                  사용자명
                  <span className="text-danger">*</span>
                </label>
                <div className="col-md-8">
                  <input
                    type="text"
                    onChange={handleInput}
                    name="personname"
                    id="personname"
                    className={
                      (errors.personname.length > 0 ? "border-danger " : "") +
                      "form-control fs-13px col"
                    }
                    placeholder="사용자명"
                  />
                  {errors.personname.length > 0 && (
                    <span className={`${styles.errorMessage}`}>
                      {errors.personname}
                    </span>
                  )}
                </div>
              </div>
              <div className="row mb-3" style={{ position: "relative" }}>
                <label className="form-label col-form-label col-md-4">
                  사용자 전화번호
                  <span className="text-danger">*</span>
                </label>
                <div className="col-md-8">
                  <div className="d-flex">
                    <input
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      type="text"
                      onChange={handleInput}
                      name="personphone1"
                      id="personphone1"
                      className={
                        (errors.personphone1.length > 0
                          ? "border-danger "
                          : "") + "form-control fs-13px col"
                      }
                      maxLength={3}
                    />
                    <input
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      type="text"
                      onChange={handleInput}
                      name="personphone2"
                      id="personphone2"
                      className={
                        (errors.personphone2.length > 0
                          ? "border-danger "
                          : "") + "form-control fs-13px col"
                      }
                      maxLength={4}
                    />
                    <input
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      type="text"
                      onChange={handleInput}
                      name="personphone3"
                      id="personphone3"
                      className={
                        (errors.personphone3.length > 0
                          ? "border-danger "
                          : "") + "form-control fs-13px col"
                      }
                      maxLength={4}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="row mb-3">
                <label className="form-label col-form-label col-md-4">
                  소속
                  <span className="text-danger">*</span>
                </label>
                <div className="col-md-8">
                  <input
                    type="text"
                    onChange={handleInput}
                    name="department"
                    id="department"
                    className={
                      (errors.department.length > 0 ? "border-danger " : "") +
                      "form-control fs-13px col"
                    }
                    placeholder="소속을 입력해주세요."
                  />
                  {errors.department.length > 0 && (
                    <span className={`${styles.errorMessage}`}>
                      {errors.department}
                    </span>
                  )}
                </div>
              </div>
              <div className="row mb-3">
                <label className="form-label col-form-label col-md-4">
                  회사메일
                  <span className="text-danger">*</span>
                </label>
                <div className="col-md-8">
                  <input
                    type="email"
                    onChange={handleInput}
                    onBlur={isEmail}
                    name="companyemail"
                    id="companyemail"
                    className={
                      (errors.companyemail.length > 0 ? "border-danger " : "") +
                      "form-control fs-13px col"
                    }
                    placeholder="ascopteTM@example.com"
                  />
                  {errors.companyemail.length > 0 && (
                    <span className={`${styles.errorMessage}`}>
                      {errors.companyemail}
                    </span>
                  )}
                </div>
              </div>
            </div>
            <div className="form-check mb-4">
              <input
                className="form-check-input"
                type="checkbox"
                value=""
                id="agreementCheckbox"
                onChange={this.toggleChange}
                defaultChecked={this.state.agreementCheckbox}
              />
              <label className="form-check-label" htmlFor="agreementCheckbox">
                이용약관에 동의하시면 체크를 눌러주십시오.
                <Link to="/user/term" target={"_blank"}>
                  이용약관 보기
                </Link>
                {/* <Link to="/user/register">Terms</Link> 우리의
                <Link to="/user/register">약관정책</Link>, including our
                <Link to="/user/register">개인정보 수집동의</Link>. */}
              </label>
            </div>
            <div className="mb-4">
              <button
                type="submit"
                className="btn btn-primary d-block w-100 btn-lg h-45px fs-13px"
              >
                회원가입하기
              </button>
            </div>
            <div className="mb-4 pb-5">
              이미 가입한 회원이시라면 <Link to="/login">로그인</Link>
              으로 이동하시기 바랍니다.
            </div>
            <hr className="bg-gray-600 opacity-2" />
            <p className="text-center text-gray-600">&copy; Ascope</p>
          </form>
        </div>
      </div>
    );
  }
}

export default withRouter(Register);
