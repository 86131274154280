import moment from "moment";
import { React, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Panel, PanelBody } from "../../components/panel/panel.jsx";

const URL = process.env.REACT_APP_BACKEND_HOST;
const LIST = URL + "/boards/view_list/support";
const CHECK_MEM = URL + "/members/check/admin";
const token = "bearer " + sessionStorage.getItem("token");

const CsAsk = () => {
  const [noticeArr, setNoticeArr] = useState([
    {
      id: "",
      title: "",
      author: "",
      tags: "",
      created_at: "",
      process: "",
    },
  ]);
  const [admin, setAdmin] = useState(false);

  useEffect(() => {
    fetch(CHECK_MEM, {
      method: "GET",
      headers: {
        Authorization: token,
      },
    })
      .then((res) => {
        if (res.status / 100 === 2) {
          return res.json();
        } else {
          alert(
            "알수없는 오류가 발생하였습니다. 새로고침 후 다시 시도해주세요."
          );
        }
      })
      .then((res) => setAdmin(res));
  });

  useEffect(() => {
    fetch(LIST, {
      method: "GET",
      headers: {
        Authorization: token,
      },
    })
      .then((res) => {
        if (res.status / 100 === 2) {
          return res.json();
        } else {
          alert(
            "알수없는 오류가 발생하였습니다. 새로고침 후 다시 시도해주세요."
          );
        }
      })
      .then((res) => {
        setNoticeArr([]);

        for (let i = 0; i < res.results.length; i++) {
          setNoticeArr((noticeArr) => [
            ...noticeArr,
            {
              id: res.results[i].id,
              title: res.results[i].title,
              author: res.results[i].author,
              tags: res.results[i].tags,
              process: res.results[i].process,
              created_at: moment(res.results[i].created_at).format(
                "yyyy.MM.DD"
              ),
            },
          ]);
        }
      });
  }, []);

  return (
    <div>
      <div className="row mb-3">
        <Panel className="col-lg-7 col-md-9 m-auto">
          <PanelBody>
            <div className="mb-3">
              <label
                className="form-label col-form-label text-blue-700"
                style={{ fontSize: "2rem", fontWeight: "normal" }}
              >
                문의하기
              </label>
            </div>
            <div className="table-responsive">
              <table className="table mb-0 text-center">
                <thead>
                  <tr>
                    <th className="w-100px">처리상태</th>
                    <th>제목</th>
                    <th className="w-150px">작성일</th>
                  </tr>
                </thead>
                <tbody>
                  {noticeArr.length !== 0 ? (
                    <>
                      {noticeArr.map((item) => {
                        return (
                          <tr key={item.id}>
                            {item.process === 1 ? (
                              <td>접수</td>
                            ) : item.process === 2 ? (
                              <td className="text-gray">처리중</td>
                            ) : item.process === 3 ? (
                              <td className="text-red">답변완료</td>
                            ) : (
                              ""
                            )}

                            <td>
                              <Link
                                id={item.id}
                                to={{
                                  pathname: `/askBoard/${item.id}`,
                                  state: {
                                    item: item,
                                  },
                                }}
                              >
                                {item.title}
                              </Link>
                            </td>
                            <td>{item.created_at}</td>
                          </tr>
                        );
                      })}
                    </>
                  ) : (
                    <tr>
                      {admin ? (
                        <td colSpan={4}>등록된 문의글이 존재하지 않습니다.</td>
                      ) : (
                        <td colSpan={4}>작성글이 존재하지 않습니다.</td>
                      )}
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </PanelBody>

          {!admin ? (
            <button type="button" className="btn btn-sm btn-info float-end">
              <Link to="/cs/ask-write" className="nav-link text-white">
                글 작성하기
              </Link>
            </button>
          ) : null}
        </Panel>
      </div>
    </div>
  );
};
export { CsAsk };
