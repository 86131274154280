import React from "react";
import { Card, CardImg, CardBody } from "reactstrap";
import { API } from "./information";
import styles from "../../assets/css/information.module.css";
//차트
import { Bar } from "react-chartjs-2";
//차트 필수 추가 해야함
import Chart from "chart.js/auto";

const URL = process.env.REACT_APP_BACKEND_HOST;
const DELETE = URL + "/project/delete/";
const CONC_CALC = URL + "/project/calculate/conc";
let headAPI = URL + "/project/";

const formData = new FormData();
const token = "bearer " + sessionStorage.getItem("token");

const validateForm = (errors) => {
    let valid = true;
    Object.values(errors).forEach((val) => val.length > 0 && (valid = false));
    return valid;
};
class CardWithText extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            facilityid: this.props.facilityid,
            newCard: this.props.newCard,
            //균열
            zone: this.props.zone,
            crack_size: this.props.crack_size,
            floor: this.props.floor,
            crack_box_size: this.props.crack_box_size,
            crack_length: this.props.crack_length,
            position: this.props.position,
            auto_analyze: this.props.auto_analyze,
            file_id: this.props.file_id,
            id: this.props.key,
            seq: this.props.seq,
            etc: this.props.etc,
            type: this.props.type,
            index: this.props.index,
            //추가
            totalUP: this.props.totalUP,
            totalDOWN: this.props.totalDOWN,
            totalElevation: this.props.totalElevation,
            //부재
            a_zone: this.props.a_zone,
            a_position: this.props.a_position,
            // floor: this.props.floor,
            aw_size: this.props.aw_size,
            ah_size: this.props.ah_size,
            a_file_id: this.props.a_file_id,
            a_etc: this.props.a_etc,
            a_seq: this.props.a_seq,
            //콘크리트
            c_zone: this.props.c_zone,
            c_position: this.props.c_position,
            c_etc: this.props.c_etc,
            // floor: this.props.floor,
            c_inspectAge: this.props.c_inspectAge,
            c_hitAngle: this.props.c_hitAngle,
            c_num1: this.props.c_num1,
            c_num2: this.props.c_num2,
            c_num3: this.props.c_num3,
            c_num4: this.props.c_num4,
            c_num5: this.props.c_num5,
            c_num6: this.props.c_num6,
            c_num7: this.props.c_num7,
            c_num8: this.props.c_num8,
            c_num9: this.props.c_num9,
            c_num10: this.props.c_num10,
            c_num11: this.props.c_num11,
            c_num12: this.props.c_num12,
            c_num13: this.props.c_num13,
            c_num14: this.props.c_num14,
            c_num15: this.props.c_num15,
            c_num16: this.props.c_num16,
            c_num17: this.props.c_num17,
            c_num18: this.props.c_num18,
            c_num19: this.props.c_num19,
            c_num20: this.props.c_num20,
            c_file_id: this.props.c_file_id,
            c_seq: this.props.c_seq,

            c_calculate_sign: false,
            set_disabled: false,
            //철근
            i_zone: this.props.i_zone,
            i_position: this.props.i_position,
            i_etc: this.props.i_etc,
            i_file_id: this.props.i_file_id,
            i_seq: this.props.i_seq,
            result_img: this.props.result_img,
            //철근-새이미지

            //탄산화
            ca_zone: this.props.ca_zone,
            ca_position: this.props.ca_position,
            ca_num: this.props.ca_num,
            ca_etc: this.props.ca_etc,
            ca_file_id: this.props.ca_file_id,
            ca_seq: this.props.ca_seq,
            //기울기
            s_position: this.props.s_position,
            s_zone: this.props.s_zone,
            s_num1: this.props.s_num1,
            s_num2: this.props.s_num2,
            s_num3: this.props.s_num3,
            s_num4: this.props.s_num4,
            s_file_id: this.props.s_file_id,
            s_seq: this.props.s_seq,
            s_etc: this.props.s_etc,
            //사용장비
            e_id: this.props.e_id,
            e_id_disabled: false,
            e_name: this.props.e_name,
            e_subject: this.props.e_subject,
            e_method: this.props.e_method,
            e_file_id: this.props.e_file_id,

            //선택했나
            // report: false,
            report: this.props.report,
            startDate: "",
            readOnly: true,
            disabled: true,
            deleteSign: false,
            subDeleteSign: false,
            btnDisabled: false,
            submitState: false,
            prevSubmitState: false,
            // 콘크리트 조사 결과 표
            age_correction_factor: this.props.age_correction_factor,
            average: this.props.average,
            data_average: this.props.data_average,
            delta_r: this.props.delta_r,
            f1: this.props.f1,
            f2: this.props.f2,
            f3: this.props.f3,
            standard_hardness: this.props.standard_hardness,
            strength: this.props.strength,
            strength_updated: this.props.strength_updated,
            chart: true,
            errors: {
                position: "",
                crack_size: "",
                crack_length: "",
                crack_box_size: "",
                etc: "",
                a_position: "",
                aw_size: "",
                ah_size: "",
                c_position: "",
                c_inspectAge: "",
                c_hitAngle: "",
                c_etc: "",
                c_num1: "",
                c_num2: "",
                c_num3: "",
                c_num4: "",
                c_num5: "",
                c_num6: "",
                c_num7: "",
                c_num8: "",
                c_num9: "",
                c_num10: "",
                c_num11: "",
                c_num12: "",
                c_num13: "",
                c_num14: "",
                c_num15: "",
                c_num16: "",
                c_num17: "",
                c_num18: "",
                c_num19: "",
                c_num20: "",
                i_position: "",
                i_etc: "",
                ca_position: "",
                ca_num: "",
                ca_etc: "",
                s_position: "",
                s_num1: "",
                s_num2: "",
                s_num3: "",
                s_num4: "",
                s_etc: "",
                e_name: "",
            },
        };
        this.onLoadData = this.onLoadData.bind(this);
        this.onLoadFile = this.onLoadFile.bind(this);
        this.onChooseCard = this.onChooseCard.bind(this);
        this.createCard = this.createCard.bind(this);
        this.updateSign = this.updateSign.bind(this);
        this.updateCard = this.updateCard.bind(this);
        this.deleteCard = this.deleteCard.bind(this);
        this.calculateConc = this.calculateConc.bind(this);
        this.handleCreateImg = this.handleCreateImg.bind(this);
        this.onLoadSelect = this.onLoadSelect.bind(this);
        this.editTitleImgSign = this.editTitleImgSign.bind(this);
        this.handleIronCreateImg = this.handleIronCreateImg.bind(this);
        this.onlyNum = this.onlyNum.bind(this);
    }

    onLoadFile = (e) => {
        const file = e.target.files;
        this.setState({
            file,
        });
    };

    onFormSubmit = (e) => {
        e.preventDefault();
        this.props.onSubmit(this.state.cardImgs);
    };

    handleOnChange = (e) => {
        e.preventDefault();
        this.setState({ [e.target.name]: e.target.value });
    };

    onlyNum = (e) => {
        e.preventDefault();
        let newVal = e.target.value;

        let str = newVal.split(".");
        str[0] = str[0]
            .replace(/[^-\.0-9]/g, "")
            .replace(/(.)(?=(\d{3})+$)/g, "$1,");
        let fmStr = str.join(".");
        let comVal = fmStr.replace(
            /[`~!@#$%^&*()_|+\-=?;:'"<>\{\}\[\]\\\|ㄱ-ㅎ|ㅏ-ㅣ-ㅢ|가-힣|a-z|A-Z]/g,
            ""
        );

        this.setState({
            [e.target.name]: comVal,
        });
    };

    onLoadSelect = (e) => {
        this.setState({
            ...this.state,
            [e.target.name]: e.target.value,
        });
    };

    onLoadData = (e) => {
        const { name, value } = e.target;
        let errors = this.state.errors;

        switch (name) {
            case "position":
                errors.position = value.length === 0 ? "필수" : "";
                break;
            case "crack_size":
                errors.crack_size = value.length === 0 ? "필수" : "";
                break;
            case "crack_length":
                errors.crack_length = value.length === 0 ? "필수" : "";
                break;
            case "crack_box_size":
                errors.crack_box_size = value.length === 0 ? "필수" : "";
                break;
            // case "etc":
            //   errors.etc = value.length === 0 ? "필수" : "";
            //   break;
            case "a_position":
                errors.a_position = value.length === 0 ? "필수" : "";
                break;
            case "aw_size":
                errors.aw_size = value.length === 0 ? "필수" : "";
                break;
            case "ah_size":
                errors.ah_size = value.length === 0 ? "필수" : "";
                break;
            case "c_position":
                errors.c_position = value.length === 0 ? "필수" : "";
                break;
            case "c_inspectAge":
                errors.c_inspectAge = value.length === 0 ? "필수" : "";
                break;
            case "c_hitAngle":
                errors.c_hitAngle = value.length === 0 ? "필수" : "";
                break;
            // case "c_etc":
            //   errors.c_etc = value.length === 0 ? "필수" : "";
            //   break;
            case "c_num1":
                errors.c_num1 = value.length === 0 ? "필수" : "";
                errors.c_num1 =
                    value >= 20 && value <= 55 ? "" : "20~55 사이의 숫자를 입력하세요";
                break;
            case "c_num2":
                errors.c_num2 = value.length === 0 ? "필수" : "";
                errors.c_num2 =
                    value >= 20 && value <= 55 ? "" : "20~55 사이의 숫자를 입력하세요";
                break;
            case "c_num3":
                errors.c_num3 = value.length === 0 ? "필수" : "";
                errors.c_num3 =
                    value >= 20 && value <= 55 ? "" : "20~55 사이의 숫자를 입력하세요";
                break;
            case "c_num4":
                errors.c_num4 = value.length === 0 ? "필수" : "";
                errors.c_num4 =
                    value >= 20 && value <= 55 ? "" : "20~55 사이의 숫자를 입력하세요";
                break;
            case "c_num5":
                errors.c_num5 = value.length === 0 ? "필수" : "";
                errors.c_num5 =
                    value >= 20 && value <= 55 ? "" : "20~55 사이의 숫자를 입력하세요";
                break;
            case "c_num6":
                errors.c_num6 = value.length === 0 ? "필수" : "";
                errors.c_num6 =
                    value >= 20 && value <= 55 ? "" : "20~55 사이의 숫자를 입력하세요";
                break;
            case "c_num7":
                errors.c_num7 = value.length === 0 ? "필수" : "";
                errors.c_num7 =
                    value >= 20 && value <= 55 ? "" : "20~55 사이의 숫자를 입력하세요";
                break;
            case "c_num8":
                errors.c_num8 = value.length === 0 ? "필수" : "";
                errors.c_num8 =
                    value >= 20 && value <= 55 ? "" : "20~55 사이의 숫자를 입력하세요";
                break;
            case "c_num9":
                errors.c_num9 = value.length === 0 ? "필수" : "";
                errors.c_num9 =
                    value >= 20 && value <= 55 ? "" : "20~55 사이의 숫자를 입력하세요";
                break;
            case "c_num10":
                errors.c_num10 = value.length === 0 ? "필수" : "";
                errors.c_num10 =
                    value >= 20 && value <= 55 ? "" : "20~55 사이의 숫자를 입력하세요";
                break;
            case "c_num11":
                errors.c_num11 = value.length === 0 ? "필수" : "";
                errors.c_num11 =
                    value >= 20 && value <= 55 ? "" : "20~55 사이의 숫자를 입력하세요";
                break;
            case "c_num12":
                errors.c_num12 = value.length === 0 ? "필수" : "";
                errors.c_num12 =
                    value >= 20 && value <= 55 ? "" : "20~55 사이의 숫자를 입력하세요";
                break;
            case "c_num13":
                errors.c_num13 = value.length === 0 ? "필수" : "";
                errors.c_num13 =
                    value >= 20 && value <= 55 ? "" : "20~55 사이의 숫자를 입력하세요";
                break;
            case "c_num14":
                errors.c_num14 = value.length === 0 ? "필수" : "";
                errors.c_num14 =
                    value >= 20 && value <= 55 ? "" : "20~55 사이의 숫자를 입력하세요";
                break;
            case "c_num15":
                errors.c_num15 = value.length === 0 ? "필수" : "";
                errors.c_num15 =
                    value >= 20 && value <= 55 ? "" : "20~55 사이의 숫자를 입력하세요";
                break;
            case "c_num16":
                errors.c_num16 = value.length === 0 ? "필수" : "";
                errors.c_num16 =
                    value >= 20 && value <= 55 ? "" : "20~55 사이의 숫자를 입력하세요";
                break;
            case "c_num17":
                errors.c_num17 = value.length === 0 ? "필수" : "";
                errors.c_num17 =
                    value >= 20 && value <= 55 ? "" : "20~55 사이의 숫자를 입력하세요";
                break;
            case "c_num18":
                errors.c_num18 = value.length === 0 ? "필수" : "";
                errors.c_num18 =
                    value >= 20 && value <= 55 ? "" : "20~55 사이의 숫자를 입력하세요";
                break;
            case "c_num19":
                errors.c_num19 = value.length === 0 ? "필수" : "";
                errors.c_num19 =
                    value >= 20 && value <= 55 ? "" : "20~55 사이의 숫자를 입력하세요";
                break;
            case "c_num20":
                errors.c_num20 = value.length === 0 ? "필수" : "";
                errors.c_num20 =
                    value >= 20 && value <= 55 ? "" : "20~55 사이의 숫자를 입력하세요";
                break;
            case "i_position":
                errors.i_position = value.length === 0 ? "필수" : "";
                errors.i_position =
                    value >= 20 && value <= 55 ? "" : "20~55 사이의 숫자를 입력하세요";
                break;
            // case "i_etc":
            //   errors.i_etc = value.length === 0 ? "필수" : "";
            //   break;
            case "ca_position":
                errors.ca_position = value.length === 0 ? "필수" : "";
                break;
            case "ca_num":
                errors.ca_num = value.length === 0 ? "필수" : "";
                break;
            // case "ca_etc":
            //   errors.ca_etc = value.length === 0 ? "필수" : "";
            //   break;
            case "s_position":
                errors.s_position = value.length === 0 ? "필수" : "";
                break;
            case "s_num1":
                errors.s_num1 = value.length === 0 ? "필수" : "";
                break;
            case "s_num2":
                errors.s_num2 = value.length === 0 ? "필수" : "";
                break;
            case "s_num3":
                errors.s_num3 = value.length === 0 ? "필수" : "";
                break;
            case "s_num4":
                errors.s_num4 = value.length === 0 ? "필수" : "";
                break;
            // case "s_etc":
            //   errors.s_etc = value.length === 0 ? "필수" : "";
            //   break;
            case "e_name":
                errors.e_name = value.length === 0 ? "사용장비를 입력하세요." : "";
                break;
            default:
                break;
        }

        this.setState({ errors, [name]: value }, () => { });
    };

    onChooseCard = (e) => {
        this.setState({
            id: e.target.value,
            report: !this.state.report,
        });
    };
    editTitleImgSign = (e) => {
        this.setState({
            deleteSign: true,
            file_id: "",
            a_file_id: "",
            c_file_id: "",
            ca_file_id: "",
            s_file_id: "",
            e_file_id: "",
        });
    };

    editSubImgSign = (e) => {
        this.setState({
            subDeleteSign: true,
            result_img: "",
        });
    };

    handleCreateImg = (e) => {
        try {
            if (e.target.files[0].type === "image/jpg" || e.target.files[0].type === "image/jpeg" || e.target.files[0].type === "image/png") {
                this.setState({ [e.target.name]: e.target.files[0] });
                formData.append("img", e.target.files[0]);
            } else {
                alert("jpg, jpeg의 확장자 이미지만 등록할 수 있습니다.");
                e.target.value = null;
            }
        } catch (error) {
            alert("파일 선택을 취소하였습니다.");
        }
    };
    handleIronCreateImg = (e) => {
        if (e.target.files[0].type === "image/jpg" || e.target.files[0].type === "image/jpeg" || e.target.files[0].type === "image/png") {
            this.setState({ [e.target.name]: e.target.files[0] });
            formData.append("result_img", e.target.files[0]);
        } else {
            alert("jpg, jpeg의 확장자 이미지만 등록할 수 있습니다.");
            e.target.value = null;
        }
    };

    handleUpdateImg = (e) => {
        if (e.target.files[0].type === "image/jpg" || e.target.files[0].type === "image/jpeg" || e.target.files[0].type === "image/png") {
            this.setState({ [e.target.name]: e.target.files[0] });
            formData.append("img", e.target.files[0]);
        } else {
            alert("jpg, jpeg의 확장자 이미지만 등록할 수 있습니다.");
            e.target.value = null;
        }
    };

    createCard = (e) => {
        const Pill = this.props.nowPill;
        const report = this.state.report === true ? 1 : 0;

        formData.append("facilityid", this.props.facilityid);

        if (Pill === "1") {
            formData.append("report", report);
            formData.append("zone", this.state.zone);
            formData.append("crack_size", this.state.crack_size);
            formData.append("type", this.state.type);
            formData.append("floor", this.state.floor);
            formData.append("etc", this.state.etc);
            formData.append("crack_box_size", this.state.crack_box_size);
            formData.append("crack_length", this.state.crack_length);
            formData.append("position", this.state.position);

            fetch(URL + "/project/crack/", {
                // fetch("project/crack/", {
                method: "POST",
                headers: {
                    Authorization: token,
                },
                body: formData,
            })
                .then((res) => res.json())
                .then((res) => {
                    alert("저장을 완료하였습니다.");
                    window.location.reload("/information");
                });
        } else if (Pill === "2") {
            formData.append("report", report);
            formData.append("zone", this.state.c_zone);
            formData.append("c_position", this.state.c_position);
            formData.append("c_etc", this.state.c_etc);
            formData.append("floor", this.state.floor);
            formData.append("c_inspectAge", this.state.c_inspectAge);
            formData.append("c_hitAngle", this.state.c_hitAngle);
            formData.append("c_num1", this.state.c_num1);
            formData.append("c_num2", this.state.c_num2);
            formData.append("c_num3", this.state.c_num3);
            formData.append("c_num4", this.state.c_num4);
            formData.append("c_num5", this.state.c_num5);
            formData.append("c_num6", this.state.c_num6);
            formData.append("c_num7", this.state.c_num7);
            formData.append("c_num8", this.state.c_num8);
            formData.append("c_num9", this.state.c_num9);
            formData.append("c_num10", this.state.c_num10);
            formData.append("c_num11", this.state.c_num11);
            formData.append("c_num12", this.state.c_num12);
            formData.append("c_num13", this.state.c_num13);
            formData.append("c_num14", this.state.c_num14);
            formData.append("c_num15", this.state.c_num15);
            formData.append("c_num16", this.state.c_num16);
            formData.append("c_num17", this.state.c_num17);
            formData.append("c_num18", this.state.c_num18);
            formData.append("c_num19", this.state.c_num19);
            formData.append("c_num20", this.state.c_num20);

            fetch(URL + "/project/conc/", {
                method: "POST",
                headers: {
                    Authorization: token,
                },
                body: formData,
            })
                .then((res) => res.json())
                .then((res) => {
                    alert("저장을 완료하였습니다.");
                    window.location.reload("/information");
                });
        } else if (Pill === "3") {
            formData.append("report", report);
            formData.append("zone", this.state.i_zone);
            formData.append("i_position", this.state.i_position);
            formData.append("i_etc", this.state.i_etc);
            formData.append("floor", this.state.floor);

            fetch(URL + "/project/iron/", {
                method: "POST",
                headers: {
                    Authorization: token,
                },
                body: formData,
            })
                .then((res) => res.json())
                .then((res) => {
                    alert("저장을 완료하였습니다.");
                    window.location.reload("/information");
                });
        } else if (Pill === "4") {
            formData.append("report", report);
            formData.append("a_position", this.state.a_position);
            formData.append("zone", this.state.a_zone);
            formData.append("a_etc", this.state.a_etc);
            formData.append("floor", this.state.floor);
            formData.append("aw_size", this.state.aw_size);
            formData.append("ah_size", this.state.ah_size);

            fetch(URL + "/project/arc/", {
                method: "POST",
                headers: {
                    Authorization: token,
                },
                body: formData,
            })
                .then((res) => res.json())
                .then((res) => {
                    alert("저장을 완료하였습니다.");
                    window.location.reload("/information");
                });
        } else if (Pill === "5") {
            formData.append("report", report);
            formData.append("s_position", this.state.s_position);
            formData.append("zone", this.state.s_zone);
            formData.append("floor", this.state.floor);
            formData.append("s_num1", this.state.s_num1);
            formData.append("s_num2", this.state.s_num2);
            formData.append("s_num3", this.state.s_num3);
            formData.append("s_num4", this.state.s_num4);
            formData.append("s_etc", this.state.s_etc);

            fetch(URL + "/project/slope/", {
                method: "POST",
                headers: {
                    Authorization: token,
                },
                body: formData,
            })
                .then((res) => res.json())
                .then((res) => {
                    alert("저장을 완료하였습니다.");
                    window.location.reload("/information");
                });
        } else if (Pill === "6") {
            formData.append("report", report);
            formData.append("zone", this.state.ca_zone);
            formData.append("ca_position", this.state.ca_position);
            formData.append("ca_num", this.state.ca_num);
            formData.append("floor", this.state.floor);
            formData.append("ca_etc", this.state.ca_etc);

            fetch(URL + "/project/carbon/", {
                method: "POST",
                headers: {
                    Authorization: token,
                },
                body: formData,
            })
                .then((res) => res.json())
                .then((res) => {
                    alert("저장을 완료하였습니다.");
                    window.location.reload("/information");
                });
        } else if (Pill === "7") {

            if (validateForm(this.state.errors)) {
                if (this.state.e_subject === undefined) {
                    alert("조사 항목을 선택하세요.");
                } else if (this.state.e_method === undefined) {
                    alert("검사방법을 선택하세요.");
                } else if (this.state.e_name === undefined) {
                    alert("사용장비를 입력하세요.");
                } else if (this.state.e_file_id === undefined) {
                    alert("사진을 등록하세요.");
                } else {
                    formData.append("name", this.state.e_name);
                    formData.append("subject", this.state.e_subject);
                    formData.append("method", this.state.e_method);
                    formData.append("report", 1);
                    formData.append("img", this.state.e_file_id);

                    fetch(URL + "/project/equipment", {
                        method: "POST",
                        headers: {
                            Authorization: token,
                        },
                        body: formData,
                    }).then((res) => {
                        res.json();
                        if (res.status / 100 === 2) {
                            alert("저장을 완료하였습니다.");
                            this.setState({ e_id_disabled: true });
                        }
                    });
                }
            }
        }
    };

    updateSign = (e) => {
        this.setState({
            readOnly: !this.state.readOnly,
            disabled: !this.state.disabled,
        });
    };

    updateFetch = (e) => { };

    updateCard = (e) => {
        const Pill = this.props.nowPill;
        const report = this.state.report === true ? 1 : 0;

        if (Pill !== "7") {
            if (Pill === "1") {
                if (validateForm(this.state.errors)) {
                    formData.append("report", report);
                    formData.append("zone", this.state.zone);
                    formData.append("crack_size", this.state.crack_size);
                    formData.append("floor", this.state.floor);
                    formData.append("crack_box_size", this.state.crack_box_size);
                    formData.append("crack_length", this.state.crack_length);
                    formData.append("position", this.state.position);
                    formData.append("seq", this.state.seq);
                    formData.append("type", this.state.type);
                    formData.append("etc", this.state.etc);

                    fetch(URL + "/project/update/crack", {
                        method: "POST",
                        headers: {
                            Authorization: token,
                        },
                        body: formData,
                    }).then((res) => {
                        res.json();
                        if (res.status / 100 === 2) {
                            alert("수정을 완료하였습니다.");
                            window.location.reload("/information");
                        }
                    });
                }
            } else if (Pill === "2") {
                if (validateForm(this.state.errors)) {
                    formData.append("result_img", "");
                    formData.append("report", report);
                    formData.append("zone", this.state.c_zone);
                    formData.append("c_position", this.state.c_position);
                    formData.append("c_etc", this.state.c_etc);
                    formData.append("floor", this.state.floor);
                    formData.append("c_inspectAge", this.state.c_inspectAge);
                    formData.append("c_hitAngle", this.state.c_hitAngle);
                    formData.append("c_num1", this.state.c_num1);
                    formData.append("c_num2", this.state.c_num2);
                    formData.append("c_num3", this.state.c_num3);
                    formData.append("c_num4", this.state.c_num4);
                    formData.append("c_num5", this.state.c_num5);
                    formData.append("c_num6", this.state.c_num6);
                    formData.append("c_num7", this.state.c_num7);
                    formData.append("c_num8", this.state.c_num8);
                    formData.append("c_num9", this.state.c_num9);
                    formData.append("c_num10", this.state.c_num10);
                    formData.append("c_num11", this.state.c_num11);
                    formData.append("c_num12", this.state.c_num12);
                    formData.append("c_num13", this.state.c_num13);
                    formData.append("c_num14", this.state.c_num14);
                    formData.append("c_num15", this.state.c_num15);
                    formData.append("c_num16", this.state.c_num16);
                    formData.append("c_num17", this.state.c_num17);
                    formData.append("c_num18", this.state.c_num18);
                    formData.append("c_num19", this.state.c_num19);
                    formData.append("c_num20", this.state.c_num20);
                    formData.append("seq", this.state.c_seq);

                    fetch(URL + "/project/update/conc", {
                        method: "POST",
                        headers: {
                            Authorization: token,
                        },
                        body: formData,
                    })
                        .then((res) => {
                            res.json();
                            if (res.status / 100 === 2) {
                                alert("수정을 완료하였습니다.");
                                this.setState({ chart: false });
                            }
                        })
                        .then((res) => window.location.reload("/information"));
                }
            } else if (Pill === "3") {
                if (validateForm(this.state.errors)) {
                    formData.append("report", report);
                    formData.append("zone", this.state.i_zone);
                    formData.append("i_position", this.state.i_position);
                    formData.append("i_etc", this.state.i_etc);
                    formData.append("floor", this.state.floor);
                    formData.append("seq", this.state.i_seq);

                    fetch(URL + "/project/update/iron", {
                        method: "POST",
                        headers: {
                            Authorization: token,
                        },
                        body: formData,
                    }).then((res) => {
                        res.json();
                        if (res.status / 100 === 2) {
                            alert("수정을 완료하였습니다.");
                            window.location.reload("/information");
                        }
                    });
                }
            } else if (Pill === "4") {
                if (validateForm(this.state.errors)) {
                    formData.append("report", report);
                    formData.append("a_position", this.state.a_position);
                    formData.append("zone", this.state.a_zone);
                    formData.append("a_etc", this.state.a_etc);
                    formData.append("floor", this.state.floor);
                    formData.append("aw_size", this.state.aw_size);
                    formData.append("ah_size", this.state.ah_size);
                    formData.append("seq", this.state.a_seq);

                    fetch(URL + "/project/update/arc", {
                        method: "POST",
                        headers: {
                            Authorization: token,
                        },
                        body: formData,
                    }).then((res) => {
                        res.json();
                        if (res.status / 100 === 2) {
                            alert("수정을 완료하였습니다.");
                            window.location.reload("/information");
                        }
                    });
                }
            } else if (Pill === "5") {
                if (validateForm(this.state.errors)) {
                    formData.append("report", report);
                    formData.append("s_position", this.state.s_position);
                    formData.append("zone", this.state.s_zone);
                    formData.append("floor", this.state.floor);
                    formData.append("s_num1", this.state.s_num1);
                    formData.append("s_num2", this.state.s_num2);
                    formData.append("s_num3", this.state.s_num3);
                    formData.append("s_num4", this.state.s_num4);
                    formData.append("s_etc", this.state.s_etc);
                    formData.append("seq", this.state.s_seq);

                    fetch(URL + "/project/update/slope", {
                        method: "POST",
                        headers: {
                            Authorization: token,
                        },
                        body: formData,
                    }).then((res) => {
                        res.json();
                        if (res.status / 100 === 2) {
                            alert("수정을 완료하였습니다.");
                            window.location.reload("/information");
                        }
                    });
                }
            } else if (Pill === "6") {
                if (validateForm(this.state.errors)) {
                    formData.append("report", report);
                    formData.append("zone", this.state.ca_zone);
                    formData.append("ca_position", this.state.ca_position);
                    formData.append("ca_num", this.state.ca_num);
                    formData.append("floor", this.state.floor);
                    formData.append("ca_etc", this.state.ca_etc);
                    formData.append("seq", this.state.ca_seq);

                    fetch(URL + "/project/update/carbon", {
                        method: "POST",
                        headers: {
                            Authorization: token,
                        },
                        body: formData,
                    }).then((res) => {
                        res.json();
                        if (res.status / 100 === 2) {
                            alert("수정을 완료하였습니다.");
                            window.location.reload("/information");
                        }
                    });
                }
            }
        } else {
            if (Pill === "7") {
                if (validateForm(this.state.errors)) {
                    formData.append("id", this.state.e_id);
                    formData.append("name", this.state.e_name);
                    formData.append("subject", this.state.e_subject);
                    formData.append("method", this.state.e_method);
                    formData.append("method", this.state.e_method);
                    formData.append("report", 1);

                    fetch(URL + "/project/update/equipment", {
                        method: "POST",
                        headers: {
                            Authorization: token,
                        },
                        body: formData,
                    }).then((res) => {
                        res.json();
                        if (res.status / 100 === 2) {
                            alert("수정을 완료하였습니다.");
                            window.location.reload();
                            window.location.reload("/information");
                        }
                    });
                }
            }
        }

        this.setState({ submitState: !this.state.submitState });
    };

    deleteCard = (e) => {
        const Pill = this.props.nowPill;

        let DELETE_CARD;
        let del_seq;
        if (Pill === "1") {
            DELETE_CARD = DELETE + "crack/";
            del_seq = this.props.seq;
        } else if (Pill === "2") {
            DELETE_CARD = DELETE + "conc/";
            del_seq = this.props.c_seq;
        } else if (Pill === "3") {
            DELETE_CARD = DELETE + "iron/";
            del_seq = this.props.i_seq;
        } else if (Pill === "4") {
            DELETE_CARD = DELETE + "arc/";
            del_seq = this.props.a_seq;
        } else if (Pill === "5") {
            DELETE_CARD = DELETE + "slope/";
            del_seq = this.props.s_seq;
        } else if (Pill === "6") {
            DELETE_CARD = DELETE + "carbon/";
            del_seq = this.props.ca_seq;
        } else if (Pill === "7") {
            DELETE_CARD = DELETE + "equipment/";
            del_seq = this.props.e_id;
        }

        if (window.confirm("정말 삭제하시겠습니까?") === true) {
            fetch(DELETE_CARD + del_seq, {
                method: "DELETE",
                headers: {
                    Authorization: token,
                },
            }).then((res) => {
                res.json();
                if (res.status / 100 === 2) {
                    alert("삭제되었습니다.");
                    window.location.reload("/information");
                }
            });
        }
    };

    calculateConc = (e) => {
        const btn = document.getElementById("calculateBtn");
        btn.setAttribute("disabled", "disabled");

        this.setState({ btnDisabled: true });

        fetch(CONC_CALC + "?id=" + this.props.c_seq, {
            method: "GET",
            headers: {
                Authorization: token,
            },
        }).then((res) => {
            res.json();
            if (res.status / 100 === 2) {
                this.setState({
                    age_correction_factor: res.age_correction_factor,
                    average: res.average,
                    data_average: res.data_average,
                    delta_r: res.delta_r,
                    f1: res.f1,
                    f2: res.f2,
                    f3: res.f3,
                    hit_angle: res.hit_angle,
                    name: res.name,
                    standard_hardness: res.standard_hardness,
                    strength: res.strength,
                    chart: true,
                    strength_updated: res.strength_updated,
                });
            }
        });
    };

    render() {
        const { errors } = this.state;

        const inputstyle = {
            border: "none",
            borderBottom: "1px solid #ccc",
        };

        const tabletd = {
            border: "none",
        };

        const {
            file_id,
            a_file_id,
            ca_file_id,
            c_file_id,
            i_file_id,
            s_file_id,
            e_file_id,
            result_img,
        } = this.state;

        const labels = [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
        ];

        const data = {
            labels,
            datasets: [
                {
                    label: "콘크리트강도조사",
                    data: this.props.arr,
                    backgroundColor: "rgba(237, 232, 159,1)",
                },
            ],
        };

        return (
            <>

            
                {API === headAPI + "crack_list?facilityid=" + this.props.facilityid ||
                    API === "" ? (
                    <Card
                        className={`${this.state.report ? "is_checked" : ""
                            } col-md-4 col-sm-6 pt-15px`}
                    >
                        <div className="row card_index">{this.props.index}</div>
                        {this.state.seq === undefined ? (
                            <input
                                type="checkbox"
                                onChange={this.onChooseCard}
                                value={this.state.report}
                                checked={this.state.report}
                                name="report"
                            />
                        ) : this.state.seq !== undefined &&
                            this.state.readOnly !== false ? (
                            ""
                        ) : (
                            <input
                                type="checkbox"
                                onChange={this.onChooseCard}
                                value={this.state.report}
                                checked={this.state.report}
                                name="report"
                            />
                        )}
                        {this.state.seq === undefined ? (
                            <input
                                type="file"
                                accept="image/jpeg, image/jpg, image/png"
                                onChange={(e) => this.handleCreateImg(e)}
                                className={`${styles.create_new_image}`}
                                name="file_id"
                            />
                        ) : this.state.seq !== undefined &&
                            this.state.readOnly !== false ? (
                            <CardImg
                                className={`${styles.result_card_img}`}
                                top
                                src={file_id}
                            />
                        ) : (
                            <>
                                {this.state.deleteSign === true ? (
                                    <input
                                        type="file"
                                        accept="image/jpeg, image/jpg, image/png"
                                        onChange={(e) => this.handleUpdateImg(e)}
                                        className={`${styles.create_new_image}`}
                                        name="file_id"
                                    />
                                ) : (
                                    <>
                                        <CardImg
                                            top
                                            src={file_id}
                                            className={`${styles.result_card_img}`}
                                        />
                                        <button
                                            name="file_id"
                                            // value={this.state.file_id}
                                            onClick={(e) => this.editTitleImgSign(e)}
                                        >
                                            이미지 수정
                                        </button>
                                    </>
                                )}
                            </>
                        )}
                        <CardBody>
                            <div className="row">
                                <label className="form-label col-form-label w-100px">층</label>
                                {this.state.disabled === true && this.state.seq ? (
                                    <input
                                        style={inputstyle}
                                        className="col col-form-control"
                                        value={
                                            this.props.floor < 0
                                                ? "지하 " + Math.abs(this.props.floor) + " 층"
                                                : "지상 " + this.props.floor + " 층"
                                        }
                                        name="floor"
                                        readOnly
                                        type="text"
                                    />
                                ) : (
                                    <select
                                        className={`${styles.inner_select} col col-form-control`}
                                        defaultValue={this.props.floor}
                                        key={this.props.floor}
                                        name="floor"
                                        onChange={this.onLoadSelect}
                                    >
                                        {this.state.totalDOWN &&
                                            this.state.totalDOWN.map((i) => {
                                                return (
                                                    <option value={"-" + i} key={i} name={"-" + i}>
                                                        지하 {i} 층
                                                    </option>
                                                );
                                            })}
                                        {this.state.totalUP &&
                                            this.state.totalUP.map((i) => {
                                                return (
                                                    <option value={i} key={i} name={i}>
                                                        지상 {i} 층
                                                    </option>
                                                );
                                            })}
                                    </select>
                                )}
                            </div>
                            <div className="row">
                                <label className="form-label col-form-label w-100px">
                                    구역
                                </label>
                                {this.state.disabled === true && this.state.seq ? (
                                    <input
                                        style={inputstyle}
                                        className="col col-form-control"
                                        value={this.state.zone}
                                        name="zone"
                                        readOnly
                                    />
                                ) : (
                                    <select
                                        className={`${styles.inner_select} col col-form-control`}
                                        defaultValue={this.props.zone}
                                        key={this.props.zone}
                                        name="zone"
                                        onChange={this.onLoadSelect}
                                    >
                                        <option value="내부">내부</option>
                                        <option value="외부">외부</option>
                                    </select>
                                )}
                            </div>
                            <div
                                className={
                                    (errors.position.length > 0 ? "error-border " : "") + "row "
                                }
                            >
                                <label className="form-label col-form-label w-100px">
                                    조사 위치
                                </label>
                                <input
                                    style={inputstyle}
                                    className={"col col-form-control"}
                                    value={this.state.position}
                                    name="position"
                                    onChange={this.onLoadData}
                                    readOnly={
                                        this.state.seq === undefined ? false : this.state.readOnly
                                    }
                                    type="text"
                                />
                            </div>
                            <div className="row">
                                <label className="form-label col-form-label w-100px">
                                    균열 종류
                                </label>

                                {this.state.disabled === true && this.state.seq ? (
                                    <input
                                        style={inputstyle}
                                        className="col col-form-control"
                                        value={this.state.type}
                                        name="type"
                                        readOnly
                                        type="type"
                                    />
                                ) : (
                                    <select
                                        className={`${styles.inner_select} col col-form-control`}
                                        defaultValue={this.props.type}
                                        key={this.props.type}
                                        name="type"
                                        onChange={this.onLoadSelect}
                                    >
                                        <option value="균열">균열</option>
                                        <option value="재료분리">재료분리</option>
                                        <option value="철근노출">철근노출</option>
                                        <option value="박리">박리</option>
                                        <option value="박락">박락</option>
                                        <option value="백화">백화</option>
                                        <option value="파손">파손</option>
                                        <option value="기타">기타</option>
                                    </select>
                                )}
                            </div>
                            <div
                                className={
                                    (errors.crack_size.length > 0 ? "error-border " : "") + "row "
                                }
                            >
                                <label className="form-label col-form-label w-100px">
                                    균열 폭 (mm)
                                    {/* {this.state.auto_analyze ? "자동" : "수동"} */}
                                </label>
                                <input
                                    style={inputstyle}
                                    className="col col-form-control"
                                    value={this.state.crack_size}
                                    name="crack_size"
                                    onChange={this.onLoadData}
                                    onKeyUp={this.onlyNum}
                                    readOnly={
                                        this.state.seq === undefined
                                            ? false
                                            : this.state.auto_analyze
                                                ? true
                                                : this.state.readOnly
                                    }
                                    type="text"
                                />
                            </div>
                            <div
                                className={
                                    (errors.crack_length.length > 0 ? "error-border " : "") +
                                    "row "
                                }
                            >
                                <label className="form-label col-form-label w-100px">
                                    균열 길이 (mm)
                                </label>
                                <input
                                    style={inputstyle}
                                    className="col col-form-control"
                                    value={this.state.crack_length}
                                    name="crack_length"
                                    onChange={this.onLoadData}
                                    onKeyUp={this.onlyNum}
                                    readOnly={
                                        this.state.seq === undefined
                                            ? false
                                            : this.state.auto_analyze
                                                ? true
                                                : this.state.readOnly
                                    }
                                    type="text"
                                />
                            </div>
                            <div
                                className={
                                    (errors.crack_box_size.length > 0 ? "error-border " : "") +
                                    "row "
                                }
                            >
                                <label className="form-label col-form-label w-100px">
                                    균열 면적 (mm)
                                </label>
                                <input
                                    style={inputstyle}
                                    className="col col-form-control"
                                    value={this.state.crack_box_size}
                                    name="crack_box_size"
                                    onChange={this.onLoadData}
                                    onKeyUp={this.onlyNum}
                                    readOnly={
                                        this.state.seq === undefined
                                            ? false
                                            : this.state.auto_analyze
                                                ? true
                                                : this.state.readOnly
                                    }
                                    type="text"
                                />
                            </div>
                            <div
                                className={
                                    (errors.etc.length > 0 ? "error-border " : "") + "row "
                                }
                            >
                                <label className="form-label col-form-label w-100px">
                                    비고
                                </label>
                                <input
                                    style={inputstyle}
                                    className="col col-form-control"
                                    value={this.state.etc}
                                    name="etc"
                                    onChange={this.onLoadData}
                                    readOnly={
                                        this.state.seq === undefined ? false : this.state.readOnly
                                    }
                                    type="text"
                                />
                            </div>
                        </CardBody>
                        <div className="mt-10px mb-10px row justify-content-evenly">
                            {this.state.seq === undefined ? (
                                <button
                                    className="btn btn-success col-4"
                                    onClick={this.createCard}
                                >
                                    추가
                                </button>
                            ) : this.state.seq !== undefined &&
                                this.state.readOnly !== true ? (
                                <button
                                    className="btn btn-yellow col-4"
                                    onClick={this.updateCard}
                                >
                                    수정 완료
                                </button>
                            ) : (
                                <button
                                    className="btn btn-purple col-4"
                                    onClick={this.updateSign}
                                >
                                    수정
                                </button>
                            )}
                            <button
                                className="btn btn-danger col-4"
                                onClick={this.deleteCard}
                            >
                                삭제
                            </button>
                        </div>
                    </Card>
                ) : null}



                {API === headAPI + "arc_list?facilityid=" + this.props.facilityid ? (
                    <Card
                        className={`${this.state.report ? "is_checked" : ""
                            } col-md-4 col-sm-6 pt-15px`}
                    >
                        <div className="row card_index">{this.props.index}</div>
                        {this.state.a_seq === undefined ? (
                            <input
                                type="checkbox"
                                onChange={this.onChooseCard}
                                value={this.state.report}
                                checked={this.state.report}
                                name="report"
                            />
                        ) : this.state.a_seq !== undefined &&
                            this.state.readOnly !== false ? (
                            ""
                        ) : (
                            <input
                                type="checkbox"
                                onChange={this.onChooseCard}
                                value={this.state.report}
                                checked={this.state.report}
                                name="report"
                            />
                        )}
                        {this.state.a_seq === undefined ? (
                            <input
                                type="file"
                                accept="image/jpeg, image/jpg, image/png"
                                onChange={(e) => this.handleCreateImg(e)}
                                className={`${styles.create_new_image}`}
                                name="a_file_id"
                            // value={this.state.file_id}
                            />
                        ) : this.state.a_seq !== undefined &&
                            this.state.readOnly !== false ? (
                            <CardImg
                                className={`${styles.result_card_img}`}
                                top
                                src={a_file_id}
                            />
                        ) : (
                            <>
                                {this.state.deleteSign === true ? (
                                    <input
                                        type="file"
                                        accept="image/jpeg, image/jpg, image/png"
                                        onChange={(e) => this.handleUpdateImg(e)}
                                        className={`${styles.create_new_image}`}
                                        name="a_file_id"
                                    />
                                ) : (
                                    <>
                                        <CardImg
                                            top
                                            src={a_file_id}
                                            className={`${styles.result_card_img}`}
                                        />
                                        <button
                                            name="a_file_id"
                                            // value={this.state.file_id}
                                            onClick={(e) => this.editTitleImgSign(e)}
                                        >
                                            이미지 수정
                                        </button>
                                    </>
                                )}
                            </>
                        )}

                        <CardBody className="row">
                            <div className="row">
                                <label className="form-label col-form-label w-100px">
                                    구역
                                </label>
                                {this.state.disabled === true && this.state.a_seq ? (
                                    <input
                                        style={inputstyle}
                                        className="col col-form-control"
                                        value={this.state.a_zone}
                                        name="a_zone"
                                        readOnly
                                    />
                                ) : (
                                    <select
                                        className={`${styles.inner_select} col col-form-control`}
                                        defaultValue={this.props.a_zone}
                                        key={this.props.a_zone}
                                        name="a_zone"
                                        onChange={this.onLoadSelect}
                                    >
                                        <option value="내부">내부</option>
                                        <option value="외부">외부</option>
                                    </select>
                                )}
                            </div>
                            <div className="row">
                                <label className="form-label col-form-label w-100px">층</label>
                                {this.state.disabled === true && this.state.a_seq ? (
                                    <input
                                        style={inputstyle}
                                        className="col col-form-control"
                                        value={
                                            this.props.floor < 0
                                                ? "지하 " + Math.abs(this.props.floor) + " 층"
                                                : "지상 " + this.props.floor + " 층"
                                        }
                                        name="floor"
                                        readOnly
                                        type="text"
                                    />
                                ) : (
                                    <select
                                        className={`${styles.inner_select} col col-form-control`}
                                        defaultValue={this.props.floor}
                                        key={this.props.floor}
                                        name="floor"
                                        onChange={this.onLoadSelect}
                                    >
                                        {this.state.totalDOWN &&
                                            this.state.totalDOWN.map((i) => {
                                                return (
                                                    <option value={"-" + i} key={i} name={"-" + i}>
                                                        지하 {i} 층
                                                    </option>
                                                );
                                            })}
                                        {this.state.totalUP &&
                                            this.state.totalUP.map((i) => {
                                                return (
                                                    <option value={i} key={i} name={i}>
                                                        지상 {i} 층
                                                    </option>
                                                );
                                            })}
                                    </select>
                                )}
                            </div>
                            <div className="row">
                                <label className="form-label col-form-label w-100px">
                                    위치
                                </label>
                                <input
                                    style={inputstyle}
                                    className="col col-form-control"
                                    value={this.state.a_position}
                                    name="a_position"
                                    onChange={this.onLoadData}
                                    readOnly={
                                        this.state.a_seq === undefined ? false : this.state.readOnly
                                    }
                                    type="text"
                                />
                            </div>
                            <div className="row">
                                <label className="form-label col-form-label w-100px">
                                    너비 ( mm )
                                </label>
                                <input
                                    style={inputstyle}
                                    className="col col-form-control"
                                    value={this.state.aw_size}
                                    name="aw_size"
                                    onChange={this.onLoadData}
                                    onKeyUp={this.onlyNum}
                                    readOnly={
                                        this.state.a_seq === undefined ? false : this.state.readOnly
                                    }
                                    type="number"
                                />
                            </div>
                            <div className="row">
                                <label className="form-label col-form-label w-100px">
                                    길이 ( mm )
                                </label>
                                <input
                                    style={inputstyle}
                                    className="col col-form-control"
                                    value={this.state.ah_size}
                                    name="ah_size"
                                    onChange={this.onLoadData}
                                    onKeyUp={this.onlyNum}
                                    readOnly={
                                        this.state.a_seq === undefined ? false : this.state.readOnly
                                    }
                                    type="number"
                                />
                            </div>
                        </CardBody>
                        <div className="mt-10px mb-10px row justify-content-evenly">
                            {this.state.a_seq === undefined ? (
                                <button
                                    className="btn btn-success col-4"
                                    onClick={this.createCard}
                                >
                                    추가
                                </button>
                            ) : this.state.a_seq !== undefined &&
                                this.state.readOnly !== true ? (
                                <button
                                    className="btn btn-yellow col-4"
                                    onClick={this.updateCard}
                                >
                                    수정 완료
                                </button>
                            ) : (
                                <button
                                    className="btn btn-purple col-4"
                                    onClick={this.updateSign}
                                >
                                    수정
                                </button>
                            )}
                            <button
                                className="btn btn-danger col-4"
                                onClick={this.deleteCard}
                            >
                                삭제
                            </button>
                        </div>
                    </Card>
                ) : null}



                {API === headAPI + "conc_list?facilityid=" + this.props.facilityid ? (
                    <div className={`${this.state.report ? "is_checked" : ""} row`}>
                        <Card className={`col-4 ${styles.conc_card}`}>
                            {/* ---------------- {this.state.strength_updated ? "false" : "true"} */}
                            <div className="row card_index">{this.props.index}</div>
                            {this.state.c_seq === undefined ? (
                                <input
                                    type="checkbox"
                                    onChange={this.onChooseCard}
                                    value={this.state.report}
                                    checked={this.state.report}
                                    name="report"
                                />
                            ) : this.state.c_seq !== undefined &&
                                this.state.readOnly !== false ? (
                                ""
                            ) : (
                                <input
                                    type="checkbox"
                                    onChange={this.onChooseCard}
                                    value={this.state.report}
                                    checked={this.state.report}
                                    name="report"
                                />
                            )}
                            {this.state.c_seq === undefined ? (
                                <input
                                    type="file"
                                    accept="image/jpeg, image/jpg, image/png"
                                    onChange={(e) => this.handleCreateImg(e)}
                                    className={`${styles.create_new_image}`}
                                    name="c_file_id"
                                // value={this.state.file_id}
                                />
                            ) : this.state.c_seq !== undefined &&
                                this.state.readOnly !== false ? (
                                <CardImg
                                    className={`${styles.result_card_img}`}
                                    top
                                    src={c_file_id}
                                />
                            ) : (
                                <>
                                    <CardImg
                                        className={`${styles.result_card_img}`}
                                        top
                                        src={c_file_id}
                                    />
                                    <button
                                        name="c_file_id"
                                        // value={this.state.file_id}
                                        onClick={(e) => this.editTitleImgSign(e)}
                                    >
                                        이미지 수정
                                    </button>
                                    {this.state.deleteSign === true ? (
                                        <input
                                            type="file"
                                            accept="image/jpeg, image/jpg, image/png"
                                            onChange={(e) => this.handleUpdateImg(e)}
                                            className={`${styles.create_new_image}`}
                                            name="c_file_id"
                                        />
                                    ) : null}
                                </>
                            )}
                            <CardBody className="row">
                                <div className="row">
                                    <label className="form-label col-form-label w-100px">
                                        콘크리트 구역
                                    </label>
                                    {this.state.disabled === true && this.state.c_seq ? (
                                        <input
                                            style={inputstyle}
                                            className="col col-form-control"
                                            value={this.state.c_zone}
                                            name="c_zone"
                                            readOnly
                                        />
                                    ) : (
                                        <select
                                            className={`${styles.inner_select} col col-form-control`}
                                            defaultValue={this.props.c_zone}
                                            key={this.props.c_zone}
                                            name="c_zone"
                                            onChange={this.onLoadSelect}
                                        >
                                            <option value="내부">내부</option>
                                            <option value="외부">외부</option>
                                        </select>
                                    )}
                                </div>
                                <div
                                    className={
                                        (errors.c_position.length > 0 ? "error-border " : "") +
                                        "row "
                                    }
                                >
                                    <label className="form-label col-form-label w-100px">
                                        위치
                                    </label>
                                    <input
                                        style={inputstyle}
                                        className="col col-form-control"
                                        value={this.state.c_position}
                                        name="c_position"
                                        onChange={this.onLoadData}
                                        readOnly={
                                            this.state.c_seq === undefined
                                                ? false
                                                : this.state.readOnly
                                        }
                                        type="text"
                                    />
                                </div>
                                <div className="row">
                                    <label className="form-label col-form-label w-100px">
                                        층
                                    </label>
                                    {this.state.disabled === true && this.state.c_seq ? (
                                        <input
                                            style={inputstyle}
                                            className="col col-form-control"
                                            value={
                                                this.props.floor < 0
                                                    ? "지하 " + Math.abs(this.props.floor) + " 층"
                                                    : "지상 " + this.props.floor + " 층"
                                            }
                                            name="floor"
                                            readOnly
                                            type="text"
                                        />
                                    ) : (
                                        <select
                                            className={`${styles.inner_select} col col-form-control`}
                                            defaultValue={this.props.floor}
                                            key={this.props.floor}
                                            name="floor"
                                            onChange={this.onLoadSelect}
                                        >
                                            {this.state.totalDOWN &&
                                                this.state.totalDOWN.map((i) => {
                                                    return (
                                                        <option value={"-" + i} key={i} name={"-" + i}>
                                                            지하 {i} 층
                                                        </option>
                                                    );
                                                })}
                                            {this.state.totalUP &&
                                                this.state.totalUP.map((i) => {
                                                    return (
                                                        <option value={i} key={i} name={i}>
                                                            지상 {i} 층
                                                        </option>
                                                    );
                                                })}
                                        </select>
                                    )}
                                </div>
                                <div
                                    className={
                                        (errors.c_inspectAge.length > 0 ? "error-border " : "") +
                                        "row "
                                    }
                                >
                                    <label className="form-label col-form-label w-100px">
                                        경과년수
                                    </label>
                                    <input
                                        style={inputstyle}
                                        className="col col-form-control"
                                        value={this.state.c_inspectAge}
                                        name="c_inspectAge"
                                        onChange={this.onLoadData}
                                        onKeyUp={this.onlyNum}
                                        readOnly={
                                            this.state.c_seq === undefined
                                                ? false
                                                : this.state.readOnly
                                        }
                                        type="number"
                                    />
                                </div>
                                <div
                                    className={
                                        (errors.c_hitAngle.length > 0 ? "error-border " : "") +
                                        "row "
                                    }
                                >
                                    <label className="form-label col-form-label w-100px">
                                        타격각도( &deg; )
                                    </label>
                                
                                    {this.state.disabled === true && this.state.i_seq ? (
                                        <input
                                            style={inputstyle}
                                            className="col col-form-control"
                                            value={this.state.c_hitAngle}
                                            name="c_hitAngle"
                                            readOnly
                                        />
                                    ) : (
                                        <select
                                            className={`${styles.inner_select} col col-form-control`}
                                            defaultValue={this.props.c_hitAngle}
                                            key={this.props.c_hitAngle}
                                            name="c_hitAngle"
                                            onChange={this.onLoadSelect}
                                        >
                                            <option value="-90">-90 &deg;</option>
                                            <option value="-45">-45 &deg;</option>
                                            <option value="0">0 &deg;</option>
                                            <option value="45">45 &deg;</option>
                                            <option value="90">90 &deg;</option>
                                        </select>
                                    )}
                                </div>
                                <div
                                    className={
                                        (errors.c_etc.length > 0 ? "error-border " : "") + "row "
                                    }
                                >
                                    <label className="form-label col-form-label w-100px">
                                        비고
                                    </label>
                                    <input
                                        style={inputstyle}
                                        className="col col-form-control"
                                        value={this.state.c_etc}
                                        name="c_etc"
                                        onChange={this.onLoadData}
                                        readOnly={
                                            this.state.c_seq === undefined
                                                ? false
                                                : this.state.readOnly
                                        }
                                        type="text"
                                    />
                                </div>
                                <table className="table mb-0 border-none">
                                    <div className="text-red">
                                        {errors.c_num1 ||
                                            errors.c_num2 ||
                                            errors.c_num3 ||
                                            errors.c_num4 ||
                                            errors.c_num5 ||
                                            errors.c_num6 ||
                                            errors.c_num7 ||
                                            errors.c_num8 ||
                                            errors.c_num9 ||
                                            errors.c_num10 ||
                                            errors.c_num11 ||
                                            errors.c_num12 ||
                                            errors.c_num13 ||
                                            errors.c_num14 ||
                                            errors.c_num15 ||
                                            errors.c_num16 ||
                                            errors.c_num17 ||
                                            errors.c_num18 ||
                                            errors.c_num19 ||
                                            errors.c_num20}
                                    </div>
                                    <tbody>
                                        <tr>
                                            <td className="p-0">
                                                <input
                                                    value={this.state.c_num1}
                                                    name="c_num1"
                                                    className={
                                                        (errors.c_num1.length > 0 ? "error-border " : "") +
                                                        "w-40px"
                                                    }
                                                    placeholder="1"
                                                    onChange={this.onLoadData}
                                                    onKeyUp={this.onlyNum}
                                                    readOnly={
                                                        this.state.c_seq === undefined
                                                            ? false
                                                            : this.state.readOnly
                                                    }
                                                    type="number"
                                                />
                                            </td>
                                            <td className="p-0">
                                                <input
                                                    value={this.state.c_num2}
                                                    name="c_num2"
                                                    placeholder="2"
                                                    className={
                                                        (errors.c_num2.length > 0 ? "error-border " : "") +
                                                        "w-40px"
                                                    }
                                                    onChange={this.onLoadData}
                                                    onKeyUp={this.onlyNum}
                                                    readOnly={
                                                        this.state.c_seq === undefined
                                                            ? false
                                                            : this.state.readOnly
                                                    }
                                                    type="number"
                                                />
                                            </td>
                                            <td className="p-0">
                                                <input
                                                    value={this.state.c_num3}
                                                    name="c_num3"
                                                    placeholder="3"
                                                    className={
                                                        (errors.c_num3.length > 0 ? "error-border " : "") +
                                                        "w-40px"
                                                    }
                                                    onChange={this.onLoadData}
                                                    onKeyUp={this.onlyNum}
                                                    readOnly={
                                                        this.state.c_seq === undefined
                                                            ? false
                                                            : this.state.readOnly
                                                    }
                                                    type="number"
                                                />
                                            </td>
                                            <td className="p-0">
                                                <input
                                                    value={this.state.c_num4}
                                                    name="c_num4"
                                                    placeholder="4"
                                                    className={
                                                        (errors.c_num4.length > 0 ? "error-border " : "") +
                                                        "w-40px"
                                                    }
                                                    onChange={this.onLoadData}
                                                    onKeyUp={this.onlyNum}
                                                    readOnly={
                                                        this.state.c_seq === undefined
                                                            ? false
                                                            : this.state.readOnly
                                                    }
                                                    type="number"
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="p-0">
                                                <input
                                                    value={this.state.c_num5}
                                                    name="c_num5"
                                                    placeholder="5"
                                                    className={
                                                        (errors.c_num5.length > 0 ? "error-border " : "") +
                                                        "w-40px"
                                                    }
                                                    onChange={this.onLoadData}
                                                    onKeyUp={this.onlyNum}
                                                    readOnly={
                                                        this.state.c_seq === undefined
                                                            ? false
                                                            : this.state.readOnly
                                                    }
                                                    type="number"
                                                />
                                            </td>
                                            <td className="p-0">
                                                <input
                                                    value={this.state.c_num6}
                                                    name="c_num6"
                                                    placeholder="6"
                                                    className={
                                                        (errors.c_num6.length > 0 ? "error-border " : "") +
                                                        "w-40px"
                                                    }
                                                    onChange={this.onLoadData}
                                                    onKeyUp={this.onlyNum}
                                                    readOnly={
                                                        this.state.c_seq === undefined
                                                            ? false
                                                            : this.state.readOnly
                                                    }
                                                    type="number"
                                                />
                                            </td>
                                            <td className="p-0">
                                                <input
                                                    value={this.state.c_num7}
                                                    name="c_num7"
                                                    placeholder="7"
                                                    className={
                                                        (errors.c_num7.length > 0 ? "error-border " : "") +
                                                        "w-40px"
                                                    }
                                                    onChange={this.onLoadData}
                                                    onKeyUp={this.onlyNum}
                                                    readOnly={
                                                        this.state.c_seq === undefined
                                                            ? false
                                                            : this.state.readOnly
                                                    }
                                                    type="number"
                                                />
                                            </td>
                                            <td className="p-0">
                                                <input
                                                    value={this.state.c_num8}
                                                    name="c_num8"
                                                    placeholder="8"
                                                    className={
                                                        (errors.c_num8.length > 0 ? "error-border " : "") +
                                                        "w-40px"
                                                    }
                                                    onChange={this.onLoadData}
                                                    onKeyUp={this.onlyNum}
                                                    readOnly={
                                                        this.state.c_seq === undefined
                                                            ? false
                                                            : this.state.readOnly
                                                    }
                                                    type="number"
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="p-0">
                                                <input
                                                    value={this.state.c_num9}
                                                    name="c_num9"
                                                    placeholder="9"
                                                    className={
                                                        (errors.c_num9.length > 0 ? "error-border " : "") +
                                                        "w-40px"
                                                    }
                                                    onChange={this.onLoadData}
                                                    onKeyUp={this.onlyNum}
                                                    readOnly={
                                                        this.state.c_seq === undefined
                                                            ? false
                                                            : this.state.readOnly
                                                    }
                                                    type="number"
                                                />
                                            </td>
                                            <td className="p-0">
                                                <input
                                                    value={this.state.c_num10}
                                                    name="c_num10"
                                                    placeholder="10"
                                                    className={
                                                        (errors.c_num10.length > 0 ? "error-border " : "") +
                                                        "w-40px"
                                                    }
                                                    onChange={this.onLoadData}
                                                    onKeyUp={this.onlyNum}
                                                    readOnly={
                                                        this.state.c_seq === undefined
                                                            ? false
                                                            : this.state.readOnly
                                                    }
                                                    type="number"
                                                />
                                            </td>
                                            <td className="p-0">
                                                <input
                                                    value={this.state.c_num11}
                                                    name="c_num11"
                                                    placeholder="11"
                                                    className={
                                                        (errors.c_num11.length > 0 ? "error-border " : "") +
                                                        "w-40px"
                                                    }
                                                    onChange={this.onLoadData}
                                                    onKeyUp={this.onlyNum}
                                                    readOnly={
                                                        this.state.c_seq === undefined
                                                            ? false
                                                            : this.state.readOnly
                                                    }
                                                    type="number"
                                                />
                                            </td>
                                            <td className="p-0">
                                                <input
                                                    value={this.state.c_num12}
                                                    name="c_num12"
                                                    placeholder="12"
                                                    className={
                                                        (errors.c_num12.length > 0 ? "error-border " : "") +
                                                        "w-40px"
                                                    }
                                                    onChange={this.onLoadData}
                                                    onKeyUp={this.onlyNum}
                                                    readOnly={
                                                        this.state.c_seq === undefined
                                                            ? false
                                                            : this.state.readOnly
                                                    }
                                                    type="number"
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="p-0">
                                                <input
                                                    value={this.state.c_num13}
                                                    name="c_num13"
                                                    placeholder="13"
                                                    className={
                                                        (errors.c_num13.length > 0 ? "error-border " : "") +
                                                        "w-40px"
                                                    }
                                                    onChange={this.onLoadData}
                                                    onKeyUp={this.onlyNum}
                                                    readOnly={
                                                        this.state.c_seq === undefined
                                                            ? false
                                                            : this.state.readOnly
                                                    }
                                                    type="number"
                                                />
                                            </td>
                                            <td className="p-0">
                                                <input
                                                    value={this.state.c_num14}
                                                    name="c_num14"
                                                    placeholder="14"
                                                    className={
                                                        (errors.c_num14.length > 0 ? "error-border " : "") +
                                                        "w-40px"
                                                    }
                                                    onChange={this.onLoadData}
                                                    onKeyUp={this.onlyNum}
                                                    readOnly={
                                                        this.state.c_seq === undefined
                                                            ? false
                                                            : this.state.readOnly
                                                    }
                                                    type="number"
                                                />
                                            </td>
                                            <td className="p-0">
                                                <input
                                                    value={this.state.c_num15}
                                                    name="c_num15"
                                                    placeholder="15"
                                                    className={
                                                        (errors.c_num15.length > 0 ? "error-border " : "") +
                                                        "w-40px"
                                                    }
                                                    onChange={this.onLoadData}
                                                    onKeyUp={this.onlyNum}
                                                    readOnly={
                                                        this.state.c_seq === undefined
                                                            ? false
                                                            : this.state.readOnly
                                                    }
                                                    type="number"
                                                />
                                            </td>
                                            <td className="p-0">
                                                <input
                                                    value={this.state.c_num16}
                                                    name="c_num16"
                                                    placeholder="16"
                                                    className={
                                                        (errors.c_num16.length > 0 ? "error-border " : "") +
                                                        "w-40px"
                                                    }
                                                    onChange={this.onLoadData}
                                                    onKeyUp={this.onlyNum}
                                                    readOnly={
                                                        this.state.c_seq === undefined
                                                            ? false
                                                            : this.state.readOnly
                                                    }
                                                    type="number"
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="p-0">
                                                <input
                                                    value={this.state.c_num17}
                                                    name="c_num17"
                                                    placeholder="17"
                                                    className={
                                                        (errors.c_num17.length > 0 ? "error-border " : "") +
                                                        "w-40px"
                                                    }
                                                    onChange={this.onLoadData}
                                                    onKeyUp={this.onlyNum}
                                                    readOnly={
                                                        this.state.c_seq === undefined
                                                            ? false
                                                            : this.state.readOnly
                                                    }
                                                    type="number"
                                                />
                                            </td>
                                            <td className="p-0">
                                                <input
                                                    value={this.state.c_num18}
                                                    name="c_num18"
                                                    placeholder="18"
                                                    className={
                                                        (errors.c_num18.length > 0 ? "error-border " : "") +
                                                        "w-40px"
                                                    }
                                                    onChange={this.onLoadData}
                                                    onKeyUp={this.onlyNum}
                                                    readOnly={
                                                        this.state.c_seq === undefined
                                                            ? false
                                                            : this.state.readOnly
                                                    }
                                                    type="number"
                                                />
                                            </td>
                                            <td className="p-0">
                                                <input
                                                    value={this.state.c_num19}
                                                    name="c_num19"
                                                    placeholder="19"
                                                    className={
                                                        (errors.c_num19.length > 0 ? "error-border " : "") +
                                                        "w-40px"
                                                    }
                                                    onChange={this.onLoadData}
                                                    onKeyUp={this.onlyNum}
                                                    readOnly={
                                                        this.state.c_seq === undefined
                                                            ? false
                                                            : this.state.readOnly
                                                    }
                                                    type="number"
                                                />
                                            </td>
                                            <td className="p-0">
                                                <input
                                                    value={this.state.c_num20}
                                                    name="c_num20"
                                                    placeholder="20"
                                                    className={
                                                        (errors.c_num20.length > 0 ? "error-border " : "") +
                                                        "w-40px"
                                                    }
                                                    onChange={this.onLoadData}
                                                    onKeyUp={this.onlyNum}
                                                    readOnly={
                                                        this.state.c_seq === undefined
                                                            ? false
                                                            : this.state.readOnly
                                                    }
                                                    type="number"
                                                />
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </CardBody>
                            <div className="mb-10px row justify-content-evenly">
                                {this.state.c_seq === undefined ? (
                                    <button
                                        className="btn btn-success col-4"
                                        onClick={this.createCard}
                                    >
                                        추가
                                    </button>
                                ) : this.state.c_seq !== undefined &&
                                    this.state.readOnly !== true ? (
                                    <button
                                        className="btn btn-yellow col-4"
                                        onClick={this.updateCard}
                                    >
                                        수정 완료
                                    </button>
                                ) : (
                                    <button
                                        className="btn btn-purple col-4"
                                        onClick={this.updateSign}
                                    >
                                        수정
                                    </button>
                                )}
                                <button
                                    className="btn btn-danger col-4"
                                    onClick={this.deleteCard}
                                >
                                    삭제
                                </button>
                            </div>
                        </Card>
                        <Card className={`${styles.conc_card_calculate} col-sm-8`}>
                            {this.state.strength_updated || this.state.f1 === null ? (
                                <button
                                    className="btn btn-lg btn-warning m-auto"
                                    onClick={this.calculateConc}
                                    id="calculateBtn"
                                    disabled={this.state.btnDisabled}
                                >
                                    조사분석 결과 표 생성
                                </button>
                            ) : (
                                <div className={`${styles.img_card_container}`}>
                                    {this.state.readOnly === false &&
                                        this.state.disabled === false ? (
                                        <div className="fs-30px text-red">
                                            수정 항목 저장 완료 후 ,<br />
                                            조사분석 결과 표를 생성 할 수 있습니다.
                                        </div>
                                    ) : (
                                        <>
                                            <Bar
                                                type="bar"
                                                data={data}
                                            // options={options}
                                            />
                                            <table className=" table text-center w-100">
                                                <tbody className="table-bordered">
                                                    <tr>
                                                        <td width="20%">평균치</td>
                                                        <td colSpan={3} style={{ color: "blue" }}>
                                                            {this.state.data_average}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td rowSpan={2}> 타격각도( &deg; )</td>
                                                        <td
                                                            rowSpan={2}
                                                            style={{ color: "red" }}
                                                            width="40%"
                                                        >
                                                            {this.props.c_hitAngle}
                                                        </td>
                                                        <td width="25%">보정치 &#916; R</td>
                                                        <td style={{ color: "blue" }} width="15%">
                                                            {this.state.delta_r}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            기준경도 R<sub>0</sub>
                                                        </td>
                                                        <td style={{ color: "blue" }}>
                                                            {this.state.standard_hardness}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td rowSpan={3}>추정식</td>
                                                        <td>F1 : 강도곡선 그래프</td>
                                                        <td colSpan={2} style={{ color: "blue" }}>
                                                            {this.state.f1}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            F2 : 13R<sub>0</sub> -184
                                                        </td>
                                                        <td colSpan={2} style={{ color: "blue" }}>
                                                            {this.state.f2}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            F3 : 7.3 R<sub>0</sub> + 100
                                                        </td>
                                                        <td colSpan={2} style={{ color: "blue" }}>
                                                            {this.state.f3}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>평균</td>
                                                        <td>(F1 + F2 + F3) / 3</td>
                                                        <td colSpan={2} style={{ color: "blue" }}>
                                                            {this.state.average}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan={2}>재 령 보 정 계 수</td>

                                                        <td colSpan={2} style={{ color: "blue" }}>
                                                            {this.state.age_correction_factor}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan={2}>추 정 압 축 강 도 (MPa)</td>

                                                        <td colSpan={2} style={{ color: "blue" }}>
                                                            {this.state.strength}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </>
                                    )}
                                </div>
                            )}
                        </Card>
                    </div>
                ) : null}



                {API === headAPI + "iron_list?facilityid=" + this.props.facilityid ? (
                    <div
                        className={`${this.state.report ? "is_checked" : ""} row h-450px`}
                    >
                        <Card className="col-6">
                            <div className="row card_index">{this.props.index}</div>
                            {this.state.i_seq === undefined ? (
                                <input
                                    type="checkbox"
                                    onChange={this.onChooseCard}
                                    value={this.state.report}
                                    checked={this.state.report}
                                    name="report"
                                />
                            ) : this.state.i_seq !== undefined &&
                                this.state.readOnly !== false ? (
                                ""
                            ) : (
                                <input
                                    type="checkbox"
                                    onChange={this.onChooseCard}
                                    value={this.state.report}
                                    checked={this.state.report}
                                    name="report"
                                />
                            )}
                            {this.state.i_seq === undefined ? (
                                <input
                                    type="file"
                                    accept="image/jpeg, image/jpg, image/png"
                                    onChange={(e) => this.handleCreateImg(e)}
                                    className={`${styles.create_new_image}`}
                                    name="i_file_id"
                                // value={this.state.file_id}
                                />
                            ) : this.state.i_seq !== undefined &&
                                this.state.readOnly !== false ? (
                                <CardImg
                                    className={`${styles.result_card_img}`}
                                    top
                                    src={i_file_id}
                                />
                            ) : (
                                <>
                                    <CardImg
                                        className={`${styles.result_card_img}`}
                                        top
                                        src={i_file_id}
                                    />
                                    <button
                                        name="i_file_id"
                                        // value={this.state.file_id}
                                        onClick={(e) => this.editTitleImgSign(e)}
                                    >
                                        이미지 수정
                                    </button>
                                    {this.state.deleteSign === true ? (
                                        <input
                                            type="file"
                                            accept="image/jpeg, image/jpg, image/png"
                                            onChange={(e) => this.handleUpdateImg(e)}
                                            className={`${styles.create_new_image_2}`}
                                            name="i_file_id"
                                        />
                                    ) : null}
                                </>
                            )}
                            <CardBody className="row">
                                <div className="row">
                                    <label className="form-label col-form-label w-100px">
                                        구역
                                    </label>
                                    {this.state.disabled === true && this.state.i_seq ? (
                                        <input
                                            style={inputstyle}
                                            className="col col-form-control"
                                            value={this.state.i_zone}
                                            name="i_zone"
                                            readOnly
                                        />
                                    ) : (
                                        <select
                                            className={`${styles.inner_select} col col-form-control`}
                                            defaultValue={this.props.i_zone}
                                            key={this.props.i_zone}
                                            name="i_zone"
                                            onChange={this.onLoadSelect}
                                        >
                                            <option value="내부">내부</option>
                                            <option value="외부">외부</option>
                                        </select>
                                    )}
                                </div>
                                <div className="row">
                                    <label className="form-label col-form-label w-100px">
                                        위치
                                    </label>
                                    <input
                                        style={inputstyle}
                                        className="col col-form-control"
                                        value={this.state.i_position}
                                        name="i_position"
                                        onChange={this.onLoadData}
                                        readOnly={
                                            this.state.i_seq === undefined
                                                ? false
                                                : this.state.readOnly
                                        }
                                        type="text"
                                    />
                                </div>
                                <div className="row">
                                    <label className="form-label col-form-label w-100px">
                                        층
                                    </label>
                                    {this.state.disabled === true && this.state.i_seq ? (
                                        <input
                                            style={inputstyle}
                                            className="col col-form-control"
                                            value={
                                                this.props.floor < 0
                                                    ? "지하 " + Math.abs(this.props.floor) + " 층"
                                                    : "지상 " + this.props.floor + " 층"
                                            }
                                            name="floor"
                                            readOnly
                                            type="text"
                                        />
                                    ) : (
                                        <select
                                            className={`${styles.inner_select} col col-form-control`}
                                            defaultValue={this.props.floor}
                                            key={this.props.floor}
                                            name="floor"
                                            onChange={this.onLoadSelect}
                                        >
                                            {this.state.totalDOWN &&
                                                this.state.totalDOWN.map((i) => {
                                                    return (
                                                        <option value={"-" + i} key={i} name={"-" + i}>
                                                            지하 {i} 층
                                                        </option>
                                                    );
                                                })}
                                            {this.state.totalUP &&
                                                this.state.totalUP.map((i) => {
                                                    return (
                                                        <option value={i} key={i} name={i}>
                                                            지상 {i} 층
                                                        </option>
                                                    );
                                                })}
                                        </select>
                                    )}
                                </div>
                                <div className="row">
                                    <label className="form-label col-form-label w-100px">
                                        비고
                                    </label>
                                    <input
                                        style={inputstyle}
                                        className="col col-form-control"
                                        value={this.state.i_etc}
                                        name="i_etc"
                                        onChange={this.onLoadData}
                                        readOnly={
                                            this.state.i_seq === undefined
                                                ? false
                                                : this.state.readOnly
                                        }
                                        type="text"
                                    />
                                </div>
                            </CardBody>
                        </Card>
                        <Card
                            className={`${styles.iron_card} col-6 h-100 position-relative`}
                        >
                            {this.state.i_seq === undefined ? (
                                <input
                                    type="file"
                                    accept="image/jpeg, image/jpg, image/png"
                                    onChange={(e) => this.handleIronCreateImg(e)}
                                    className={`${styles.create_new_image_2}`}
                                    name="result_img"
                                // value={this.state.file_id}
                                />
                            ) : this.state.i_seq !== undefined &&
                                this.state.readOnly !== false ? (
                                <>
                                    <CardImg
                                        className={`${styles.result_card_img_iron}`}
                                        top
                                        src={result_img}
                                    />
                                </>
                            ) : (
                                <>
                                    1
                                    <CardImg
                                        className={`${styles.result_card_img_iron}`}
                                        top
                                        src={result_img}
                                    />
                                    <button
                                        name="result_img"
                                        // value={this.state.file_id}
                                        onClick={(e) => this.editSubImgSign(e)}
                                    >
                                        이미지 수정
                                    </button>
                                    {this.state.subDeleteSign === true ? (
                                        <input
                                            type="file"
                                            accept="image/jpeg, image/jpg, image/png"
                                            onChange={(e) => this.handleIronCreateImg(e)}
                                            className={`${styles.create_new_image_2}`}
                                            name="result_img"
                                        />
                                    ) : null}
                                </>
                            )}

                            <div className="mt-10px mb-10px row justify-content-evenly position-absolute bottom-0 w-100">
                                {this.state.i_seq === undefined ? (
                                    <button
                                        className="btn btn-success col-4"
                                        onClick={this.createCard}
                                    >
                                        추가
                                    </button>
                                ) : this.state.i_seq !== undefined &&
                                    this.state.readOnly !== true ? (
                                    <button
                                        className="btn btn-yellow col-4"
                                        onClick={this.updateCard}
                                    >
                                        수정 완료
                                    </button>
                                ) : (
                                    <button
                                        className="btn btn-purple col-4"
                                        onClick={this.updateSign}
                                    >
                                        수정
                                    </button>
                                )}
                                <button
                                    className="btn btn-danger col-4"
                                    onClick={this.deleteCard}
                                >
                                    삭제
                                </button>
                            </div>
                        </Card>
                    </div>
                ) : null}



                {API === headAPI + "carbon_list?facilityid=" + this.props.facilityid ? (
                    <Card
                        className={`${this.state.report ? "is_checked" : ""
                            } col-md-4 col-sm-6 pt-15px`}
                    >
                        <div className="row card_index">{this.props.index}</div>
                        {this.state.ca_seq === undefined ? (
                            <input
                                type="checkbox"
                                onChange={this.onChooseCard}
                                value={this.state.report}
                                checked={this.state.report}
                                name="report"
                            />
                        ) : this.state.ca_seq !== undefined &&
                            this.state.readOnly !== false ? (
                            ""
                        ) : (
                            <input
                                type="checkbox"
                                onChange={this.onChooseCard}
                                value={this.state.report}
                                checked={this.state.report}
                                name="report"
                            />
                        )}
                        {this.state.ca_seq === undefined ? (
                            <input
                                type="file"
                                accept="image/jpeg, image/jpg, image/png"
                                onChange={(e) => this.handleCreateImg(e)}
                                className={`${styles.create_new_image}`}
                                name="ca_file_id"
                            // value={this.state.file_id}
                            />
                        ) : this.state.ca_seq !== undefined &&
                            this.state.readOnly !== false ? (
                            <CardImg
                                className={`${styles.result_card_img}`}
                                top
                                src={ca_file_id}
                            />
                        ) : (
                            <>
                                {this.state.deleteSign === true ? (
                                    <input
                                        type="file"
                                        accept="image/jpeg, image/jpg, image/png"
                                        onChange={(e) => this.handleUpdateImg(e)}
                                        className={`${styles.create_new_image}`}
                                        name="ca_file_id"
                                    />
                                ) : (
                                    <>
                                        <CardImg
                                            top
                                            src={ca_file_id}
                                            className={`${styles.result_card_img}`}
                                        />
                                        <button
                                            name="ca_file_id"
                                            // value={this.state.file_id}
                                            onClick={(e) => this.editTitleImgSign(e)}
                                        >
                                            이미지 수정
                                        </button>
                                    </>
                                )}
                            </>
                        )}

                        <CardBody className="row">
                            <div className="row">
                                <label className="form-label col-form-label w-100px">
                                    구역
                                </label>
                                {this.state.disabled === true && this.state.ca_seq ? (
                                    <input
                                        style={inputstyle}
                                        className="col col-form-control"
                                        value={this.state.ca_zone}
                                        name="ca_zone"
                                        readOnly
                                    />
                                ) : (
                                    <select
                                        className={`${styles.inner_select} col col-form-control`}
                                        defaultValue={this.props.ca_zone}
                                        key={this.props.ca_zone}
                                        name="ca_zone"
                                        onChange={this.onLoadSelect}
                                    >
                                        <option value="내부">내부</option>
                                        <option value="외부">외부</option>
                                    </select>
                                )}
                            </div>
                            <div className="row">
                                <label className="form-label col-form-label w-100px">
                                    위치
                                </label>
                                <input
                                    style={inputstyle}
                                    className="col col-form-control"
                                    value={this.state.ca_position}
                                    name="ca_position"
                                    onChange={this.onLoadData}
                                    readOnly={
                                        this.state.ca_seq === undefined
                                            ? false
                                            : this.state.readOnly
                                    }
                                    type="text"
                                />
                            </div>
                            <div className="row">
                                <label className="form-label col-form-label w-100px">층</label>
                                {this.state.disabled === true && this.state.ca_seq ? (
                                    <input
                                        style={inputstyle}
                                        className="col col-form-control"
                                        value={
                                            this.props.floor < 0
                                                ? "지하 " + Math.abs(this.props.floor) + " 층"
                                                : "지상 " + this.props.floor + " 층"
                                        }
                                        name="floor"
                                        readOnly
                                        type="text"
                                    />
                                ) : (
                                    <select
                                        className={`${styles.inner_select} col col-form-control`}
                                        defaultValue={this.props.floor}
                                        key={this.props.floor}
                                        name="floor"
                                        onChange={this.onLoadSelect}
                                    >
                                        {this.state.totalDOWN &&
                                            this.state.totalDOWN.map((i) => {
                                                return (
                                                    <option value={"-" + i} key={i} name={"-" + i}>
                                                        지하 {i} 층
                                                    </option>
                                                );
                                            })}
                                        {this.state.totalUP &&
                                            this.state.totalUP.map((i) => {
                                                return (
                                                    <option value={i} key={i} name={i}>
                                                        지상 {i} 층
                                                    </option>
                                                );
                                            })}
                                    </select>
                                )}
                            </div>
                            <div className="row">
                                <label className="form-label col-form-label w-100px">
                                    측정값 ( mm )
                                </label>
                                <input
                                    style={inputstyle}
                                    className="col col-form-control"
                                    value={this.state.ca_num}
                                    name="ca_num"
                                    onChange={this.onLoadData}
                                    onKeyUp={this.onlyNum}
                                    readOnly={
                                        this.state.ca_seq === undefined
                                            ? false
                                            : this.state.readOnly
                                    }
                                    type="number"
                                />
                            </div>
                            <div className="row">
                                <label className="form-label col-form-label w-100px">
                                    비고
                                </label>
                                <input
                                    style={inputstyle}
                                    className="col col-form-control"
                                    value={this.state.ca_etc}
                                    name="ca_etc"
                                    onChange={this.onLoadData}
                                    readOnly={
                                        this.state.ca_seq === undefined
                                            ? false
                                            : this.state.readOnly
                                    }
                                    type="text"
                                />
                            </div>
                        </CardBody>
                        <div className="mt-10px mb-10px row justify-content-evenly">
                            {this.state.ca_seq === undefined ? (
                                <button
                                    className="btn btn-success col-4"
                                    onClick={this.createCard}
                                >
                                    추가
                                </button>
                            ) : this.state.ca_seq !== undefined &&
                                this.state.readOnly !== true ? (
                                <button
                                    className="btn btn-yellow col-4"
                                    onClick={this.updateCard}
                                >
                                    수정 완료
                                </button>
                            ) : (
                                <button
                                    className="btn btn-purple col-4"
                                    onClick={this.updateSign}
                                >
                                    수정
                                </button>
                            )}
                            <button
                                className="btn btn-danger col-4"
                                onClick={this.deleteCard}
                            >
                                삭제
                            </button>
                        </div>
                    </Card>
                ) : null}



                {API === headAPI + "slope_list?facilityid=" + this.props.facilityid ? (
                    <Card
                        className={`${this.state.report ? "is_checked" : ""
                            } col-md-4 col-sm-6 pt-15px`}
                    >
                        <div className="row card_index">{this.props.index}</div>
                        {this.state.s_seq === undefined ? (
                            <input
                                type="checkbox"
                                onChange={this.onChooseCard}
                                value={this.state.report}
                                checked={this.state.report}
                                name="report"
                            />
                        ) : this.state.s_seq !== undefined &&
                            this.state.readOnly !== false ? (
                            ""
                        ) : (
                            <input
                                type="checkbox"
                                onChange={this.onChooseCard}
                                value={this.state.report}
                                checked={this.state.report}
                                name="report"
                            />
                        )}
                        {this.state.s_seq === undefined ? (
                            <input
                                type="file"
                                accept="image/jpeg, image/jpg, imgage/png"
                                onChange={(e) => this.handleCreateImg(e)}
                                className={`${styles.create_new_image}`}
                                name="s_file_id"
                            // value={this.state.file_id}
                            />
                        ) : this.state.s_seq !== undefined &&
                            this.state.readOnly !== false ? (
                            <CardImg
                                className={`${styles.result_card_img}`}
                                top
                                src={s_file_id}
                            />
                        ) : (
                            <>
                                <CardImg
                                    className={`${styles.result_card_img}`}
                                    top
                                    src={s_file_id}
                                />
                                <button
                                    name="s_file_id"
                                    // value={this.state.file_id}
                                    onClick={(e) => this.editTitleImgSign(e)}
                                >
                                    이미지 수정
                                </button>
                                {this.state.deleteSign === true ? (
                                    <input
                                        type="file"
                                        accept="image/jpeg, image/jpg, image/png"
                                        onChange={(e) => this.handleUpdateImg(e)}
                                        className={`${styles.create_new_image}`}
                                        name="s_file_id"
                                    />
                                ) : (
                                    ""
                                )}
                            </>
                        )}
                        <CardBody className="row">
                            <div className="row">
                                <label className="form-label col-form-label w-100px">
                                    구역
                                </label>
                                {this.state.disabled === true && this.state.s_seq ? (
                                    <input
                                        style={inputstyle}
                                        className="col col-form-control"
                                        value={this.state.s_zone}
                                        name="s_zone"
                                        readOnly
                                    />
                                ) : (
                                    <select
                                        className={`${styles.inner_select} col col-form-control`}
                                        defaultValue={this.props.s_zone}
                                        key={this.props.s_zone}
                                        name="s_zone"
                                        onChange={this.onLoadSelect}
                                    >
                                        <option value="내부">내부</option>
                                        <option value="외부">외부</option>
                                    </select>
                                )}
                            </div>
                            <div className="row">
                                <label className="form-label col-form-label w-100px">
                                    위치
                                </label>
                                <input
                                    style={inputstyle}
                                    className="col col-form-control"
                                    value={this.state.s_position}
                                    name="s_position"
                                    onChange={this.onLoadData}
                                    readOnly={
                                        this.state.s_seq === undefined ? false : this.state.readOnly
                                    }
                                    type="text"
                                />
                            </div>
                            <div className="row">
                                <label className="form-label col-form-label w-100px">층</label>
                                {this.state.disabled === true && this.state.s_seq ? (
                                    <input
                                        style={inputstyle}
                                        className="col col-form-control"
                                        value={
                                            this.props.floor < 0
                                                ? "지하 " + Math.abs(this.props.floor) + " 층"
                                                : "지상 " + this.props.floor + " 층"
                                        }
                                        name="floor"
                                        readOnly
                                        type="text"
                                    />
                                ) : (
                                    <select
                                        className={`${styles.inner_select} col col-form-control`}
                                        defaultValue={this.props.floor}
                                        key={this.props.floor}
                                        name="floor"
                                        onChange={this.onLoadSelect}
                                    >
                                        {this.state.totalDOWN &&
                                            this.state.totalDOWN.map((i) => {
                                                return (
                                                    <option value={"-" + i} key={i} name={"-" + i}>
                                                        지하 {i} 층
                                                    </option>
                                                );
                                            })}
                                        {this.state.totalUP &&
                                            this.state.totalUP.map((i) => {
                                                return (
                                                    <option value={i} key={i} name={i}>
                                                        지상 {i} 층
                                                    </option>
                                                );
                                            })}
                                    </select>
                                )}
                            </div>
                            <div className="row">
                                <label className="form-label col-form-label w-100px">
                                    좌측상단 ( &deg; )
                                </label>
                                <input
                                    style={inputstyle}
                                    className="col col-form-control"
                                    value={this.state.s_num1}
                                    name="s_num1"
                                    onChange={this.onLoadData}
                                    onKeyUp={this.onlyNum}
                                    readOnly={
                                        this.state.s_seq === undefined ? false : this.state.readOnly
                                    }
                                    type="number"
                                />
                            </div>
                            <div className="row">
                                <label className="form-label col-form-label w-100px">
                                    우측상단 ( &deg; )
                                </label>
                                <input
                                    style={inputstyle}
                                    className="col col-form-control"
                                    value={this.state.s_num2}
                                    name="s_num2"
                                    onChange={this.onLoadData}
                                    onKeyUp={this.onlyNum}
                                    readOnly={
                                        this.state.s_seq === undefined ? false : this.state.readOnly
                                    }
                                    type="number"
                                />
                            </div>
                            <div className="row">
                                <label className="form-label col-form-label w-100px">
                                    좌측하단 ( &deg; )
                                </label>
                                <input
                                    style={inputstyle}
                                    className="col col-form-control"
                                    value={this.state.s_num3}
                                    name="s_num3"
                                    onChange={this.onLoadData}
                                    onKeyUp={this.onlyNum}
                                    readOnly={
                                        this.state.s_seq === undefined ? false : this.state.readOnly
                                    }
                                    type="number"
                                />
                            </div>
                            <div className="row">
                                <label className="form-label col-form-label w-100px">
                                    우측하단 ( &deg; )
                                </label>
                                <input
                                    style={inputstyle}
                                    className="col col-form-control"
                                    value={this.state.s_num4}
                                    name="s_num4"
                                    onChange={this.onLoadData}
                                    onKeyUp={this.onlyNum}
                                    readOnly={
                                        this.state.s_seq === undefined ? false : this.state.readOnly
                                    }
                                    type="number"
                                />
                            </div>
                            <div className="row">
                                <label className="form-label col-form-label w-100px">
                                    비고
                                </label>
                                <input
                                    style={inputstyle}
                                    className="col col-form-control"
                                    value={this.state.s_etc}
                                    name="s_etc"
                                    onChange={this.onLoadData}
                                    readOnly={
                                        this.state.s_seq === undefined ? false : this.state.readOnly
                                    }
                                    type="text"
                                />
                            </div>
                        </CardBody>
                        <div className="mt-10px mb-10px row justify-content-evenly">
                            {this.state.s_seq === undefined ? (
                                <button
                                    className="btn btn-success col-4"
                                    onClick={this.createCard}
                                >
                                    추가
                                </button>
                            ) : this.state.s_seq !== undefined &&
                                this.state.readOnly !== true ? (
                                <button
                                    className="btn btn-yellow col-4"
                                    onClick={this.updateCard}
                                >
                                    수정 완료
                                </button>
                            ) : (
                                <button
                                    className="btn btn-purple col-4"
                                    onClick={this.updateSign}
                                >
                                    수정
                                </button>
                            )}
                            <button
                                className="btn btn-danger col-4"
                                onClick={this.deleteCard}
                            >
                                삭제
                            </button>
                        </div>
                    </Card>
                ) : null}


                {API === headAPI + "equipment_list?facilityid=" + this.state.facilityid ? (
                    <div className={`${styles.seven_tr} d-flex`}>


                        <div className={`${styles.td}`} style={{ width: "200px" }}>
                            {this.state.e_id !== undefined ? (
                                this.state.disabled ? (
                                    <input
                                        style={inputstyle}
                                        className="col col-form-control w-100 text-center"
                                        value={this.state.e_subject}
                                        name="e_subject"
                                        onChange={this.onLoadData}
                                        readOnly={
                                            this.state.e_id === undefined
                                                ? false
                                                : this.state.readOnly
                                        }
                                        type="text"
                                    />
                                ) : (
                                    <select
                                        name="e_subject"
                                        id="e_subject"
                                        className={`${styles.inner_select} col col-form-control`}
                                        onChange={this.onLoadData}
                                        defaultValue={this.props.e_subject}
                                        key={this.props.e_subject}
                                    >
                                        <option value="콘크리트 추정압축 강도조사">
                                            콘크리트 추정압축 강도조사
                                        </option>
                                        <option value="부재 치수 조사">부재 치수 조사</option>
                                        <option value="철근배근 상태조사">철근배근 상태조사</option>
                                        <option value="건축물 탄산화조사">건축물 탄산화조사</option>
                                        <option value="건축물 기울기 및 부재 처짐조사">
                                            건축물 기울기 및 부재 처짐조사
                                        </option>
                                        <option value="균열 및 외관조사">균열 및 외관조사</option>
                                    </select>
                                )
                            ) : (
                                <select
                                    name="e_subject"
                                    id="e_subject"
                                    className={`${styles.inner_select} col col-form-control`}
                                    onChange={this.onLoadData}
                                    defaultValue="0"
                                >
                                    <option value="0" disabled>
                                        선택하세요.
                                    </option>
                                    <option value="콘크리트 추정압축 강도조사">
                                        콘크리트 추정압축 강도조사
                                    </option>
                                    <option value="부재 치수 조사">부재 치수 조사</option>
                                    <option value="철근배근 상태조사">철근배근 상태조사</option>
                                    <option value="건축물 탄산화조사">건축물 탄산화조사</option>
                                    <option value="건축물 기울기 및 부재 처짐조사">
                                        건축물 기울기 및 부재 처짐조사
                                    </option>
                                    <option value="균열 및 외관조사">균열 및 외관조사</option>
                                </select>
                            )}
                        </div>



                        <div className={`${styles.td}`} style={{ width: "150px" }}>
                            {this.state.e_id !== undefined ? (
                                this.state.disabled ? (
                                    <input
                                        style={inputstyle}
                                        className="col col-form-control w-100 text-center"
                                        value={this.props.e_method}
                                        name="e_method"
                                        onChange={this.onLoadData}
                                        readOnly={
                                            this.state.e_id === undefined
                                                ? false
                                                : this.state.readOnly
                                        }
                                        type="text"
                                    />
                                ) : (
                                    <select
                                        name="e_method"
                                        id="e_method"
                                        className={`${styles.inner_select} col col-form-control`}
                                        onChange={this.onLoadData}
                                        defaultValue={this.props.e_method}
                                        key={this.props.e_method}
                                    >
                                        <option value="반발경도법">반발경도법</option>
                                        <option value="실측조사">실측조사</option>
                                        <option value="전자파">전자파</option>
                                        <option value="염색법">염색법</option>
                                        <option value="더있겠지">더있겠지</option>
                                    </select>
                                )
                            ) : (
                                <select
                                    name="e_method"
                                    id="e_method"
                                    onChange={this.onLoadData}
                                    selected={this.props.e_method}
                                    defaultValue="0"
                                    className={`${styles.inner_select} col col-form-control`}
                                >
                                    <option value="0" disabled>
                                        선택하세요.
                                    </option>
                                    <option value="반발경도법">반발경도법</option>
                                    <option value="실측조사">실측조사</option>
                                    <option value="전자파">전자파</option>
                                    <option value="염색법">염색법</option>
                                </select>
                            )}
                        </div>



                        <div className={`${styles.td}`}>
                            {this.state.e_id === undefined ? (
                                <input
                                    style={inputstyle}
                                    className="col col-form-control w-100 text-center"
                                    name="e_name"
                                    onChange={this.onLoadData}
                                    type="text"
                                />
                            ) : this.state.disabled ? (
                                <input
                                    style={inputstyle}
                                    className="col col-form-control w-100 text-center"
                                    value={this.props.e_name}
                                    name="e_name"
                                    onChange={this.onLoadData}
                                    readOnly={
                                        this.state.e_id === undefined ? false : this.state.readOnly
                                    }
                                    type="text"
                                />
                            ) : (
                                <>
                                    <input
                                        style={inputstyle}
                                        className="col col-form-control w-100 text-center"
                                        name="e_name"
                                        onChange={this.onLoadData}
                                        type="text"
                                    />
                                    {errors.e_name.length > 0 && (
                                        <div className="text-red">{errors.e_name}</div>
                                    )}
                                </>
                            )}
                        </div>



                        <div className={`${styles.td}`} style={{ width: "200px" }}>
                            {this.state.e_id === undefined ? (
                                <input
                                    type="file"
                                    accept="image/jpeg, image/jpg, image/png"
                                    onChange={(e) => this.handleCreateImg(e)}
                                    className={`${styles.create_new_image_7td}`}
                                    name="e_file_id"
                                // value={this.state.file_id}
                                />
                            ) : this.state.e_id !== undefined &&
                                this.state.readOnly !== false ? (
                                <>
                                    {/* 기본으로 우선 보여는 곳  */}
                                    <CardImg
                                        className={`${styles.result_card_img_7}`}
                                        top
                                        src={e_file_id}
                                    />
                                </>
                            ) : (
                                <>
                                    <CardImg
                                        className={`${styles.result_card_img_7}`}
                                        top
                                        src={e_file_id}
                                    />
                                    <button
                                        name="e_file_id"
                                        // value={this.state.file_id}
                                        onClick={(e) => this.editTitleImgSign(e)}
                                    >
                                        이미지 수정
                                    </button>
                                    {this.state.deleteSign === true ? (
                                        <input
                                            type="file"
                                            accept="image/jpeg, image/jpg, image/png"
                                            onChange={(e) => this.handleUpdateImg(e)}
                                            className={`${styles.td}`}
                                            name="e_file_id"
                                        />
                                    ) : null}
                                </>
                            )}
                        </div>


                        <div className="m-auto" style={{ width: "100px" }}>
                            {this.state.e_id === undefined ? (
                                <button
                                    className="btn btn-success d-block m-auto"
                                    onClick={this.createCard}
                                    disabled={this.state.e_id_disabled}
                                >
                                    {this.state.e_id_disabled === false ? "등록" : "등록완료"}
                                </button>
                            ) : this.state.e_id !== undefined &&
                                this.state.readOnly !== true ? (
                                <button
                                    className="btn btn-yellow d-block m-auto"
                                    onClick={this.updateCard}
                                >
                                    수정 완료
                                </button>
                            ) : (
                                <button
                                    className="btn btn-purple d-block m-auto"
                                    onClick={this.updateSign}
                                >
                                    수정
                                </button>
                            )}

                            {this.state.e_id === undefined ? (
                                ""
                            ) : (
                                <button
                                    className="btn btn-danger d-block m-auto"
                                    onClick={this.deleteCard}
                                >
                                    삭제
                                </button>
                            )}
                        </div>
                    </div>
                ) : null}
            </>
        );
    }
}

export { CardWithText };
