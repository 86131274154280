import React from "react";
import { Link } from "react-router-dom";
import { AppSettings } from "../../config/app-settings.js";

const URL = process.env.REACT_APP_BACKEND_HOST;

const API = URL + "/members/login/"; //수정 필요

const validateForm = (errors) => {
  let valid = true;
  Object.values(errors).forEach((val) => val.length > 0 && (valid = false));
  return valid;
};
class Login extends React.Component {
  static contextType = AppSettings;

  constructor(props) {
    super(props);
    this.state = {
      userid: "",
      password: "",
      errors: {
        userid: "",
        password: "",
      },
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    this.context.handleSetAppSidebarNone(true);
    this.context.handleSetAppHeaderNone(true);
    this.context.handleSetAppContentClass("p-0");
  }

  componentWillUnmount() {
    this.context.handleSetAppSidebarNone(false);
    this.context.handleSetAppHeaderNone(false);
    this.context.handleSetAppContentClass("");
  }

  handleInput = (e) => {
    const { name, value } = e.target;
    let errors = this.state.errors;

    switch (name) {
      case "userid":
        errors.userid =
          value.length < 1 ? "아이디를 정확하게 입력해주세요." : "";
        break;
      case "password":
        errors.password =
          value.length < 3 ? "비밀번호를 정확하게 입력해주세요." : "";
        break;
      default:
        break;
    }

    this.setState({ errors, [name]: value }, () => {});
  };
  handleSubmit(event) {
    event.preventDefault();
    if (validateForm(this.state.errors)) {
      fetch(API, {
        method: "POST",
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
        body: JSON.stringify({
          userid: this.state.userid,
          password: this.state.password,
        }),
      })
        .then((res) => {
          if (res.status / 100 === 2) {
            return res.json();
          } else {
            alert(
              "알수없는 오류가 발생하였습니다. 새로고침 후 다시 시도해주세요."
            );
          }
        })
        .then((res) => {
          if (res.success === "True") {
            sessionStorage.setItem("token", res.token);
            sessionStorage.setItem("userName", this.state.userid);
            localStorage.setItem("token", res.token);
            localStorage.setItem("userName", this.state.userid);
            window.location.replace("/home");
          } else if (res.message === "no user") {
            alert("아이디를 확인해주세요.");
            this.setState({ userid: "", password: "" });
          } else if (res.message === "wrong password") {
            if (res.attempt < 5) {
              alert(
                "비밀번호를 확인해주세요. 총 5회 중 " + res.attempt + "회 오류"
              );
              this.setState({ userid: "", password: "" });
            } else if (res.attempt === 5) {
              alert(
                "총 5회 오류. 계정이 잠금 보호되었습니다. 010-3335-2459로 문의바랍니다."
              );
              this.setState({ userid: "", password: "" });
              sessionStorage.clear();
            }
          } else if (res.message === "locked") {
            alert("계정이 보호되어있습니다. 010-3335-2459로 문의바랍니다.");
            sessionStorage.clear();
          }
        });
    }
  }

  render() {
    const { handleInput } = this;
    const { errors } = this.state;

    return (
      <div className="login login-v1">
        <div className="login-container">
          <div className="login-header">
            <div className="brand">
              <div className="d-flex align-items-center">
                <span className="logo"></span> <b>Ascope</b>
              </div>
              <small>
                건축시설물 안전진단 솔루션 Ascope에 오신것을 환영합니다.
              </small>
            </div>
            <div className="icon">
              <i className="fa fa-lock"></i>
            </div>
          </div>
          <div className="login-body">
            <div className="login-content fs-13px">
              <form onSubmit={this.handleSubmit}>
                <div className="form-floating mb-20px">
                  <input
                    type="text"
                    className="form-control fs-13px h-45px"
                    onChange={handleInput}
                    name="userid"
                    id="userid"
                    value={this.state.userid}
                    placeholder="User ID"
                  />
                  {errors.userid.length > 0 && (
                    <div
                      className="error"
                      style={{
                        color: "#db2269",
                        fontSize: "1em",
                        display: "block",
                        height: "auto",
                        textAlign: "start",
                        position: "relative",
                        marginTop: "5px",
                      }}
                      placeholder="사용자 ID"
                    >
                      {errors.userid}
                    </div>
                  )}
                  <label
                    htmlFor="userid"
                    className="d-flex align-items-center py-0"
                  >
                    사용자 ID
                  </label>
                </div>
                <div className="form-floating mb-20px">
                  <input
                    type="password"
                    className="form-control fs-13px h-45px"
                    onChange={handleInput}
                    name="password"
                    id="password"
                    value={this.state.password}
                    placeholder="Password"
                  />
                  {errors.password.length > 0 && (
                    <span
                      className="error"
                      style={{
                        color: "#db2269",
                        fontSize: "1em",
                        display: "block",
                        height: "auto",
                        textAlign: "start",
                        position: "relative",
                        marginTop: "5px",
                      }}
                    >
                      {errors.password}
                    </span>
                  )}
                  <label
                    htmlFor="password"
                    className="d-flex align-items-center py-0"
                  >
                    비밀번호
                  </label>
                </div>
                <div className="login-buttons">
                  <button
                    type="submit"
                    className="btn h-45px btn-success d-block w-100 btn-lg"
                  >
                    로그인
                  </button>

                  <Link
                    to="/user/register"
                    className="navbar-brand d-block me-0"
                    style={{ height: "70px" }}
                  >
                    <button
                      type="submit"
                      className="btn h-45px btn-outline-success d-block w-100 btn-lg"
                    >
                      회원 가입 하러가기
                    </button>
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export { Login };
