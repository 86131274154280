import { React, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import { CsAskAdmin } from "./cs-ask-admin.js";

const URL = process.env.REACT_APP_BACKEND_HOST;
const LIST = URL + "/boards/view/support";
const LIST_PATCH = URL + "/boards/update/notice";
const PROCESS_PATCH = URL + "/boards/update/support";
const CHECK_MEM = URL + "/members/check/admin";
const token = "bearer " + sessionStorage.getItem("token");

const CsAskView = ({ history, match }) => {
  const [data, setData] = useState({});
  const [date, setDate] = useState({
    created_at: "",
    updated_at: "",
  });
  const [admin, setAdmin] = useState(false);
  const [answered, setAnswered] = useState(false);
  const { no } = match.params;

  const changeProcess = () => {
    const formData = new FormData();
    formData.append("process", 2);
    formData.append("id", no);

    fetch(PROCESS_PATCH, {
      method: "PATCH",
      headers: {
        Authorization: token,
      },
      body: formData,
    })
      .then((res) => {
        if (res.status / 100 === 2) {
          return res.json();
        } else {
          alert(
            "알수없는 오류가 발생하였습니다. 새로고침 후 다시 시도해주세요."
          );
        }
      })
      .then((res) => {
        alert("처리 중으로 변경되었습니다.");
        window.location.replace("/cs/ask");
      });
  };

  useEffect(() => {
    fetch(CHECK_MEM, {
      method: "GET",
      headers: {
        Authorization: token,
      },
    })
      .then((res) => {
        if (res.status / 100 === 2) {
          return res.json();
        } else {
          alert(
            "알수없는 오류가 발생하였습니다. 새로고침 후 다시 시도해주세요."
          );
        }
      })
      .then((res) => {
        setAdmin(res);
      });
  });

  useEffect(() => {
    fetch(LIST + "?id=" + no, {
      method: "GET",
      headers: {
        Authorization: token,
      },
    })
      .then((res) => {
        if (res.status / 100 === 2) {
          return res.json();
        } else {
          alert(
            "알수없는 오류가 발생하였습니다. 새로고침 후 다시 시도해주세요."
          );
        }
      })
      .then((res) => {
        res.answer === null ? setAnswered(false) : setAnswered(true);
        setData(res);
        setAnswered(res.answer);

        setDate({
          created_at: moment(res.created_at).format("yyyy.MM.DD"),
          updated_at: moment(res.updated_at).format("yyyy.MM.DD"),
        });
      });
  }, []);

  const handleDelete = () => {
    const formData = new FormData();

    alert("삭제하시겠습니까?");

    formData.append("id", no);
    formData.append("is_public", "0");

    fetch(LIST_PATCH, {
      method: "PATCH",
      headers: {
        Authorization: token,
      },
      body: formData,
    })
      .then((res) => {
        if (res.status / 100 === 2) {
          return res.json();
        } else {
          alert(
            "알수없는 오류가 발생하였습니다. 새로고침 후 다시 시도해주세요."
          );
        }
      })
      .then((res) => {
        alert("삭제 되었습니다.");
        window.location.replace("/cs/ask");
      });
  };

  return (
    <>
      <div className="post-view-wrapper col-lg-7 col-md-9 ">
        <div className="common_title">
          <h2 className="title">문의내역 확인하기</h2>
        </div>

        {data ? (
          <div className="post-view-table">
            <div className="post-view-title">
              <div className="process">
                {data.process === 1 ? (
                  <div>접수</div>
                ) : data.process === 2 ? (
                  <div className="text-gray">처리중</div>
                ) : data.process === 3 ? (
                  <div className="text-red">답변완료</div>
                ) : (
                  ""
                )}
              </div>
              <div className="tit_end">{data.title}</div>
              <div>
                {date.created_at !== date.updated_at
                  ? date.updated_at
                  : date.created_at}
              </div>
            </div>

            <div className="post-view-row">
              Q.<div dangerouslySetInnerHTML={{ __html: data.content }}></div>
            </div>

            {answered ? (
              <div className="post-view-row">
                <div className="text-red">A.</div>
                <div
                  dangerouslySetInnerHTML={{ __html: answered.content }}
                ></div>
              </div>
            ) : null}
          </div>
        ) : (
          "해당 게시글을 찾을 수 없습니다."
        )}

        {data.process === 1 && !admin ? (
          <>
            <Link
              className="btn btn-primary float-end mt-1"
              to={{
                pathname: `/askEdit/${no}`,
                state: {
                  content: data.content,
                  title: data.title,
                },
              }}
            >
              수정
            </Link>
            <button
              className="btn btn-primary float-end mt-1"
              onClick={() => handleDelete()}
            >
              삭제
            </button>
          </>
        ) : null}

        <button
          className="btn btn-primary float-end mt-1"
          onClick={() => history.goBack()}
        >
          목록으로 돌아가기
        </button>
        {/* 답변 게시판  */}
        {admin ? (
          <>
            {data.process === 1 ? (
              <div className="mt-5">
                접수 완료 버튼
                <button onClick={(e) => changeProcess(e)}>접수 완료</button>
              </div>
            ) : data.process === 2 ? (
              <CsAskAdmin no={no} />
            ) : data.process === 3 ? null : null}
          </>
        ) : null}
      </div>
    </>
  );
};

export { CsAskView };
