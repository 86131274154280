import React from "react";
import { Link } from "react-router-dom";
import PrivateRoute from "./../lib/privateRoute";
class TopMenuNavList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: -1,
    };
    this.handleExpand = this.handleExpand.bind(this);
    this.onClick = this.onClick.bind(this);
  }

  handleExpand(e, i, match) {
    e.preventDefault();

    this.setState((state) => ({
      active: this.state.active === i ? -1 : i,
    }));
  }

  onClick(e) {
    window.location.replace(this.props.data.path);
  }

  render() {
    var icon = this.props.data.icon && (
      <div className="menu-icon">
        <i className={this.props.data.icon}></i>
      </div>
    );
    var caret = this.props.data.children && !this.props.data.badge && (
      <div className="menu-caret"></div>
    );
    var title = this.props.data.title && (
      <div className="menu-text">{this.props.data.title}</div>
    );

    return (
      <PrivateRoute
        path={this.props.data.path}
        exact={this.props.data.exact}
        children={({ match }) => (
          <div
            className={
              "menu-item " +
              (match ? "active" : "") +
              (this.props.data.children ? "has-sub " : "")
            }
          >
            {this.props.data.children ? (
              <Link className="menu-link " onClick={this.props.expand} to="">
                {icon} {title} {caret}
              </Link>
            ) : (
              <Link className="menu-link " onClick={this.onClick} to="">
                {icon} {title} {caret}
              </Link>
            )}
            {this.props.data.children && (
              <div
                style={{ zIndex: "99" }}
                className={
                  "child-menu menu-submenu " +
                  (this.props.active ? "d-block " : "")
                }
              >
                {this.props.data.children &&
                  this.props.data.children.map((submenu, i) => (
                    <TopMenuNavList
                      data={submenu}
                      key={i}
                      expand={(e) => this.handleExpand(e, i, match)}
                      active={i === this.state.active}
                      clicked={this.state.clicked}
                    />
                  ))}
              </div>
            )}
          </div>
        )}
      />
    );
  }
}

export default TopMenuNavList;
