import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
} from "reactstrap";
import { result_prj } from "../pages/facilitylayout/layout";
import { AppSettings } from "./../config/app-settings.js";

const URL = process.env.REACT_APP_BACKEND_HOST;

let API = "";

const validateForm = (errors) => {
  let valid = true;
  Object.values(errors).forEach((val) => val.length > 0 && (valid = false));
  return valid;
};
class Evaluation extends React.Component {
  static contextType = AppSettings;

  constructor(props) {
    super(props);

    this.state = {
      ID: this.props.ID,
      UFD: this.props.UFD,
      facilityid: "",

      crack_result: "",
      arc_result: "",
      conc_result: "",
      iron_result: "",
      carbon_result: "",
      slope_result: "",
      result_disabled: true,
      result_sign: false,
      result_null: true,

      slab: "",
      beam: "",
      column: "",
      result: "",
      structural_disabled: true,
      structural_sign: false,
      structural_null: true,

      name: "",
      prjchief_disabled: true,
      prjchief_sign: false,
      prjchief_null: true,

      summary: "",
      plan: "",
      criteria: "",
      method: "",
      remuneration_disabled: true,
      remuneration_sign: false,
      remuneration_null: true,

      file: "",
      errors: {
        crack_result: "",
        arc_result: "",
        conc_result: "",
        iron_result: "",
        carbon_result: "",
        slope_result: "",
        slab: "",
        name: "",
        summary: "",
        plan: "",
        criteria: "",
        method: "",
        beam: "",
        column: "",
        result: "",
      },
      activeTab: "1",
    };
    this.handleCreate = this.handleCreate.bind(this);
    this.handleUpdateSign = this.handleUpdateSign.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
    this.tabSwitch = this.tabSwitch.bind(this);
  }
  componentDidMount() {
    const token = "bearer " + sessionStorage.getItem("token");
    if (this.props.ID.length !== 0) {
      if (this.state.activeTab) {
        API = URL + "/project/result_list";
        fetch(API + "?facilityid=" + result_prj, {
          method: "GET",
          headers: {
            Authorization: token,
          },
        })
          .then((res) => {
            if (res.status / 100 === 2) {
              return res.json();
            } else {
              alert(
                "알수없는 오류가 발생하였습니다. 새로고침 후 다시 시도해주세요."
              );
            }
          })
          .then((res) => {
            this.setState({ facilityid: result_prj });
            const response_arr = res.results;

            for (let i = 0; i < response_arr.length; i++) {
              if (res["results"][i]["facilityid"] === result_prj) {
                let result = res["results"][i];

                this.setState({
                  crack_result: result["crack_result"],
                  arc_result: result["arc_result"],
                  conc_result: result["conc_result"],
                  iron_result: result["iron_result"],
                  carbon_result: result["carbon_result"],
                  slope_result: result["slope_result"],
                  result_null: false,
                });
                this.forceUpdate();
              }
            }
          });
      }
    }
  }

  tabSwitch(tab) {
    if (this.state.activeTab !== tab) this.setState({ activeTab: tab });
    const token = "bearer " + sessionStorage.getItem("token");

    this.forceUpdate();
    if (tab === "1") {
      API = URL + "/project/result_list";
      fetch(API + "?facilityid=" + result_prj, {
        method: "GET",
        headers: {
          Authorization: token,
        },
      })
        .then((res) => {
          if (res.status / 100 === 2) {
            return res.json();
          } else {
            alert(
              "알수없는 오류가 발생하였습니다. 새로고침 후 다시 시도해주세요."
            );
          }
        })
        .then((res) => {
          this.setState({ facilityid: result_prj });
          const response_arr = res.results;

          for (let i = 0; i < response_arr.length; i++) {
            if (res["results"][i]["facilityid"] === result_prj) {
              let result = res["results"][i];

              this.setState({
                crack_result: result["crack_result"],
                arc_result: result["arc_result"],
                conc_result: result["conc_result"],
                iron_result: result["iron_result"],
                carbon_result: result["carbon_result"],
                slope_result: result["slope_result"],
                result_null: false,
              });
              this.forceUpdate();
            }
          }
        });
    } else if (tab === "2") {
      API = URL + "/project/structural_list";
      fetch(API + "?facilityid=" + result_prj, {
        method: "GET",
        headers: {
          Authorization: token,
        },
      })
        .then((res) => {
          if (res.status / 100 === 2) {
            return res.json();
          } else {
            alert(
              "알수없는 오류가 발생하였습니다. 새로고침 후 다시 시도해주세요."
            );
          }
        })
        .then((res) => {
          this.setState({
            slab: "",
            beam: "",
            column: "",
            result: "",
            structural_disabled: true,
            structural_sign: false,
            structural_null: true,
          });
          this.setState({ facilityid: result_prj });
          const response_arr = res.results;
          for (let i = 0; i < response_arr.length; i++) {
            if (res["results"][i]["facilityid"] === result_prj) {
              let result = res["results"][i];

              this.setState({
                slab: result["slab"],
                beam: result["beam"],
                column: result["column"],
                result: result["result"],
                structural_null: false,
              });
              this.forceUpdate();
            }
          }
        });
    } else if (tab === "3") {
      API = URL + "/project/chief_list";
      fetch(API + "?facilityid=" + result_prj, {
        method: "GET",
        headers: {
          Authorization: token,
        },
      })
        .then((res) => {
          if (res.status / 100 === 2) {
            return res.json();
          } else {
            alert(
              "알수없는 오류가 발생하였습니다. 새로고침 후 다시 시도해주세요."
            );
          }
        })
        .then((res) => {
          this.setState({
            name: "",
            result: "",
            prjchief_disabled: true,
            prjchief_sign: false,
            prjchief_null: true,
          });

          this.setState({ facilityid: result_prj });
          const response_arr = res.results;

          for (let i = 0; i < response_arr.length; i++) {
            if (res["results"][i]["facilityid"] === result_prj) {
              let result = res["results"][i];
              this.setState({
                result: result["result"],
                name: result["name"],
                prjchief_null: false,
              });
              this.forceUpdate();
            }
          }
        });
    } else if (tab === "4") {
      API = URL + "/project/remuneration_list";
      fetch(API + "?facilityid=" + result_prj, {
        method: "GET",
        headers: {
          Authorization: token,
        },
      })
        .then((res) => {
          if (res.status / 100 === 2) {
            return res.json();
          } else {
            alert(
              "알수없는 오류가 발생하였습니다. 새로고침 후 다시 시도해주세요."
            );
          }
        })
        .then((res) => {
          this.setState({
            summary: "",
            plan: "",
            criteria: "",
            method: "",
            remuneration_disabled: true,
            remuneration_sign: false,
            remuneration_null: true,
          });
          this.setState({ facilityid: result_prj });
          const response_arr = res.results;

          for (let i = 0; i < response_arr.length; i++) {
            if (res["results"][i]["facilityid"] === result_prj) {
              let result = res["results"][i];

              this.setState({
                summary: result["summary"],
                plan: result["plan"],
                criteria: result["criteria"],
                method: result["method"],
                remuneration_null: false,
              });
              this.forceUpdate();
            }
          }
        });
    }
  }

  handleInput = (e) => {
    const { name, value } = e.target;
    let errors = this.state.errors;

    this.setState({ errors, [name]: value }, () => {});
  };

  handleUpload(e) {
    // if (e.target.name === "arc_img") {
    //   formData.append("arc_img", e.target.files[0], "file");
    // } else if (e.target.name === "carbon_img") {
    //   formData.append("carbon_img", e.target.files[0], "file");
    // } else if (e.target.name === "conc_img") {
    //   formData.append("conc_img", e.target.files[0], "file");
    // } else if (e.target.name === "iron_img") {
    //   formData.append("iron_img", e.target.files[0], "file");
    // } else if (e.target.name === "slope_img") {
    //   formData.append("slope_img", e.target.files[0], "file");
    // } else if (e.target.name === "crack_img") {
    //   formData.append("crack_img", e.target.files[0], "file");
    // }
  }

  handleUpdate(e) {
    API = URL + "/project/update";
    const token = "bearer " + sessionStorage.getItem("token");

    const formdata = new FormData();
    formdata.append("facilityid", this.state.facilityid);

    if (this.state.activeTab === "1") {
      formdata.append("crack_result", this.state.crack_result);
      formdata.append("arc_result", this.state.arc_result);
      formdata.append("conc_result", this.state.conc_result);
      formdata.append("iron_result", this.state.iron_result);
      formdata.append("carbon_result", this.state.carbon_result);
      formdata.append("slope_result", this.state.slope_result);
      if (this.state.crack_result === "") {
        alert("균열 및 외관 조사에 대해 입력해주세요.");
      } else if (this.state.arc_result === "") {
        alert("부재 제원 조사에 대해 입력해주세요.");
      } else if (this.state.conc_result === "") {
        alert("콘크리트 강도 조사에 대해 입력해주세요.");
      } else if (this.state.iron_result === "") {
        alert("철근 배근 조사에 대해 입력해주세요.");
      } else if (this.state.carbon_result === "") {
        alert("탄산화 조사에 대해 입력해주세요.");
      } else if (this.state.slope_result === "") {
        alert("기울기 조사에 대해 입력해주세요.");
      } else {
        if (validateForm(this.state.errors)) {
          fetch(API + "/inspection_result", {
            method: "POST",
            headers: {
              Authorization: token,
            },
            body: formdata,
          }).then((res) => res.text());

          alert("상태조사 수정에 성공하였습니다.");
          window.location.reload("/evaluation");
        } else {
          alert("상태조사 수정에 실패하였습니다.");
        }
      }
    } else if (this.state.activeTab === "2") {
      formdata.append("slab", this.state.slab);
      formdata.append("beam", this.state.beam);
      formdata.append("column", this.state.column);
      formdata.append("result", this.state.result);

      if (this.state.slab === "") {
        alert("슬래브에 대해 입력해주세요.");
      } else if (this.state.beam === "") {
        alert("보에 대해 입력해주세요.");
      } else if (this.state.column === "") {
        alert("기둥에 대해 입력해주세요.");
      } else if (this.state.result === "") {
        alert("구조검토 결과에 대해 입력해주세요.");
      } else {
        if (validateForm(this.state.errors)) {
          fetch(API + "/structural", {
            method: "POST",
            headers: {
              Authorization: token,
            },
            body: formdata,
          }).then((res) => res.text());

          alert("구조검토 수정에 성공하였습니다.");
          window.location.reload("/evaluation");
        } else {
          alert("구조검토 수정에 실패하였습니다.");
        }
      }
    } else if (this.state.activeTab === "3") {
      formdata.append("name", this.state.name);
      formdata.append("result", this.state.result);
      if (this.state.name === "") {
        alert("책임기술자 성명을 입력해주세요.");
      } else if (this.state.result === "") {
        alert("책임기술자 종합의견을 입력해주세요.");
      } else {
        if (validateForm(this.state.errors)) {
          fetch(API + "/prjchief", {
            method: "POST",
            headers: {
              Authorization: token,
            },
            body: formdata,
          }).then((res) => res.text());

          alert("책임기술자 수정에 성공하였습니다.");
          window.location.reload("/evaluation");
        } else {
          alert("책임기술자 수정에 실패하였습니다.");
        }
      }
    } else if (this.state.activeTab === "4") {
      formdata.append("summary", this.state.summary);
      formdata.append("plan", this.state.plan);
      formdata.append("criteria", this.state.criteria);
      formdata.append("method", this.state.method);
      if (this.state.summary === "") {
        alert("보수 보강 개요를 입력해주세요.");
      } else if (this.state.plan === "") {
        alert("보수 보강 계획을 입력해주세요.");
      } else if (this.state.criteria === "") {
        alert("보수 보강 선정기준 입력해주세요.");
      } else if (this.state.method === "") {
        alert("보수 보강 공법 입력해주세요.");
      } else {
        if (validateForm(this.state.errors)) {
          fetch(API + "/remuneration", {
            method: "POST",
            headers: {
              Authorization: token,
            },
            body: formdata,
          }).then((res) => res.text());

          alert("보수 보강 수정에 성공하였습니다.");
          window.location.reload("/evaluation");
        } else {
          alert("보수 보강 수정에 실패하였습니다.");
        }
      }
    }
  }

  handleUpdateSign(e) {
    if (this.state.activeTab === "1") {
      this.setState({
        result_disabled: !this.state.result_disabled,
        result_sign: !this.state.result_sign,
      });
    } else if (this.state.activeTab === "2") {
      this.setState({
        structural_disabled: !this.state.structural_disabled,
        structural_sign: !this.state.structural_sign,
      });
    } else if (this.state.activeTab === "3") {
      this.setState({
        prjchief_disabled: !this.state.prjchief_disabled,
        prjchief_sign: !this.state.prjchief_sign,
      });
    } else if (this.state.activeTab === "4") {
      this.setState({
        remuneration_disabled: !this.state.remuneration_disabled,
        remuneration_sign: !this.state.remuneration_sign,
      });
    }
  }

  handleCreate(e) {
    API = URL + "/project";
    const token = "bearer " + sessionStorage.getItem("token");

    const formdata = new FormData();
    formdata.append("facilityid", this.state.facilityid);

    if (this.state.activeTab === "1") {
      formdata.append("crack_result", this.state.crack_result);
      formdata.append("arc_result", this.state.arc_result);
      formdata.append("conc_result", this.state.conc_result);
      formdata.append("iron_result", this.state.iron_result);
      formdata.append("carbon_result", this.state.carbon_result);
      formdata.append("slope_result", this.state.slope_result);

      if (this.state.crack_result === "") {
        alert("균열 및 외관 조사에 대해 입력해주세요.");
      } else if (this.state.arc_result === "") {
        alert("부재 제원 조사에 대해 입력해주세요.");
      } else if (this.state.conc_result === "") {
        alert("콘크리트 강도 조사에 대해 입력해주세요.");
      } else if (this.state.iron_result === "") {
        alert("철근 배근 조사에 대해 입력해주세요.");
      } else if (this.state.carbon_result == null) {
        alert("탄산화 조사에 대해 입력해주세요.");
      } else if (this.state.slope_result == null) {
        alert("기울기 조사에 대해 입력해주세요.");
      } else {
        if (validateForm(this.state.errors)) {
          fetch(API + "/inspection_result", {
            method: "POST",
            headers: {
              Authorization: token,
            },
            body: formdata,
          }).then((res) => res.text());

          alert("상태조사 저장에 성공하였습니다.");
          // window.location.reload("/evaluation");
        } else {
          alert("상태조사 저장에 실패하였습니다.");
        }
      }
    } else if (this.state.activeTab === "2") {
      formdata.append("slab", this.state.slab);
      formdata.append("beam", this.state.beam);
      formdata.append("column", this.state.column);
      formdata.append("result", this.state.result);
      if (this.state.slab === "") {
        alert("슬래브에 대해 입력해주세요.");
      } else if (this.state.beam === "") {
        alert("보에 대해 입력해주세요.");
      } else if (this.state.column === "") {
        alert("기둥에 대해 입력해주세요.");
      } else if (this.state.result === "") {
        alert("구조검토 결과에 대해 입력해주세요.");
      } else {
        if (validateForm(this.state.errors)) {
          fetch(API + "/structural", {
            method: "POST",
            headers: {
              Authorization: token,
            },
            body: formdata,
          }).then((res) => res.text());

          alert("구조검토 결과 저장에 성공하였습니다.");
          // window.location.reload("/evaluation");
        } else {
          alert("구조검토 결과 저장에 실패하였습니다.");
        }
      }
    } else if (this.state.activeTab === "3") {
      formdata.append("name", this.state.name);
      formdata.append("result", this.state.result);
      if (this.state.name === "") {
        alert("책임기술자 성함을 입력해주세요.");
      } else if (this.state.result === "") {
        alert("책임기술자 종합의견에 대해 입력해주세요.");
      } else {
        if (validateForm(this.state.errors)) {
          fetch(API + "/prjchief", {
            method: "POST",
            headers: {
              Authorization: token,
            },
            body: formdata,
          }).then((res) => res.text());

          alert("책임기술자 종합 의견 저장에 성공하였습니다.");
          // window.location.reload("/evaluation");
        } else {
          alert("책임기술자 종합 의견 저장에 실패하였습니다.");
        }
      }
    } else if (this.state.activeTab === "4") {
      formdata.append("summary", this.state.summary);
      formdata.append("plan", this.state.plan);
      formdata.append("criteria", this.state.criteria);
      formdata.append("method", this.state.method);
      if (this.state.summary === "") {
        alert("보수 보강 개요를 입력해주세요.");
      } else if (this.state.plan === "") {
        alert("보수 보강 계획을 입력해주세요.");
      } else if (this.state.criteria === "") {
        alert("보수 보강 선정기준 입력해주세요.");
      } else if (this.state.method === "") {
        alert("보수 보강 공법 입력해주세요.");
      } else {
        if (validateForm(this.state.errors)) {
          fetch(API + "/remuneration", {
            method: "POST",
            headers: {
              Authorization: token,
            },
            body: formdata,
          }).then((res) => res.text());

          alert("보수 보강 저장에 성공하였습니다.");
          // window.location.reload("/evaluation");
        } else {
          alert("보수 보강 저장에 실패하였습니다.");
        }
      }
    }

    window.location.reload("/evaluation");
  }

  render() {
    const { handleInput } = this;

    return (
      <>
        {this.state.ID.length !== 0 ? (
          <div className="forcedFrame col-sm-8">
            <div className="m-auto">
              <Nav tabs>
                <NavItem
                  className={`${this.state.activeTab === "1"} `}
                  onClick={() => {
                    this.tabSwitch("1");
                  }}
                >
                  <NavLink>상태조사 결과</NavLink>
                </NavItem>
                <NavItem
                  className={`${this.state.activeTab === "2"} ${
                    this.props.UFD === "안전점검" ? "d-none" : ""
                  }`}
                  onClick={() => {
                    this.tabSwitch("2");
                  }}
                >
                  <NavLink>구조검토 결과</NavLink>
                </NavItem>
                <NavItem
                  className={`${this.state.activeTab === "3"}`}
                  onClick={() => {
                    this.tabSwitch("3");
                  }}
                >
                  <NavLink>책임기술자 종합 의견</NavLink>
                </NavItem>
                <NavItem
                  className={` ${this.state.activeTab === "4"} ${
                    this.props.UFD === "안전점검" ? "d-none" : ""
                  }`}
                  onClick={() => {
                    this.tabSwitch("4");
                  }}
                >
                  <NavLink>보수보강 방안</NavLink>
                </NavItem>
              </Nav>
              <div
                style={{
                  maxHeight: "40rem",
                  Height: "40rem",
                  overflowY: "scroll",
                  overflowX: "hidden",
                }}
              >
                <TabContent activeTab={this.state.activeTab}>
                  <TabPane tabId="1">
                    <Row>
                      <Col>
                        <div className="mb-3">
                          <label
                            className="form-label"
                            htmlFor="균열 및 외관조사"
                          >
                            균열 및 외관조사
                          </label>
                          <textarea
                            className="form-control"
                            rows="4"
                            value={this.state.crack_result}
                            name="crack_result"
                            id="crack_result"
                            onChange={handleInput}
                            placeholder="균열 및 외관조사에 대해 작성해주세요."
                            readOnly={
                              this.state.result_null === true
                                ? false
                                : this.state.result_sign === true
                                ? false
                                : true
                            }
                          ></textarea>
                          {/* <input
                          type="file"
                          multiple
                          accept="image/jpeg, image/jpg"
                          name="crack_img"
                          onChange={(e) => this.handleUpload(e)}
                        /> */}
                        </div>
                        <div className="mb-3">
                          <label
                            className="form-label"
                            htmlFor="부재 제원 조사"
                          >
                            부재 제원 조사
                          </label>
                          <textarea
                            className="form-control"
                            rows="4"
                            value={this.state.arc_result}
                            name="arc_result"
                            id="arc_result"
                            onChange={handleInput}
                            placeholder="부재 제원 조사에 대해 작성해주세요."
                            readOnly={
                              this.state.result_null === true
                                ? false
                                : this.state.result_sign === true
                                ? false
                                : true
                            }
                          ></textarea>
                          {/* <input
                          type="file"
                          multiple
                          accept="image/jpeg, image/jpg"
                          name="arc_img"
                          onChange={(e) => this.handleUpload(e)}
                        /> */}
                        </div>
                        <div className="mb-3">
                          <label
                            className="form-label"
                            htmlFor="콘크리트 강도 조사"
                          >
                            콘크리트 강도 조사
                          </label>
                          <textarea
                            className="form-control"
                            rows="4"
                            value={this.state.conc_result}
                            name="conc_result"
                            id="conc_result"
                            onChange={handleInput}
                            placeholder="콘크리트 강도 조사에 대해 작성해주세요."
                            readOnly={
                              this.state.result_null === true
                                ? false
                                : this.state.result_sign === true
                                ? false
                                : true
                            }
                          ></textarea>
                          {/* <input
                          type="file"
                          multiple
                          name="conc_img"
                          accept="image/jpeg, image/jpg"
                          onChange={(e) => this.handleUpload(e)}
                        /> */}
                        </div>
                        <div className="mb-3">
                          <label
                            className="form-label"
                            htmlFor="철근 배근 조사"
                          >
                            철근 배근 조사
                          </label>
                          <textarea
                            className="form-control"
                            rows="4"
                            value={this.state.iron_result}
                            name="iron_result"
                            id="iron_result"
                            onChange={handleInput}
                            placeholder="철근 배근 조사에 대해 작성해주세요."
                            readOnly={
                              this.state.result_null === true
                                ? false
                                : this.state.result_sign === true
                                ? false
                                : true
                            }
                          ></textarea>
                          {/* <input
                          type="file"
                          multiple
                          name="iron_img"
                          accept="image/jpeg, image/jpg"
                          onChange={(e) => this.handleUpload(e)}
                        /> */}
                        </div>
                        <div className="mb-3">
                          <label className="form-label" htmlFor="탄산화 조사">
                            탄산화 조사
                          </label>
                          <textarea
                            className="form-control"
                            rows="4"
                            value={this.state.carbon_result}
                            name="carbon_result"
                            id="carbon_result"
                            onChange={handleInput}
                            placeholder="탄산화 조사에 대해 작성해주세요."
                            readOnly={
                              this.state.result_null === true
                                ? false
                                : this.state.result_sign === true
                                ? false
                                : true
                            }
                          ></textarea>
                          {/* <input
                          type="file"
                          multiple
                          name="carbon_img"
                          accept="image/jpeg, image/jpg"
                          onChange={(e) => this.handleUpload(e)}
                        /> */}
                        </div>
                        <div className="mb-3">
                          <label className="form-label" htmlFor="기울기 조사">
                            기울기 조사
                          </label>
                          <textarea
                            className="form-control"
                            rows="4"
                            value={this.state.slope_result}
                            name="slope_result"
                            id="slope_result"
                            onChange={handleInput}
                            placeholder="기울기 조사에 대해 작성해주세요."
                            readOnly={
                              this.state.result_null === true
                                ? false
                                : this.state.result_sign === true
                                ? false
                                : true
                            }
                          ></textarea>
                          {/* <input
                          type="file"
                          multiple
                          name="slope_img"
                          accept="image/jpeg, image/jpg"
                          onChange={(e) => this.handleUpload(e)}
                        /> */}
                        </div>
                      </Col>
                    </Row>

                    {this.state.result_null === true ? (
                      <button
                        className="btn btn-info float-end"
                        type="submit"
                        encType="multipart/form-data"
                        onClick={this.handleCreate}
                      >
                        상태조사 결과 저장
                      </button>
                    ) : this.state.result_sign === true ? (
                      <button
                        className="btn btn-info float-end"
                        type="submit"
                        encType="multipart/form-data"
                        onClick={this.handleUpdate}
                      >
                        상태조사 수정 저장
                      </button>
                    ) : (
                      <button
                        className="btn btn-info float-end"
                        type="submit"
                        encType="multipart/form-data"
                        onClick={this.handleUpdateSign}
                      >
                        상태조사 수정
                      </button>
                    )}
                  </TabPane>
                  <TabPane tabId="2">
                    <Row>
                      <Col>
                        <div className="mb-3">
                          <label className="form-label" htmlFor="슬래브">
                            슬래브
                          </label>
                          <textarea
                            className="form-control"
                            rows="4"
                            name="slab"
                            value={this.state.slab}
                            id="slab"
                            onChange={handleInput}
                            placeholder="슬래브에 대해 작성해주세요."
                            readOnly={
                              this.state.structural_null === true
                                ? false
                                : this.state.structural_sign === true
                                ? false
                                : true
                            }
                          ></textarea>
                          {/* <input
                          type="file"
                          multiple
                          accept="image/jpeg, image/jpg"
                          name="slab_img"
                          onChange={(e) => this.handleUpload(e)}
                        /> */}
                        </div>
                        <div className="mb-3">
                          <label className="form-label" htmlFor="보">
                            보
                          </label>
                          <textarea
                            className="form-control"
                            rows="4"
                            name="beam"
                            value={this.state.beam}
                            id="beam"
                            onChange={handleInput}
                            placeholder="보에 대해 작성해주세요."
                            readOnly={
                              this.state.structural_null === true
                                ? false
                                : this.state.structural_sign === true
                                ? false
                                : true
                            }
                          ></textarea>
                          {/* <input
                          type="file"
                          multiple
                          accept="image/jpeg, image/jpg"
                          name="crossbeam_img"
                          onChange={(e) => this.handleUpload(e)}
                        /> */}
                        </div>
                        <div className="mb-3">
                          <label className="form-label" htmlFor="기둥">
                            기둥
                          </label>
                          <textarea
                            className="form-control"
                            rows="4"
                            value={this.state.column}
                            name="column"
                            id="column"
                            onChange={handleInput}
                            placeholder="기둥에 대해 작성해주세요."
                            readOnly={
                              this.state.structural_null === true
                                ? false
                                : this.state.structural_sign === true
                                ? false
                                : true
                            }
                          ></textarea>
                          {/* <input
                          type="file"
                          multiple
                          accept="image/jpeg, image/jpg"
                          name="pillar_img"
                          onChange={(e) => this.handleUpload(e)}
                        /> */}
                        </div>
                        <div className="mb-3">
                          <label className="form-label" htmlFor="구조검토결과">
                            구조검토 결과
                          </label>
                          <textarea
                            className="form-control"
                            rows="4"
                            value={this.state.result}
                            name="result"
                            id="result"
                            onChange={handleInput}
                            placeholder=" 구조검토 결과에 대해 작성해주세요."
                            readOnly={
                              this.state.structural_null === true
                                ? false
                                : this.state.structural_sign === true
                                ? false
                                : true
                            }
                          ></textarea>
                          {/* <input
                          type="file"
                          multiple
                          accept="image/jpeg, image/jpg"
                          name=""
                          onChange={(e) => this.handleUpload(e)}
                        /> */}
                        </div>
                      </Col>
                    </Row>

                    {this.state.structural_null === true ? (
                      <button
                        className="btn btn-info float-end"
                        type="submit"
                        encType="multipart/form-data"
                        onClick={this.handleCreate}
                      >
                        구조검토 결과 저장
                      </button>
                    ) : this.state.structural_sign === true ? (
                      <button
                        className="btn btn-info float-end"
                        type="submit"
                        encType="multipart/form-data"
                        onClick={this.handleUpdate}
                      >
                        구조검토 수정 저장
                      </button>
                    ) : (
                      <button
                        className="btn btn-info float-end"
                        type="submit"
                        encType="multipart/form-data"
                        onClick={this.handleUpdateSign}
                      >
                        구조검토 수정
                      </button>
                    )}
                  </TabPane>
                  <TabPane tabId="3">
                    <Row>
                      <Col>
                        <div className="mb-3">
                          <label
                            className="form-label"
                            htmlFor="책임기술자 성명"
                          >
                            책임기술자 성명
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            value={this.state.name}
                            name="name"
                            id="name"
                            onChange={handleInput}
                            placeholder="책임기술자 성명을 입력해주세요."
                            readOnly={
                              this.state.prjchief_null === true
                                ? false
                                : this.state.prjchief_sign === true
                                ? false
                                : true
                            }
                          />
                        </div>
                        <div className="mb-3">
                          <label
                            className="form-label"
                            htmlFor="책임기술자 종합의견"
                          >
                            책임기술자 종합의견
                          </label>
                          <textarea
                            className="form-control"
                            rows="8"
                            value={this.state.result}
                            name="result"
                            id="result"
                            onChange={handleInput}
                            placeholder="책임기술자 종합의견에 대해 작성해주세요."
                            readOnly={
                              this.state.prjchief_null === true
                                ? false
                                : this.state.prjchief_sign === true
                                ? false
                                : true
                            }
                          ></textarea>
                        </div>
                      </Col>
                    </Row>
                    {this.state.prjchief_null === true ? (
                      <button
                        className="btn btn-info float-end"
                        type="submit"
                        encType="multipart/form-data"
                        onClick={this.handleCreate}
                      >
                        책임기술자의견 저장
                      </button>
                    ) : this.state.prjchief_sign === true ? (
                      <button
                        className="btn btn-info float-end"
                        type="submit"
                        encType="multipart/form-data"
                        onClick={this.handleUpdate}
                      >
                        책임기술자의견 저장
                      </button>
                    ) : (
                      <button
                        className="btn btn-info float-end"
                        type="submit"
                        encType="multipart/form-data"
                        onClick={this.handleUpdateSign}
                      >
                        책임기술자의견 수정
                      </button>
                    )}
                  </TabPane>
                  <TabPane tabId="4">
                    <Row>
                      <Col>
                        <div className="mb-3">
                          <label
                            className="form-label"
                            htmlFor="보수·보강 개요"
                          >
                            보수·보강 개요
                          </label>
                          <textarea
                            className="form-control"
                            rows="4"
                            value={this.state.summary}
                            name="summary"
                            id="summary"
                            onChange={handleInput}
                            placeholder=" 보수·보강 개요에 대해 작성해주세요."
                            readOnly={
                              this.state.remuneration_null === true
                                ? false
                                : this.state.remuneration_sign === true
                                ? false
                                : true
                            }
                          ></textarea>
                        </div>
                        <div className="mb-3">
                          <label
                            className="form-label"
                            htmlFor="보수·보강 계획"
                          >
                            보수·보강 계획
                          </label>
                          <textarea
                            className="form-control"
                            rows="4"
                            value={this.state.plan}
                            name="plan"
                            id="plan"
                            onChange={handleInput}
                            placeholder="보수·보강 계획에 대해 작성해주세요."
                            readOnly={
                              this.state.remuneration_null === true
                                ? false
                                : this.state.remuneration_sign === true
                                ? false
                                : true
                            }
                          ></textarea>
                        </div>
                        <div className="mb-3">
                          <label
                            className="form-label"
                            htmlFor="보수·보강 선정기준"
                          >
                            보수·보강 선정기준
                          </label>
                          <textarea
                            className="form-control"
                            rows="4"
                            value={this.state.criteria}
                            name="criteria"
                            id="criteria"
                            onChange={handleInput}
                            placeholder="보수·보강 선정기준에 대해 작성해주세요."
                            readOnly={
                              this.state.remuneration_null === true
                                ? false
                                : this.state.remuneration_sign === true
                                ? false
                                : true
                            }
                          ></textarea>
                        </div>
                        <div className="mb-3">
                          <label
                            className="form-label"
                            htmlFor="보수·보강 공법"
                          >
                            보수·보강 공법
                          </label>
                          <textarea
                            className="form-control"
                            rows="4"
                            value={this.state.method}
                            name="method"
                            id="method"
                            onChange={handleInput}
                            placeholder="보수·보강 공법에 대해 작성해주세요."
                            readOnly={
                              this.state.remuneration_null === true
                                ? false
                                : this.state.remuneration_sign === true
                                ? false
                                : true
                            }
                          ></textarea>
                        </div>
                      </Col>
                    </Row>
                    {this.state.remuneration_null === true ? (
                      <button
                        className="btn btn-info float-end"
                        type="submit"
                        encType="multipart/form-data"
                        onClick={this.handleCreate}
                      >
                        보수보강 결과 저장
                      </button>
                    ) : this.state.remuneration_sign === true ? (
                      <button
                        className="btn btn-info float-end"
                        type="submit"
                        encType="multipart/form-data"
                        onClick={this.handleUpdate}
                      >
                        보수보강 수정 저장
                      </button>
                    ) : (
                      <button
                        className="btn btn-info float-end"
                        type="submit"
                        encType="multipart/form-data"
                        onClick={this.handleUpdateSign}
                      >
                        보수보강 수정
                      </button>
                    )}
                  </TabPane>
                </TabContent>
              </div>
            </div>
          </div>
        ) : null}
      </>
    );
  }
}

export default Evaluation;
