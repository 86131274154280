import React, { useEffect, useState, useRef } from "react";
import { CardText, CardBody } from "reactstrap";
import { useResultContext } from "../../Contexts/LayoutContext.jsx";
import styles from "../../assets/css/information.module.css";
import {
  selectColor,
  drawArc,
  drawDiamond,
  drawHexagon,
  drawRectangle,
  drawStar,
  drawTriangle,
} from "../../utils/canvas";

const URL = process.env.REACT_APP_BACKEND_HOST;

// 현재는 조사망도 수정 불가
let edit = 0;

function ResearchCard(props) {
  const { detailFacilityId } = useResultContext();
  const [floor, setFloor] = useState("");
  const [unFloor, setUnFloor] = useState("");
  const [url, setUrl] = useState("");
  const [resultUrl, setResultUrl] = useState("");
  const [coordinateX, setCoordinateX] = useState("");
  const canvasRef = useRef(null);
  const contextRef = useRef(null);
  const [imgX, setImgX] = useState("");
  const [imgY, setImgY] = useState("");
  const [points, setPoints] = useState([]);
  useEffect(() => {
    setFloor(props.floor);
    setUrl(props.url);
    let url_path = url.split("/");
    let result_path =
      url_path[url_path.length - 3] +
      "/" +
      url_path[url_path.length - 2] +
      "/" +
      url_path[url_path.length - 1];
    setResultUrl(result_path);
    if (floor < 0) {
      setUnFloor(Math.abs(floor));
    }
  }, [floor]);

  useEffect(() => {
    const coordinateURL = URL + "/project/list/planCoordinate";
    const token = "bearer " + sessionStorage.getItem("token");
    fetch(
      coordinateURL +
        "?facilityid=" +
        detailFacilityId +
        "&floor=" +
        props.floor +
        "&type=" +
        props.activePill,

      {
        method: "GET",
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: token,
        },
      }
    )
      .then((res) => res.json())
      .then((res) => {
        for (const i in res.results) {
          let point = [];
          point.push(res.results[i]["type"]);
          point.push(res.results[i]["x"]);
          point.push(res.results[i]["y"]);
          point.push(res.results[i]["index"]);
          // point.push(Number(res.results[i]["scale"]));
          setPoints((points) => [...points, point]);
        }
      });
  }, [props.floor, props.activePill, detailFacilityId]);

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");
    context.scale(1, 1);
    contextRef.current = context;
    // contextRef.current.beginPath();
    const centerPositions = [];
    for (let i = 0; i < points.length; i++) {
      if (points[i][0] === Number(props.activePill)) {
        drawPoint(Number(props.activePill), i, centerPositions);
      }
    }
  }, [points]);

  const drawPoint = (type, i, centerPositions) => {
    // 앱 좌표와 변화 비율 및 조사인덱스
    const ratio_x = 740 / imgX;
    const ratio_y = 600 / imgY;
    const x = points[i][1] * ratio_x;
    const y = (points[i][2]) * ratio_y;
    const index = points[i][3];

    // 도형 그리기 전에 겹침을 검사하고 조정
    const adjustedPosition = adjustPosition(x, y, centerPositions);
    // 중심 위치 저장
    centerPositions.push({ x: adjustedPosition.x, y: adjustedPosition.y });

    // 도형별 크기 정하기
    const arc_size = 8;
    const triangle_size = 10;
    const hexagon_size = 5;
    const rectangle_size = 7;
    const dia_size = 7;
    const star_size = 6;
    // 색상 설정 (현재 기본 빨강색)
    const color = selectColor(type);
    let ctx = contextRef.current;
    ctx.beginPath();
    ctx.fillStyle = color;
    // ctx.strokeStyle = color; 
    // 조사 타입별 모양 설정
    if (type === 1 || type > 6) {
      // ctx = drawArc(ctx, x, y, arc_size);
      ctx = drawArc(ctx, adjustedPosition.x, adjustedPosition.y, arc_size);
    } else if (type === 2) {
      // ctx = drawTriangle(ctx, x, y, triangle_size);
      ctx = drawTriangle(ctx, adjustedPosition.x, adjustedPosition.y, triangle_size);
    } else if (type === 3) {
      // ctx = drawHexagon(ctx, x, y, hexagon_size);
      ctx = drawHexagon(ctx, adjustedPosition.x, adjustedPosition.y, hexagon_size);
    } else if (type === 4) {
      // ctx = drawRectangle(ctx, x, y, rectangle_size);
      ctx = drawRectangle(ctx, adjustedPosition.x, adjustedPosition.y, rectangle_size);
    } else if (type === 5) {
      // ctx = drawDiamond(ctx, x, y, dia_size);
      ctx = drawDiamond(ctx, adjustedPosition.x, adjustedPosition.y, dia_size);
    } else if (type === 6) {
      // ctx = drawStar(ctx, x, y, 5, star_size);
      ctx = drawStar(ctx, adjustedPosition.x, adjustedPosition.y, 5, star_size);
    }
    // 도형 채우기 및 그리기 종료
    ctx.fill();
    // ctx.stroke();
    ctx.closePath();
    ctx.font = "bold 12px apple";
    ctx.fillStyle = "black";
    ctx.textAlign = "center";       // 가로 중앙 정렬
    ctx.textBaseline = "middle";    // 세로 중앙 정렬
    ctx.fillText(index, adjustedPosition.x, adjustedPosition.y + 1);
  };

   // 켄버스 도형의 중심 위치를 조정하여 겹치지 않게 만드는 함수
   function adjustPosition(x, y, centers) {
    const radius = 7;
    const padding = 0; // 원 사이의 최소 간격
    
    let adjustedX = x;
    let adjustedY = y;

    for (const center of centers) {
        const distance = Math.sqrt((center.x - adjustedX) ** 2 + (center.y - adjustedY) ** 2);
        if (distance < radius + radius) {
            // 겹침을 감지하면 x축만 위치를 조정
            if(center.x - adjustedX < 0){
              adjustedX += (radius + radius + padding + (center.x - adjustedX))
            } else {
              adjustedX -= (radius + radius + padding - (center.x - adjustedX))
            }
        }
    }

    return { x: adjustedX, y: adjustedY };
}

  // 추후 웹에서 조사데이터 및 좌표 추가할 경우 사용
  const startDrawing = ({ nativeEvent }) => {
    const { offsetX, offsetY } = nativeEvent;
    setCoordinateX(offsetX);
    contextRef.current.restore();
    contextRef.current.beginPath();
    const color = selectColor(props.activePill);
    contextRef.current.fillStyle = color;
    contextRef.current.arc(offsetX, offsetY, 5, 0, Math.PI * 2, true);
    contextRef.current.fill();
    contextRef.current.closePath();
  };

  const deleteDrawing = () => {
    if (coordinateX) {
      contextRef.current.clearRect(0, 0, 740, 600);
    }
  };

  const img = new Image();
  img.src = URL + "/" + resultUrl;

  img.onload = () => {
    setImgX(img.width);
    setImgY(img.height);
  };
  // 추후 저장하기
  const savePoint = () => {};
  return (
    <>
      <div className={`${styles.canvas_container}`}>
        {edit === 1 ? (
          <>
            <canvas
              // 클릭으로 조사망도 그리기
              onMouseDown={deleteDrawing}
              onMouseUp={startDrawing}
              ref={canvasRef}
              width="740"
              height="523"
              className={`${styles.canvas}`}
              style={{
                backgroundImage: "url(" + URL + "/" + resultUrl + ")",
                cursor: "crosshair",
                backgroundRepeat: "no-repeat",
                margin: "auto",
                backgroundSize: "740px 600px",
                width: "-webkit-fill-available",
              }}
              />
          </>
        ) : (
          <>
            <canvas
              ref={canvasRef}
              width="740"
              height="523"
              className={`${styles.canvas}`}
              style={{
                backgroundImage: "url(" + URL + "/" + resultUrl + ")",
                cursor: "crosshair",
                backgroundRepeat: "no-repeat",
                margin: "auto",
                backgroundSize: "740px 600px",
                width: "-webkit-fill-available",
              }}
            />
          </>
        )}
      </div>
      <CardBody>
        <CardText className="text-center">
          {floor < 0
           ? "지하 " + unFloor + " 층 도면"
           : (floor === 999 && "정면도") || 
              (floor === 998 && "우측면도") || 
              (floor === 997 && "배면도") || 
              (floor === 996 && "좌측면도") ||
              ("지상 " + floor + " 층 도면")}
        </CardText>
      </CardBody>
    </>
  );
}
export default ResearchCard;
