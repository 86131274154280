import { React, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

const URL = process.env.REACT_APP_BACKEND_HOST;

const API = URL + "/project/prj/";

let userName = sessionStorage.getItem("userName");

function DropdownProfile() {
  const [auth, setAuth] = useState("");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  useEffect(() => {
    if (sessionStorage.getItem("token") !== null) {
      setAuth(true);
    }
  }, []);

  const toggle = (prevState) => {
    setDropdownOpen((dropdownOpen) => !dropdownOpen);
  };

  const handleLogout = () => {
    fetch(API, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Token ${sessionStorage.getItem("token")}`,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        sessionStorage.clear();
        window.location.replace("/home");
      });
  };

  return (
    <>
      {auth ? (
        <>
          <Dropdown
            isOpen={dropdownOpen}
            toggle={toggle}
            className="navbar-item navbar-user dropdown"
            tag="div"
          >
            <DropdownToggle
              tag="a"
              className="navbar-link dropdown-toggle d-flex align-items-center"
            >
              <span>
                <span className="d-none d-md-inline">{userName}</span>님
                반갑습니다.
                <b className="caret"></b>
              </span>
            </DropdownToggle>
            <DropdownMenu
              className="dropdown-menu dropdown-menu-end"
              end="true"
              tag="div"
            >
              <DropdownItem>
                <Link
                  to="/supervision"
                  style={{ textDecoration: "none" }}
                  className=""
                  exact="true"
                >
                  현장 조사자 관리
                </Link>
              </DropdownItem>
              <DropdownItem>
                <Link
                  to="/myPage/myInfo"
                  style={{ textDecoration: "none" }}
                  className=""
                  exact="true"
                >
                  회원 정보 변경
                </Link>
              </DropdownItem>
              <div className="dropdown-divider"></div>
              <DropdownItem key="logout" onClick={handleLogout}>
                로그아웃
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </>
      ) : (
        <div key="signin" className="me-3 ms-3">
          <Link
            to="/login"
            style={{ textDecoration: "none" }}
            className="btn btn-outline-info"
            exact="true"
          >
            로그인
          </Link>
        </div>
      )}
      {auth ? (
        <></>
      ) : (
        <div key="signup" className="me-3 ms-3">
          <Link
            to="/user/register"
            style={{ textDecoration: "none" }}
            className="btn btn-info"
          >
            회원가입
          </Link>
        </div>
      )}
    </>
  );
}

export default DropdownProfile;
