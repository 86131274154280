import React from "react";

const PanelStat = React.createContext();

class Panel extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      expand: false,
      collapse: false,
      reload: false,
      remove: false
    };
  }
  render() {
    return (
      <PanelStat.Provider value={this.state}>
        {!this.state.remove && (
          <div
            className={
              "panel panel-" +
              (this.props.theme ? this.props.theme : "inverse") +
              " " +
              (this.state.expand ? "panel-expand " : " ") +
              (this.state.reload ? "panel-loading " : " ") +
              (this.props.className ? this.props.className : "")
            }
          >
            {this.props.children}
          </div>
        )}
      </PanelStat.Provider>
    );
  }
}

class PanelBody extends React.Component {
  render() {
    return (
      <PanelStat.Consumer>
        {({ collapse, reload }) => (
          <div
            className={
              "panel-body " +
              (collapse ? "d-none " : " ") +
              this.props.className
            }
          >
            {this.props.children}

            {reload && (
              <div className="panel-loader">
                <span className="spinner spinner-sm"></span>
              </div>
            )}
          </div>
        )}
      </PanelStat.Consumer>
    );
  }
}

export { Panel, PanelBody };
