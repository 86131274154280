import React, { useCallback, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";
import ko from "date-fns/locale/ko";
import getYear from "date-fns/getYear";
import moment from "moment";
import getMonth from "date-fns/getMonth";
// import { URL } from "../../config/app-settings";
import styles from "../../assets/css/fms.module.css";
import { result_prj } from "../facilitylayout/layout";
import "react-datepicker/dist/react-datepicker.css";
import { useResultContext } from "../../Contexts/LayoutContext.jsx";
import DaumPostCode from "react-daum-postcode";

registerLocale("ko", ko);

const URL = process.env.REACT_APP_BACKEND_HOST;

let API2 = URL + "/project/prj_list";
const API = URL + "/project/prj/"; //수정 필요
let project = {
  facilityaddress: "",
  facilityarea1: "",
  facilityarea2: "",
  facilitydate: "",
  facilitydivide: "",
  facilityetc: "",
  facilityfloordown: "",
  facilityfloorup: "",
  facilityid: "",
  facilityname: "",
  facilityowner: "",
  facilitystructure: "",
  facilityuse: "",
  userid: "",
};

function ManualWrite() {
  const { result, detailFacilityId } = useResultContext();
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);
  const [projectDiv, setProjectDiv] = useState({
    facilitydivide: "",
  });
  const [startDate, setStartDate] = useState(new Date());
  const [floorDown, setFloordown] = useState({
    facilityfloordown: "",
  });
  const [floorUp, setFloorUp] = useState({
    facilityfloorup: "",
  });
  const [area1, setArea1] = useState({
    facilityarea1: "",
  });
  const [area2, setArea2] = useState({
    facilityarea2: "",
  });
  const years = range(1900, getYear(new Date()), -1);
  const months = [
    "1월",
    "2월",
    "3월",
    "4월",
    "5월",
    "6월",
    "7월",
    "8월",
    "9월",
    "10월",
    "11월",
    "12월",
  ];
  const [isDaumPost, setIsDaumPost] = useState(false);
  const [buildingCode, setBuildingCode] = useState("");
  const [bcode, setBcode] = useState("");
  const [input, setInput] = useState({
    facilityname: "",
    facilityaddress: "",
    facilitystructure: 0,
    facilityuse: 0,
    facilityowner: "",
    facilityetc: "",
    facilitydate: "",
    facilityelevation: "4",
    zoneCode: "",
    isRegister: false,
    register: [],
    errors: {
      facilityname: "",
      facilityaddress: "",
      facilitystructure: 0,
      facilityuse: 0,
      facilityowner: "",
      facilityetc: "",
      facilitydate: "",
    },
  });

  const {
    facilityname,
    facilityaddress,
    facilitystructure,
    facilityuse,
    facilityowner,
    facilityetc,
  } = input;

  function range(size, startAt = 0) {
    return [...Array(size).keys()].map((i) => startAt - i);
  }

  const handleChange = (name, value) => {
    setInput({
      ...input,
      [name]: value,
    });
  };

  const handleNumArea1Change = (e) => {
    const newName = e.target.name;
    const newVal = e.target.value;

    let str = newVal.split(".");
    str[0] = str[0]
      .replace(/[^-\.0-9]/g, "")
      .replace(/(.)(?=(\d{3})+$)/g, "$1,");
    let fmStr = str.join(".");
    let comVal = fmStr.replace(
      /[`~!@#$%^&*()_|+\-=?;:'"<>\{\}\[\]\\\|ㄱ-ㅎ|ㅏ-ㅣ-ㅢ|가-힣|a-z|A-Z]/g,
      ""
    );

    document.getElementById("facilityarea1").value = comVal;

    setArea1({
      [newName]: comVal,
    });
  };

  const handleNumArea2Change = (e) => {
    const newName = e.target.name;
    const newVal = e.target.value;

    let str = newVal.split(".");
    str[0] = str[0]
      .replace(/[^-\.0-9]/g, "")
      .replace(/(.)(?=(\d{3})+$)/g, "$1,");
    let fmStr = str.join(".");
    let comVal = fmStr.replace(
      /[`~!@#$%^&*()_|+\-=?;:'"<>\{\}\[\]\\\|ㄱ-ㅎ|ㅏ-ㅣ-ㅢ|가-힣|a-z|A-Z]/g,
      ""
    );
    document.getElementById("facilityarea2").value = comVal;

    setArea2({
      [newName]: comVal,
    });
  };

  const handleOnChange = (e) => {
    const newName = e.target.name;
    const curVal = e.target.value;

    setProjectDiv({
      [newName]: curVal,
    });
  };

  const facilityfloordown = (e) => {
    const curVal = e.target.value;
    const newName = e.target.name;
    const newVal = curVal.replace(/[^0-9]/g, "");
    document.getElementById("underVal").value = newVal;
    setFloordown({
      [newName]: newVal,
    });
  };

  const facilityfloorup = (e) => {
    const curVal = e.target.value;
    const newName = e.target.name;
    const newVal = curVal.replace(/[^0-9]/g, "");
    document.getElementById("groundVal").value = newVal;
    setFloorUp({
      [newName]: newVal,
    });
  };

  const handleAddress = (data) => {
    let AllAddress = data.address;
    let extraAddress = "";
    let zoneCodes = data.zonecode;
    let bcode = data.bcode;
    let buildingCode = data.buildingCode;

    if (data.addressType === "R") {
      if (data.bname !== "") {
        extraAddress += data.bname;
      }
      if (data.buildingName !== "") {
        extraAddress +=
          extraAddress !== "" ? `, ${data.buildingName}` : data.buildingName;
      }
      AllAddress += extraAddress !== "" ? ` (${extraAddress})` : "";
    }
    setInput({
      ...input,
      facilityaddress: AllAddress,
      zoneCode: zoneCodes,
    });
    setBcode({ bcode });
    setBuildingCode({ buildingCode });
  };

  useEffect(() => {
    // 여기는 시설물 관리 수정시 정보 불러오는 곳
    const manualFrame = document.getElementById("manualFrame");

    if (result === 1) {
      manualFrame.classList.remove("d-none");
      manualFrame.classList.add("d-block");
    } else {
      manualFrame.classList.remove("d-none");
      manualFrame.classList.add("d-block");
    }

    const token = "bearer " + sessionStorage.getItem("token");
    fetch(API2, {
      method: "GET",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: token,
        Accept: "application/json;charset=UTF-8",
      },
    })
      .then((res) => {
        if (res.status / 100 === 2) {
          return res.json();
        } else {
          alert(
            "알수없는 오류가 발생하였습니다. 새로고침 후 다시 시도해주세요."
          );
        }
      })

      .then((res) => {
        let prj_list = [];

        for (let i = 0; i < res["results"].length; i++) {
          project = res["results"][i];
          prj_list.push(project);
        }

        for (let i = 0; i < prj_list.length; i++) {
          if (prj_list[i]["facilityid"] === result_prj) {
            setInput({
              facilityname: prj_list[i]["facilityname"],
              facilityaddress: prj_list[i]["facilityaddress"],
              facilitydate: prj_list[i]["facilitydate"],
              facilityetc: prj_list[i]["facilityetc"],
              facilityowner: prj_list[i]["facilityowner"],
              facilitystructure: prj_list[i]["facilitystructure"],
              facilityuse: prj_list[i]["facilityuse"],
              userid: prj_list[i]["userid"],
              facilityid: prj_list[i]["facilityid"],
            });
            setArea1({ facilityarea1: prj_list[i]["facilityarea1"] });
            setArea2({ facilityarea2: prj_list[i]["facilityarea2"] });
            setFloordown({
              facilityfloordown: prj_list[i]["facilityfloordown"],
            });
            setFloorUp({ facilityfloorup: prj_list[i]["facilityfloorup"] });
            setProjectDiv({ facilitydivide: prj_list[i]["facilitydivide"] });
          }
        }
      });
  }, [detailFacilityId]);

  const goNext1 = document.getElementById("goNext1");
  const goNext2 = document.getElementById("goNext2");
  const fmsForm1 = document.getElementById("newFmsFirst");
  const fmsForm2 = document.getElementById("manualSecond");

  const hadleSubmit = (e) => {
    e.preventDefault();
    const result_date = moment(startDate).format("yyyy-MM-DD");
    const result_state = Object.assign(
      input,
      floorUp,
      floorDown,
      projectDiv,
      area1,
      area2
    );

    input.facilitydate = result_date;

    if (result_state.facilityname === "") {
      alert("시설물명칭은 필수항목입니다.");
    } else if (result_state.facilitydate === "") {
      alert("사용승인일은 필수항목입니다.");
    } else if (result_state.facilityaddress === "") {
      alert("시설물 주소는 필수항목입니다.");
    } else if (result_state.facilitystructure === 0) {
      alert("주구조 필수항목입니다.");
    } else if (result_state.facilityuse === 0) {
      alert("주용도는 필수항목입니다.");
    } else if (result_state.facilityarea1 === "") {
      alert("연면적은 필수항목입니다.");
    } else if (result_state.facilityarea2 === "") {
      alert("건축면적은 필수항목입니다.");
    } else if (result_state.facilityowner === "") {
      alert("대표자명은 필수항목입니다.");
    } else if (floorDown.facilityfloordown === "") {
      alert("지하 층수 입력은 필수항목입니다. 지하가 없다면 0 을 입력하세요.");
    } else if (floorUp.facilityfloorup === "") {
      alert("지상 층수 입력은 필수항목입니다.");
    } else if (result_state.facilitydivide === "") {
      alert("프로젝트 구분은 필수항목입니다.");
    } else if (result_state.facilityetc === "") {
      input.facilityetc = "-";
    } else {
      const token = "bearer " + sessionStorage.getItem("token");

      fetch(API, {
        method: "POST",
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: token,
        },
        body: JSON.stringify(result_state),
      }).then((res) => {
        if (res.status === 201) {
          alert(
            "시설물 저장에 성공하였습니다.\n사진과 도면은 조회 -> 수정 단계에서 가능합니다."
          );
          window.location.replace("/fms");
        } else {
          alert(
            "알 수 없는 오류가 발생하였습니다. 새로고침 후 다시 시도해주세요."
          );
        }
        return res.json();
      });
      forceUpdate();
      goNext1.classList.add("completed");
      fmsForm1.classList.add("d-none");
    }
  };

  const hadleUpdateSubmit = (e) => {
    e.preventDefault();

    const year = startDate.getFullYear();
    const month = startDate.getMonth() + 1;
    const day = startDate.getDate();
    const result_date = year + "-" + month + "-" + day;
    const result_state = Object.assign(
      input,
      floorUp,
      floorDown,
      projectDiv,
      area1,
      area2
    );
    input.facilitydate = result_date;

    if (result_state.facilityname === "") {
      alert("시설물명칭은 필수항목입니다.");
    } else if (result_state.facilitydate === "") {
      alert("사용승인일은 필수항목입니다.");
    } else if (result_state.facilityaddress === "") {
      alert("시설물 주소는 필수항목입니다.");
    } else if (result_state.facilitystructure === 0) {
      alert("주구조 필수항목입니다.");
    } else if (result_state.facilityuse === 0) {
      alert("주용도는 필수항목입니다.");
    } else if (result_state.facilityarea1 === "") {
      alert("연면적은 필수항목입니다.");
    } else if (result_state.facilityarea2 === "") {
      alert("건축면적은 필수항목입니다.");
    } else if (result_state.facilityowner === "") {
      alert("대표자명은 필수항목입니다.");
    } else if (floorDown.facilityfloordown == null) {
      alert("지하 층수 입력은 필수항목입니다.");
    } else if (floorUp.facilityfloorup == null) {
      alert("지상 층수 입력은 필수항목입니다.");
    } else if (result_state.facilitydivide === "") {
      alert("프로젝트 구분은 필수항목입니다.");
    } else {
      const token = "bearer " + sessionStorage.getItem("token");
      fetch(API, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: token,
        },
        body: JSON.stringify(result_state),
      }).then((res) => {
        console.log(res, res.status);
        if (res.status / 100 === 2) {
          return res.json();
        } else {
          alert(
            "알수없는 오류가 발생하였습니다. 새로고침 후 다시 시도해주세요."
          );
        }
      });
      forceUpdate();
      goNext1.classList.add("completed");
      fmsForm1.classList.add("d-none");
      fmsForm2.classList.remove("d-none");
      goNext2.classList.add("active");
    }
  };

  const modalStyle = {
    position: "absolute",
    width: "600px",
    height: "400px",
    left: "120px",
    zIndex: "100",
    border: "1px solid #000000",
    overflow: "hidden",
  };

  return (
    <div className={`col-md-8 m-auto manualFrame`} id="manualFrame">
      <div className={`nav-wizards-container ${styles.nav_wizards_container}`}>
        <nav className="nav nav-wizards-2 mb-3">
          <div className="nav-item w-200px">
            <div className="nav-link active" id="goNext1">
              <div className="nav-title fw-lighter text-center">
                1. 시설물 정보 입력
              </div>
            </div>
          </div>
          {/* <div className="nav-item w-200px">
            <div className="nav-link" id="goNext2">
              <div className="nav-title fw-lighter text-center">
                2. 도면첨부
              </div>
            </div>
          </div> */}
        </nav>
      </div>
      <div id="addBuilding">
        <div id="manualFirst">
          <form id="newFmsFirst" className={styles.newFmsFirst}>
            <div className={`${styles.tab_group}`}>
              <ul className={`${styles.select_task_division}`}>
                <div className="m-0">
                  과업선택<span className="text-red">*</span>
                </div>

                <li className={styles.form_check}>
                  <input
                    className={`form-check-input ${styles.form_check_input}`}
                    type="radio"
                    onChange={handleOnChange}
                    id="안전점검"
                    defaultValue="안전점검"
                    name="facilitydivide"
                    checked={projectDiv.facilitydivide === "안전점검"}
                  />
                  <label
                    className="form-check-label col-form-label p-0"
                    htmlFor="안전점검"
                  >
                    안전점검
                  </label>
                </li>
                <li className={styles.form_check}>
                  <input
                    className={`form-check-input ${styles.form_check_input}`}
                    type="radio"
                    onChange={handleOnChange}
                    id="안전진단"
                    defaultValue="안전진단"
                    name="facilitydivide"
                    checked={projectDiv.facilitydivide === "안전진단"}
                  />
                  <label
                    className="form-check-label col-form-label p-0"
                    htmlFor="안전진단"
                  >
                    안전진단
                  </label>
                </li>
                <li className={styles.form_check}>
                  <input
                    className={`form-check-input ${styles.form_check_input}`}
                    type="radio"
                    onChange={handleOnChange}
                    id="정밀안전진단"
                    defaultValue="정밀안전진단"
                    name="facilitydivide"
                    // checked={facilitydivide === "정밀안전진단"}
                    checked={projectDiv.facilitydivide === "정밀안전진단"}
                  />
                  <label
                    className="form-check-label col-form-label p-0"
                    htmlFor="정밀안전진단"
                  >
                    정밀안전진단
                  </label>
                </li>
                <li className={styles.form_check}>
                  <input
                    className={`form-check-input ${styles.form_check_input}`}
                    type="radio"
                    onChange={handleOnChange}
                    id="하자진단"
                    defaultValue="하자진단"
                    name="facilitydivide"
                    checked={projectDiv.facilitydivide === "하자진단"}
                  />
                  <label
                    className="form-check-label col-form-label p-0"
                    htmlFor="하자진단"
                  >
                    하자진단
                  </label>
                </li>
                <li className={styles.form_check}>
                  <input
                    className={`form-check-input ${styles.form_check_input}`}
                    type="radio"
                    onChange={handleOnChange}
                    id="기타"
                    defaultValue="기타"
                    name="facilitydivide"
                    checked={projectDiv.facilitydivide === "기타"}
                  />
                  <label
                    className="form-check-label col-form-label p-0"
                    htmlFor="기타"
                  >
                    기타
                  </label>
                </li>
              </ul>
            </div>

            <div className="row">
              <div className="col-md-6">
                <div className="row mb-3">
                  <label className={`form-label col-form-label w-100px `}>
                    시설물 명칭<span className="text-red">*</span>
                  </label>
                  <div className="col-md-9">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="예) 삼성 아이티밸리"
                      name="facilityname"
                      value={facilityname}
                      onChange={(e) =>
                        handleChange("facilityname", e.target.value)
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="row mb-3">
                  <label className={`form-label col-form-label w-100px `}>
                    대표자명<span className="text-red">*</span>
                  </label>
                  <div className="col-md-9">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="예) 홍길동"
                      value={facilityowner}
                      name="facilityowner"
                      onChange={(e) =>
                        handleChange("facilityowner", e.target.value)
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row mb-3">
              <label className={`form-label col-form-label w-100px `}>
                시설물 주소<span className="text-red">*</span>
              </label>

              <div className="modalCell col">
                <div className="cellFirst mb-1 d-flex">
                  <div
                    className="zipCode form-control fs-13px w-100pxpx me-3"
                    style={{ height: "34px" }}
                    readOnly
                  >
                    {input.zoneCode}
                  </div>
                  <button
                    type="button"
                    className="btn btn-default w-150px"
                    onClick={() => setIsDaumPost(!isDaumPost)}
                  >
                    <span>우편번호 찾기</span>
                  </button>
                </div>
                {isDaumPost ? (
                  <div>
                    <DaumPostCode
                      onComplete={handleAddress}
                      autoClose
                      style={modalStyle}
                      isDaumPost={isDaumPost}
                    />
                  </div>
                ) : null}
                <div className="address mb-1">
                  <div
                    className="form-control fs-13p"
                    style={{ height: "34px" }}
                    readOnly
                  >
                    {facilityaddress}
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-7">
                <div className="row mb-3">
                  <label className={`form-label col-form-label w-100px `}>
                    사용승인일<span className="text-red">*</span>
                  </label>
                  <div className="col-md-9">
                    <DatePicker
                      renderCustomHeader={({
                        date,
                        changeYear,
                        changeMonth,
                        decreaseMonth,
                        increaseMonth,
                        prevMonthButtonDisabled,
                        nextMonthButtonDisabled,
                      }) => (
                        <div
                          style={{
                            margin: 10,
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <button
                            onClick={decreaseMonth}
                            disabled={prevMonthButtonDisabled}
                          >
                            {"<"}
                          </button>
                          <select
                            value={getYear(date)}
                            onChange={({ target: { value } }) =>
                              changeYear(value)
                            }
                          >
                            {years.map((option) => (
                              <option key={option} value={option}>
                                {option}
                              </option>
                            ))}
                          </select>

                          <select
                            value={months[getMonth(date)]}
                            onChange={({ target: { value } }) =>
                              changeMonth(months.indexOf(value))
                            }
                          >
                            {months.map((option) => (
                              <option key={option} value={option}>
                                {option}
                              </option>
                            ))}
                          </select>

                          <button
                            onClick={increaseMonth}
                            disabled={nextMonthButtonDisabled}
                          >
                            {">"}
                          </button>
                        </div>
                      )}
                      selected={startDate}
                      className="form-control"
                      onChange={(date) => setStartDate(date)}
                      dateFormat="yyyy-MM-dd"
                      locale="ko"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="row mb-3">
                  <label className={`form-label col-form-label w-100px `}>
                    건축층수<span className="text-red">*</span>
                  </label>
                  <div className="col row" style={{ display: "flex" }}>
                    <label className={`form-label col-form-label w-60px`}>
                      지하
                    </label>
                    <input
                      type="text"
                      className="form-control col text-end"
                      placeholder="숫자만 입력하세요"
                      id="underVal"
                      value={floorDown.facilityfloordown}
                      name="facilityfloordown"
                      onChange={facilityfloordown}
                    />
                    <label className={`form-label col-form-label w-30px`}>
                      층
                    </label>
                  </div>
                </div>
                <div className="row mb-3">
                  <label className={`form-label col-form-label w-100px `}>
                    연면적<span className="text-red">*</span>
                  </label>
                  <div className="col" style={{ display: "flex" }}>
                    <input
                      type="text"
                      pattern="[0-9]*"
                      className="form-control col text-end"
                      placeholder="숫자만 입력하세요"
                      value={area1.facilityarea1}
                      name="facilityarea1"
                      onChange={handleNumArea1Change}
                      id="facilityarea1"
                    />
                    <label
                      className={`form-label col-form-label w-40px text-end`}
                    >
                      m<sup>2</sup>
                    </label>
                  </div>
                </div>
                <div className="row mb-3">
                  <label className={`form-label col-form-label w-100px `}>
                    주용도<span className="text-red">*</span>
                  </label>
                  <div className="col-md-9">
                    <select
                      className="form-select"
                      onChange={(e) =>
                        handleChange("facilityuse", e.target.value)
                      }
                      value={facilityuse}
                      name="facilityuse"
                    >
                      <option value="0" name="" disabled>
                        선택하세요
                      </option>
                      <option name="단독주택" value="단독주택">
                        단독주택
                      </option>
                      <option name="공동주택" value="공동주택">
                        공동주택
                      </option>
                      <option
                        name="제1종근린생활시설"
                        value="제1종근린생활시설"
                      >
                        제1종 근린생활시설
                      </option>
                      <option
                        name="제2종근린생활시설"
                        value="제2종근린생활시설"
                      >
                        제2종 근린생활시설
                      </option>
                      <option name="문화및집회시설" value="문화및집회시설">
                        문화 및 집회시설
                      </option>
                      <option name="종교시설" value="종교시설">
                        종교시설
                      </option>
                      <option name="판매시설" value="판매시설">
                        판매시설
                      </option>
                      <option name="운수시설" value="운수시설">
                        운수시설
                      </option>
                      <option name="의료시설" value="의료시설">
                        의료시설
                      </option>
                      <option name="교육연구시설" value="교육연구시설">
                        교육연구시설
                      </option>
                      <option name="노유자시설" value="노유자시설">
                        노유자 시설
                      </option>
                      <option name="수련시설" value="수련시설">
                        수련시설
                      </option>
                      <option name="운동시설" value="운동시설">
                        운동시설
                      </option>
                      <option name="업무시설" value="업무시설">
                        업무시설
                      </option>
                      <option name="숙박시설" value="숙박시설">
                        숙박시설
                      </option>
                      <option name="위락시설" value="위락시설">
                        위락시설
                      </option>
                      <option name="공장" value="공장">
                        공장
                      </option>
                      <option name="창고시설" value="창고시설">
                        창고시설
                      </option>
                      <option
                        name="위험물저장및처리시"
                        value="위험물저장및처리시"
                      >
                        위험물 저장 및 처리시
                      </option>
                      <option name="자동차관리시설" value="자동차관리시설">
                        자동차 관리시설
                      </option>
                      <option
                        name="동물및식물관련시설"
                        value="동물및식물관련시설"
                      >
                        동물 및 식물관련시설
                      </option>
                      <option
                        name="분료및쓰레기처리시설"
                        value="분료및쓰레기처리시설"
                      >
                        분료 및 쓰레기처리시설
                      </option>
                      <option name="교정및군사시설" value="교정및군사시설">
                        교정 및 군사시설
                      </option>
                      <option name="방송통신시설" value="방송통신시설">
                        방송통신시설
                      </option>
                      <option name="발전시설" value="발전시설">
                        발전시설
                      </option>
                      <option name="묘지시설시설" value="묘지시설시설">
                        묘지시설 시설
                      </option>
                      <option name="관광휴게시설" value="관광휴게시설">
                        관광 휴게시설
                      </option>
                      <option name="장례식장" value="장례식장">
                        장례식장
                      </option>
                    </select>
                  </div>
                </div>
                {/* <div className="row mb-3">
                  <label className={`form-label col-form-label col-md-3`}>
                    시설물 위치사진
                  </label>
                  <div className="col-md-9">
                    <BuildingImg />
                  </div>
                </div> */}
              </div>
              <div className="col-md-6">
                <div className="row mb-3">
                  <div className="col-md-3">
                    <div className={styles.tilde}>~</div>
                  </div>
                  <label className={`form-label col-form-label w-60px `}>
                    지상
                  </label>
                  <input
                    type="text"
                    className="form-control col text-end"
                    placeholder="숫자만 입력하세요"
                    id="groundVal"
                    name="facilityfloorup"
                    value={floorUp.facilityfloorup}
                    onChange={facilityfloorup}
                  />
                  <label className={`form-label col-form-label w-30px `}>
                    층
                  </label>
                </div>
                <div className="row mb-3">
                  <label className={`form-label col-form-label w-100px `}>
                    건축면적<span className="text-red">*</span>
                  </label>
                  <div className="col" style={{ display: "flex" }}>
                    <input
                      type="text"
                      pattern="[0-9]*"
                      className="form-control col text-end"
                      placeholder="숫자만 입력하세요"
                      name="facilityarea2"
                      id="facilityarea2"
                      value={area2.facilityarea2}
                      onChange={handleNumArea2Change}
                    />
                    <label
                      className={`form-label col-form-label w-40px text-end `}
                    >
                      m<sup>2</sup>
                    </label>
                  </div>
                </div>
                <div className="row mb-3">
                  <label className={`form-label col-form-label w-100px `}>
                    주구조<span className="text-red">*</span>
                  </label>
                  <div className="col-md-9">
                    <select
                      className="form-select"
                      onChange={(e) =>
                        handleChange("facilitystructure", e.target.value)
                      }
                      value={facilitystructure}
                      name="facilitystructure"
                    >
                      <option value="0" name="" disabled>
                        선택하세요
                      </option>
                      <option name="철골콘크리트조" value="철골콘크리트조">
                        철골(철골철근)콘크리트조
                      </option>
                      <option name="통나무조" value="통나무조">
                        통나무조
                      </option>
                      <option name="스틸하우스조" value="스틸하우스조">
                        스틸하우스조
                      </option>
                      <option name="철근콘크리트조" value="철근콘크리트조">
                        철근콘크리트조
                      </option>
                      <option name="라멘조" value="라멘조">
                        라멘조
                      </option>
                      <option name="철골조" value="철골조">
                        철골조
                      </option>
                      <option name="석조" value="석조">
                        석조
                      </option>
                      <option
                        name="프리캐스트콘크리트조"
                        value="프리캐스트콘크리트조"
                      >
                        프리캐스트콘크리트조
                      </option>
                      <option name="목구조" value="목구조">
                        목구조
                      </option>
                      <option name="연와조" value="연와조">
                        연와조
                      </option>
                      <option name="보강콘크리트조" value="보강콘크리트조">
                        보강콘크리트조
                      </option>
                      <option name="황토조" value="황토조">
                        황토조
                      </option>
                      <option name="보강블럭조" value="보강블럭조">
                        보강블럭조
                      </option>
                      <option name="ALC조" value="ALC조">
                        ALC조
                      </option>
                      <option name="와이어패널조" value="와이어패널조">
                        와이어패널조
                      </option>
                      <option name="조립식패널조" value="조립식패널조">
                        조립식패널조
                      </option>
                      <option name="시멘트벽돌조" value="시멘트벽돌조">
                        시멘트벽돌조
                      </option>
                      <option name="목조" value="목조">
                        목조
                      </option>
                      <option name="경량철골조" value="경량철골조">
                        경량철골조
                      </option>
                      <option
                        name="석회및흙벽돌조돌담및토담조"
                        value="석회및흙벽돌조돌담및토담조"
                      >
                        석회 및 흙벽돌조, 돌담 및 토담조
                      </option>
                      <option name="철파이프조" value="철파이프조">
                        철파이프조
                      </option>
                      <option name="컨테이너건물" value="컨테이너건물">
                        컨테이너건물
                      </option>
                    </select>
                  </div>
                </div>
                {/* <div className="row mb-3">
                  <label className={`form-label col-form-label col-md-3`}>
                    시설물 전경사진
                  </label>
                  <div className="col-md-9">
                    <PanoramaImg />
                  </div>
                </div> */}
              </div>
            </div>
            <div className="row">
              <div className="mb-3 row">
                <label className={`form-label col-form-label  w-100px`}>
                  비고
                </label>
                <div className="col">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="내용을 입력하세요."
                    value={facilityetc}
                    name="facilityetc"
                    onChange={(e) =>
                      handleChange("facilityetc", e.target.value)
                    }
                  />
                </div>
              </div>
            </div>
            <button
              className="btn btn-info btn-lg float-end"
              onClick={hadleSubmit}
              id="result"
            >
              시설물 정보 저장
            </button>
            {/* <button
              className="btn btn-info btn-lg float-end"
              onClick={hadleUpdateSubmit}
              id="result"
            >
              시설물 정보 수정
            </button> */}
          </form>
        </div>
        {/* <div id="manualSecond" className="d-none">
          <div className="row">
            <div>
              <label className="form-label col-form-label w-100pxpx">
                도면첨부
              </label>
              <span className="fas fa-exclamation">
                .jpg, .jpeg 형식의 파일만 업로드 할 수 있습니다.
              </span>
            </div>

            <ul className="col">
              {underArr_result.map((item, i) => {
                return (
                  <li>
                    <UnderImg propsData={item} key={i} />
                  </li>
                );
              })}
            </ul>
            <ul className="col">
              {upArr_result.map((item, i) => {
                return (
                  <li>
                    <UpImg propsData={item} key={i} />
                  </li>
                );
              })}
            </ul>
          </div>

          <button className="btn btn-info btn-lg float-start" onClick={prev}>
            이전으로
          </button>
          <button className="btn btn-info btn-lg float-end" onClick={sendImg}>
            도면 저장
          </button>
        </div> */}
      </div>
    </div>
  );
}

export default ManualWrite;
