import React, { useEffect, useState } from "react";
import { Panel, PanelBody } from "../../components/panel/panel.jsx";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import "@ckeditor/ckeditor5-build-classic/build/translations/ko";

const URL = process.env.REACT_APP_BACKEND_HOST;
const WRITE = URL + "/boards/create/answer";
const PROCESS_PATCH = URL + "/boards/update/support";
const token = "bearer " + sessionStorage.getItem("token");

const CsAskAdmin = (props) => {
  const [input, setInput] = useState({
    title: "",
    content: "",
  });
  const [no, setNo] = useState("");

  const handleInput = (name, value) => {
    setInput({
      ...input,
      [name]: value,
    });
  };

  useEffect(() => {
    setNo(props.no);
  }, []);

  const submit = (e) => {
    const formData = new FormData();

    if (input.content !== "") {
      formData.append("title", "답변입니다.");
      formData.append("support_id", no);
      formData.append("content", input.content);

      fetch(WRITE, {
        method: "POST",
        headers: {
          Authorization: token,
        },
        body: formData,
      })
        .then((res) => {
          if (res.status / 100 === 2) {
            return res.json();
          } else {
            alert(
              "알수없는 오류가 발생하였습니다. 새로고침 후 다시 시도해주세요."
            );
          }
        })
        .then((res) => {
          const formData = new FormData();

          formData.append("process", 3);
          formData.append("id", no);

          fetch(PROCESS_PATCH, {
            method: "PATCH",
            headers: {
              Authorization: token,
            },
            body: formData,
          })
            .then((res) => {
              if (res.status / 100 === 2) {
                return res.json();
              } else {
                alert(
                  "알수없는 오류가 발생하였습니다. 새로고침 후 다시 시도해주세요."
                );
              }
            })
            .then((res) => {
              alert("답변이 성공적으로 등록되었습니다.");
              window.location.replace("/cs/ask");
            });
        });
    } else {
      alert("내용을 입력하세요.");
    }
  };
  return (
    <Panel className="col-12">
      <PanelBody>
        <div className="mb-1">
          <label
            className="form-label col-form-label text-blue-700"
            style={{ fontSize: "2rem", fontWeight: "normal" }}
          >
            문의 글 답변
          </label>
        </div>
        <div className="mt-1 row">
          <CKEditor
            className="mb-5"
            config={{
              language: "ko",
            }}
            editor={ClassicEditor}
            // data="<p>내용을 작성하세요.</p>"
            placeholder="내용을 입력하세요."
            onReady={(editor) => {
              editor.editing.view.change((writer) => {
                writer.setStyle(
                  "height",
                  "400px",
                  editor.editing.view.document.getRoot()
                );
              });
            }}
            value={input.content}
            onChange={(event, editor) => {
              const data = editor.getData();
              handleInput("content", data);
            }}
            onBlur={(event, editor) => {
              // console.log("Blur.", editor);
            }}
            onFocus={(event, editor) => {
              // console.log("Focus.", editor);
            }}
          />
        </div>
        <button
          type="button"
          onClick={submit}
          className="btn btn-primary float-end mt-5px"
        >
          등록하기
        </button>
      </PanelBody>
    </Panel>
  );
};

export { CsAskAdmin };
