import React, { useState, useCallback, useEffect } from "react";
import Prospecter from "./prospecter";
import { Link } from "react-router-dom";
import { useResultContext } from "../../Contexts/LayoutContext";
import "react-datepicker/dist/react-datepicker.css";
import Deactive from "./deactive";
import Active from "./active";

const token = "bearer " + sessionStorage.getItem("token");

const URL = process.env.REACT_APP_BACKEND_HOST;
const API = URL + "/project/prj_list";
const API_MEM = URL + "/members";
const API_VL = "/get_list";
const API_INSPEC = "/inspector";

let activeList = [];
let activeList_item = {
  project: "",
  id: "",
  pw: "",
  name: "",
  phone: "",
};

let deactiveList = [];
let deactiveList_item = {
  project: "",
  id: "",
  pw: "",
  name: "",
  phone: "",
};

function Supervision() {
  const { detailFacilityId } = useResultContext();
  const [, updateState] = useState();
  const [createCard, setCreateCard] = useState([]);
  const forceUpdate = useCallback(() => updateState({}), []);

  useEffect(() => {
    if (detailFacilityId.length !== 0) {
      fetch(API_MEM + API_VL + API_INSPEC + "?facilityid=" + detailFacilityId, {
        method: "GET",
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: token,
          Accept: "application/json;charset=UTF-8",
        },
      })
        .then((res) => {
          if (res.status / 100 === 2) {
            return res.json();
          } else {
            alert(
              "알수없는 오류가 발생하였습니다. 새로고침 후 다시 시도해주세요."
            );
          }
        })
        .then((res) => {
          deactiveList = [];
          for (let i = 0; i < res["deactivated"].length; i++) {
            let prospecter = res["deactivated"][i];
            deactiveList.unshift(prospecter);
          }

          for (let i = 0; i < deactiveList.length; i++) {
            deactiveList_item.project = deactiveList[i]["project"];
            deactiveList_item.id = deactiveList[i]["id"];
            deactiveList_item.pw = deactiveList[i]["pw"];
            deactiveList_item.name = deactiveList[i]["name"];
            deactiveList_item.phone = deactiveList[i]["phone"];
          }
          activeList = [];
          for (let i = 0; i < res["inspectors"].length; i++) {
            let prospecter = res["inspectors"][i];
            activeList.unshift(prospecter);
          }

          for (let i = 0; i < activeList.length; i++) {
            activeList_item.project = activeList[i]["project"];
            activeList_item.id = activeList[i]["id"];
            activeList_item.pw = activeList[i]["pw"];
            activeList_item.name = activeList[i]["name"];
            activeList_item.phone = activeList[i]["phone"];
          }
          forceUpdate();
        });
    }
  }, [detailFacilityId]);

  const handleAddProspecter = (el) => {
    createCard.length = 1;
    setCreateCard((createCard) => [...createCard, <Prospecter />]);
  };

  return (
    <>
      <div
        className={
          (useResultContext().detailFacilityId.length === 0
            ? "d-none "
            : "d-block ") + "search_form_right col-sm-8 "
        }
        style={{ marginTop: "40px" }}
      >
        <div className="supervision">
          <Link
            to={{
              pathname: `/supervision/prospecter`,
              state: {},
            }}
          >
            <button className="btn btn-info" onClick={handleAddProspecter}>
              조사자 정보 추가
            </button>
            {createCard.map((item, i) => (
              <div key={i}>{item}</div>
            ))}
          </Link>

          <div className="col">
            <table className="table-border table">
              <thead>
                <tr>
                  <th className="w-60px">상태</th>
                  <th className="w-80px">조사자 이름</th>
                  <th className="w-100px">휴대폰 번호</th>
                  <th>아이디</th>
                  <th>비밀번호</th>
                  <th>조사기간</th>
                  <th className="w-80px">수정</th>
                </tr>
              </thead>
              <tbody>
                {activeList.length !== 0 ? (
                  activeList.map((item, i) => {
                    return (
                      <Active
                        key={i}
                        id={item.id}
                        idx={item.idx}
                        name={item.name}
                        phone={item.phone}
                        project={item.project}
                        project_id={item.project_id}
                        pw={item.pw}
                        startdate={item.startdate}
                        enddate={item.enddate}
                        active={item.active}
                      />
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={7} className="text-center">
                      활성화된 조사자가 존재하지 않습니다.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          <div className="col mt-5">
            <table className="table-border table">
              <thead>
                <tr>
                  <th className="w-60px">상태</th>
                  <th className="w-80px">조사자 이름</th>
                  <th className="w-100px">휴대폰 번호</th>
                  <th>아이디</th>
                  <th>비밀번호</th>
                  <th>조사기간</th>
                  <th className="w-80px">수정</th>
                </tr>
              </thead>
              <tbody>
                {deactiveList.length !== 0 ? (
                  deactiveList.map((item, i) => {
                    return (
                      <Deactive
                        key={i}
                        id={item.id}
                        idx={item.idx}
                        name={item.name}
                        phone={item.phone}
                        project={item.project}
                        project_id={item.project_id}
                        pw={item.pw}
                        startdate={item.startdate}
                        enddate={item.enddate}
                        active={item.active}
                      />
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={7} className="text-center">
                      비활성화된 조사자가 존재하지 않습니다.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}
export default Supervision;
