const Menu = [
  {
    path: "/home",
    title: "HOME",
  },
  {
    path: "/fms",
    title: "시설물 관리",
  },
  {
    path: "/information",
    title: "현장조사 관리",
  },
  { path: "/evaluation", title: "현장조사 분석평가" },
  {
    path: "/report",
    title: "보고서 작성",
  },
  {
    path: "/form",
    title: "고객센터",
    children: [
      { path: "/cs/map", title: "사용 가이드" },
      { path: "/cs/faq", title: "자주하는 질문" },
      { path: "/cs/ask", title: "문의하기" },
      { path: "/cs/notice", title: "공지사항" },
    ],
  },
];

export default Menu;
