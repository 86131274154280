import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";
import ko from "date-fns/locale/ko";
import { useResultContext } from "../../Contexts/LayoutContext";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import { useHistory } from "react-router-dom";

registerLocale("ko", ko);
const token = "bearer " + sessionStorage.getItem("token");
const URL = process.env.REACT_APP_BACKEND_HOST;
const API = URL + "/members";
const API_CI = "/create";
const API_INSPEC = "/inspector";

function Prospecter() {
  const [prospecterName, setProspecterName] = useState("");
  const [prospecterTel, setProspecterTel] = useState("");
  const [getResID, setGetTesID] = useState();
  const [getResPW, setGetResPW] = useState("");
  const { detailFacilityId } = useResultContext();
  const [isSaved, setIsSaved] = useState(false);
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [disabled, setDisabled] = useState(true);

  let history = useHistory();

  const handleInput = (e) => {
    e.preventDefault();

    const curVal = e.target.value;
    const onlyNum = curVal.replace(/[^0-9]/g, "");

    setProspecterTel(onlyNum);
  };

  const handleInputN = (e) => {
    e.preventDefault();

    const curVal = e.target.value;
    setProspecterName(curVal);
  };
  useEffect(() => {
    if (
      prospecterName !== "" &&
      prospecterTel !== "" &&
      dateRange[0] !== null &&
      dateRange[1] !== null
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [prospecterTel, prospecterName, dateRange]);

  const handleCreateInspector = () => {
    setDisabled(true);

    fetch(
      API +
        API_CI +
        API_INSPEC +
        "?name=" +
        prospecterName +
        "&phone=" +
        prospecterTel +
        "&facilityid=" +
        detailFacilityId +
        "&startdate=" +
        moment(dateRange[0]).format("yyyyMMDD") +
        "&enddate=" +
        moment(dateRange[1]).format("yyyyMMDD"),
      {
        method: "GET",
        headers: {
          Authorization: token,
          "Content-type": "application/json",
        },
      }
    )
      .then((res) => {
        if (res.status / 100 === 2) {
          setIsSaved(true);
        } else {
          setDisabled(false);
          alert(
            "알 수 없는 오류가 발생하였습니다. 새로고침 후 다시 시도해주세요."
          );
        }
        return res.json();
      })
      .then((data) => {
        history.goBack();
      });
  };

  return (
    <>
      {detailFacilityId.length !== 0 ? (
        <div className="col-sm-8 mt-5">
          <div className="row">
            <div className="row ">
              <div className="form-group row col-sm-6">
                <label className="col-lg-4 col-form-label ">조사자 이름</label>
                <div className="col-lg-8">
                  <input
                    type="text"
                    onKeyUp={handleInputN}
                    name="prospecterName"
                    id="prospecterName"
                    maxLength={5}
                    className={"form-control fs-13px col"}
                    placeholder="이름을 입력해주세요."
                  />
                </div>
              </div>
              <div className="form-group row col-sm-6">
                <label className="col-lg-4 col-form-label text-end">
                  전화번호
                </label>
                <div className="col-lg-8">
                  <input
                    type="tel"
                    maxLength="11"
                    onChange={handleInput}
                    value={prospecterTel}
                    name="prospecterTel"
                    id="prospecterTel"
                    className={"form-control fs-13px col"}
                    placeholder="숫자만 입력하세요."
                  />
                </div>
              </div>
              <div className="form-group row mt-2">
                <label className="form-label col-form-label col-lg-2 ">
                  시설물 조사기간
                </label>
                <div className="col ps-5px">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="input-group">
                        <DatePicker
                          className="col form-control"
                          selectsRange={true}
                          startDate={startDate}
                          endDate={endDate}
                          onChange={(update) => {
                            setDateRange(update);
                          }}
                          locale="ko"
                          dateFormat="yyyy.MM.dd"
                          placeholderText="선택해주세요."
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {isSaved ? (
              <div className="row mt-10px">
                <div className="form-group row col-sm-6">
                  <label className="col-lg-4 col-form-label">
                    부여받은 아이디
                  </label>
                  <div className="col-lg-8">
                    <input
                      value={getResID}
                      className={"form-control fs-13px col"}
                      readOnly
                      disabled
                      placeholder="정보 입력 후 저장시 아이디가 생성됩니다."
                    />
                  </div>
                </div>
                <div className="form-group row col-sm-6">
                  <label className="col-lg-4 col-form-label">
                    부여받은 비밀번호
                  </label>
                  <div className="col-lg-8">
                    <input
                      value={getResPW}
                      className={"form-control fs-13px col"}
                      readOnly
                      disabled
                      placeholder="정보 입력 후 저장시 비밀번호가 생성됩니다."
                    />
                  </div>
                </div>
              </div>
            ) : null}
          </div>
          {isSaved ? null : (
            <button
              className="btn btn-default mt-10px float-end"
              onClick={handleCreateInspector}
              disabled={disabled}
            >
              조사자 저장
            </button>
          )}
        </div>
      ) : (
        ""
      )}
    </>
  );
}

export default Prospecter;
