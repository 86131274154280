import React, { useEffect, useState } from "react";

const URL = process.env.REACT_APP_BACKEND_HOST;
const API = URL + "/members/";
const LOGOUT = URL + "/project/prj/";
const token = "bearer " + sessionStorage.getItem("token");

let userName = sessionStorage.getItem("userName");

function MyInfo() {
  const [accredit, setAccredit] = useState(false);
  const [getPW, setGetPW] = useState("");
  const handleInput = (e) => {
    setGetPW(e.target.value);
  };

  const handleLogin = (event) => {
    event.preventDefault();

    const formData = new FormData();
    formData.append("userid", userName);
    formData.append("password", getPW);

    fetch(API + "login/", {
      method: "POST",
      headers: {},
      body: formData,
    })
      .then((res) => {
        if (res.status / 100 === 2) {
          return res.json();
        } else {
          alert(
            "알수없는 오류가 발생하였습니다. 새로고침 후 다시 시도해주세요."
          );
        }
      })
      .then((res) => {
        console.log(res);
        if (res.success === "True") {
          sessionStorage.setItem("token", res.token);
          setAccredit(!accredit);
          alert("입장");
        } else if (res.message === "wrong password") {
          if (res.attempt < 5) {
            alert(
              "비밀번호를 확인해주세요. 총 5회 중 " + res.attempt + "회 오류"
            );
          } else if (res.attempt === 5) {
            alert(
              "총 5회 오류. 계정이 잠금 보호되었습니다. 010-3335-2459로 문의바랍니다."
            );
            sessionStorage.clear();
            window.location.replace("/home");
          }
        } else if (res.message === "locked") {
          alert("계정이 보호되어있습니다. 010-3335-2459로 문의바랍니다.");
          sessionStorage.clear();
          window.location.replace("/home");
        }
      });
  };

  return (
    <>
      <div className="row col-md-8 m-auto">
        <div className="">개인 정보 수정 및 변경 </div>

        {accredit ? null : (
          <div
            className="col-md-6 m-auto"
            //  style={{ border: "2px solid red" }}
          >
            <div className="row mb-3">
              <label className="w-150px col-form-label">회원아이디</label>
              <input
                type="text"
                value={userName}
                className={"form-control fs-13px col"}
                disabled
                readOnly
              />
            </div>
            <div className="row mb-3">
              <label className="w-150px col-form-label">비밀번호</label>
              <input
                type="password"
                name="getPW"
                id="getPW"
                onChange={handleInput}
                className={"form-control fs-13px col"}
              />
            </div>
            <button className="btn btn-warning" onClick={handleLogin}>
              인증하기
            </button>
          </div>
        )}
        {accredit ? <MyInfoUpdate /> : null}
      </div>
    </>
  );
}

function MyInfoUpdate() {
  const [sign, setSign] = useState("false");
  const [input, setInput] = useState({
    password: "",
    passwordAgain: "",
    personphone1: "",
    personphone2: "",
    personphone3: "",
    personname: "",
    companyemail: "",
    department: "",
  });

  const [errors, setErrors] = useState({
    password: "",
    passwordAgain: "",
    personphone1: "",
    personphone2: "",
    personphone3: "",
    personname: "",
    companyemail: "",
    department: "",
  });

  const [before, setBefore] = useState({
    personphone1: "",
    personphone2: "",
    personphone3: "",
    personname: "",
    companyemail: "",
    department: "",
  });

  useEffect(() => {
    fetch(API + "getUserInfo", {
      method: "GET",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: token,
      },
    })
      .then((res) => {
        if (res.status / 100 === 2) {
          return res.json();
        } else {
          alert(
            "알수없는 오류가 발생하였습니다. 새로고침 후 다시 시도해주세요."
          );
        }
      })
      .then((res) => {
        console.log(res);
        setBefore({
          personphone1: res.personphone1,
          personphone2: res.personphone2,
          personphone3: res.personphone3,
          personname: res.personname,
          companyemail: res.companyemail,
          department: res.department,
        });
      });

    // setInput({
    //   personphone1: before.personphone1,
    //   personphone2: before.personphone2,
    //   personphone3: before.personphone3,
    //   personname: before.personname,
    //   companyemail: before.companyemail,
    //   department: before.department,
    // });
  }, [sign]);

  const handleInput = (e) => {
    const { name, value } = e.target;
    setErrors(errors);

    console.log(value);
    const passCheck = /^(?=.*?[0-9])(?=.*?[#?!@$ %^&*-]).{8,15}$/;
    const blank_pattern = /[\s]/g;

    if (input.password !== input.passwordAgain) {
      errors.passwordAgain =
        input.password !== input.passwordAgain
          ? "비밀번호가 일치하지않습니다."
          : "";
    }

    switch (name) {
      case "companyemail":
        errors.companyemail = value.includes("@")
          ? ""
          : "유효하지 않은 이메일 형식입니다.";
        break;
      case "personname":
        errors.personname =
          value.length < 0 ? "이름을 정확히 입력해주세요." : "";
        break;
      case "password":
        errors.password =
          passCheck.test(value) && !blank_pattern.test(value)
            ? ""
            : "영문 대소문자/숫자/특수문자( #?!@$ %^&*- )최소 1개씩 포함, 8자~15자";
        break;
      default:
        break;
    }

    setInput({
      ...input,
      [name]: value,
    });

    setErrors({ ...errors });
  };

  const isEmail = (email) => {
    const emailRegex =
      /^(([^<>()\[\].,;:\s@"]+(\.[^<>()\[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;

    return emailRegex.test(email);
  };

  const handleEdit = (e) => {
    setSign(!sign);
  };

  const handleUpdate = (e) => {
    const formData = new FormData();

    if (input.password !== "") {
      formData.append("userpassword", input.password);
    }
    if (input.personphone1 !== "") {
      formData.append("personphone1", input.personphone1);
    }
    if (input.personphone2 !== "") {
      formData.append("personphone2", input.personphone2);
    }
    if (input.personphone3 !== "") {
      formData.append("personphone3", input.personphone3);
    }
    if (input.personname !== "") {
      formData.append("personname", input.personname);
    }
    if (input.companyemail !== "") {
      formData.append("companyemail", input.companyemail);
    }
    if (input.department !== "") {
      formData.append("department", input.department);
    }

    for (var key of formData.entries()) {
      console.log(key[0] + ", " + key[1]);
    }

    fetch(API + "update/", {
      method: "PATCH",
      headers: {
        Authorization: token,
      },
      body: formData,
    }).then((res) => {
      res.json();
      if (res.status === 200) {
        alert("성공적으로 수정되었습니다.");
        window.location.replace("/home");
      } else {
        alert("수정ㅇ에 실패했습니다. 다시 시도해주세요");
        window.location.replace("/home");
      }
    });
  };

  const handleQuitMem = (e) => {
    const formData = new FormData();
    if (window.confirm("정말 탈퇴하시겠습니까??") === true) {
      formData.append("is_active", 0);

      fetch(API + "update/", {
        method: "PATCH",
        headers: {
          Authorization: token,
        },
        body: formData,
      })
        .then((res) => {
          return res.json();
        })
        .then((res) => {
          alert("탈퇴신청 되었습니다.");

          fetch(LOGOUT, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Token ${sessionStorage.getItem("token")}`,
            },
          })
            .then((res) => {
              return res.json();
            })
            .then((data) => {
              sessionStorage.clear();
              window.location.replace("/home");
            });
        });
    } else {
      //취소

      return false;
    }
  };

  return (
    <>
      <div className="row m-auto">
        <div id="updateMyinfo">
          {sign ? (
            <>
              <div className="row mb-3">
                <div className="col-md-6">
                  <div className="row">
                    <label className="w-150px col-form-label">
                      비밀번호
                      <span className="text-danger">*</span>
                    </label>

                    <input
                      type="password"
                      disabled
                      className={"form-control fs-13px col"}
                      placeholder="*******"
                    />
                  </div>
                </div>
              </div>
              <div className="row mb-3">
                <label className="w-150px col-form-label">
                  사용자 전화번호
                  <span className="text-danger">*</span>
                </label>
                <div className="d-flex col p-0">
                  <input
                    type="text"
                    disabled
                    value={before.personphone1}
                    className={"form-control fs-13px col"}
                  />
                  <input
                    type="text"
                    disabled
                    value={before.personphone2}
                    className={"form-control fs-13px col"}
                  />
                  <input
                    type="text"
                    disabled
                    value={before.personphone3}
                    className={"form-control fs-13px col"}
                  />
                </div>
              </div>
              <div className="row mb-3">
                <label className="w-150px col-form-label">
                  회사메일
                  <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  disabled
                  value={before.companyemail}
                  className={"form-control fs-13px col"}
                />
              </div>
              <div className="row mb-3">
                <label className="w-150px col-form-label">
                  소속
                  <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  disabled
                  value={before.department}
                  className={"form-control fs-13px col"}
                />
              </div>
              <div className="row mb-3">
                <label className="w-150px col-form-label">
                  사용자명
                  <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  value={before.personname}
                  className={"form-control fs-13px col"}
                  disabled
                />
              </div>
            </>
          ) : (
            <>
              <div className="row mb-3">
                <label className="w-150px col-form-label">
                  비밀번호
                  <span className="text-danger">*</span>
                </label>
                <>
                  <input
                    type="password"
                    onChange={handleInput}
                    name="password"
                    id="password"
                    className={"form-control fs-13px col"}
                    placeholder="비밀번호"
                  />
                  {errors.password.length > 0 ? (
                    <div style={{ color: "red" }}>{errors.password}</div>
                  ) : (
                    ""
                  )}
                </>
              </div>
              <div className="row mb-3">
                <label className="w-150px col-form-label">
                  비밀번호 재확인
                  <span className="text-danger">*</span>
                </label>
                <>
                  <input
                    type="password"
                    onChange={handleInput}
                    name="passwordAgain"
                    id="passwordAgain"
                    className={"form-control fs-13px col"}
                    placeholder="비밀번호"
                  />
                  {input.password !== input.passwordAgain ? (
                    <div style={{ color: "red" }}>
                      비밀번호가 일치하지 않습니다.
                    </div>
                  ) : (
                    ""
                  )}
                </>
              </div>
              <div className="row mb-3">
                <label className="w-150px col-form-label">
                  사용자 전화번호
                </label>
                <div className="d-flex col p-0">
                  <input
                    type="text"
                    onChange={handleInput}
                    name="personphone1"
                    id="personphone1"
                    className={"form-control fs-13px col"}
                    defaultValue={before.personphone1}
                    key={before.personphone1}
                    maxLength={3}
                  />
                  <input
                    type="text"
                    onChange={handleInput}
                    name="personphone2"
                    id="personphone2"
                    className={"form-control fs-13px col"}
                    defaultValue={before.personphone2}
                    key={before.personphone2}
                    maxLength={4}
                  />
                  <input
                    type="text"
                    onChange={handleInput}
                    name="personphone3"
                    id="personphone3"
                    className={"form-control fs-13px col"}
                    defaultValue={before.personphone3}
                    key={before.personphone3}
                    maxLength={4}
                  />
                </div>
              </div>
              <div className="row mb-3">
                <label className="w-150px col-form-label">회사메일</label>
                <input
                  type="text"
                  onChange={handleInput}
                  name="companyemail"
                  id="companyemail"
                  defaultValue={before.companyemail}
                  key={before.companyemail}
                  className={"form-control fs-13px col"}
                />

                {errors.companyemail.length > 0 ? (
                  <div style={{ color: "red" }}>
                    유효하지 않은 이메일 입니다.
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="row mb-3">
                <label className="w-150px col-form-label">소속</label>
                <input
                  type="text"
                  onChange={handleInput}
                  name="department"
                  id="department"
                  defaultValue={before.department}
                  key={before.department}
                  className={"form-control fs-13px col"}
                />
              </div>
              <div className="row mb-3">
                <label className="w-150px col-form-label ">사용자명</label>
                <input
                  type="text"
                  onChange={handleInput}
                  name="personname"
                  id="personname"
                  defaultValue={before.personname}
                  key={before.personname}
                  className={"form-control fs-13px col"}
                />
                {errors.personname.length > 0 ? (
                  <div style={{ color: "red" }}>
                    이름을 정확히 입력해주세요.
                  </div>
                ) : (
                  ""
                )}
              </div>
            </>
          )}

          <div className="">
            {sign ? (
              <button className="btn btn-default" onClick={handleEdit}>
                회원정보 수정하기
              </button>
            ) : (
              <button className="btn btn-default" onClick={handleUpdate}>
                저장하기
              </button>
            )}
            <button className="btn btn-warning" onClick={handleQuitMem}>
              탈퇴하기
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default MyInfo;
