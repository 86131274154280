import moment from "moment";
import { React, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Panel, PanelBody } from "../../components/panel/panel.jsx";

const URL = process.env.REACT_APP_BACKEND_HOST;
const LIST = URL + "/boards/view_list/notice";
const CHECK_MEM = URL + "/members/check/admin";
const token = "bearer " + sessionStorage.getItem("token");

const CsNotice = () => {
  const [noticeArr, setNoticeArr] = useState([
    {
      id: "",
      title: "",
      tags: "",
      updated_at: "",
      author: "",
      views: "",
    },
  ]);
  const [admin, setAdmin] = useState(false);

  useEffect(() => {
    fetch(CHECK_MEM, {
      method: "GET",
      headers: {
        Authorization: token,
      },
    })
      .then((res) => {
        if (res.status / 100 === 2) {
          return res.json();
        } else {
          alert(
            "알수없는 오류가 발생하였습니다. 새로고침 후 다시 시도해주세요."
          );
        }
      })
      .then((res) => setAdmin(res));
  });

  useEffect(() => {
    fetch(LIST + "?tag=1", {
      method: "GET",
    })
      .then((res) => {
        if (res.status / 100 === 2) {
          return res.json();
        } else {
          alert(
            "알수없는 오류가 발생하였습니다. 새로고침 후 다시 시도해주세요."
          );
        }
      })
      .then((res) => {
        setNoticeArr([]);
        for (let i = 0; i < res.length; i++) {
          setNoticeArr((noticeArr) => [
            ...noticeArr,
            {
              id: res[i].id,
              title: res[i].title,
              tags: res[i].tags,
              views: res[i].views,
              author: res[i].author,
              updated_at: moment(res[i].updated_at).format("yyyy.MM.DD"),
            },
          ]);
        }
      });
  }, []);

  return (
    <Panel className="col-lg-7 col-md-9 m-auto">
      <PanelBody>
        <div className="mb-3">
          <label
            className="form-label col-form-label text-blue-700"
            style={{ fontSize: "2rem", fontWeight: "normal" }}
          >
            공지사항
          </label>
        </div>
        <div className="table-responsive">
          <table className="table mb-0 text-center">
            <thead>
              <tr>
                <th className="w-50px">번호</th>
                <th>제목</th>
                <th className="w-150px">작성자</th>
                <th className="w-150px">작성일</th>
                <th className="w-50px">조회수</th>
              </tr>
            </thead>
            <tbody>
              {noticeArr.map((item, i) => {
                return (
                  <tr key={item.id}>
                    <td>{i + 1}</td>
                    <td>
                      <Link
                        id={item.id}
                        to={{
                          pathname: `/noticeBoard/${item.id}`,
                          state: {
                            item: item,
                          },
                        }}
                      >
                        {item.title}
                      </Link>
                    </td>
                    <td>{item.author}</td>
                    <td>{item.updated_at} </td>
                    <td>{item.views} </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </PanelBody>
      {admin ? (
        <button type="button" className="btn btn-sm btn-info float-end">
          <Link to="/cs/notice-write" className="nav-link text-white">
            글 작성하기
          </Link>
        </button>
      ) : null}
    </Panel>
  );
};

export { CsNotice };
