import React from "react";
import { Panel, PanelBody } from "../../components/panel/panel.jsx";

const URL = process.env.REACT_APP_BACKEND_HOST;
class CsMap extends React.Component {
  render() {
    return (
      <Panel>
        <PanelBody className="col-md-6 m-auto fs-15px">
          <label className="form-label col-form-label fs-3 text-blue-700">
            사용 가이드 입니다.
          </label>
          <div className="alert alert-muted d-block ">
            <a href={URL + "/uploadImg/uploadFile/manual/ascope_manual.pdf"}>
              사용자 가이드 보기
            </a>
          </div>
          <div className="alert alert-muted d-block ">
            <a href={URL + "/uploadImg/uploadFile/report/report_sample.zip"}>
              보고서 샘플 보기
            </a>
          </div>
        </PanelBody>
      </Panel>
    );
  }
}

export { CsMap };
