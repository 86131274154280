import React from "react";
import PrivateRoute from "./../lib/privateRoute";
import TopMenuNavList from "./top-menu-nav-list.jsx";
import menus from "./menu.jsx";

class TopMenuNav extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: -1,
      controlLeft: false,
      controlRight: false,
      marginLeft: 0,
      marginRight: 0,
      navWidth: 0
    };

    this.topMenu = React.createRef();
    this.handleExpand = this.handleExpand.bind(this);
  }

  componentDidMount() {
    var windowWidth = this.topMenu.current.offsetWidth - 128;
    var listFullWidth = 0;
    var listPrevWidth = 0;
    var listActive = false;

    document
      .querySelectorAll(".app-top-menu .menu > .menu-item")
      .forEach(function(elm) {
        listFullWidth += elm.offsetWidth;
        listPrevWidth += !listActive ? elm.offsetWidth : 0;
        listActive = elm.classList.contains("active") ? true : listActive;
      });

    listPrevWidth = !listActive ? 0 : listPrevWidth;

    if (listPrevWidth >= windowWidth) {
      var finalScrollWidth = listPrevWidth - windowWidth + 128;
      var finalLeft, finalRight;
      if (!document.body.classList.contains("rtl-mode")) {
        finalLeft = finalScrollWidth;
        finalRight = 0;
      } else {
        finalRight = finalScrollWidth;
        finalLeft = 0;
      }
      this.setState(state => ({
        marginLeft: finalLeft,
        marginRight: finalRight
      }));
    }

    this.setState(state => ({
      navWidth: listFullWidth,
      controlLeft:
        listPrevWidth >= windowWidth && listFullWidth >= windowWidth
          ? true
          : false,
      controlRight:
        listPrevWidth !== listFullWidth && listFullWidth >= windowWidth
          ? true
          : false
    }));

    this.forceUpdate();
  }

  handleExpand(e, i, match) {
    e.preventDefault();
    this.setState(state => ({
      active: this.state.active === i ? -1 : i
    }));
  }

  render() {
    return (
      <div ref={this.topMenu} className="menu">
        {menus.map((menu, i) => (
          <PrivateRoute
            path={menu.path}
            exact={menu.exact}
            key={i}
            children={({ match }) => (
              <TopMenuNavList
                data={menu}
                key={i}
                expand={e => this.handleExpand(e, i, match)}
                active={i === this.state.active}
                clicked={this.state.clicked}
              />
            )}
          />
        ))}
      </div>
    );
  }
}

export default TopMenuNav;
