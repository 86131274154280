import { React, useEffect, useCallback, useState } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import adPNG from "../../assets/img/ad.png";

const URL = process.env.REACT_APP_BACKEND_HOST;
const API = URL + "/project/prj_list";
const NOTICE_LIST = URL + "/boards/view_list/notice";
const DOWNLOADFILE = "/uploadImg/uploadFile/notice";

let prj_list = [];
let project = {
  facilityaddress: "",
  facilityarea1: "",
  facilityarea2: "",
  facilitydate: "",
  facilitydivide: "",
  facilityetc: "",
  facilityfloordown: "",
  facilityfloorup: "",
  facilityid: "",
  facilityname: "",
  facilityowner: "",
  facilitystructure: "",
  facilityuse: "",
  userid: "",
};

let show_list = [];
let showList = {
  address: "",
  name: "",
  divide: "",
};

function Home() {
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);
  const [noticeArr, setNoticeArr] = useState([
    {
      id: "",
      title: "",
      tags: "",
      updated_at: "",
      author: "",
      views: "",
    },
  ]);

  const token = "bearer " + sessionStorage.getItem("token");
  const isToken = sessionStorage.getItem("token");

  useEffect(() => {
    if (isToken !== null) {
      fetch(API, {
        method: "GET",
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: token,
          Accept: "application/json;charset=UTF-8",
        },
      })
        .then((res) => {
          if (res.status / 100 === 2) {
            return res.json();
          } else {
            alert(
              "알수없는 오류가 발생하였습니다. 새로고침 후 다시 시도해주세요."
            );
          }
        })
        .then((res) => {
          if (res["results"].length !== 0) {
          }
          const response_arr = res["results"];

          for (let i = 0; i < res["results"].length; i++) {
            project = res["results"][i];
            prj_list.push(project);
          }

          show_list = [];
          for (let i = 0; i < response_arr.length; i++) {
            showList.address = response_arr[i]["facilityaddress"];
            showList.name = response_arr[i]["facilityname"];
            showList.divide = response_arr[i]["facilitydivide"];

            show_list.push(showList);
            showList = {
              address: "",
              name: "",
              divide: "",
            };
          }
          forceUpdate();
        });
    } else {
    }
  }, []);

  useEffect(() => {
    fetch(NOTICE_LIST + "?tag=1", {
      method: "GET",
    })
      .then((res) => {
        if (res.status / 100 === 2) {
          return res.json();
        } else {
          alert(
            "알수없는 오류가 발생하였습니다. 새로고침 후 다시 시도해주세요."
          );
        }
      })
      .then((res) => {
        setNoticeArr([]);
        for (let i = 0; i < res.length; i++) {
          setNoticeArr((noticeArr) => [
            ...noticeArr,
            {
              id: res[i].id,
              title: res[i].title,
              tags: res[i].tags,
              views: res[i].views,
              author: res[i].author,
              updated_at: moment(res[i].updated_at).format("yyyy.MM.DD"),
            },
          ]);
        }
      });
  }, []);

  return (
    <div className="column_wrap row m-auto pt-3">
      {/* <div className="column_left col-md-2">
        <img src={adPNG} alt="광고 배너" className="ad_banner" />
      </div> */}
      <div className="column_middle col">
        <div className="card mb-3 row">
          <div className="card-body  p-10px ps-15px">
            <div className="d-flex justify-content-between">
              <h5 className="d-inline">등록된 건축물 리스트</h5>
              {/* <Link
              to="/information"
              className="btn btn-inverse btn-sm go_detail"
            >
              <span className="text-gray-500">
                <i className="fa fa-plus-circle" id="popover4">
                  더보기
                </i>
              </span>
            </Link> */}
            </div>

            <div className="card-body">
              <div
                style={{
                  height: "265px",
                  overflowY: "scroll",
                  overflowX: "hidden",
                }}
              >
                <ul>
                  {show_list.map((listItem, i) => {
                    return (
                      <li key={i} className="search_result_listItem mb-5px row">
                        <div
                          className="result_listItem_division fw-bold col-sm-4"
                          style={{
                            color:
                              listItem.divide === "안전점검"
                                ? "#D4AC0D"
                                : listItem.divide === "정밀진단"
                                ? "#52BE80"
                                : listItem.divide === "구조점검"
                                ? "#DC7633"
                                : listItem.divide === "안전진단"
                                ? "#5DADE2"
                                : "#1F618D",
                          }}
                        >
                          {listItem.divide}
                        </div>

                        <div className="ps-10px col-sm-8">
                          <div className="result_listItem_building fw-bold">
                            {listItem.name}
                          </div>
                          <div
                            style={{
                              display: "block",
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                            }}
                            className="result_listItem_address fs-12px text-gray-600"
                          >
                            {listItem.address}
                          </div>
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
          {/* <div className="card-body">
            <div
            // style={{
            //   height: "269px",
            //   overflowY: "scroll",
            //   overflowX: "hidden"
            // }}
            >
              <ul>
                {show_list.map((listItem, i) => {
                  return (
                    <li key={i} className="search_result_listItem mb-5px row">
                      <div
                        className="result_listItem_division fw-bold col-sm-4"
                        style={{
                          color:
                            listItem.divide === "안전점검"
                              ? "#D4AC0D"
                              : listItem.divide === "정밀진단"
                              ? "#52BE80"
                              : listItem.divide === "구조점검"
                              ? "#DC7633"
                              : listItem.divide === "안전진단"
                              ? "#5DADE2"
                              : "#1F618D",
                        }}
                      >
                        {listItem.divide}
                      </div>

                      <div className="ps-10px col-sm-8">
                        <div className="result_listItem_building fw-bold">
                          {listItem.name}
                        </div>
                        <div
                          style={{
                            display: "block",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                          }}
                          className="result_listItem_address fs-12px text-gray-600"
                        >
                          {listItem.address}
                        </div>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div> */}
        </div>
        <div className=" mb-3 row" style={{ flexDirection: "row" }}>
          <div className="card ">
            <div
              className="card-body ps-0 pe-0 pb-0"
              style={{ borderRadius: "4px 4px 0 0 " }}
            >
              <h5 className="d-inline">관련 법령정보</h5>
            </div>
            <div className="card-body">
              <div
                style={{
                  height: "220px",
                  overflowY: "scroll",
                  overflowX: "hidden",
                }}
              >
                <ul className="notice-list ps-0">
                  <li>
                    <i className="fas me-10px fa-plus"></i>
                    <div>
                      <a
                        href={
                          URL +
                          DOWNLOADFILE +
                          "/시설물의_안전_및_유지관리에_관한_특별법_3단비교표_21.1.hwp"
                        }
                        download
                      >
                        시설물의 안전 및 유지관리에 관한 특별법
                      </a>
                      <i className="ion ion-md-download fa-fw text-black-lighter"></i>
                    </div>
                  </li>
                  <li>
                    <i className="fas me-10px fa-plus"></i>
                    <div>
                      <a
                        href={
                          URL +
                          DOWNLOADFILE +
                          "/시설물통합정보관리체계_운영규정.pdf"
                        }
                        download
                      >
                        시설물통합정보관리체계 운영규정
                      </a>
                      <i className="ion ion-md-download fa-fw text-black-lighter"></i>
                    </div>
                  </li>
                  <li>
                    <i className="fas me-10px fa-plus"></i>
                    <div>
                      <a
                        href={
                          URL +
                          DOWNLOADFILE +
                          "/시설물의_안전_및_유지관리_실시_등에_관한_지침.pdf"
                        }
                        download
                      >
                        시설물의 안전 및 유지관리 실시 등에 관한 지침
                      </a>
                      <i className="ion ion-md-download fa-fw text-black-lighter"></i>
                    </div>
                  </li>
                  <li>
                    <i className="fas me-10px fa-plus"></i>
                    <div>
                      <a
                        href={
                          URL +
                          DOWNLOADFILE +
                          "/시설물의_안전_및_유지관리_실시_세부지침(안전점검,진단편).pdf"
                        }
                        download
                      >
                        안전점검 및 정밀안전진단 세부지침
                      </a>
                      <i className="ion ion-md-download fa-fw text-black-lighter"></i>
                    </div>
                  </li>
                  <li>
                    <i className="fas me-10px fa-plus"></i>
                    <div>
                      <a
                        href={
                          URL +
                          DOWNLOADFILE +
                          "/제3종시설물_안전등급_평가_매뉴얼(2021.07.05.).zip"
                        }
                        download
                      >
                        제3종시설물 안전등급 평가 매뉴얼
                      </a>
                      <i className="ion ion-md-download fa-fw text-black-lighter"></i>
                    </div>
                  </li>
                  <li>
                    <i className="fas me-10px fa-plus"></i>
                    <div>
                      <a
                        href={
                          URL +
                          DOWNLOADFILE +
                          "/2016_소규모_취약시설_안전점검_매뉴얼.pdf"
                        }
                        download
                      >
                        2016 소규모 취약시설 안전점검 매뉴얼
                      </a>
                      <i className="ion ion-md-download fa-fw text-black-lighter"></i>
                    </div>
                  </li>
                  <li>
                    <i className="fas me-10px fa-plus"></i>
                    <div>
                      <a
                        href={URL + DOWNLOADFILE + "/test.pdf"}
                        download="건축물 정기 점검 매뉴얼"
                      >
                        건축물_정기_점검_매뉴얼
                        <i className="ion ion-md-download fa-fw text-black-lighter"></i>
                      </a>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="column_right col-md-3">
        <div className="card mb-3">
          <div className="d-flex justify-content-between">
            <h5 className="d-inline">공지사항</h5>
            <Link
              to="/cs/notice"
              className="btn p-0 btn-sm ms-8px mb-4px float-end"
            >
              <span className="ms-2 text-gray-500">
                <i className="fa fa-plus-circle" id="popover4"></i>
                <span className="pe-2 ps-2">더보기</span>
              </span>
            </Link>
          </div>

          <div className="card-body">
            <ul className="notice-list ps-0">
              {noticeArr.map((item, i) => {
                return (
                  <li key={item.id}>
                    <i className="ion ion-md-arrow-dropright fa-fw float-start"></i>
                    <div>
                      <Link
                        id={item.id}
                        to={{
                          pathname: `/noticeBoard/${item.id}`,
                          state: {
                            item: item,
                          },
                        }}
                      >
                        {item.title}
                      </Link>
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
        <div className="card mb-3">
          <h5 className="d-inline">시스템 문의</h5>
          <div className="card-body">
            <ul className="notice-list ps-0">
              <li>
                <i className="ion ion-md-arrow-dropright fa-fw float-start"></i>
                <div> 사용자 ID 발급 </div>
                <div className="cs_tel"> 1588-8788+1 &gt; 1번</div>
              </li>
              <li>
                <i className="ion ion-md-arrow-dropright fa-fw float-start"></i>
                <div>시설물관리대장</div>
                <div className="cs_tel"> 1588-8788+1 &gt; 2번</div>
              </li>
              <li>
                <i className="ion ion-md-arrow-dropright fa-fw float-start"></i>
                <div>E보고서 및 설계도서</div>
                <div className="cs_tel"> 1588-8788+1 &gt; 3번</div>
              </li>
              {/* <li>
                <i className="ion ion-md-arrow-dropright fa-fw float-start"></i>
                <div> 계획 및 점검진단결과 제출</div>
                <div className="cs_tel"> 1588-8788+1 &gt; 4번</div>
              </li> */}
              <li>
                <i className="ion ion-md-arrow-dropright fa-fw float-start"></i>
                <div>기타 시스템문의</div>
                <div className="cs_tel">1588-8788+1 &gt; 5번</div>
              </li>
            </ul>
          </div>
        </div>
        {/* <div className="card mb-3">
          <h5 className="d-inline">정보공개 서비스</h5>
          <div className="card-body">
            <ul className="notice-list ps-0">
              <li>
                <i className="fas me-10px fa-plus"></i>
                <span>
                  <Link to="" href="">
                    시설물 안전관리 현황
                  </Link>
                </span>
              </li>
              <li>
                <i className="fas me-10px fa-plus"></i>
                <span>
                  <a href="">안전점검 등 대행실적</a>
                </span>
              </li>
              <li>
                <i className="fas me-10px fa-plus"></i>
                <span>
                  <a href="">시설물유지관리업체 현황</a>
                </span>
              </li>
              {/* <li>
                <i className="fas me-10px fa-plus"></i>
                <span>
                  <a href=""> 위반자 명단 공표</a>
                </span>
              </li>
              <li>
                <i className="fas me-10px fa-plus"></i>
                <span>
                  <a href="">3종시설물 지정/해제 현황</a>
                </span>
              </li>
              <li>
                <i className="fas me-10px fa-plus"></i>
                <span>
                  <a href="">안전진단전문기관 현황</a>
                </span>
              </li> 
              <li>
                <i className="fas me-10px fa-plus"></i>
                <span>
                  <a href="">시설물 사고사례 현황</a>
                </span>
              </li>
            </ul>
          </div>
        </div> */}
      </div>
    </div>
  );
  // }
}

export default Home;
