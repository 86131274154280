import React, { useState, useCallback, useEffect } from "react";
import Prospecter from "../../components/users/prospecter";
// import { URL } from "../../config/app-settings";
import { useResultContext } from "../../Contexts/LayoutContext";

const token = "bearer " + localStorage.getItem("token");

const URL = process.env.REACT_APP_BACKEND_HOST;
const API = URL + "/project/prj_list";
const API_MEM = URL + "/members";
// const API = "project/prj_list";
// const API_MEM = "/members";
const API_VL = "/get_list";
const API_INSPEC = "/inspector";

let prj_list = [];
let result_prj = "";
let project = {
  facilityaddress: "",
  facilityarea1: "",
  facilityarea2: "",
  facilitydate: "",
  facilitydivide: "",
  facilityetc: "",
  facilityfloordown: "",
  facilityfloorup: "",
  facilityid: "",
  facilityname: "",
  facilityowner: "",
  facilitystructure: "",
  facilityuse: "",
  userid: "",
};

let ownList_arr = [];
let ownList_item = {
  address: "",
  name: "",
  divide: "",
};

const Hangul = require("hangul-js");

let returnList = [];
let returnList_item = {
  project: "",
  id: "",
  pw: "",
  name: "",
  phone: "",
};

function Supervision() {
  // const [prospecterStartDate, setProspecterStartDate] = useState(null);
  // const [prospecterEndDate, setProspecterEndDate] = useState(null);
  const [searchInput, setSearchInput] = useState("");
  const [projectName, setProjectName] = useState("");
  const [projectDivide, setProjectDivide] = useState("");
  const {
    setDetailFacilityId,
    setReturnPrjName,
    setReturnPrjAddress,
    setReturnPrjDivide,
  } = useResultContext();
  const { detailFacilityId } = useResultContext();
  const [, updateState] = useState();
  const [createCard, setCreateCard] = useState([]);
  const forceUpdate = useCallback(() => updateState({}), []);

  // useEffect(() => {
  //   try {
  //     if (localStorage.getItem("token") === null) {
  //       window.location.replace("/home");
  //     } else {
  //       fetch(API, {
  //         method: "GET",
  //         headers: {
  //           // "Content-Type": "application/json;charset=UTF-8",
  //           Authorization: token,
  //           // Accept: "application/json;charset=UTF-8",
  //         },
  //       })
  //         .then((res) => res.json())
  //         .then((res) => {
  //           console.log(res);
  //           const response_arr = res["results"];
  //           ownList_arr = [];

  //           for (let i = 0; i < res["results"].length; i++) {
  //             project = res["results"][i];
  //             prj_list.unshift(project);
  //           }
  //           for (let i = 0; i < response_arr.length; i++) {
  //             ownList_item.address = response_arr[i]["facilityaddress"];
  //             ownList_item.name = response_arr[i]["facilityname"];
  //             ownList_item.divide = response_arr[i]["facilitydivide"];

  //             ownList_arr.unshift(ownList_item);
  //             ownList_item = {
  //               address: "",
  //               name: "",
  //               divide: "",
  //             };
  //           }

  //           forceUpdate();
  //         });
  //     }
  //   } catch (e) {
  //     throw new Error("비정산적인 접근입니다. 로그인 후 이용해주세요.");
  //   }

  //   if (localStorage.getItem("token") === null) {
  //     alert("비정산적인 접근입니다. 로그인 후 이용해주세요.");
  //     window.location.replace("/login");
  //   }
  // }, [detailFacilityId]);

  useEffect(() => {
    try {
      if (localStorage.getItem("token") === null) {
        window.location.replace("/home");
      } else {
        fetch(API, {
          // fetch("http://www.dyscopekorea.com/project/prj_list", {
          method: "GET",
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
            Authorization: token,
            Accept: "application/json;charset=UTF-8",
          },
        })
          .then((res) => res.json())
          .then((res) => {
            const response_arr = res["results"];
            ownList_arr = [];

            for (let i = 0; i < res["results"].length; i++) {
              project = res["results"][i];
              prj_list.unshift(project);
            }

            for (let i = 0; i < response_arr.length; i++) {
              ownList_item.address = response_arr[i]["facilityaddress"];
              ownList_item.name = response_arr[i]["facilityname"];
              ownList_item.divide = response_arr[i]["facilitydivide"];

              ownList_arr.unshift(ownList_item);
              ownList_item = {
                address: "",
                name: "",
                divide: "",
              };
            }

            forceUpdate();
          });
      }
    } catch (e) {
      throw new Error("비정산적인 접근입니다. 로그인 후 이용해주세요.");
    }

    if (localStorage.getItem("token") === null) {
      alert("비정산적인 접근입니다. 로그인 후 이용해주세요.");
      window.location.replace("/login");
    }
  }, []);

  useEffect(() => {
    if (detailFacilityId.length !== 0) {
      returnList = [];
      fetch(API_MEM + API_VL + API_INSPEC + "?facilityid=" + detailFacilityId, {
        method: "GET",
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: token,
          Accept: "application/json;charset=UTF-8",
        },
      })
        .then((res) => res.json())
        .then((res) => {
          for (let i = 0; i < res["inspectors"].length; i++) {
            let prospecter = res["inspectors"][i];
            returnList.unshift(prospecter);
          }

          for (let i = 0; i < returnList.length; i++) {
            returnList_item.project = returnList[i]["project"];
            returnList_item.id = returnList[i]["id"];
            returnList_item.pw = returnList[i]["pw"];
            returnList_item.name = returnList[i]["name"];
            returnList_item.phone = returnList[i]["phone"];
          }
          forceUpdate();
        });
    }
  }, [detailFacilityId]);

  const searchText = (e) => {
    let searchValue = e.target.value;
    setSearchInput(searchValue);
  };

  ownList_arr.forEach(function (item) {
    var dis = Hangul.disassemble(item.name, true);
    var cho = dis.reduce(function (prev, elem) {
      elem = elem[0] ? elem[0] : elem;

      return prev + elem;
    }, "");
    item.diassembled = cho;
  });

  function hangulSearch() {
    document
      .getElementById("searchInput")
      .addEventListener("keyup", function () {
        let ul = document.getElementById("find");
        while (ul.firstChild) {
          ul.removeChild(ul.firstChild);
        }

        var search = this.value;
        var search1 = Hangul.disassemble(search).join(""); // ㄺ=>ㄹㄱ

        ownList_arr
          // 문자열 검색 || 초성검색
          .filter(function (item) {
            return (
              item.name.includes(search) || item.diassembled.includes(search1)
            );
          })
          // 검색결과 ul 아래에 li 로 추가
          .forEach(function (item) {
            var div = document.createElement("div");

            div.setAttribute("data-divide", item.divide);
            div.setAttribute("data-name", item.name);
            div.setAttribute("data-address", item.address);
            div.onclick = function (e) {
              getListInfo(e);
            };
            div.style.cursor = "pointer";

            let content = '<li class="search_result_listItem d-flex mb-5px">';
            content += '<div class="d-flex">';
            content +=
              '<div class="result_listItem_division fw-bold w-90px" data-divide="' +
              item.divide +
              '" data-name="' +
              item.name +
              '" data-address="' +
              item.address;
            content += '" style="color:';
            if (item.divide === "안전점검") {
              content += '#D4AC0D">';
            } else if (item.divide === "정밀진단") {
              content += '#52BE80">';
            } else if (item.divide === "구조점검") {
              content += '#DC7633">';
            } else if (item.divide === "하자진단") {
              content += '#5DADE2">';
            } else {
              content += '#1F618D">';
            }
            content += item.divide + "</div>";
            content += '<div class="ps-10px w-200px">';
            content += '<div class="result_listItem_building fw-bold"';
            content += 'data-divide="';
            content += item.divide + '" data-name="';
            content += item.name + '" data-address="';
            content += item.address + '" >';
            content += item.name;
            content += " </div>";
            content +=
              ' <div class="result_listItem_address fs-12px text-gray-600" style="display: block; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;"';
            content += 'data-divide="';
            content += item.divide + '" data-name="';
            content += item.name + '" data-address="';
            content += item.address + '" >';
            content += item.address;
            content += "</div>";
            content += "</div>";
            content += "</li>";
            content += "</div>";
            div.innerHTML = content;

            if (search1 === "") {
              div.innerHTML = "";
            }
            ul.appendChild(div);
          });
      });
  }

  const getListInfo = (e) => {
    const getDivide = e.target.getAttribute("data-divide");
    const getAddress = e.target.getAttribute("data-address");
    const getName = e.target.getAttribute("data-name");
    for (let i = 0; i < prj_list.length; i++) {
      if (
        prj_list[i]["facilitydivide"] +
          prj_list[i]["facilityname"] +
          prj_list[i]["facilityaddress"] ===
        getDivide + getName + getAddress
      ) {
        setProjectName(getName);
        setProjectDivide(getDivide);
        result_prj = prj_list[i]["facilityid"];
        setDetailFacilityId(result_prj);
        setReturnPrjName(prj_list[i]["facilityname"]);
        setReturnPrjAddress(prj_list[i]["facilityaddress"]);
        setReturnPrjDivide(prj_list[i]["facilitydivide"]);
        forceUpdate();
      }
    }
  };

  useEffect(() => {
    hangulSearch();
  }, []);

  const handleAddProspecter = (el) => {
    createCard.length = 1;
    setCreateCard((createCard) => [...createCard, <Prospecter />]);
  };

  return (
    <>
      <div className="container row m-auto">
        <div className="search_form_wrap col-sm-4">
          <div className="search_form_left">
            <div className="search_form_result">
              <label className="form-label text-gray-500" htmlFor="시설물명">
                시설물명
              </label>
              <div className="col form-control bg-blue-100">
                {projectDivide} - {projectName}
              </div>
            </div>
            <div className="search_form">
              <label
                className="form-check-label col-form-label p-0 mb-1 text-gray-600"
                htmlFor="inlineCheckbox1"
              >
                시설물명 조회
              </label>
              <div className="menu">
                <div className="menu-search mb-n3">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="건축물 이름을 검색하세요."
                    onKeyUp={searchText}
                    name="searchInput"
                    id="searchInput"
                    autoComplete="false"
                  />
                </div>
              </div>
            </div>
            <div className="search_result_list_wrap">
              <ul id="find" className="searchResultUl">
                <li className="findChild"></li>
              </ul>
              <div className="around">등록된 시설물 리스트</div>
              <ul>
                {ownList_arr.map((listItem, i) => {
                  return (
                    <div
                      onClick={(e) => getListInfo(e)}
                      data-divide={listItem.divide}
                      data-name={listItem.name}
                      data-address={listItem.address}
                      style={{
                        cursor: "pointer",
                      }}
                      key={i}
                    >
                      <li
                        key={i}
                        className="search_result_listItem d-flex mb-5px"
                      >
                        <div className="d-flex">
                          <div
                            className="result_listItem_division fw-bold w-90px"
                            data-divide={listItem.divide}
                            data-name={listItem.name}
                            data-address={listItem.address}
                            style={{
                              color:
                                listItem.divide === "안전점검"
                                  ? "#D4AC0D"
                                  : listItem.divide === "정밀진단"
                                  ? "#52BE80"
                                  : listItem.divide === "구조점검"
                                  ? "#DC7633"
                                  : listItem.divide === "하자진단"
                                  ? "#5DADE2"
                                  : "#1F618D",
                            }}
                          >
                            {listItem.divide}
                          </div>
                          <div className="ps-10px w-200px">
                            <div
                              className="result_listItem_building fw-bold"
                              data-divide={listItem.divide}
                              data-name={listItem.name}
                              data-address={listItem.address}
                            >
                              {listItem.name}
                            </div>
                            <div
                              style={{
                                display: "block",
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                              }}
                              className="result_listItem_address fs-12px text-gray-600"
                              data-divide={listItem.divide}
                              data-name={listItem.name}
                              data-address={listItem.address}
                            >
                              {listItem.address}
                            </div>
                          </div>
                        </div>
                      </li>
                    </div>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
        <div
          className={
            (useResultContext().detailFacilityId.length === 0
              ? "d-none "
              : "d-block ") + "search_form_right col-sm-8 "
          }
          style={{ marginTop: "40px" }}
        >
          {/* <div className="search_form_result row">
              <div className="col-md-5">
                <label className="form-label text-gray-500" htmlFor="시설물명">
                  시설물명
                </label>
                <div className="col form-control">
                  {projectDivide} - {projectName}
                </div>
              </div>
            </div> */}
          <div className="supervision">
            <button className="btn btn-info" onClick={handleAddProspecter}>
              조사자 정보 추가
            </button>
            {createCard.map((item, i) => (
              <div key={i}>{item}</div>
            ))}
            <div className="col">
              <table className="table-border table">
                <thead>
                  <tr>
                    <th>No.</th>
                    <th>조사자 이름</th>
                    <th>휴대폰 번호</th>
                    <th>아이디</th>
                    <th>비밀번호</th>
                  </tr>
                </thead>
                <tbody>
                  {returnList.length !== 0 ? (
                    returnList.map((item, i) => {
                      return (
                        <tr key={i}>
                          <td>{i + 1}</td>
                          <td>{item.name}</td>
                          <td>{item.phone}</td>
                          <td>{item.id}</td>
                          <td>{item.pw}</td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr className="text-center" colSpan="5">
                      등록된 조사자가 존재하지 않습니다.
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Supervision;
