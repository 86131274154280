// import { URL } from "../config/app-settings";

export default async function fetchData(url, method, params, data) {
    const token = "bearer " + localStorage.getItem("token");
    let result = [];
    if (method === "GET") {
        let get_url = url + "?" + params[0] + "=" + data[0];
        for (let i=0; i < params.length; i++) {
            get_url = get_url + "&" + params[i+1] + "=" + data[i+1];
        }
        await fetch(
            get_url,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json;charset=UTF-8",
                Authorization: token,
              },
            }
          )
            .then((res) => res.json())
            .then((res) => {
                result.push(res);
            });
    }
    else if (method === "POST") {
        let post_url = url;
        await fetch(post_url, {
            method: "POST",
            headers: {
              Authorization: token,
            },
            body: data,
          }).then((res) => res.json())
          .then(res => {
            result.push(res);
          });
    }
    return result;
}