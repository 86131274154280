import React, { useCallback, useState } from "react";
import DaumPostCode from "react-daum-postcode";
import { useDaumPostcodePopup } from "react-daum-postcode";
import styles from "../../assets/css/fms.module.css";
import { useResultContext } from "../../Contexts/LayoutContext";
import { UpImg, formData_upper } from "../upImg.js";
import { UnderImg, formData_under } from "../underImg.js";
import { PanoramaImg, formData as panorama } from "../panoramaImg.js";
import { BuildingImg, formData as position } from "../buildingImg.js";
import CircularProgress from "@mui/material/CircularProgress";

const URL = process.env.REACT_APP_BACKEND_HOST;
const API = URL + "/project/";
const API_BC = URL + "/project/create/project";
const API_IMG = URL + "/project/post_building_img";
const API_PANO = "panorama";
const API_POSI = "position";

const Navigate = () => (
  <>
    <div className={`nav-wizards-container ${styles.nav_wizards_container}`}>
      <nav className="nav nav-wizards-2 mb-3">
        <div className="nav-item w-200px">
          <div className="nav-link active" id="goNext1">
            <div className="nav-title fw-lighter text-center">
              1. 시설물 정보 입력
            </div>
          </div>
        </div>
        <div className="nav-item w-200px">
          <div className="nav-link" id="goNext2">
            <div className="nav-title fw-lighter text-center">2. 도면첨부</div>
          </div>
        </div>
      </nav>
    </div>
  </>
);

export { Navigate };

function Write() {
  const open = useDaumPostcodePopup();

  const { detailFacilityId } = useResultContext();
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({}), []);
  const [isDaumPost, setIsDaumPost] = useState(false);
  const [buildingCode, setBuildingCode] = useState("");
  const [buildingName, setBuildingName] = useState("");
  const [active, setActive] = useState("");
  const [bcode, setBcode] = useState("");
  const [response, setResponse] = useState(false);
  const [projectDiv, setProjectDiv] = useState({
    facilitydivide: "",
  });
  const [input, setInput] = useState({
    facilityid: "",
    facilityname: "",
    facilityarea2: "",
    facilityarea1: "",
    facilityfloorup: "",
    facilityfloordown: "",
    facilityaddress: "",
    facilitystructure: "",
    facilityuse: "",
    facilityowner: "",
    facilityetc: "",
    facilitydate: "",
    zoneCode: "",
    isRegister: false,
    register: [],
    errors: {
      facilityid: "",
      facilityname: "",
      facilityarea2: "",
      facilityarea1: "",
      facilityfloorup: "",
      facilityfloordown: "",
      facilityaddress: "",
      facilitystructure: "",
      facilityuse: "",
      facilityowner: "",
      facilityetc: "",
      facilitydate: "",
    },
  });

  const {
    facilityfloorup,
    facilityfloordown,
    facilityaddress,
    facilityowner,
    facilityetc,
  } = input;
  const goNext1 = document.getElementById("goNext1");
  const goNext2 = document.getElementById("goNext2");
  const fmsForm1 = document.getElementById("FmsFirst");
  const fmsForm2 = document.getElementById("FmsSecond");

  const handleChange = (name, value) => {
    setInput({
      ...input,
      [name]: value,
    });
  };

  const addActiveClass = (e) => {
    let clicked = e.currentTarget.id;

    if (active === clicked) {
      setActive({ active: "" });
    } else {
      setActive({ active: clicked });
    }
  };

  const handleOnChange = (e) => {
    const newName = e.target.name;
    const curVal = e.target.value;

    setProjectDiv({
      [newName]: curVal,
    });
  };

  const handleOpenPost = () => {
    // setIsDaumPost(!isDaumPost);
    open({ onComplete: handleAddress });
  };

  const handleAddress = (data) => {
    let AllAddress = data.address;
    let extraAddress = "";
    let zoneCodes = data.zonecode;

    if (data.addressType === "R") {
      if (data.bname !== "") {
        extraAddress += data.bname;
      }
      if (data.buildingName !== "") {
        extraAddress +=
          extraAddress !== "" ? `, ${data.buildingName}` : data.buildingName;
      }
      AllAddress += extraAddress !== "" ? ` (${extraAddress})` : "";
    }
    setInput({
      ...input,
      facilityaddress: AllAddress,
      zoneCode: zoneCodes,
    });
    setBcode(data.bcode);
    setBuildingCode(data.buildingCode);
    setBuildingName(data.buildingName);
  };

  const handleCreateProject = (e) => {
    e.preventDefault();

    if (facilityaddress === "") {
      alert("시설물 주소는 필수항목입니다.");
    } else if (facilityowner === "") {
      alert("대표자명은 필수항목입니다.");
    } else if (projectDiv.facilitydivide === "") {
      alert("프로젝트 구분은 필수항목입니다.");
    } else {
      const token = "bearer " + sessionStorage.getItem("token");

      fetch(
        API_BC +
          "?buildingCode=" +
          buildingCode +
          "&inspectType=" +
          projectDiv.facilitydivide +
          "&buildingOwner=" +
          facilityowner +
          "&etc=" +
          facilityetc +
          "&buildingName=" +
          buildingName,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json;charset=UTF-8",
            Authorization: token,
          },
        }
      )
        .then(function (res) {
          if (res.ok) {
            return res.json();
          } else {
            alert(
              "알수없는 오류가 발생하였습니다. 새로고침 후 다시 시도해주세요."
            );
            throw new Error("Something went wrong.");
          }
        })
        .then(function (res) {
          setResponse(true);

          // if (res.status === 500) {
          //   alert(
          //     "500 error.자동 등록불가한 건물입니다. 수동등록 페이지로 넘어갑니다"
          //   );
          //   window.location.replace("/fms/manualwrite");
          // }

          setInput({
            facilityfloorup: res.up,
            facilityfloordown: res.down,
            facilityid: res.id,
          });

          if (Object.prototype.toString.call(res) === "[object Array]") {
            alert(
              "집합 건물 등록이 완료 되었습니다.\n 사진과 도면은 조회 -> 수정 단계에서 가능합니다.’."
            );
            window.location.replace("/fms");
          }

          if (Array.isArray(res)) {
            alert(res[0].message);
          } else {
            alert(res.message);
          }
        })
        .catch(function (error) {
          // catch

          alert("자동 등록불가한 건물입니다. 수동등록 페이지로 넘어갑니다");
          window.location.replace("/fms/manualwrite");
        });

      // .then((res) => res.json())
      // .then((res) => {
      //   console.log(res);

      //   if (res.status === 500) {
      //     alert(
      //       "500 error.자동 등록불가한 건물입니다. 수동등록 페이지로 넘어갑니다"
      //     );
      //     window.location.replace("/fms/manualwrite");
      //   }

      //   setInput({
      //     facilityfloorup: res.up,
      //     facilityfloordown: res.down,
      //     facilityid: res.id,
      //   });

      //   console.log(Array.isArray(res));
      //   if (Object.prototype.toString.call(res) === "[object Array]") {
      //     alert(
      //       "집합 건물 등록이 완료 되었습니다.\n 해당 도면과 시설물 전경, 위치사진은 \n 정보 조회 후 수정 단계에서\n 가능합니다."
      //     );
      //     window.location.replace("/fms");
      //   }

      //   if (Array.isArray(res)) {
      //     alert(res[0].message);
      //   } else {
      //     alert(res.message);
      //   }
      // });
      // .catch(error => console.log('error', error));
      forceUpdate();
      goNext1.classList.add("active");
      fmsForm1.classList.add("d-none");
      fmsForm2.classList.remove("d-block");
      fmsForm2.classList.remove("d-none");
      goNext2.classList.add("active");
    }
  };

  const handleCreateImg = async () => {
    const token = "bearer " + sessionStorage.getItem("token");

    for (let i of formData_upper) {
      fetch(API_IMG, {
        method: "POST",
        headers: {
          Authorization: token,
        },
        body: i,
      }).then((res) => res.text());
    }

    for (let i of formData_under) {
      fetch(API_IMG, {
        method: "POST",
        headers: {
          Authorization: token,
        },
        body: i,
      }).then((res) => res.text());
    }

    fetch(API + API_PANO, {
      method: "POST",
      headers: {
        Authorization: token,
      },
      body: panorama,
    }).then((res) => res.text());

    fetch(API + API_POSI, {
      method: "POST",
      headers: {
        Authorization: token,
      },
      body: position,
    }).then((res) => res.text());

    alert("도면저장에 성공하였습니다.");
    // window.location.replace("/fms");
  };

  const prev = (e) => {
    e.preventDefault();

    goNext1.classList.add("active");
    fmsForm1.classList.remove("d-none");
    fmsForm2.classList.add("d-block");
    fmsForm2.classList.remove("d-block");
    fmsForm2.classList.add("d-none");
    goNext2.classList.remove("active");
  };

  const modalStyle = {
    position: "absolute",
    width: "500px",
    height: "400px",
    left: "250px",
    zIndex: "100",
    border: "1px solid #000000",
    overflow: "hidden",
  };

  return (
    <div
      id="AddBuildingWrap"
      className="col-sm-8 m-auto AddBuildingWrap"
      onClick={addActiveClass}
    >
      <button
        type="button"
        className={`
          btn btn-outline-primary d-block fs-16px`}
        onClick={() => window.location.replace("/fms")}
      >
        시설물 목록으로 돌아가기
      </button>
      <div className="fs-15px m-auto mt-20px">
        <Navigate />
        <form id="FmsFirst" className={styles.newFmsFirst}>
          <div id="createFmsFirst">
            <div className="d-block">
              <div className={`${styles.tab_group}`}>
                <ul className={`${styles.select_task_division}`}>
                  <div className="m-0">
                    과업선택<span className="text-red">*</span>
                  </div>
                  <li className={styles.form_check}>
                    <input
                      className={`form-check-input ${styles.form_check_input}`}
                      type="radio"
                      onChange={handleOnChange}
                      id="안전점검"
                      defaultValue="안전점검"
                      name="facilitydivide"
                      checked={projectDiv.facilitydivide === "안전점검"}
                    />
                    <label
                      className="form-check-label col-form-label p-0"
                      htmlFor="안전점검"
                    >
                      안전점검
                    </label>
                  </li>
                  <li className={styles.form_check}>
                    <input
                      className={`form-check-input ${styles.form_check_input}`}
                      type="radio"
                      onChange={handleOnChange}
                      id="안전진단"
                      defaultValue="안전진단"
                      name="facilitydivide"
                      checked={projectDiv.facilitydivide === "안전진단"}
                    />
                    <label
                      className="form-check-label col-form-label p-0"
                      htmlFor="안전진단"
                    >
                      안전진단
                    </label>
                  </li>
                  <li className={styles.form_check}>
                    <input
                      className={`form-check-input ${styles.form_check_input}`}
                      type="radio"
                      onChange={handleOnChange}
                      id="정밀안전진단"
                      defaultValue="정밀안전진단"
                      name="facilitydivide"
                      checked={projectDiv.facilitydivide === "정밀안전진단"}
                    />
                    <label
                      className="form-check-label col-form-label p-0"
                      htmlFor="정밀안전진단"
                    >
                      정밀안전진단
                    </label>
                  </li>
                  <li className={styles.form_check}>
                    <input
                      className={`form-check-input ${styles.form_check_input}`}
                      type="radio"
                      onChange={handleOnChange}
                      id="하자진단"
                      defaultValue="하자진단"
                      name="facilitydivide"
                      checked={projectDiv.facilitydivide === "하자진단"}
                    />
                    <label
                      className="form-check-label col-form-label p-0"
                      htmlFor="하자진단"
                    >
                      하자진단
                    </label>
                  </li>
                  <li className={styles.form_check}>
                    <input
                      className={`form-check-input ${styles.form_check_input}`}
                      type="radio"
                      onChange={handleOnChange}
                      id="기타"
                      defaultValue="기타"
                      name="facilitydivide"
                      checked={projectDiv.facilitydivide === "기타"}
                    />
                    <label
                      className="form-check-label col-form-label p-0"
                      htmlFor="기타"
                    >
                      기타
                    </label>
                  </li>
                </ul>
              </div>
              <div className="row mb-3">
                <label
                  className={`form-label col-form-label col-md-2 offset-md-1`}
                >
                  대표자명<span className="text-red">*</span>
                </label>
                <div className="col-md-8">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="예) 홍길동"
                    value={facilityowner}
                    name="facilityowner"
                    onChange={(e) =>
                      handleChange("facilityowner", e.target.value)
                    }
                  />
                </div>
              </div>
              <div className="row mb-3">
                <label
                  className={`form-label col-form-label col-md-2 offset-md-1`}
                >
                  시설물 주소<span className="text-red">*</span>
                </label>
                <div className="col-md-8">
                  <div className="modalCell col">
                    <div className="cellFirst d-flex justify-content-around">
                      {isDaumPost ? (
                        <>
                          <DaumPostCode
                            onComplete={handleAddress}
                            autoClose
                            style={modalStyle}
                            isDaumPost={isDaumPost}
                          />
                        </>
                      ) : null}
                      <button
                        type="button"
                        className="btn btn-default"
                        // onClick={() => setIsDaumPost(!isDaumPost)}
                        onClick={handleOpenPost}
                      >
                        <span>주소 검색</span>
                      </button>
                      <div className="address col-10">
                        <div
                          className="form-control fs-13p"
                          style={{ height: "34px" }}
                          readOnly
                        >
                          {facilityaddress}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mb-3">
                <label
                  className={`form-label col-form-label col-md-2 offset-md-1`}
                >
                  비고
                </label>
                <div className="col-md-8">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="내용을 입력하세요."
                    value={facilityetc}
                    name="facilityetc"
                    onChange={(e) =>
                      handleChange("facilityetc", e.target.value)
                    }
                  />
                </div>
              </div>
              <button
                className="btn btn-info btn-lg float-end"
                onClick={handleCreateProject}
                id="result"
              >
                시설물 정보 저장
              </button>
            </div>
          </div>
        </form>
        <div id="FmsSecond" className="d-none">
          {response ? (
            <div id="createFmsSecond">
              <div className="row mb-3 position-relative">
                <label className={`form-label col-form-label col-md-3`}>
                  시설물 위치사진
                </label>
                <div className="col-md-9">
                  <BuildingImg facilityid={input.facilityid} />
                </div>
              </div>
              <div className="row mb-3 position-relative">
                <label className={`form-label col-form-label col-md-3`}>
                  시설물 전경사진
                </label>
                <div className="col-md-9">
                  <PanoramaImg facilityid={input.facilityid} />
                </div>
              </div>
              <div className="row">
                <div>
                  <label className="form-label col-form-label w-100px">
                    도면첨부
                  </label>
                  <span className="fas fa-exclamation">
                    .jpg 또는 jpeg 형식의 파일만 업로드 할 수 있습니다.(최대
                    5MB를 권장합니다.)
                    {/* (가로가 긴 이미지만 사용해 주세요.) */}
                  </span>
                </div>

                <ul className="col">
                  <UnderImg
                    floor={facilityfloordown}
                    facilityid={input.facilityid}
                  />
                </ul>
                <ul className="col">
                  <li>
                    <UpImg
                      floor={facilityfloorup}
                      facilityid={input.facilityid}
                    />
                  </li>
                </ul>
              </div>
              <button
                className="btn btn-info btn-lg float-end"
                onClick={handleCreateImg}
              >
                도면 저장
              </button>
            </div>
          ) : (
            <div className="disablePage">
              <CircularProgress
                className="d-block m-auto"
                style={{ marginTop: "150px" }}
              />
              <div className="progressText">
                <p>건물 층수를 계산중 입니다.</p>
                <p>잠시만 기다려주세요.</p>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Write;
