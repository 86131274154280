import React, { useState } from "react";
import DatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";
import ko from "date-fns/locale/ko";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";

registerLocale("ko", ko);
const token = "bearer " + sessionStorage.getItem("token");
const URL = process.env.REACT_APP_BACKEND_HOST;
const API = URL + "/members";
const API_UP = "/update";
const API_INSPEC = "/inspector";

const Prosedit = ({ location, history }) => {
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [ap, setAp] = useState({
    id: location.state.id,
    idx: location.state.idx,
    name: location.state.name,
    phone: location.state.phone,
    project: location.state.project,
    project_id: location.state.project_id,
    pw: location.state.pw,
    startdate: location.state.startdate,
    enddate: location.state.enddate,
    active: location.state.active,
  });

  const handleInput = (e) => {
    e.preventDefault();
    const newName = e.target.name;
    const curVal = e.target.value;

    setAp({
      ...ap,
      [newName]: curVal,
    });
  };

  const handleInputT = (e) => {
    e.preventDefault();
    const curVal = e.target.value;
    const onlyNum = curVal.replace(/[^0-9]/g, "");

    setAp({
      ...ap,
      phone: onlyNum,
    });
  };

  const handleUpdate = () => {
    const formData = new FormData();

    if (
      moment(endDate).format("yyyyMMDD") === "Invalid date" ||
      moment(startDate).format("yyyyMMDD") === "Invalid date"
    ) {
      formData.append("startdate", moment(ap.startdate).format("yyyyMMDD"));
      formData.append("enddate", moment(ap.enddate).format("yyyyMMDD"));
    } else {
      formData.append("startdate", moment(startDate).format("yyyyMMDD"));
      formData.append("enddate", moment(endDate).format("yyyyMMDD"));
    }

    if (ap.name === "") {
      alert("이름을 입력하세요");
    } else if (ap.phone === "") {
      alert("번호를 입력하세요");
    } else {
      formData.append("id", ap.idx);
      formData.append("name", ap.name);
      formData.append("phone", ap.phone);

      fetch(API + API_UP + API_INSPEC, {
        method: "PATCH",
        headers: {
          Authorization: token,
        },
        body: formData,
      })
        .then((res) => {
          return res.json();
        })
        .then((res) => {
          if (res.message === "success") {
            alert("업데이트에 성공했습니다.");
            history.goBack();
          } else {
            alert("알수없는 오류가 발생했습니다. 다시 시도해주세요.");
          }
        });
    }
  };
  return (
    <div className="col-sm-8 mt-5">
      <table className="w-100">
        <thead>
          <tr>
            <th className="w-60px">상태</th>
            <th className="w-80px">조사자 이름</th>
            <th className="w-100px">휴대폰 번호</th>
            <th>아이디</th>
            <th>비밀번호</th>
            <th>조사기간</th>
            <th className="w-80px">수정</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="w-60px">
              {ap.active ? (
                <span className=" text-green">활성</span>
              ) : (
                <span className=" text-red">비활성</span>
              )}
            </td>
            <td>
              <input
                type="text"
                value={ap.name}
                onChange={handleInput}
                className="col form-control w-80px"
                name="name"
                maxLength={5}
                onKeyPress={(event) => {
                  if (!/[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
              />
            </td>
            <td>
              <input
                type="tel"
                value={ap.phone}
                maxLength="11"
                onChange={handleInputT}
                className="col form-control w-100px"
                name="phone"
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
              />
            </td>
            <td>{ap.id}</td>
            <td>{ap.pw}</td>
            <td>
              <DatePicker
                className="btn btn-outline-warning "
                selectsRange={true}
                startDate={startDate}
                endDate={endDate}
                onChange={(update) => {
                  setDateRange(update);
                }}
                locale="ko"
                dateFormat="yyyy.MM.dd"
                placeholderText={
                  moment(ap.startdate).format("yyyy.MM.DD") +
                  "~" +
                  moment(ap.enddate).format("yyyy.MM.DD")
                }
              />
            </td>
            <td>
              <button className="btn btn-purple w-80px" onClick={handleUpdate}>
                저장
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default Prosedit;
