import React from "react";
import { Collapse, CardBody } from "reactstrap";
import { CardWithText } from "./cardWithText";
import {
  crack_arr,
  arc_arr,
  conc_arr,
  iron_arr,
  carbon_arr,
  slope_arr,
  equipment_arr,
} from "./information";
import styles from "../../assets/css/information.module.css";

class InformationTable extends React.Component {
  constructor(props) {
    super(props);

    this.newCard = [];
    this.newRow = [];

    this.state = {
      cardZone: "",
      searchInput: "",
      facilityid: this.props.facilityid,
      nowPill: "",
      collapse: [
        { id: 1, collapse: true },
        { id: 2, collapse: true },
      ],
      totalUP: this.props.totalUP,
      totalDOWN: this.props.totalDOWN,
    };
    this.toggleCollapse = this.toggleCollapse.bind(this);
  }

  toggleCollapse(index) {
    var newArray = [];

    for (let collapseObj of this.state.collapse) {
      newArray.push(collapseObj);
    }

    this.setState({
      collapse: newArray,
    });
  }

  render() {
    const nowPill = this.props.activePill;
    return (
      <>
        {nowPill === "1" ? (
          <>
            {crack_arr.map((crack, i) => {
              return this.props.select === crack_arr[i].floor ? (
                <tr key={i}>
                  <td>{crack.index}</td>
                  <td>
                    {crack_arr[i].floor > 0
                      ? "지상 " + crack_arr[i].floor + " 층"
                      : "지하 " + Math.abs(crack_arr[i].floor) + " 층"}
                  </td>
                  <td>{crack_arr[i].zone}</td>
                  <td>{crack_arr[i].position}</td>
                  <td>{crack_arr[i].type}</td>
                  <td>{crack_arr[i].crack_size}</td>
                  <td>{crack_arr[i].crack_length}</td>
                  <td>{crack_arr[i].crack_box_size}</td>
                  <td>{crack_arr[i].etc}</td>
                </tr>
              ) : null;
            })}
          </>
        ) : null}

        {nowPill === "2" ? (
          <>
            {conc_arr.map((conc, i) => {
              return this.props.select === conc_arr[i].floor ? (
                <tr key={i}>
                  <td>{conc.index}</td>
                  <td>
                    {conc_arr[i].floor > 0
                      ? "지상 " + conc_arr[i].floor + " 층"
                      : "지하 " + Math.abs(conc_arr[i].floor) + " 층"}
                  </td>
                  <td>{conc_arr[i].c_zone}</td>
                  <td>{conc_arr[i].c_position}</td>
                  <td>추정압축강도</td>
                  <td>{conc_arr[i].c_etc}</td>
                </tr>
              ) : null;
            })}
          </>
        ) : null}
        {nowPill === "3" ? (
          <>
            {iron_arr.map((iron, i) => {
              return this.props.select === iron_arr[i].floor ? (
                <tr key={i}>
                  <td>{iron.index}</td>
                  <td>
                    {iron_arr[i].floor > 0
                      ? "지상 " + iron_arr[i].floor + " 층"
                      : "지하 " + Math.abs(iron_arr[i].floor) + " 층"}
                  </td>
                  <td>{iron_arr[i].i_zone}</td>
                  <td>{iron_arr[i].i_position}</td>
                  <td>{iron_arr[i].i_etc}</td>
                </tr>
              ) : null;
            })}
          </>
        ) : null}

        {nowPill === "4" ? (
          <>
            {arc_arr.map((arc, i) => {
              return this.props.select === arc_arr[i].floor ? (
                <tr key={i}>
                  <td>{arc.index}</td>
                  <td>
                    {arc_arr[i].floor > 0
                      ? "지상 " + arc_arr[i].floor + " 층"
                      : "지하 " + Math.abs(arc_arr[i].floor) + " 층"}
                  </td>
                  <td>{arc_arr[i].a_zone}</td>
                  <td>{arc_arr[i].a_position}</td>
                  <td>{arc_arr[i].aw_size}</td>
                  <td>{arc_arr[i].ah_size}</td>
                  <td>{arc_arr[i].a_etc}</td>
                </tr>
              ) : null;
            })}
          </>
        ) : null}
        {nowPill === "5" ? (
          <>
            {slope_arr.map((slope, i) => {
              return this.props.select === slope_arr[i].floor ? (
                <tr key={i}>
                  <td>{slope.index}</td>
                  <td>
                    {slope_arr[i].floor > 0
                      ? "지상 " + slope_arr[i].floor + " 층"
                      : "지하 " + Math.abs(slope_arr[i].floor) + " 층"}
                  </td>
                  <td>{slope_arr[i].s_zone}</td>
                  <td>{slope_arr[i].s_position}</td>
                  <td>{slope_arr[i].s_num1}</td>
                  <td>{slope_arr[i].s_num2}</td>
                  <td>{slope_arr[i].s_num3}</td>
                  <td>{slope_arr[i].s_num4}</td>
                  <td>{slope_arr[i].s_etc}</td>
                </tr>
              ) : null;
            })}
          </>
        ) : null}
        {nowPill === "6" ? (
          <>
            {carbon_arr.map((carbon, i) => {
              return this.props.select === carbon_arr[i].floor ? (
                <tr key={i}>
                  <td>{carbon.index}</td>
                  <td>
                    {carbon_arr[i].floor > 0
                      ? "지상 " + carbon_arr[i].floor + " 층"
                      : "지하 " + Math.abs(carbon_arr[i].floor) + " 층"}
                  </td>
                  <td>{carbon_arr[i].zone}</td>
                  <td>{carbon_arr[i].position}</td>
                  <td>{carbon_arr[i].ca_num}</td>
                  <td>{carbon_arr[i].etc}</td>
                </tr>
              ) : null;
            })}
          </>
        ) : null}
        {nowPill === "7" ? (
          <>
            <div className={`d-flex ${styles.fake}`}>
              <div className="text-center" style={{ width: "200px" }}>
                항목
              </div>
              <div className="text-center" style={{ width: "150px" }}>
                검사방법
              </div>
              <div className="text-center" style={{ width: "220px" }}>
                사용장비
              </div>
              <div className="text-center" style={{ width: "200px" }}>
                사진
              </div>
              <div className="text-center" style={{ width: "80px" }}>
                등록/삭제
              </div>
            </div>
            <Collapse isOpen={true}>
              <CardBody>
                {this.newCard}
                {equipment_arr.map((equipment, i) => {
                  return (
                    <>
                      <CardWithText
                        nowPill={nowPill}
                        facilityid={this.props.facilityid}
                        e_id={equipment.e_id}
                        e_name={equipment.e_name}
                        e_subject={equipment.e_subject}
                        e_method={equipment.e_method}
                        e_file_id={equipment.e_file_id}
                      />
                    </>
                  );
                })}
              </CardBody>
            </Collapse>
          </>
        ) : null}
      </>
    );
  }
}
export { InformationTable };
