import React from "react";
import { useResultContext } from "../Contexts/LayoutContext";

const formData_upper = [];

const UpImg = (props) => {
  const { detailFacilityId } = useResultContext();

  let floor = props.floor;
  let arr = [];

  for (let i = 0; i < floor; i++) {
    arr[i] = [i];
  }

  const formData = new FormData();

  const handleUploadImg = (e) => {
    if (
      e.target.files[0].type === "image/jpg" ||
      e.target.files[0].type === "image/jpeg" ||
      e.target.files[0].type === "image/png"
    ) {
      formData.append("img", e.target.files[0]);
      formData.append("floor", String(Number(e.target.name) + 1));

      if (props.facilityid !== "") {
        formData.append("facilityid", props.facilityid);
      } else if (detailFacilityId) {
        formData.append("facilityid", detailFacilityId);
      }
      formData_upper.push(formData);

      for (var key of formData.entries()) {
        console.log(key[0] + ", " + key[1]);
      }
    } else {
      alert("jpg, jpeg의 확장자 이미지만 등록할 수 있습니다.");
      e.target.value = null;
    }
  };

  const result = [];

  for (let i = 0; i < arr.length; i++) {
    let count = Number(arr[i]);
    result.push(
      <li className="d-inline-flex" key={arr[i]}>
        <div style={{ lineHeight: "34px", whiteSpace: "nowrap" }}>
          지상 {count + 1} 층
        </div>
        <input
          type="file"
          multiple
          accept="image/jpeg, image/jpg, image/png"
          className="p-4px"
          name={i}
          id={"upfile" + i}
          onChange={(e) => handleUploadImg(e)}
          key={arr[i]}
        />
      </li>
    );
  }

  return <>{result}</>;
};

export { UpImg, formData_upper };
