import React from "react";
import { Link, withRouter } from "react-router-dom";
import { Panel, PanelBody } from "../../components/panel/panel.jsx";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import "@ckeditor/ckeditor5-build-classic/build/translations/ko";

function CsWrite() {
  const submit = (e) => {
    const submitBtn = document.getElementById("goNext1");

    submitBtn.classList.add("completed");
  };
  return (
    <div>
      <div className="row mb-3">
        <Panel>
          <PanelBody>
            <div className="mb-3">
              <label
                className="form-label col-form-label text-blue-700"
                style={{ fontSize: "2rem", fontWeight: "normal" }}
              >
                문의글 작성
              </label>
            </div>
            <CKEditor
              className="mb-5"
              config={{
                language: "ko",
              }}
              editor={ClassicEditor}
              data="<p>내용을 작성하세요.</p>"
              onReady={(editor) => {
                editor.editing.view.change((writer) => {
                  writer.setStyle(
                    "height",
                    "400px",
                    editor.editing.view.document.getRoot()
                  );
                });
              }}
              onChange={(event, editor) => {
                const data = editor.getData();
              }}
              onBlur={(event, editor) => {
                // console.log("Blur.", editor);
              }}
              onFocus={(event, editor) => {
                // console.log("Focus.", editor);
              }}
            />
            <button
              type="button"
              onClick={submit}
              className="btn btn-primary float-end mt-5px"
            >
              등록하기
            </button>
          </PanelBody>
        </Panel>
      </div>
    </div>
  );
}

export { CsWrite };
