function selectColor(type) {
    let color = "red";
    if (type === 1) {
      color = "red";  //red
    } else if (type === 2) {
      color = "#7F827F"; //gray
    } else if (type === 3) {
      color = "#6CC56B"; //green
    } else if (type === 4) {
      color = "#5882FA";   // blue
    } else if (type === 5) {
      color = "#AC58FA"; //purple
    } else if (type === 6) {
      color = "#D7D448"; //yellow
    }
    return color;
  };

function drawArc(ctx, x, y, size) {
  ctx.arc(x, y, size, 0, Math.PI * 2, true);
  return ctx;
};

function drawTriangle(ctx, x, y, size) {
  ctx.moveTo(x, y - (size / 2) * Math.sqrt(3));
  ctx.lineTo(x + size, y + (size / 2) * Math.sqrt(3));
  ctx.lineTo(x - size, y + (size / 2) * Math.sqrt(3));
  return ctx;
};

function drawRectangle (ctx, x, y, size) {
  ctx.fillRect(x - size, y - size, size * 2, size * 2);
  return ctx;
};

function drawHexagon(ctx, x, y, size) {
  ctx.moveTo(x - size, y - size * 2);
  ctx.lineTo(x + size, y - size * 2);
  ctx.lineTo(x + size * 2, y);
  ctx.lineTo(x + size, y + size * 2);
  ctx.lineTo(x - size, y + size * 2);
  ctx.lineTo(x - size * 2, y);
  // ctx.lineTo(x - size, y - size * 2);
  return ctx;
};

function drawStar(ctx, cx, cy, spikes, innerRadius) {
  let rot = (Math.PI / 2) * 3;
  let x = cx;
  let y = cy;
  const outerRadius = innerRadius * 2;
  const step = Math.PI / spikes;
  ctx.strokeSyle = "#000";
  ctx.beginPath();
  ctx.moveTo(cx, cy - outerRadius);
  for (let i = 0; i < spikes; i++) {
    x = cx + Math.cos(rot) * outerRadius;
    y = cy + Math.sin(rot) * outerRadius;
    ctx.lineTo(x, y);
    rot += step;
    x = cx + Math.cos(rot) * innerRadius;
    y = cy + Math.sin(rot) * innerRadius;
    ctx.lineTo(x, y);
    rot += step;
  }
  ctx.lineTo(cx, cy - outerRadius);
  return ctx;
}

function drawDiamond(ctx, x, y, size) {
  ctx.moveTo(x, y - size * 1.5);
  ctx.lineTo(x + size, y);
  ctx.lineTo(x, y + size * 1.5);
  ctx.lineTo(x - size, y);
  return ctx;
}

export {selectColor, drawArc, drawDiamond, drawHexagon, drawRectangle, drawStar, drawTriangle}