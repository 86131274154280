import { React } from "react";
import { Link } from "react-router-dom";
import DropdownProfile from "./dropdown/profile.jsx";
import { AppSettings } from "./../../config/app-settings.js";

function Header() {
  return (
    <AppSettings.Consumer>
      {({ appTopMenu, appSidebarNone }) => (
        <div id="header" className={"app-header "}>
          <div className="navbar-header">
            <Link
              to="/home"
              className="navbar-brand d-block"
              style={{ height: "70px" }}
            >
              <div
                style={{
                  fontSize: "30px",
                  fontWeight: "600",
                }}
              >
                건축시설물 안전점검진단 플랫폼 V1.0
              </div>
              <div
                style={{
                  fontSize: "14px",
                  lineHeight: "15px",
                }}
              >
                Simple, Innovation, Systematic.
              </div>
            </Link>

            {appTopMenu && !appSidebarNone && (
              <button type="button" className="">
                <span className="fa-stack fa-lg text-inverse">
                  <i className="far fa-square fa-stack-2x"></i>
                  <i className="fa fa-cog fa-stack-1x"></i>
                </span>
              </button>
            )}
          </div>
          <div className="navbar-nav">
            <DropdownProfile />
          </div>
        </div>
      )}
    </AppSettings.Consumer>
  );
  // }
}

export default Header;
