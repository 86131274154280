import React from "react";

export const AppSettings = React.createContext();
// export const URL = "http://localhost:8000/";
// export const DEV_URL = "";
// export const URL = "http://175.126.176.46:8000/";
// export const URL = "http://www.dyscopekorea.com/";

export const URL = process.env.REACT_APP_BACKEND_HOST;
// export const URL = "";
export const CRACK_IMG_PATH = "uploadImg/crackMaskedImg/";
export const ARC_IMG_PATH = "uploadImg/ArcdataImg/";
export const CARBON_IMG_PATH = "uploadImg/CarbonImg/";
export const CONC_IMG_PATH = "uploadImg/ConcreatImg/";
export const IRON_IMG_PATH = "uploadImg/IronImg/";
export const SLOPE_IMG_PATH = "uploadImg/SlopeImg/";
export const EQUIPMENT_IMG_PATH = "uploadImg/EquipmentImg/";
export const REPORT_UPLOAD = "uploadImg/uploadFile/report/";

//콘크리트 강도조사 조사분석결과 전송
export const COMPANY_HWP_SERVER = "http://203.237.125.153:8888/";

//보고서 post
export const REPORT_URL_PATH = "report/make/result";
