import React from 'react'
import { Route } from 'react-router-dom'
import { Redirect } from 'react-router'

const PrivateRoute = ({ component: Component, ...rest }) => {
  const token = localStorage.getItem('token')
  return (
    <Route
      {...rest}
      render={props =>
        token === null ? <Redirect to="/login" /> : <Component {...props} />
      }
    />
  )
}

export default PrivateRoute
