import React from "react";
import { result_prj, prj_list } from "../../pages/facilitylayout/layout";
import { TabContent, TabPane, Nav, NavItem, NavLink, Card } from "reactstrap";
import classnames from "classnames";
import { InformationFloorPlan } from "./informationFloorPlan";
import styles from "../../assets/css/information.module.css";
import ResearchCard from "./researchCard";
import { Filter } from "./filter";
// import Filter from "../informaiton/filter";
import { InformationTable } from "./informationTable";
import guide from "../../assets/img/guide2.jpg";

const URL = process.env.REACT_APP_BACKEND_HOST;
const token = "bearer " + sessionStorage.getItem("token");

let API = "";
let API2 = "";
const GET_DETAIL = URL + "/project/view_all_data?";

let crack_arr = [];
let arc_arr = [];
let conc_arr = [];
let iron_arr = [];
let carbon_arr = [];
let slope_arr = [];
let equipment_arr = [];
let totalDOWN = [];
let totalUP = [];
let totalElevation = [];

let img_list = [];
let img = {
  url: "",
  floor: "",
};

// class PositionResultNone extends React.Component {
//   render() {
//     return (
//       <>
//         <div>등록된 조사 결과가 존재하지 않습니다. </div>
//       </>
//     );
//   }
// }
class PlanResultNone extends React.Component {
  render() {
    return (
      <div className={`${styles.if_plan_none}`}>
        <div>등록된 도면이 존재하지 않습니다. </div>
      </div>
    );
  }
}
class SelectOptionWrap extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      totalUP: "",
      totalDOWN: "",
      totalElevation: "",
      searchBuilding: this.props.searchBuilding,
      filter: "",
      selected: 1,
    };
    this.handleFilterChange = this.handleFilterChange.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    console.log(prj_list);
    for (let i = 0; i < prj_list.length; i++) {
      if (prj_list[i]["facilityid"] === nextProps.searchBuilding) {
        this.setState({
          totalDOWN: prj_list[i].facilityfloordown,
          totalUP: prj_list[i].facilityfloorup,
          totalElevation: prj_list[i].facilityelevation,
        });
      }
    }

    totalDOWN = [];
    totalUP = [];
    totalElevation = [];
    for (let i = 0; i < this.state.totalDOWN; ++i) {
      totalDOWN.push(i + 1);
    }
    for (let i = 0; i < this.state.totalUP; ++i) {
      totalUP.push(i + 1);
    }
    for (let i = 0; i < this.state.totalElevation; ++i) {
      totalElevation.push(999 - i);
    }
  }
  handleFilterChange = (e) => {
    console.log(e.target.value)
    this.setState({
      filter: e.target.value,
    });
    this.props.filter(e.target.value);
  };

  render() {
    return (
      <>
        <div className={`${styles.form_select}`}>
          <select
            name="filter"
            id="filter"
            className={`form-select w-400px`}
            onChange={this.handleFilterChange}
            defaultValue={this.state.selected}
          >
            {totalUP.map((i) => {
              return <Filter upFloor={i} key={i} />;
            })}
            {totalDOWN.map((i) => {
              return <Filter downFloor={i} key={i} />;
            })}
            {totalElevation.map((i) => {
              return <Filter totalElevation={i} key={i} />;
            })}
          </select>
        </div>
      </>
    );
  }
}
class Information extends React.Component {
  constructor(props) {
    super(props);

    this.newCard = [];

    this.state = {
      ID: this.props.ID,

      crack_result_zone: "",
      // crack_result: {
      //   zone: "",
      //   crack_size: "",
      //   floor: "",
      //   crack_box_size: "",
      //   auto_analyze: "",
      //   crack_length: "",
      //   position: "",
      //   file_id: "",
      //   seq: "",
      //   type: "",
      //   etc: "",
      //   report: "",
      //   index: "",
      // },
      // arc_result: {
      //   a_zone: "",
      //   a_position: "",
      //   floor: "",
      //   aw_size: "",
      //   ah_size: "",
      //   a_file_id: "",
      //   a_etc: "",
      //   a_seq: "",
      //   report: "",
      //   index: "",
      // },
      // conc_result: {
      //   c_zone: "",
      //   c_position: "",
      //   c_etc: "",
      //   floor: "",
      //   c_inspectAge: "",
      //   c_hitAngle: "",
      //   c_num1: "",
      //   c_num2: "",
      //   c_num3: "",
      //   c_num4: "",
      //   c_num5: "",
      //   c_num6: "",
      //   c_num7: "",
      //   c_num8: "",
      //   c_num9: "",
      //   c_num10: "",
      //   c_num11: "",
      //   c_num12: "",
      //   c_num13: "",
      //   c_num14: "",
      //   c_num15: "",
      //   c_num16: "",
      //   c_num17: "",
      //   c_num18: "",
      //   c_num19: "",
      //   c_num20: "",
      //   c_file_id: "",
      //   c_seq: "",
      //   report: "",
      //   index: "",
      // },
      // iron_result: {
      //   i_zone: "",
      //   i_position: "",
      //   floor: "",
      //   i_etc: "",
      //   i_file_id: "",
      //   i_seq: "",
      //   result_img: "",
      //   report: "",
      //   index: "",
      // },
      // carbon_result: {
      //   ca_zone: "",
      //   ca_position: "",
      //   floor: "",
      //   ca_num: "",
      //   ca_etc: "",
      //   ca_file_id: "",
      //   ca_seq: "",
      //   report: "",
      //   index: "",
      // },
      // slope_result: {
      //   s_zone: "",
      //   s_position: "",
      //   floor: "",
      //   s_num1: "",
      //   s_num2: "",
      //   s_num3: "",
      //   s_num4: "",
      //   s_file_id: "",
      //   s_seq: "",
      //   s_etc: "",
      //   report: "",
      //   index: "",
      // },
      // equipment_result: {
      //   e_id: "",
      //   e_name: "",
      //   e_subject: "",
      //   e_method: "",
      //   e_file_id: "",
      // },
      researchKind: "",
      // getAddress: "",
      // getName: "",
      // facilityname: "",
      // cardZone: "",
      // searchInput: "",
      // project_name: "",
      // project_address: "",
      // facilityarea1: "",
      // facilityarea2: "",
      // facilitydate: "",
      // facilitydivide: "",
      // facilityetc: "",
      // facilityfloordown: "",
      // facilityfloorup: "",
      facilityid: result_prj,
      // facilityowner: "",
      // facilitystructure: "",
      // facilityuse: "",
      // 추가
      activeTab: "1",
      activePill: "1",
      activeViewTab: "10",
      totalDOWN: "",
      totalUP: "",
      totalElevation: "",
      select: 1,
      count: "",
      indexCount: 0,
    };

    this.handleOnChange = this.handleOnChange.bind(this);
    this.handleFilterChange = this.handleFilterChange.bind(this);
  }

  handleFilterChange = (res) => {
    res = Number(res);
    this.setState({ select: res });

    API2 = GET_DETAIL + "projectid=" + this.props.ID;

    if (this.state.activePill === "1") {
      API2 += "&investigation=2&";
    } else if (this.state.activePill === "2") {
      API2 += "&investigation=4&";
    } else if (this.state.activePill === "3") {
      API2 += "&investigation=5&";
    } else if (this.state.activePill === "4") {
      API2 += "&investigation=1&";
    } else if (this.state.activePill === "5") {
      API2 += "&investigation=6&";
    } else if (this.state.activePill === "6") {
      API2 += "&investigation=3&";
    }
    API2 += "floor=" + res;

    fetch(API2, {
      method: "GET",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: token,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (this.state.activePill === "1") {
          this.setState({ indexCount: res["crack"].length });
        } else if (this.state.activePill === "2") {
          this.setState({ indexCount: res["conc"].length });
        } else if (this.state.activePill === "3") {
          this.setState({ indexCount: res["iron"].length });
        } else if (this.state.activePill === "4") {
          this.setState({ indexCount: res["arc"].length });
        } else if (this.state.activePill === "5") {
          this.setState({ indexCount: res["slope"].length });
        } else if (this.state.activePill === "6") {
          this.setState({ indexCount: res["carbon"].length });
        }
      });
  };

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  togglePill(pill) {
    if (this.state.activePill !== pill) {
      this.setState({
        activePill: pill,
      });

      API2 = GET_DETAIL + "projectid=" + this.props.ID;
      if (pill === "1") {
        API2 += "&investigation=2&";
      } else if (pill === "2") {
        API2 += "&investigation=4&";
      } else if (pill === "3") {
        API2 += "&investigation=5&";
      } else if (pill === "4") {
        API2 += "&investigation=1&";
      } else if (pill === "5") {
        API2 += "&investigation=6&";
      } else if (pill === "6") {
        API2 += "&investigation=3&";
      }
      API2 += "floor=" + this.state.select;

      fetch(API2, {
        method: "GET",
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: token,
        },
      })
        .then((res) => res.json())
        .then((res) => {
          if (pill === "1") {
            this.setState({ indexCount: res["crack"].length });
          } else if (pill === "2") {
            this.setState({ indexCount: res["conc"].length });
          } else if (pill === "3") {
            this.setState({ indexCount: res["iron"].length });
          } else if (pill === "4") {
            this.setState({ indexCount: res["arc"].length });
          } else if (pill === "5") {
            this.setState({ indexCount: res["slope"].length });
          } else if (pill === "6") {
            this.setState({ indexCount: res["carbon"].length });
          }
        });
    }
  }

  toggleViewTab(tab) {
    if (this.state.activeViewTab !== tab) {
      this.setState({ activeViewTab: tab });
    }
  }

  componentDidMount() {
    for (let i = 0; i < prj_list.length; i++) {
      if (prj_list[i]["facilityid"] === this.state.facilityid) {
        this.setState({
          totalDOWN: prj_list[i].facilityfloordown,
          totalUP: prj_list[i].facilityfloorup,
          totalElevation: prj_list[i].facilityelevation,
        });
      }
    }

    if (this.state.facilityid !== "") {
      const formdata = new FormData();
      formdata.append("facilityid", this.state.facilityid);

      fetch(URL + "/project/prj_img_list", {
        method: "POST",
        headers: {
          Authorization: token,
        },
        body: formdata,
      })
        .then((res) => {
          if (res.status / 100 === 2) {
            return res.json();
          } else {
            alert(
              "알수없는 오류가 발생하였습니다. 새로고침 후 다시 시도해주세요."
            );
          }
        })
        .then((res) => {
          console.log(res);
          img_list = [];
          const response_arr = res.result;
          console.log(response_arr);

          for (let i = 0; i < response_arr.length; i++) {
            img.url = response_arr[i]["img"];
            img.floor = response_arr[i]["floor"];
            img_list.push(img);
            img = {
              url: "",
              floor: "",
            };
          }
          this.forceUpdate();
        });

      crack_arr = [];
      arc_arr = [];
      carbon_arr = [];
      iron_arr = [];
      conc_arr = [];
      slope_arr = [];
      equipment_arr = [];

      if (this.state.activePill === "1") {
        API = URL + "/project/crack_list?facilityid=" + this.state.facilityid;
      } else if (this.state.activePill === "2") {
        API = URL + "/project/arc_list?facilityid=" + this.state.facilityid;
      } else if (this.state.activePill === "3") {
        API = URL + "/project/conc_list?facilityid=" + this.state.facilityid;
      } else if (this.state.activePill === "4") {
        API = URL + "/project/iron_list?facilityid=" + this.state.facilityid;
      } else if (this.state.activePill === "5") {
        API = URL + "/project/carbon_list?facilityid=" + this.state.facilityid;
      } else if (this.state.activePill === "6") {
        API = URL + "/project/slope_list?facilityid=" + this.state.facilityid;
      } else if (this.state.activePill === "7") {
        API =
          URL + "/project/equipment_list?facilityid=" + this.state.facilityid;
      }

      fetch(API, {
        method: "GET",
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: token,
        },
      })
        .then((res) => {
          if (res.status / 100 === 2) {
            return res.json();
          } else {
            alert(
              "알수없는 오류가 발생하였습니다. 새로고침 후 다시 시도해주세요."
            );
          }
        })
        .then((res) => {
          if (API === URL + "/project/crack_list?facilityid=" + this.props.ID) {
            crack_arr = [];
            let count = 0;
            for (let i = 0; i < Object.keys(res["results"]).length; i++) {
              if (res["results"][i]["facilityid"] === result_prj) {
                let result = res["results"][i];

                this.setState({ count: res["count"] });
                this.setState({
                  crack_result: {
                    // ...this.state,
                    zone: result["zone"],
                    crack_size: result["crack_size"],
                    floor: result["floor"],
                    crack_box_size: result["crack_box_size"],
                    crack_length: result["crack_length"],
                    position: result["position"],
                    file_id: result["img"],
                    seq: result["seq"],
                    etc: result["etc"],
                    type: result["type"],
                    report: result["report"],
                    auto_analyze: result["auto_analyze"],
                    index: result["plan_coordinate"]["index"],
                  },
                });
                crack_arr.push(this.state.crack_result);

                this.forceUpdate();
              }
            }
          } else if (
            API ===
            URL + "/project/arc_list?facilityid=" + this.props.ID
          ) {
            arc_arr = [];
            for (let i = 0; i < Object.keys(res["results"]).length; i++) {
              if (res["results"][i]["facilityid"] === result_prj) {
                let result = res["results"][i];
                this.setState({ count: res["count"] });
                this.setState({
                  arc_result: {
                    ...this.state,
                    a_zone: result["zone"],
                    a_position: result["a_position"],
                    floor: result["floor"],
                    aw_size: result["aw_size"],
                    ah_size: result["ah_size"],
                    a_seq: result["seq"],
                    a_file_id: result["img"],
                    a_etc: result["a_etc"],
                    report: result["report"],
                    index: result["plan_coordinate"]["index"],
                  },
                });
                arc_arr.push(this.state.arc_result);
                // 이미지 추가필요
                this.forceUpdate();
              }
            }
          } else if (
            API ===
            URL + "/project/conc_list?facilityid=" + this.props.ID
          ) {
            conc_arr = [];
            for (let i = 0; i < Object.keys(res["results"]).length; i++) {
              if (res["results"][i]["facilityid"] === result_prj) {
                let result = res["results"][i];

                this.setState({ count: res["count"] });
                this.setState({
                  conc_result: {
                    ...this.state,
                    c_zone: result["zone"],
                    c_position: result["c_position"],
                    c_etc: result["c_etc"],
                    floor: result["floor"],
                    c_inspectAge: result["c_inspectAge"],
                    c_hitAngle: result["c_hitAngle"],
                    c_seq: result["seq"],
                    c_num1: result["c_num1"],
                    c_num2: result["c_num2"],
                    c_num3: result["c_num3"],
                    c_num4: result["c_num4"],
                    c_num5: result["c_num5"],
                    c_num6: result["c_num6"],
                    c_num7: result["c_num7"],
                    c_num8: result["c_num8"],
                    c_num9: result["c_num9"],
                    c_num10: result["c_num10"],
                    c_num11: result["c_num11"],
                    c_num12: result["c_num12"],
                    c_num13: result["c_num13"],
                    c_num14: result["c_num14"],
                    c_num15: result["c_num15"],
                    c_num16: result["c_num16"],
                    c_num17: result["c_num17"],
                    c_num18: result["c_num18"],
                    c_num19: result["c_num19"],
                    c_num20: result["c_num20"],
                    c_file_id: result["img"],
                    report: result["report"],
                    age_correction_factor: result["age_correction_factor"],
                    average: result["average"],
                    data_average: result["data_average"],
                    delta_r: result["delta_r"],
                    f1: result["f1"],
                    f2: result["f2"],
                    f3: result["f3"],
                    standard_hardness: result["standard_hardness"],
                    strength: result["strength"],
                    index: result["plan_coordinate"]["index"],
                    strength_updated: result["strength_updated"],
                  },
                });
                conc_arr.push(this.state.conc_result);
                // 이미지 추가필요
                this.forceUpdate();
              }
            }
          } else if (
            API ===
            URL + "/project/iron_list?facilityid=" + this.props.ID
          ) {
            iron_arr = [];
            for (let i = 0; i < Object.keys(res["results"]).length; i++) {
              if (res["results"][i]["facilityid"] === result_prj) {
                let result = res["results"][i];

                this.setState({ count: res["count"] });
                this.setState({
                  iron_result: {
                    ...this.state,
                    i_zone: result["zone"],
                    i_position: result["i_position"],
                    floor: result["floor"],
                    i_etc: result["i_etc"],
                    i_seq: result["seq"],
                    i_file_id: result["img"],
                    result_img: result["result_img"],
                    report: result["report"],
                    index: result["plan_coordinate"]["index"],
                  },
                });
                iron_arr.push(this.state.iron_result);
                // 이미지 추가필요
                this.forceUpdate();
              }
            }
          } else if (
            API ===
            URL + "/project/slope_list?facilityid=" + this.props.ID
          ) {
            slope_arr = [];
            for (let i = 0; i < Object.keys(res["results"]).length; i++) {
              if (res["results"][i]["facilityid"] === result_prj) {
                let result = res["results"][i];
                this.setState({ count: res["count"] });

                this.setState({
                  slope_result: {
                    ...this.state,
                    s_zone: result["zone"],
                    s_position: result["s_position"],
                    floor: result["floor"],
                    s_num1: result["s_num1"],
                    s_num2: result["s_num2"],
                    s_num3: result["s_num3"],
                    s_seq: result["seq"],
                    s_etc: result["s_etc"],
                    s_num4: result["s_num4"],
                    s_file_id: result["img"],
                    report: result["report"],
                    index: result["plan_coordinate"]["index"],
                  },
                });
                slope_arr.push(this.state.slope_result);
                // 이미지 추가필요
                this.forceUpdate();
              }
            }
          } else if (
            API ===
            URL + "/project/carbon_list?facilityid=" + this.props.ID
          ) {
            carbon_arr = [];
            for (let i = 0; i < Object.keys(res["results"]).length; i++) {
              if (res["results"][i]["facilityid"] === result_prj) {
                let result = res["results"][i];
                this.setState({ count: res["count"] });

                this.setState({
                  carbon_result: {
                    ...this.state,
                    ca_zone: result["zone"],
                    ca_position: result["ca_position"],
                    floor: result["floor"],
                    ca_num: result["ca_num"],
                    ca_seq: result["seq"],
                    ca_etc: result["ca_etc"],
                    ca_file_id: result["img"],
                    report: result["report"],
                    index: result["plan_coordinate"]["index"],
                  },
                });
                carbon_arr.push(this.state.carbon_result);
                this.forceUpdate();
              }
            }
          } else if (
            API ===
            URL + "/project/equipment_list?facilityid=" + this.props.ID
          ) {
            equipment_arr = [];
            for (let i = 0; i < Object.keys(res["results"]).length; i++) {
              if (res["results"][i]["facilityid"] === result_prj) {
                let result = res["results"][i];

                this.setState({
                  equipment_result: {
                    ...this.state,
                    e_id: result["id"],
                    e_name: result["name"],
                    e_subject: result["subject"],
                    e_method: result["method"],
                    e_file_id: result["img"],
                  },
                });
                equipment_arr.push(this.state.equipment_result);
                // 이미지 추가필요
                this.forceUpdate();
              }
            }
          }
        });
    }

    if (this.state.facilityid !== "") {
      API2 = GET_DETAIL + "projectid=" + this.state.facilityid;

      if (this.state.activePill === "1") {
        API2 += "&investigation=2&";
      }
      API2 += "floor=" + this.state.select;
      fetch(API2, {
        method: "GET",
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: token,
        },
      })
        .then((res) => res.json())
        .then((res) => {
          if (this.state.activePill === "1") {
            this.setState({ indexCount: res["crack"].length });
          } else if (this.state.activePill === "2") {
            this.setState({ indexCount: res["arc"].length });
          } else if (this.state.activePill === "3") {
            this.setState({ indexCount: res["conc"].length });
          } else if (this.state.activePill === "4") {
            this.setState({ indexCount: res["iron"].length });
          } else if (this.state.activePill === "5") {
            this.setState({ indexCount: res["carbon"].length });
          } else if (this.state.activePill === "6") {
            this.setState({ indexCount: res["slope"].length });
          }
        });
    }
  }

  handleOnChange = (e) => {
    this.forceUpdate();
    const newName = e.target.name;
    const curVal = e.target.defaultValue;
    this.setState({ [newName]: curVal });
    this.setState({ researchKind: curVal });
    crack_arr = [];
    arc_arr = [];
    carbon_arr = [];
    iron_arr = [];
    conc_arr = [];
    slope_arr = [];
    equipment_arr = [];
    img_list = [];

    if (curVal === "균열및외관조사") {
      API = URL + "/project/crack_list?facilityid=" + this.props.ID;
    } else if (curVal === "부재제원조사") {
      API = URL + "/project/arc_list?facilityid=" + this.props.ID;
    } else if (curVal === "콘크리트강도조사") {
      API = URL + "/project/conc_list?facilityid=" + this.props.ID;
    } else if (curVal === "철근배근조사") {
      API = URL + "/project/iron_list?facilityid=" + this.props.ID;
    } else if (curVal === "탄산화조사") {
      API = URL + "/project/carbon_list?facilityid=" + this.props.ID;
    } else if (curVal === "기울기조사") {
      API = URL + "/project/slope_list?facilityid=" + this.props.ID;
    } else if (curVal === "사용장비") {
      API = URL + "/project/equipment_list?facilityid=" + this.props.ID;
    }

    fetch(API, {
      method: "GET",
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        Authorization: token,
      },
    })
      .then((res) => {
        if (res.status / 100 === 2) {
          return res.json();
        } else {
          alert(
            "알수없는 오류가 발생하였습니다. 새로고침 후 다시 시도해주세요."
          );
        }
      })
      .then((res) => {
        this.setState({ facilityid: result_prj });
        const formdata = new FormData();
        formdata.append("facilityid", result_prj);

        fetch(URL + "/project/prj_img_list", {
          method: "POST",
          headers: {
            Authorization: token,
          },
          body: formdata,
        })
          .then((res) => {
            if (res.status / 100 === 2) {
              return res.json();
            } else {
              alert(
                "알수없는 오류가 발생하였습니다. 새로고침 후 다시 시도해주세요."
              );
            }
          })
          .then((res) => {
            const response_arr = res.result;

            for (let i = 0; i < response_arr.length; i++) {
              img.url = response_arr[i]["img"];
              img.floor = response_arr[i]["floor"];
              img_list.push(img);
              img = {
                url: "",
                floor: "",
              };
            }
            this.forceUpdate();
          });

        if (API === URL + "/project/crack_list?facilityid=" + this.props.ID) {
          crack_arr = [];
          let count = 0;
          for (let i = 0; i < Object.keys(res["results"]).length; i++) {
            if (res["results"][i]["facilityid"] === result_prj) {
              let result = res["results"][i];

              this.setState({ count: res["count"] });
              this.setState({
                crack_result: {
                  // ...this.state,
                  zone: result["zone"],
                  crack_size: result["crack_size"],
                  floor: result["floor"],
                  crack_box_size: result["crack_box_size"],
                  crack_length: result["crack_length"],
                  position: result["position"],
                  file_id: result["img"],
                  seq: result["seq"],
                  etc: result["etc"],
                  type: result["type"],
                  report: result["report"],
                  auto_analyze: result["auto_analyze"],
                  index: result["plan_coordinate"]["index"],
                },
              });
              crack_arr.push(this.state.crack_result);

              this.forceUpdate();
            }
          }
        } else if (
          API ===
          URL + "/project/arc_list?facilityid=" + this.props.ID
        ) {
          arc_arr = [];
          for (let i = 0; i < Object.keys(res["results"]).length; i++) {
            if (res["results"][i]["facilityid"] === result_prj) {
              let result = res["results"][i];
              this.setState({ count: res["count"] });
              this.setState({
                arc_result: {
                  ...this.state,
                  a_zone: result["zone"],
                  a_position: result["a_position"],
                  floor: result["floor"],
                  aw_size: result["aw_size"],
                  ah_size: result["ah_size"],
                  a_seq: result["seq"],
                  a_file_id: result["img"],
                  a_etc: result["a_etc"],
                  report: result["report"],
                  index: result["plan_coordinate"]["index"],
                },
              });
              arc_arr.push(this.state.arc_result);
              // 이미지 추가필요
              this.forceUpdate();
            }
          }
        } else if (
          API ===
          URL + "/project/conc_list?facilityid=" + this.props.ID
        ) {
          conc_arr = [];
          for (let i = 0; i < Object.keys(res["results"]).length; i++) {
            if (res["results"][i]["facilityid"] === result_prj) {
              let result = res["results"][i];

              this.setState({ count: res["count"] });
              this.setState({
                conc_result: {
                  ...this.state,
                  c_zone: result["zone"],
                  c_position: result["c_position"],
                  c_etc: result["c_etc"],
                  floor: result["floor"],
                  c_inspectAge: result["c_inspectAge"],
                  c_hitAngle: result["c_hitAngle"],
                  c_seq: result["seq"],
                  c_num1: result["c_num1"],
                  c_num2: result["c_num2"],
                  c_num3: result["c_num3"],
                  c_num4: result["c_num4"],
                  c_num5: result["c_num5"],
                  c_num6: result["c_num6"],
                  c_num7: result["c_num7"],
                  c_num8: result["c_num8"],
                  c_num9: result["c_num9"],
                  c_num10: result["c_num10"],
                  c_num11: result["c_num11"],
                  c_num12: result["c_num12"],
                  c_num13: result["c_num13"],
                  c_num14: result["c_num14"],
                  c_num15: result["c_num15"],
                  c_num16: result["c_num16"],
                  c_num17: result["c_num17"],
                  c_num18: result["c_num18"],
                  c_num19: result["c_num19"],
                  c_num20: result["c_num20"],
                  c_file_id: result["img"],
                  report: result["report"],
                  age_correction_factor: result["age_correction_factor"],
                  average: result["average"],
                  data_average: result["data_average"],
                  delta_r: result["delta_r"],
                  f1: result["f1"],
                  f2: result["f2"],
                  f3: result["f3"],
                  standard_hardness: result["standard_hardness"],
                  strength: result["strength"],
                  index: result["plan_coordinate"]["index"],
                  strength_updated: result["strength_updated"],
                },
              });
              conc_arr.push(this.state.conc_result);
              // 이미지 추가필요
              this.forceUpdate();
            }
          }
        } else if (
          API ===
          URL + "/project/iron_list?facilityid=" + this.props.ID
        ) {
          iron_arr = [];
          for (let i = 0; i < Object.keys(res["results"]).length; i++) {
            if (res["results"][i]["facilityid"] === result_prj) {
              let result = res["results"][i];

              this.setState({ count: res["count"] });
              this.setState({
                iron_result: {
                  ...this.state,
                  i_zone: result["zone"],
                  i_position: result["i_position"],
                  floor: result["floor"],
                  i_etc: result["i_etc"],
                  i_seq: result["seq"],
                  i_file_id: result["img"],
                  result_img: result["result_img"],
                  report: result["report"],
                  index: result["plan_coordinate"]["index"],
                },
              });
              iron_arr.push(this.state.iron_result);
              // 이미지 추가필요
              this.forceUpdate();
            }
          }
        } else if (
          API ===
          URL + "/project/slope_list?facilityid=" + this.props.ID
        ) {
          slope_arr = [];
          for (let i = 0; i < Object.keys(res["results"]).length; i++) {
            if (res["results"][i]["facilityid"] === result_prj) {
              let result = res["results"][i];
              this.setState({ count: res["count"] });

              this.setState({
                slope_result: {
                  ...this.state,
                  s_zone: result["zone"],
                  s_position: result["s_position"],
                  floor: result["floor"],
                  s_num1: result["s_num1"],
                  s_num2: result["s_num2"],
                  s_num3: result["s_num3"],
                  s_seq: result["seq"],
                  s_etc: result["s_etc"],
                  s_num4: result["s_num4"],
                  s_file_id: result["img"],
                  report: result["report"],
                  index: result["plan_coordinate"]["index"],
                },
              });
              slope_arr.push(this.state.slope_result);
              // 이미지 추가필요
              this.forceUpdate();
            }
          }
        } else if (
          API ===
          URL + "/project/carbon_list?facilityid=" + this.props.ID
        ) {
          carbon_arr = [];
          for (let i = 0; i < Object.keys(res["results"]).length; i++) {
            if (res["results"][i]["facilityid"] === result_prj) {
              let result = res["results"][i];
              this.setState({ count: res["count"] });

              this.setState({
                carbon_result: {
                  ...this.state,
                  ca_zone: result["zone"],
                  ca_position: result["ca_position"],
                  floor: result["floor"],
                  ca_num: result["ca_num"],
                  ca_seq: result["seq"],
                  ca_etc: result["ca_etc"],
                  ca_file_id: result["img"],
                  report: result["report"],
                  index: result["plan_coordinate"]["index"],
                },
              });
              carbon_arr.push(this.state.carbon_result);
              this.forceUpdate();
            }
          }
        } else if (
          API ===
          URL + "/project/equipment_list?facilityid=" + this.props.ID
        ) {
          equipment_arr = [];
          for (let i = 0; i < Object.keys(res["results"]).length; i++) {
            if (res["results"][i]["facilityid"] === result_prj) {
              let result = res["results"][i];

              this.setState({
                equipment_result: {
                  ...this.state,
                  e_id: result["id"],
                  e_name: result["name"],
                  e_subject: result["subject"],
                  e_method: result["method"],
                  e_file_id: result["img"],
                },
              });
              equipment_arr.push(this.state.equipment_result);
              // 이미지 추가필요
              this.forceUpdate();
            }
          }
        }
      });
  };

  render() {
    const { activePill } = this.state;

    return (
      <>
        {this.state.ID.length !== 0 ? (
          <div className={`forcedFrame col-sm-8 pe-0 ps-0`}>
            <div>
              <div className={`${styles.searchbar_wrap}`}>
                <Nav pills className={`${styles.nav_pills} mb-0`}>
                  <NavItem>
                    <NavLink
                      className={classnames("p-0", {
                        active: this.state.activePill === "1",
                      })}
                      onClick={() => {
                        this.togglePill("1");
                      }}
                      style={{
                        padding: "0 !important",
                      }}
                    >
                      <input
                        className={`form-check-input
                                  ${styles.fake_form_check_input}`}
                        type="radio"
                        onChange={this.handleOnChange}
                        id="균열및외관조사"
                        defaultValue="균열및외관조사"
                        name="researchKind"
                        style={{
                          borderRadius: "0",
                        }}
                      />
                      <label
                        className="form-check-label col-form-label p-10px  border-348fe2"
                        htmlFor="균열및외관조사"
                      >
                        균열 및 외관조사
                      </label>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames("p-0", {
                        active: this.state.activePill === "4",
                      })}
                      onClick={() => {
                        this.togglePill("4");
                      }}
                    >
                      <input
                        className={`form-check-input 
                        ${styles.fake_form_check_input}`}
                        type="radio"
                        onChange={this.handleOnChange}
                        id="부재제원조사"
                        defaultValue="부재제원조사"
                        name="researchKind"
                        style={{
                          borderRadius: "0",
                        }}
                      />
                      <label
                        className="form-check-label col-form-label p-10px  border-348fe2"
                        htmlFor="부재제원조사"
                      >
                        부재 제원 조사
                      </label>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames("p-0", {
                        active: this.state.activePill === "2",
                      })}
                      onClick={() => {
                        this.togglePill("2");
                      }}
                    >
                      <input
                        className={`form-check-input 
                        ${styles.fake_form_check_input}`}
                        type="radio"
                        onChange={this.handleOnChange}
                        id="콘크리트강도조사"
                        defaultValue="콘크리트강도조사"
                        name="researchKind"
                        style={{
                          borderRadius: "0",
                        }}
                      />
                      <label
                        className="form-check-label col-form-label p-10px  border-348fe2"
                        htmlFor="콘크리트강도조사"
                      >
                        콘크리트 강도조사
                      </label>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames("p-0", {
                        active: this.state.activePill === "3",
                      })}
                      onClick={() => {
                        this.togglePill("3");
                      }}
                    >
                      <input
                        className={`form-check-input 
                        ${styles.fake_form_check_input}`}
                        type="radio"
                        onChange={this.handleOnChange}
                        id="철근배근조사"
                        defaultValue="철근배근조사"
                        name="researchKind"
                        style={{
                          borderRadius: "0",
                        }}
                      />
                      <label
                        className="form-check-label col-form-label p-10px  border-348fe2"
                        htmlFor="철근배근조사"
                      >
                        철근 배근 조사
                      </label>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames("p-0", {
                        active: this.state.activePill === "6",
                      })}
                      onClick={() => {
                        this.togglePill("6");
                      }}
                    >
                      <input
                        className={`form-check-input 
                        ${styles.fake_form_check_input}`}
                        type="radio"
                        onChange={this.handleOnChange}
                        id="탄산화조사"
                        defaultValue="탄산화조사"
                        name="researchKind"
                        style={{
                          borderRadius: "0",
                        }}
                      />
                      <label
                        className="form-check-label col-form-label p-10px  border-348fe2"
                        htmlFor="탄산화조사"
                      >
                        탄산화 조사
                      </label>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames("p-0", {
                        active: this.state.activePill === "5",
                      })}
                      onClick={() => {
                        this.togglePill("5");
                      }}
                    >
                      <input
                        className={`form-check-input 
                        ${styles.fake_form_check_input}`}
                        type="radio"
                        onChange={this.handleOnChange}
                        id="기울기조사"
                        defaultValue="기울기조사"
                        name="researchKind"
                        style={{
                          borderRadius: "0",
                        }}
                      />
                      <label
                        className="form-check-label col-form-label p-10px  border-348fe2"
                        htmlFor="기울기조사"
                      >
                        기울기 조사
                      </label>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames("p-0", {
                        active: this.state.activePill === "7",
                      })}
                      onClick={() => {
                        this.togglePill("7");
                      }}
                    >
                      <input
                        className={`form-check-input 
                        ${styles.fake_form_check_input}`}
                        type="radio"
                        onChange={this.handleOnChange}
                        id="사용장비"
                        defaultValue="사용장비"
                        name="researchKind"
                        style={{
                          borderRadius: "0",
                        }}
                      />
                      <label
                        className="form-check-label col-form-label p-10px  border-348fe2"
                        htmlFor="사용장비"
                      >
                        사용 장비
                      </label>
                    </NavLink>
                  </NavItem>
                </Nav>
              </div>

              <TabContent
                className="rounded-top panel mb-0 rounded-0 mt-3"
                activeTab={activePill}
              >
                {this.state.activePill !== "7" ? (
                  <>
                    <div className=" d-flex justify-content-between">
                      <SelectOptionWrap
                        searchBuilding={this.state.facilityid}
                        filter={this.handleFilterChange}
                      />
                      <div style={{ alignSelf: "center" }}>
                        총 {this.state.indexCount}/
                        {this.state.activePill === "1"
                          ? crack_arr.length
                          : this.state.activePill === "2"
                          ? conc_arr.length
                          : this.state.activePill === "3"
                          ? iron_arr.length
                          : this.state.activePill === "4"
                          ? arc_arr.length
                          : this.state.activePill === "5"
                          ? slope_arr.length
                          : this.state.activePill === "6"
                          ? carbon_arr.length
                          : "0"}
                        개의 조사 결과
                      </div>
                    </div>

                    {img_list.length !== 0 ? (
                      img_list.map((item, i) => {
                        console.log(item, i)
                        return this.state.select === img_list[i].floor ? (
                          <ResearchCard
                            key={i}
                            url={item.url}
                            floor={item.floor}
                            select={this.state.select}
                            activePill={this.state.activePill}
                            facilityid={this.state.facilityid}
                          />
                        ) : (
                          ""
                        );
                      })
                    ) : (
                      <PlanResultNone />
                    )}
                    <Nav tabs>
                      <NavItem
                        className={`${this.state.activeViewTab === "10"} `}
                        onClick={() => {
                          this.toggleViewTab("10");
                        }}
                      >
                        <NavLink>카드로 보기</NavLink>
                      </NavItem>
                      <NavItem
                        className={`${this.state.activeViewTab === "20"} `}
                        onClick={() => {
                          this.toggleViewTab("20");
                        }}
                      >
                        <NavLink>표로 보기</NavLink>
                      </NavItem>
                    </Nav>
                  </>
                ) : (
                  ""
                )}

                <TabContent activeviewtab={this.state.activeViewTab}>
                  <TabPane
                    tabId="10"
                    className={classnames("p-0", {
                      active: this.state.activeViewTab === "10",
                    })}
                  >
                    {this.state.activePill === "1" ? (
                      <TabPane tabId="1" className="position-relative">
                        <Card className="rounded-0 border-0 mb-15px" key={1}>
                          <InformationFloorPlan
                            activePill={activePill}
                            facilityid={this.state.ID}
                            totalUP={totalUP}
                            totalDOWN={totalDOWN}
                            totalElevation={totalElevation}
                            select={this.state.select}
                          />
                        </Card>
                      </TabPane>
                    ) : null}
                    {this.state.activePill === "2" ? (
                      <TabPane tabId="2" className="position-relative">
                        <Card className="rounded-0 border-0 mb-15px" key={1}>
                          <InformationFloorPlan
                            activePill={activePill}
                            facilityid={this.state.ID}
                            totalUP={totalUP}
                            totalDOWN={totalDOWN}
                            totalElevation={totalElevation}
                            select={this.state.select}
                          />
                        </Card>
                      </TabPane>
                    ) : null}
                    {this.state.activePill === "3" ? (
                      <TabPane tabId="3" className="position-relative">
                        <Card className="rounded-0 border-0 mb-15px" key={1}>
                          <InformationFloorPlan
                            activePill={activePill}
                            facilityid={this.state.ID}
                            totalUP={totalUP}
                            totalDOWN={totalDOWN}
                            totalElevation={totalElevation}
                            select={this.state.select}
                          />
                        </Card>
                      </TabPane>
                    ) : null}
                    {this.state.activePill === "4" ? (
                      <TabPane tabId="4" className="position-relative">
                        <Card className="rounded-0 border-0 mb-15px" key={1}>
                          <InformationFloorPlan
                            activePill={activePill}
                            facilityid={this.state.ID}
                            totalUP={totalUP}
                            totalDOWN={totalDOWN}
                            totalElevation={totalElevation}
                            select={this.state.select}
                          />
                        </Card>
                      </TabPane>
                    ) : null}
                    {this.state.activePill === "5" ? (
                      <TabPane tabId="5" className="position-relative">
                        <Card className="rounded-0 border-0 mb-15px" key={1}>
                          <InformationFloorPlan
                            activePill={activePill}
                            facilityid={this.state.ID}
                            totalUP={totalUP}
                            totalDOWN={totalDOWN}
                            totalElevation={totalElevation}
                            select={this.state.select}
                          />
                        </Card>
                      </TabPane>
                    ) : null}
                    {this.state.activePill === "6" ? (
                      <TabPane tabId="6" className="position-relative">
                        <Card className="rounded-0 border-0 mb-15px" key={1}>
                          <InformationFloorPlan
                            activePill={activePill}
                            facilityid={this.state.ID}
                            totalUP={totalUP}
                            totalDOWN={totalDOWN}
                            totalElevation={totalElevation}
                            select={this.state.select}
                          />
                        </Card>
                      </TabPane>
                    ) : null}
                  </TabPane>
                  <TabPane
                    tabId="20"
                    className={classnames("p-0", {
                      active: this.state.activeViewTab === "20",
                    })}
                  >
                    {this.state.activePill === "1" ? (
                      <table className="table ">
                        <thead>
                          <tr>
                            <th>No.</th>
                            <th>층</th>
                            <th>구역</th>
                            <th>조사 위치</th>
                            <th>균열 종류</th>
                            <th>균열 폭 (mm)</th>
                            <th>균열 길이 (mm)</th>
                            <th>균열 면적 (mm)</th>
                            <th>비고</th>
                          </tr>
                        </thead>
                        <tbody>
                          <InformationTable
                            activePill={activePill}
                            facilityid={this.state.ID}
                            totalUP={totalUP}
                            totalDOWN={totalDOWN}
                            totalElevation={totalElevation}
                            select={this.state.select}
                          />
                        </tbody>
                      </table>
                    ) : null}
                    {this.state.activePill === "2" ? (
                      <table className="table ">
                        <thead>
                          <tr>
                            <th>No.</th>
                            <th>층</th>
                            <th>구역</th>
                            <th>위치</th>
                            <th>추정압축강도(MPa)</th>
                            <th>비고</th>
                          </tr>
                        </thead>
                        <tbody>
                          <InformationTable
                            activePill={activePill}
                            facilityid={this.state.ID}
                            totalUP={totalUP}
                            totalDOWN={totalDOWN}
                            totalElevation={totalElevation}
                            select={this.state.select}
                          />
                        </tbody>
                      </table>
                    ) : null}
                    {this.state.activePill === "3" ? (
                      <table className="table ">
                        <thead>
                          <tr>
                            <th>No.</th>
                            <th>층</th>
                            <th>구역</th>
                            <th>조사 위치</th>
                            <th>비고</th>
                          </tr>
                        </thead>
                        <tbody>
                          <InformationTable
                            activePill={activePill}
                            facilityid={this.state.ID}
                            totalUP={totalUP}
                            totalDOWN={totalDOWN}
                            totalElevation={totalElevation}
                            select={this.state.select}
                          />
                        </tbody>
                      </table>
                    ) : null}
                    {this.state.activePill === "4" ? (
                      <table className="table ">
                        <thead>
                          <tr>
                            <th>No.</th>
                            <th>층</th>
                            <th>구역</th>
                            <th>조사 위치</th>
                            <th>너비 (mm)</th>
                            <th>높이 (mm)</th>
                            <th>비고</th>
                          </tr>
                        </thead>
                        <tbody>
                          <InformationTable
                            activePill={activePill}
                            facilityid={this.state.ID}
                            totalUP={totalUP}
                            totalDOWN={totalDOWN}
                            totalElevation={totalElevation}
                            select={this.state.select}
                          />
                        </tbody>
                      </table>
                    ) : null}
                    {this.state.activePill === "5" ? (
                      <table className="table ">
                        <thead>
                          <tr>
                            <th>No.</th>
                            <th>층</th>
                            <th>구역</th>
                            <th>조사 위치</th>
                            <th>좌측상단 ( &deg; )</th>
                            <th>우측상단 ( &deg; )</th>
                            <th>좌측하단 ( &deg; )</th>
                            <th>우측하단 ( &deg; )</th>
                            <th>비고</th>
                          </tr>
                        </thead>
                        <tbody>
                          <InformationTable
                            activePill={activePill}
                            facilityid={this.state.ID}
                            totalUP={totalUP}
                            totalDOWN={totalDOWN}
                            totalElevation={totalElevation}
                            select={this.state.select}
                          />
                        </tbody>
                      </table>
                    ) : null}
                    {this.state.activePill === "6" ? (
                      <table className="table ">
                        <thead>
                          <tr>
                            <th>No.</th>
                            <th>층</th>
                            <th>구역</th>
                            <th>조사 위치</th>
                            <th>측정값 (mm)</th>
                            <th>비고</th>
                          </tr>
                        </thead>
                        <tbody>
                          <InformationTable
                            activePill={activePill}
                            facilityid={this.state.ID}
                            totalUP={totalUP}
                            totalDOWN={totalDOWN}
                            totalElevation={totalElevation}
                            select={this.state.select}
                          />
                        </tbody>
                      </table>
                    ) : null}
                  </TabPane>
                </TabContent>
                <TabPane tabId="7" className="position-relative">
                  <Card className="rounded-0 border-0 mb-15px" key={1}>
                    <InformationFloorPlan
                      key={activePill + this.state.facilityid}
                      activePill={activePill}
                      facilityid={this.state.facilityid}
                    />
                  </Card>
                </TabPane>
              </TabContent>
            </div>
          </div>
        ) : (
          <div className="forcedFrame col-sm-8 pe-0 ps-0">
            <img src={guide} style={{ width: "100%" }} alt="guide" />
          </div>
        )}
      </>
    );
  }
}

export {
  Information,
  API,
  crack_arr,
  arc_arr,
  conc_arr,
  iron_arr,
  carbon_arr,
  slope_arr,
  equipment_arr,
};
