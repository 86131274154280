import React, { useState } from "react";
import { Panel, PanelBody } from "../../components/panel/panel.jsx";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import "@ckeditor/ckeditor5-build-classic/build/translations/ko";

const URL = process.env.REACT_APP_BACKEND_HOST;
const WRITE = URL + "/boards/create/support";
const token = "bearer " + sessionStorage.getItem("token");

function CsAskWrite() {
  const [input, setInput] = useState({
    title: "",
    content: "",
  });

  const handleInput = (name, value) => {
    setInput({
      ...input,
      [name]: value,
    });
  };
  const submit = (e) => {
    const formData = new FormData();

    if (input.title !== "" && input.content !== "") {
      formData.append("title", input.title);
      formData.append("tags", 2);
      formData.append("content", input.content);

      fetch(WRITE, {
        method: "POST",
        headers: {
          Authorization: token,
        },
        body: formData,
      })
        .then((res) => {
          if (res.status / 100 === 2) {
            return res.json();
          } else {
            alert(
              "알수없는 오류가 발생하였습니다. 새로고침 후 다시 시도해주세요."
            );
          }
        })
        .then((res) => {
          alert("문의가 접수되었습니다.");
          window.location.replace("/cs/ask");
        });
    } else {
      alert("내용을 입력하세요.");
    }
  };
  return (
    <Panel className="col-lg-7 col-md-9 m-auto">
      <PanelBody>
        <div className="mb-3">
          <label
            className="form-label col-form-label text-blue-700"
            style={{ fontSize: "2rem", fontWeight: "normal" }}
          >
            문의글 작성하기
          </label>
        </div>
        <div className="row">
          <label htmlFor=" 제목" className="col-1 pt-2">
            제목
          </label>
          <input
            type="text"
            placeholder="제목을 입력하세요."
            style={{ width: "100%" }}
            className="p-2 col"
            value={input.title}
            onChange={(e) => handleInput("title", e.target.value)}
          />
        </div>
        <div className="mt-3 row">
          <label htmlFor="내용" className="col-1">
            내용
          </label>
          <CKEditor
            className="mb-5"
            config={{
              language: "ko",
            }}
            editor={ClassicEditor}
            // data="<p>내용을 작성하세요.</p>"
            placeholder="내용을 입력하세요."
            onReady={(editor) => {
              editor.editing.view.change((writer) => {
                writer.setStyle(
                  "height",
                  "400px",
                  editor.editing.view.document.getRoot()
                );
              });
            }}
            value={input.content}
            onChange={(event, editor) => {
              const data = editor.getData();
              handleInput("content", data);
            }}
            onBlur={(event, editor) => {
              // console.log("Blur.", editor);
            }}
            onFocus={(event, editor) => {
              // console.log("Focus.", editor);
            }}
          />
        </div>

        <button
          type="button"
          onClick={submit}
          className="btn btn-primary float-end mt-5px"
        >
          등록하기
        </button>
      </PanelBody>
    </Panel>
  );
}

export { CsAskWrite };
