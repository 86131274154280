import React from "react";
class Filter extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <>
        <option
          value={
            this.props.downFloor
              ? "-" + this.props.downFloor
              : (this.props.upFloor ? this.props.upFloor : this.props.totalElevation)
          }
        >
          {this.props.downFloor
            ? "지하 " + this.props.downFloor + " 층"
            : (this.props.upFloor ? "지상 " + this.props.upFloor + " 층" : 
                this.props.totalElevation === 999 && "정면도" ||
                this.props.totalElevation === 998 && "우측면도" ||
                this.props.totalElevation === 997 && "배면도" ||
                this.props.totalElevation === 996 && "좌측면도"
              )
          }
        </option>
      </>
    );
  }
}

export { Filter };
