// context.js
import { createContext, useContext, useState } from "react";

export const ResultContext = createContext(undefined);

// createContext 선언

export function ResultContextProvider({ children }) {
  const [result, setResult] = useState([]); ////글로벌하게 관리할 state
  const [detailFacilityId, setDetailFacilityId] = useState([]);
  const [returnPrjName, setReturnPrjName] = useState([]);
  const [returnPrjAddress, setReturnPrjAddress] = useState([]);
  const [returnPrjDivide, setReturnPrjDivide] = useState([]);

  const value = {
    result,
    setResult,
    detailFacilityId,
    setDetailFacilityId,
    returnPrjName,
    setReturnPrjName,
    returnPrjAddress,
    setReturnPrjAddress,
    returnPrjDivide,
    setReturnPrjDivide,
  };

  sessionStorage.setItem("ID", detailFacilityId);

  return (
    <ResultContext.Provider value={value}>{children}</ResultContext.Provider>
  );
}

export function useResultContext() {
  return useContext(ResultContext);
} //다른 컴포넌트에서 context를 사용할 때 쓰임.
