import React from "react";
import { AppSettings } from "./config/app-settings.js";

import Header from "./components/header/header.jsx";
import TopMenu from "./components/top-menu/top-menu.jsx";
import Content from "./components/content/setTitle.jsx";
import { TotalWrap } from "./components/wrap/TotalWrap.js";
import { Switch } from "react-router-dom";
import PublicRoute from "./components/lib/publicRoute";
import PrivateRoute from "./components/lib/privateRoute";
import Home from "./pages/home/home.js";
import { CsMap } from "./pages/cs/cs-map.js";
import { CsFaq } from "./pages/cs/cs-faq.js";
import { CsFaqWrite } from "./pages/cs/cs-faq-write.js";
import { CsFaqEdit } from "./pages/cs/cs-faq-edit.js";
import { CsFaqView } from "./pages/cs/cs-faq-view.js";
import { CsAskAdmin } from "./pages/cs/cs-ask-admin.js";
import { CsWrite } from "./pages/cs/cs-write";
import { CsAsk } from "./pages/cs/cs-ask.js";
import { CsAskView } from "./pages/cs/cs-ask-view.js";
import { CsAskWrite } from "./pages/cs/cs-ask-write.js";
import { CsAskEdit } from "./pages/cs/cs-ask-edit.js";
import { CsNotice } from "./pages/cs/cs-notice.js";
import { CsNoticeView } from "./pages/cs/cs-notice-view.js";
import { CsNoticeWrite } from "./pages/cs/cs-notice-write.js";
import { CsNoticeEdit } from "./pages/cs/cs-notice-edit.js";

import { Login } from "./pages/user/login.js";
import Register from "./pages/user/register.js";
import MyInfo from "./pages/user/myInfo.js";
import Supervision from "./pages/myPage/supervision.js";
import { ResultContextProvider } from "./Contexts/LayoutContext";
import Write from "./pages/fms/write.js";
import ManualWrite from "./pages/fms/manualwrite.js";

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      appsearch: false,
    };

    this.handleSetAppSidebarNone = (value) => {
      this.setState((state) => ({
        appSidebarNone: value,
      }));
    };

    this.handleAppSidebarFloatSubMenuClick = () => {
      if (this.state.appSidebarMinify) {
        const windowHeight = window.innerHeight;
        const targetHeight = document.getElementById(
          "app-sidebar-float-submenu"
        ).offsetHeight;
        const targetTop = this.state.appSidebarFloatSubMenuOffset.top;
        const top =
          windowHeight - targetTop > targetHeight ? targetTop : "auto";
        const left =
          this.state.appSidebarFloatSubMenuOffset.left +
          document.getElementById("sidebar").offsetWidth +
          "px";
        const bottom = windowHeight - targetTop > targetHeight ? "auto" : "0";
        const arrowTop =
          windowHeight - targetTop > targetHeight ? "20px" : "auto";
        const arrowBottom =
          windowHeight - targetTop > targetHeight
            ? "auto"
            : windowHeight - targetTop - 21 + "px";
        const lineTop =
          windowHeight - targetTop > targetHeight ? "20px" : "auto";
        const lineBottom =
          windowHeight - targetTop > targetHeight
            ? "auto"
            : windowHeight - targetTop - 21 + "px";

        this.setState((state) => ({
          appSidebarFloatSubMenuTop: top,
          appSidebarFloatSubMenuLeft: left,
          appSidebarFloatSubMenuBottom: bottom,
          appSidebarFloatSubMenuLineTop: lineTop,
          appSidebarFloatSubMenuLineBottom: lineBottom,
          appSidebarFloatSubMenuArrowTop: arrowTop,
          appSidebarFloatSubMenuArrowBottom: arrowBottom,
        }));
      }
    };

    this.handleSetAppContentClass = (value) => {
      this.setState((state) => ({
        appContentClass: value,
      }));
    };

    this.handleSetAppHeaderNone = (value) => {
      this.setState((state) => ({
        appHeaderNone: value,
      }));
    };

    this.state = {
      handleSetAppHeaderNone: this.handleSetAppHeaderNone,
      handleSetAppSidebarNone: this.handleSetAppSidebarNone,
      appSidebarFloatSubMenu: "",
      handleSetAppContentClass: this.handleSetAppContentClass,
    };
  }

  render() {
    return (
      <AppSettings.Provider value={this.state}>
        <ResultContextProvider>
          <div
            className="app"
            style={{
              minWidth: "900px",
            }}
          >
            {!this.state.appHeaderNone && <Header />}
            {!this.state.appSidebarNone && <TopMenu />}
            {!this.state.appContentNone && <Content />}
          </div>
          <Switch>
            <PublicRoute
              restricted={false}
              component={Home}
              path="/home"
              exact
            />
            <PublicRoute restricted={true} component={Login} path="/login" />
            <PublicRoute component={Register} path="/user/register" exact />
            <PublicRoute
              restricted={false}
              component={CsMap}
              path="/cs/map"
              exact
            />

            <PublicRoute exact path="/faqBoard/:no" component={CsFaqView} />
            <PublicRoute exact path="/faqOADMCEdit/:no" component={CsFaqEdit} />
            <PublicRoute
              restricted={false}
              component={CsFaq}
              path="/cs/faq"
              exact
            />
            <PublicRoute
              restricted={false}
              component={CsFaqWrite}
              path="/cs/faq-write"
              exact
            />

            <PublicRoute exact path="/askBoard/:no" component={CsAskView} />
            <PublicRoute exact path="/askEdit/:no" component={CsAskEdit} />
            <PublicRoute component={CsAskAdmin} path="/ask-OADMCW/:no" exact />
            <PublicRoute
              restricted={false}
              component={CsAsk}
              path="/cs/ask"
              exact
            />
            <PublicRoute
              restricted={false}
              component={CsAskWrite}
              path="/cs/ask-write"
              exact
            />

            <PublicRoute
              exact
              path="/noticeBoard/:no"
              component={CsNoticeView}
            />
            <PublicRoute
              exact
              path="/noticeOADMEdit/:no"
              component={CsNoticeEdit}
            />
            <PublicRoute
              restricted={true}
              component={CsNotice}
              path="/cs/notice"
              exact
            />
            <PublicRoute
              restricted={false}
              component={CsNoticeWrite}
              path="/cs/notice-write"
              exact
            />
            <PrivateRoute component={CsWrite} path="/cs/write" exact />
            <PrivateRoute component={MyInfo} path="/myPage/myInfo" exact />
            <PrivateRoute
              component={Supervision}
              path="/myPage/supervision"
              exact
            />
            <PrivateRoute component={Write} path="/fms/write" exact />

            <PrivateRoute
              component={ManualWrite}
              path="/fms/manualwrite"
              exact
            />
            <TotalWrap />
          </Switch>
        </ResultContextProvider>
      </AppSettings.Provider>
    );
  }
}

export default App;
