import React from "react";
import { Route } from "react-router-dom";
import { Redirect } from "react-router";

const PublicRoute = ({ component: Component, restricted, ...rest }) => {
  const token = localStorage.getItem("token");
  return (
    <Route
      {...rest}
      render={props =>
        token === !null && restricted ? (
          <Redirect to="/home" />
        ) : (
          <Component {...props} />
        )
      }
    />
  );
};

export default PublicRoute;
