import React, { useState } from "react";
import { Panel, PanelBody } from "../../components/panel/panel.jsx";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import "@ckeditor/ckeditor5-build-classic/build/translations/ko";

const URL = process.env.REACT_APP_BACKEND_HOST;
const WRITE = URL + "/boards/update/notice";
const token = "bearer " + sessionStorage.getItem("token");

const CsFaqEdit = ({ match, location }) => {
  const [input, setInput] = useState({
    title: location.state.title,
    content: location.state.content,
  });

  const handleInput = (name, value) => {
    setInput({
      ...input,
      [name]: value,
    });
  };

  const submit = (e) => {
    const formData = new FormData();

    if (input.title !== "" && input.content !== "") {
      formData.append("title", input.title);
      formData.append("tags", 2);
      formData.append("content", input.content);
      formData.append("id", match.params.no);

      fetch(WRITE, {
        method: "PATCH",
        headers: {
          Authorization: token,
        },
        body: formData,
      }).then((res) => {
        res.json();
        if (Math.floor(res.status / 100) === 2) {
          alert("자주하는 질문이 수정 되었습니다.");
          window.location.replace("/cs/faq");
        } else {
          alert(
            "알수없는 오류가 발생하였습니다. 새로고침 후 다시 시도해주세요."
          );
        }
      });
    }
  };
  return (
    <div className="row mb-3 col-lg-7 col-md-10 m-auto">
      <Panel>
        <PanelBody>
          <div className="row">
            <label htmlFor=" 제목" className="col-1 pt-2">
              제목
            </label>
            <input
              type="text"
              placeholder="제목을 입력하세요."
              style={{ width: "100%" }}
              className="p-2 col"
              value={input.title}
              onChange={(e) => handleInput("title", e.target.value)}
            />
          </div>
          <CKEditor
            className="mb-5"
            config={{
              language: "ko",
            }}
            editor={ClassicEditor}
            onReady={(editor) => {
              editor.editing.view.change((writer) => {
                writer.setStyle(
                  "height",
                  "400px",
                  editor.editing.view.document.getRoot()
                );
              });
            }}
            data={input.content}
            onChange={(event, editor) => {
              const data = editor.getData();
              handleInput("content", data);
            }}
            onBlur={(event, editor) => {
              // console.log("Blur.", editor);
            }}
            onFocus={(event, editor) => {
              // console.log("Focus.", editor);
            }}
          />
          <button
            type="button"
            onClick={submit}
            className="btn btn-primary float-end mt-5px"
          >
            수정하기
          </button>
        </PanelBody>
      </Panel>
    </div>
  );
};

export { CsFaqEdit };
